const statusesMap = {
  SHOW_MINE_CALLS: 'responsibleId',
  SHOW_NOT_ASSIGNED_RESP_CALLS: 'noResponsible',
  SHOW_MISSED_CALLS: 'missed',
};

const directionMap = {
  SHOW_IN_CALLS: 'inbound',
  SHOW_OUT_CALLS: 'outbound',
};

const sortOrderByType = {
  SHOW_OLD_CALLS: 'asc',
  SHOW_NEW_CALLS: 'desc',
  SHOW_OLDEST_UPDATED_CALLS: 'asc',
  SHOW_RECENT_UPDATED_CALLS: 'desc',
};

const callsParamsAdapter = ({
  offset,
  limit,
  search = '',
  contentSearchMode,
  filterStatus,
  filterChannels,
  sortBy,
  filterResponsibleUsers,
}) => {
  let filteredByStatus;
  let direction;
  let event;

  if (
    filterStatus === 'SHOW_MINE_CALLS' ||
    filterStatus === 'SHOW_NOT_ASSIGNED_RESP_CALLS'
  ) {
    filteredByStatus = statusesMap[filterStatus];
  } else if (
    filterStatus === 'SHOW_IN_CALLS' ||
    filterStatus === 'SHOW_OUT_CALLS'
  ) {
    direction = directionMap[filterStatus];
  } else if (filterStatus === 'SHOW_MISSED_CALLS') {
    event = 'call_missed';
  }

  return {
    ...(offset !== undefined ? { offset } : {}),
    ...(limit !== undefined ? { limit } : {}),
    search,
    ...(search && contentSearchMode ? { contentSearchMode } : {}),
    filter: JSON.stringify({
      channelId: filterChannels,
      ...(filterResponsibleUsers
        ? { responsibleIds: filterResponsibleUsers }
        : {}),
    }),
    ...(filteredByStatus ? { filteredByStatus } : {}),
    ...(direction ? { direction } : {}),
    ...(event ? { event } : {}),
    sort: JSON.stringify({ type: sortBy, order: sortOrderByType[sortBy] }),
    origin: 'pbx',
  };
};

export default callsParamsAdapter;
