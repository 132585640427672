import React, { createRef, PureComponent } from 'react';
import PropTypes from 'prop-types';
// import { Button } from 'antd-mobile';
import secureIframeLinks from '../../utils/secureIframeLinks';
import { withTranslation } from 'react-i18next';
import './IframeWrapper.css';

class IframeWrapper extends PureComponent {
  static propTypes = {
    src: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    withPreviewOverlay: PropTypes.bool,
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      contentLoading: true,
      height: null,
      collapsed: false,
    };
    this.iframeRef = createRef();
  }

  componentDidMount() {
    setTimeout(this.handleIframeLoad, 4000);
  }

  toggleCollapse = () => {
    this.setState({ collapsed: !this.state.collapsed });
  };

  setIframeHeightOnBaseOfContent = () => {
    const iframeEl = this.iframeRef.current;

    if (
      !iframeEl ||
      !iframeEl.contentWindow ||
      !iframeEl.contentWindow.document
    ) {
      // element may disappear during virtualized list scroll
      return;
    }

    if (
      this.state.height !==
      iframeEl.contentWindow.document.documentElement.scrollHeight
    ) {
      this.setState({
        height: iframeEl.contentWindow.document.documentElement.scrollHeight,
        ...(iframeEl.contentWindow.document.documentElement.scrollHeight <= 100
          ? { collapsed: true }
          : {}),
      });
    }
  };

  handleIframeLoad = () => {
    if (this.state.contentLoading) {
      this.setState({ contentLoading: false });

      const iframeEl = this.iframeRef.current;

      if (iframeEl) {
        secureIframeLinks(iframeEl);

        // we need to wait until iframe is fully rendered
        // TODO -> remove timeout
        setTimeout(this.setIframeHeightOnBaseOfContent, 50);
      }
    }
  };

  determineIframeHeight = () => {
    const { withPreviewOverlay = false } = this.props;
    const { height, collapsed } = this.state;

    if (withPreviewOverlay) {
      return collapsed && height ? height : 60;
    }

    if (height) {
      return height;
    }

    return 0;
  };

  render() {
    const {
      src = '',
      title = '',
      type = 'link',
      withPreviewOverlay = false,
    } = this.props;
    const { collapsed } = this.state;

    const iframeHeight = this.determineIframeHeight();

    const emptyHtml = `<p class="md-block-unstyled" style="margin: 0px !important;"><br></p>`;
    return (
      <div className="iframe-wrapper">
        <iframe
          title={title}
          className={`iframe-wrapper__content ${
            src.trim() === emptyHtml ? 'iframe-wrapper__content--empty' : ''
          }`}
          sandbox="allow-same-origin allow-popups"
          ref={this.iframeRef}
          {...(iframeHeight ? { height: iframeHeight } : {})}
          {...(type === 'document' ? { onLoad: this.handleIframeLoad } : {})}
          {...(type === 'link'
            ? { src }
            : {
                srcDoc: `<html><body style="margin: 0; overflow-y: hidden;">${src}</head><body>`,
              })}
        />
        {withPreviewOverlay && (
          <div
            className={`iframe-wrapper__preview-overlay ${
              collapsed ? 'iframe-wrapper__preview-overlay--hidden' : ''
            }`}
            onClick={this.toggleCollapse}
          >
            <spna className="iframe-wrapper__preview-collapse-btn">
              {this.props.t('iframeWrapper.showText')}
            </spna>
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation()(IframeWrapper);
