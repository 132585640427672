/* eslint-disable no-underscore-dangle  */
import * as R from 'ramda';
import { createSelector } from 'reselect';

export const RECIEVE_CHATRA_ACCOUNTS = 'RECIEVE_CHATRA_ACCOUNTS';
export const RECIEVE_CHATRA_ACCOUNT = 'RECIEVE_CHATRA_ACCOUNT';
export const DELETE_CHATRA_ACCOUNT = 'DELETE_CHATRA_ACCOUNT';
export const UPDATE_CHATRA_ACCOUNT = 'UPDATE_CHATRA_ACCOUNT';
export const ENABLE_CHATRA_ACCOUNT = 'ENABLE_CHATRA_ACCOUNT';
export const DISABLE_CHATRA_ACCOUNT = 'DISABLE_CHATRA_ACCOUNT';
export const SET_NAME = 'SET_NAME';
export const SET_PUBLIC_KEY = 'SET_PUBLIC_KEY';
export const SET_SECRET_KEY = 'SET_SECRET_KEY';
export const SET_MATCH = 'SET_MATCH';
export const SET_MATCH_ROBOT = 'SET_MATCH_ROBOT';
export const DELETE_CHATRA_ACCOUNT_DRAFT = 'DELETE_CHATRA_ACCOUNT_DRAFT';

function chatraAccountFactory(account) {
  return { [account._id]: account };
}

function chatraAccountsReducer(state = {}, action = { type: '' }) {
  switch (action.type) {
    case RECIEVE_CHATRA_ACCOUNTS: {
      return R.mergeAll(
        R.map(chatraAccountFactory, action.payload.chatraAccounts),
      );
    }
    case RECIEVE_CHATRA_ACCOUNT: {
      return { ...state, ...action.payload.chatraAccount };
    }
    case UPDATE_CHATRA_ACCOUNT: {
      return {
        ...state,
        ...(action.payload.publicKey
          ? { publicKey: action.payload.publicKey }
          : {}),
        ...(action.payload.secretKey
          ? { secretKey: action.payload.secretKey }
          : {}),
        ...(action.payload.name ? { name: action.payload.name } : {}),
        ...(action.payload.match ? { match: action.payload.match } : {}),
        ...(action.payload.matchRobot
          ? { matchRobot: action.payload.matchRobot }
          : {}),
      };
    }
    case DISABLE_CHATRA_ACCOUNT: {
      return { ...state, isEnabled: false };
    }
    case ENABLE_CHATRA_ACCOUNT: {
      return { ...state, isEnabled: true };
    }
    case SET_NAME: {
      return { ...state, name: action.payload.name };
    }
    case SET_PUBLIC_KEY: {
      return { ...state, publicKey: action.payload.publicKey };
    }
    case SET_SECRET_KEY: {
      return { ...state, secretKey: action.payload.secretKey };
    }
    case SET_MATCH_ROBOT: {
      return { ...state, matchRobot: action.payload.matchRobot };
    }
    case SET_MATCH: {
      const { email, chatraEmail } = action.payload;
      return {
        ...state,
        match: R.mergeRight(state.match || {}, { [email]: chatraEmail }),
      };
    }
    case DELETE_CHATRA_ACCOUNT: {
      return { ...state, isDeleted: true };
    }
    default: {
      return state;
    }
  }
}

export const recieveChatraAccounts = (chatraAccounts) => ({
  type: RECIEVE_CHATRA_ACCOUNTS,
  payload: { chatraAccounts },
});

export const recieveChatraAccount = (chatraAccount) => ({
  type: RECIEVE_CHATRA_ACCOUNT,
  payload: { chatraAccount },
});

export const renewChatraAccount = ({
  chatraAccountId,
  publicKey,
  secretKey,
  name,
  match,
  matchRobot,
}) => ({
  type: UPDATE_CHATRA_ACCOUNT,
  payload: { chatraAccountId, publicKey, secretKey, name, match, matchRobot },
});

export const removeChatraAccount = (_id) => ({
  type: DELETE_CHATRA_ACCOUNT,
  payload: { _id },
});

export const enableChatraAccount = (chatraAccountId) => ({
  type: ENABLE_CHATRA_ACCOUNT,
  payload: { chatraAccountId },
});

export const disableChatraAccount = (chatraAccountId) => ({
  type: DISABLE_CHATRA_ACCOUNT,
  payload: { chatraAccountId },
});

export const setName = (_id, name) => ({
  type: SET_NAME,
  payload: { _id, name },
});

export const setPublicKey = (_id, publicKey) => ({
  type: SET_PUBLIC_KEY,
  payload: { _id, publicKey },
});

export const setSecretKey = (_id, secretKey) => ({
  type: SET_SECRET_KEY,
  payload: { _id, secretKey },
});

export const setMatch = (_id, chatraEmail, email) => ({
  type: SET_MATCH,
  payload: { _id, chatraEmail, email },
});

export const setMatchRobot = (_id, matchRobot) => ({
  type: SET_MATCH_ROBOT,
  payload: { _id, matchRobot },
});

export const deleteChatraAccountDraft = () => ({
  type: DELETE_CHATRA_ACCOUNT_DRAFT,
});

export default function reducer(state = {}, action = { type: '' }) {
  switch (action.type) {
    case RECIEVE_CHATRA_ACCOUNTS: {
      return R.mergeRight(state, chatraAccountsReducer(state, action));
    }
    case RECIEVE_CHATRA_ACCOUNT: {
      return R.assoc(
        action.payload.chatraAccount._id,
        chatraAccountsReducer(state[action.payload.chatraAccount._id], action),
      )(state);
    }
    case ENABLE_CHATRA_ACCOUNT:
    case DISABLE_CHATRA_ACCOUNT:
    case UPDATE_CHATRA_ACCOUNT: {
      return R.assoc(
        action.payload.chatraAccountId,
        chatraAccountsReducer(state[action.payload.chatraAccountId], action),
      )(state);
    }
    case SET_NAME:
    case SET_PUBLIC_KEY:
    case SET_SECRET_KEY:
    case SET_MATCH:
    case SET_MATCH_ROBOT:
    case DELETE_CHATRA_ACCOUNT: {
      return R.assoc(
        action.payload._id,
        chatraAccountsReducer(state[action.payload._id], action),
      )(state);
    }
    case DELETE_CHATRA_ACCOUNT_DRAFT: {
      return R.dissoc('new')(state);
    }
    default: {
      return state;
    }
  }
}

export const chatraAccountsSelector = (state) => state.chatraAccounts;

export const chatraAccountsArraySelector = createSelector(
  chatraAccountsSelector,
  (chatraAccounts) =>
    R.filter(
      (_) => !_.isDeleted && _._id && _._id !== 'new',
      R.values(chatraAccounts),
    ),
);

export const deletedChatraAccountsArraySelector = createSelector(
  chatraAccountsSelector,
  (chatraAccounts) => R.filter((_) => _.isDeleted, R.values(chatraAccounts)),
);

export const chatraAccountIdSelector = (state, ownProps) => {
  const { params: { channelId } = {} } = ownProps.match;
  return channelId || ownProps.id || ownProps._id;
};

export const chatraAccountSelector = createSelector(
  chatraAccountsSelector,
  chatraAccountIdSelector,
  (accounts, id) => R.propOr({}, id, accounts),
);
