/*
 * Import all deleted channels from state and gather them into single array
 */
import { createSelector } from 'reselect';
import { deletedChatraAccountsArraySelector } from '../reducers/chatra-accounts';
import { deletedTelegramAccountsArraySelector } from '../reducers/telegram-accounts';
import { deletedMailAccountsArraySelector } from '../reducers/mail-accounts';
import { deletedWhatsAppAccountsArraySelector } from '../reducers/whatsappAccounts';
import { deletedVKAccountsArraySelector } from '../reducers/vk-accounts';
import { deletedSkypeAccountsArraySelector } from '../reducers/skype-accounts';
import { deletedFbAccountsArraySelector } from '../reducers/fb-accounts';
import { deletedIgAccountsArraySelector } from '../reducers/ig-accounts';
import { deletedCustomAccountsArraySelector } from '../reducers/custom-accounts';

const getDeletedChannelsFromState = createSelector(
  [
    deletedChatraAccountsArraySelector,
    deletedTelegramAccountsArraySelector,
    deletedMailAccountsArraySelector,
    deletedWhatsAppAccountsArraySelector,
    deletedVKAccountsArraySelector,
    deletedSkypeAccountsArraySelector,
    deletedFbAccountsArraySelector,
    deletedIgAccountsArraySelector,
    deletedCustomAccountsArraySelector,
  ],
  (
    chatraAccounts,
    telegramAccounts,
    mailAccounts,
    whatsAppAccounts,
    vkAccounts,
    skypeAccounts,
    fbAccounts,
    igAccounts,
    customAccounts,
  ) =>
    [
      ...chatraAccounts,
      ...telegramAccounts,
      ...mailAccounts,
      ...whatsAppAccounts,
      ...vkAccounts,
      ...skypeAccounts,
      ...fbAccounts,
      ...igAccounts,
      ...customAccounts,
    ].map((acc) => {
      if (chatraAccounts.includes(acc)) {
        return { ...acc, channelType: 'chatra' };
      }
      if (telegramAccounts.includes(acc)) {
        return { ...acc, channelType: 'telegram' };
      }
      if (mailAccounts.includes(acc)) {
        return { ...acc, channelType: 'email' };
      }
      if (whatsAppAccounts.includes(acc)) {
        return { ...acc, channelType: 'whatsapp' };
      }
      if (vkAccounts.includes(acc)) {
        return { ...acc, channelType: 'vk' };
      }
      if (skypeAccounts.includes(acc)) {
        return { ...acc, channelType: 'skype' };
      }
      if (fbAccounts.includes(acc)) {
        return { ...acc, channelType: 'fb' };
      }
      if (igAccounts.includes(acc)) {
        return { ...acc, channelType: 'ig' };
      }
      if (customAccounts.includes(acc)) {
        return { ...acc, channelType: 'custom' };
      }
      return acc;
    }),
);

export default getDeletedChannelsFromState;
