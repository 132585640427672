import React from 'react';
import PropTypes from 'prop-types';

import chatraIcon from '../../../../assets/images/chatraIconColored.png';
import mailIcon from '../../../../assets/images/mailIconColored.png';
import fbIcon from '../../../../assets/images/fbIcon.png';
import igIcon from '../../../../assets/images/igIcon.png';
import customIcon from '../../../../assets/images/customIcon.png';
import telegramIcon from '../../../../assets/images/telegram.svg';
import whatsAppIcon from '../../../../assets/images/whatsAppIcon.svg';
import vkIcon from '../../../../assets/images/vkIcon.svg';
import pbxIcon from '../../../../assets/images/pbxIcon.png';
import skypeIcon from '../../../../assets/images/skypeIconColored.png';
import tasksIcon from '../../../../assets/images/tasks.svg';

function EventIconByOrigin({ origin = 'mail' }) {
  const eventIconByOrigin = {
    chatra: <img src={chatraIcon} alt="chatra" width="36px" />,
    mail: <img src={mailIcon} alt="mail" width="36px" />,
    pbx: <img src={pbxIcon} alt="pbx" width="36px" />,
    skype: <img src={skypeIcon} alt="skype" width="36px" />,
    fb: <img src={fbIcon} alt="fb" width="36px" />,
    ig: <img src={igIcon} alt="ig" width="36px" />,
    ig2: <img src={igIcon} alt="ig" width="36px" />,
    custom: <img src={customIcon} alt="custom" width="36px" />,
    demo: <img src={mailIcon} alt="demo" width="36px" />,
    telegram: <img src={telegramIcon} alt="telegram" width="36px" />,
    whatsapp: <img src={whatsAppIcon} alt="whatsapp" width="36px" />,
    vk: <img src={vkIcon} alt="vk" width="36px" />,
    task: <img src={tasksIcon} alt="task" width="36px" />,
  };

  return eventIconByOrigin[origin];
}

EventIconByOrigin.propTypes = {
  origin: PropTypes.string,
};

export default EventIconByOrigin;
