import * as R from 'ramda';
import { createSelector } from 'reselect';

export const GET_CLIENT_EVENTS = 'GET_CLIENT_EVENTS';

function clientEventFactory(event) {
  return { [event._id]: event };
}

function clientEventReducer(state = {}, action = { type: '' }) {
  switch (action.type) {
    case GET_CLIENT_EVENTS: {
      return R.mergeAll(R.map(clientEventFactory, action.payload.events));
    }
    default: {
      return state;
    }
  }
}

export const getClientEventsItems = (events) => ({
  type: GET_CLIENT_EVENTS,
  payload: { events },
});

export default function reducer(state = {}, action = { type: '' }) {
  switch (action.type) {
    case GET_CLIENT_EVENTS: {
      return R.mergeRight(state, clientEventReducer(state, action));
    }
    default: {
      return state;
    }
  }
}

export const eventsSelector = (state) => R.propOr({}, 'clientEvents', state);

export const eventsToArraySelector = createSelector(eventsSelector, (events) =>
  R.values(events),
);
