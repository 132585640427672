import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd-mobile';
import { useTranslation } from 'react-i18next';

const EmptyDialogsListView = ({ listMode, handleOnClick }) => {
  const { t } = useTranslation();
  return (
    <div className="dialogsListNoContent">
      <Button color="primary" onClick={handleOnClick}>
        {`${t('EmptyDialgosList.btn')} ${listMode === 'dialogs' ? t('EmptyDialgosList.dialogs') : t('EmptyDialgosList.calls')}`}
      </Button>
    </div>
  );
};

EmptyDialogsListView.propTypes = {
  listMode: PropTypes.string.isRequired,
  handleOnClick: PropTypes.func.isRequired,
};

export default React.memo(EmptyDialogsListView);
