import { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { components as componentsConf } from '../../../configs';
import throttle from '../../../utils/throttle';
import dialogMessagesListItemCountSelector from '../../../selectors/dialogMessagesListItemCountSelector';
import { getDialogMessagesListPositions } from '../../../api/messages';
import { resetDialogMessagesListPositions } from '../../../reducers/dialogMessagesList';
import dialogMessagesListDialogPositionsSelector from '../../../selectors/dialogMessagesListDialogPositionsSelector';

const {
  pages: {
    dialogs: {
      messagesList: {
        itemCountRefreshTimeout: messagesListItemCountRefreshTimeout,
      },
    },
  },
} = componentsConf;

/**
 * @param {object} objectParams
 * @param {string} objectParams.dialogId
 * @param {function} objectParams.handleReqError
 */
const useDialogsMessagesListPositions = ({ dialogId, handleReqError } = {}) => {
  const dispatch = useDispatch();

  const dialogMessagesListItemCountSelectorWithInjectedProps = useCallback(
    (state) => dialogMessagesListItemCountSelector(state, { dialogId }),
    [dialogId],
  );
  /**
   * @type {number|null}
   */
  const itemCount = useSelector(
    dialogMessagesListItemCountSelectorWithInjectedProps,
  );

  /**
   * List positions refresh state
   */
  // const [needToRefreshListPositions, setNeedToRefreshListPositions] = useState(
  //   itemCount === null,
  // );
  // возможно нужно будет вернуть проверку itemCount === null,
  // сейчас это решает что если зайти в чат потом выйти получить в чате новое сообщение то не обновится список
  const [needToRefreshListPositions, setNeedToRefreshListPositions] =
    useState(true);

  const requestListPositionsRefresh = useCallback(() => {
    if (!needToRefreshListPositions) {
      setNeedToRefreshListPositions(true);
    }
  }, [needToRefreshListPositions]);

  /**
   * List positions refresh api wrappers
   */
  const refreshListPositions = useCallback(
    (cb) => dispatch(getDialogMessagesListPositions(dialogId, cb)),
    [dispatch, dialogId],
  );
  const throttledRefreshListPositions = throttle(
    refreshListPositions,
    messagesListItemCountRefreshTimeout,
  );
  const handleListPositionsRefresh = useCallback(() => {
    setNeedToRefreshListPositions(false);
    throttledRefreshListPositions(({ err }) => {
      if (handleReqError) {
        handleReqError(err);
      }
    });
  }, [throttledRefreshListPositions, handleReqError]);

  /**
   * Handle list positions refresh
   */
  useEffect(() => {
    if (needToRefreshListPositions) {
      handleListPositionsRefresh();
    }
  }, [needToRefreshListPositions, handleListPositionsRefresh]);

  /**
   * List positions and index/id (and vice versa) mappers
   */
  const dialogMessagesListPositionsSelectorWithInjectedProps = useCallback(
    (state) => dialogMessagesListDialogPositionsSelector(state, { dialogId }),
    [dialogId],
  );
  const listPositions = useSelector(
    dialogMessagesListPositionsSelectorWithInjectedProps,
  );

  const mapItemIdToIndex = useCallback(
    /**
     * @param {string|number} itemId
     * @param {string|number} fallbackIndex Defaults to 0.
     * @returns {number} Item or fallback index.
     */
    (itemId, fallbackIndex = 0) => {
      const itemIndex = listPositions ? listPositions.indexOf(itemId) : -1;

      return itemIndex !== -1 ? itemIndex : fallbackIndex;
    },
    [listPositions],
  );
  const mapIndexToItemId = useCallback(
    /**
     * @param {number} itemIndex
     * @param {number|string} fallbackItemId Defaults to item index.
     * @returns {number|string} Item id or passed index as a fallback.
     */
    (itemIndex, fallbackItemId = itemIndex) => {
      const itemId = listPositions ? listPositions[itemIndex] : fallbackItemId;

      return itemId || fallbackItemId;
    },
    [listPositions],
  );

  /**
   * List positions reset
   */
  const resetListPositions = useCallback(
    (dialogIdForReset) =>
      dispatch(resetDialogMessagesListPositions(dialogIdForReset)),
    [dispatch],
  );

  return {
    itemCount,
    requestListPositionsRefresh,
    listPositions,
    mapItemIdToIndex,
    mapIndexToItemId,
    resetListPositions,
  };
};

export default useDialogsMessagesListPositions;
