import { createSelector } from 'reselect';

const SET_DIALOG_INFO_ACTIVE_CLIENT_SECTIONS =
  'SET_DIALOG_INFO_ACTIVE_CLIENT_SECTIONS';

export const setDialogInfoActiveClientSections = (activeSections) => ({
  type: SET_DIALOG_INFO_ACTIVE_CLIENT_SECTIONS,
  payload: { activeSections },
});

const defaultState = { activeClientSections: [] };

export default function reducer(state = defaultState, action = { type: '' }) {
  switch (action.type) {
    case SET_DIALOG_INFO_ACTIVE_CLIENT_SECTIONS: {
      return { ...state, activeClientSections: action.payload.activeSections };
    }
    default: {
      return state;
    }
  }
}

export const dialogInfoSelector = (state) => state.dialogInfo;

export const dialogInfoActiveClientSectionsSelector = createSelector(
  dialogInfoSelector,
  (dialogInfo) => dialogInfo.activeClientSections,
);
