function debounce(fn, ms) {
  let timer = null;

  return function wrapper(...args) {
    const onFinish = () => {
      fn.apply(this, args);
      timer = null;
    };

    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(onFinish, ms);
  };
}

export default debounce;
