import * as R from 'ramda';
import { createSelector } from 'reselect';

export const RECIEVE_AMOCRM_INTEGRATIONS = 'RECIEVE_AMOCRM_INTEGRATIONS';
export const RECIEVE_AMOCRM_INTEGRATION = 'RECIEVE_AMOCRM_INTEGRATION';
export const DELETE_AMOCRM_INTEGRATION = 'DELETE_AMOCRM_INTEGRATION';
export const UPDATE_AMOCRM_INTEGRATION = 'UPDATE_AMOCRM_INTEGRATION';
export const ENABLE_AMOCRM_INTEGRATION = 'ENABLE_AMOCRM_INTEGRATION';
export const DISABLE_AMOCRM_INTEGRATION = 'DISABLE_AMOCRM_INTEGRATION';
export const DELETE_AMOCRM_INTEGRATION_DRAFT =
  'DELETE_AMOCRM_INTEGRATION_DRAFT';

function amoCrmIntegrationFactory(account) {
  return { [account._id]: account };
}

function amoCrmIntegrationsReducer(state = {}, action = { type: '' }) {
  switch (action.type) {
    case RECIEVE_AMOCRM_INTEGRATIONS: {
      return R.mergeAll(
        R.map(amoCrmIntegrationFactory, action.payload.amoCrmIntegrations),
      );
    }
    case RECIEVE_AMOCRM_INTEGRATION: {
      return { ...state, ...action.payload.amoCrmIntegration };
    }
    case UPDATE_AMOCRM_INTEGRATION: {
      return {
        ...state,
        ...(action.payload.userHash
          ? { userHash: action.payload.userHash }
          : {}),
        ...(action.payload.userLogin
          ? { userLogin: action.payload.userLogin }
          : {}),
        ...(action.payload.userSite
          ? { userSite: action.payload.userSite }
          : {}),
      };
    }
    case DISABLE_AMOCRM_INTEGRATION: {
      return { ...state, isEnabled: false };
    }
    case ENABLE_AMOCRM_INTEGRATION: {
      return { ...state, isEnabled: true };
    }
    case DELETE_AMOCRM_INTEGRATION: {
      return { ...state, isDeleted: true };
    }
    default: {
      return state;
    }
  }
}

export const recieveAmoCrmIntegrations = (amoCrmIntegrations) => ({
  type: RECIEVE_AMOCRM_INTEGRATIONS,
  payload: { amoCrmIntegrations },
});

export const recieveAmoCrmIntegration = (amoCrmIntegration) => ({
  type: RECIEVE_AMOCRM_INTEGRATION,
  payload: { amoCrmIntegration },
});

export const renewAmoCrmIntegration = ({
  amoCrmIntegrationId,
  userHash,
  userLogin,
  userSite,
}) => ({
  type: UPDATE_AMOCRM_INTEGRATION,
  payload: { amoCrmIntegrationId, userHash, userLogin, userSite },
});

export const removeAmoCrmIntegration = (_id) => ({
  type: DELETE_AMOCRM_INTEGRATION,
  payload: { _id },
});

export const enableAmoCrmIntegration = (amoCrmIntegrationId) => ({
  type: ENABLE_AMOCRM_INTEGRATION,
  payload: { amoCrmIntegrationId },
});

export const disableAmoCrmIntegration = (amoCrmIntegrationId) => ({
  type: DISABLE_AMOCRM_INTEGRATION,
  payload: { amoCrmIntegrationId },
});

export const deleteAmoCrmIntegrationDraft = () => ({
  type: DELETE_AMOCRM_INTEGRATION_DRAFT,
});

export default function reducer(state = {}, action = { type: '' }) {
  switch (action.type) {
    case RECIEVE_AMOCRM_INTEGRATIONS: {
      return R.mergeRight(state, amoCrmIntegrationsReducer(state, action));
    }
    case RECIEVE_AMOCRM_INTEGRATION: {
      return R.assoc(
        action.payload.amoCrmIntegration._id,
        amoCrmIntegrationsReducer(
          state[action.payload.amoCrmIntegration._id],
          action,
        ),
      )(state);
    }
    case ENABLE_AMOCRM_INTEGRATION:
    case DISABLE_AMOCRM_INTEGRATION:
    case UPDATE_AMOCRM_INTEGRATION: {
      return R.assoc(
        action.payload.amoCrmIntegrationId,
        amoCrmIntegrationsReducer(
          state[action.payload.amoCrmIntegrationId],
          action,
        ),
      )(state);
    }
    case DELETE_AMOCRM_INTEGRATION: {
      return R.assoc(
        action.payload._id,
        amoCrmIntegrationsReducer(state[action.payload._id], action),
      )(state);
    }
    case DELETE_AMOCRM_INTEGRATION_DRAFT: {
      return R.dissoc('new')(state);
    }
    default: {
      return state;
    }
  }
}

export const amoCrmIntegrationsSelector = (state) => state.amoCrmIntegrations;

export const amoCrmIntegrationsArraySelector = createSelector(
  amoCrmIntegrationsSelector,
  (amoCrmIntegrations) =>
    R.filter((_) => !_.isDeleted, R.values(amoCrmIntegrations)),
);

export const deletedAmoCrmIntegrationsArraySelector = createSelector(
  amoCrmIntegrationsSelector,
  (amoCrmIntegrations) =>
    R.filter((_) => _.isDeleted, R.values(amoCrmIntegrations)),
);

export const amoCrmActiveIntegrationSelector = createSelector(
  amoCrmIntegrationsArraySelector,
  (integrations) => integrations.find(({ _id: id }) => id && id !== 'new'),
);

export const amoCrmIntegrationIdSelector = (state, ownProps) => {
  const { params: { channelId } = {} } = ownProps.match;
  return channelId || ownProps.id || ownProps._id;
};

export const amoCrmIntegrationselector = createSelector(
  amoCrmIntegrationsSelector,
  amoCrmIntegrationIdSelector,
  (accounts, id) => R.propOr({}, id, accounts),
);
