import React, { useState } from 'react';
import { Button, Popup, List, Ellipsis } from 'antd-mobile';
import { AppstoreOutline, UnorderedListOutline } from 'antd-mobile-icons';
import { useTranslation } from 'react-i18next';
import TemplateListSelection from './TemplateListSelection';
import DialogFormFile from './DialogFormFile';

const DialogFormMenu = ({ disabled, uploadFile, insertTemplateText }) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [visibleTemplateListSelection, setVisibleTemplateListSelection] =
    useState(false);

  return (
    <>
      <Button
        fill="none"
        disabled={disabled}
        onClick={() => setVisible(true)}
        style={{ padding: '11px 12px' }}
      >
        <AppstoreOutline fontSize={24} style={{ display: 'block' }} />
      </Button>
      <Popup
        visible={visible}
        destroyOnClose
        onMaskClick={() => setVisible(false)}
      >
        <div style={{ maxHeight: '50vh', overflowY: 'auto' }}>
          <List>
            <List.Item
              arrowIcon={[]}
              prefix={
                <UnorderedListOutline
                  fontSize={24}
                  style={{ display: 'block', transform: 'scaleX(-1)' }}
                />
              }
              onClick={() => {
                setVisibleTemplateListSelection(true);
                setVisible(false);
              }}
            >
              <Ellipsis
                direction="end"
                content={t('TemplateListSelection.quickResponses')}
              />
            </List.Item>
            <DialogFormFile
              uploadFile={uploadFile}
              onClose={() => setVisible(false)}
            />
          </List>
        </div>
      </Popup>
      <TemplateListSelection
        insertTemplateText={insertTemplateText}
        visible={visibleTemplateListSelection}
        onClose={() => setVisibleTemplateListSelection(false)}
      />
    </>
  );
};

export default DialogFormMenu;
