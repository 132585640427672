/* eslint-disable no-console */
import fetchJWT from '../../utils/fetchJWT';
import { logout } from '../../actions';
import { recieveDialogsStatusCounters } from '../../reducers/statusCounters';
import backend from '../../configs/backend';

const initCounters = () => (dispatch) => {
  const request = new Request(
    backend.applyBaseUrl('/api/dialogs/init/counters'),
    {
      method: 'GET',
      mode: 'cors',
      cache: 'default',
    },
  );
  fetchJWT(request)
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
      if (res.status === 401) {
        dispatch(logout());
      }
      return {};
    })
    .then((res) => {
      dispatch(recieveDialogsStatusCounters(res));
    })
    .catch((e) => console.error(e));
};

export default initCounters;
