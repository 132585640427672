/* eslint-disable no-underscore-dangle  */
import * as R from 'ramda';
import { createSelector } from 'reselect';

export const RECIEVE_DEMO_ACCOUNTS = 'RECIEVE_DEMO_ACCOUNTS';
export const RECIEVE_DEMO_ACCOUNT = 'RECIEVE_DEMO_ACCOUNT';
export const ENABLE_DEMO_ACCOUNT = 'ENABLE_DEMO_ACCOUNT';

function demoAccountFactory(account) {
  return { [account._id]: account };
}

function demoAccountsReducer(state = {}, action = { type: '' }) {
  switch (action.type) {
    case RECIEVE_DEMO_ACCOUNTS: {
      return R.mergeAll(R.map(demoAccountFactory, action.payload.demoAccounts));
    }
    case RECIEVE_DEMO_ACCOUNT: {
      return { ...state, ...action.payload.demoAccount };
    }
    case ENABLE_DEMO_ACCOUNT: {
      return { ...state, isEnabled: true };
    }
    default: {
      return state;
    }
  }
}

export const recieveDemoAccounts = (demoAccounts) => ({
  type: RECIEVE_DEMO_ACCOUNTS,
  payload: { demoAccounts },
});

export const recieveDemoAccount = (demoAccount) => ({
  type: RECIEVE_DEMO_ACCOUNT,
  payload: { demoAccount },
});

export const enableDemoAccount = (demoAccountId) => ({
  type: ENABLE_DEMO_ACCOUNT,
  payload: { demoAccountId },
});

export default function reducer(state = {}, action = { type: '' }) {
  switch (action.type) {
    case RECIEVE_DEMO_ACCOUNTS: {
      return R.mergeRight(state, demoAccountsReducer(state, action));
    }
    case RECIEVE_DEMO_ACCOUNT: {
      return R.assoc(
        action.payload.demoAccount._id,
        demoAccountsReducer(state[action.payload.demoAccount._id], action),
      )(state);
    }
    case ENABLE_DEMO_ACCOUNT: {
      return R.assoc(
        action.payload.demoAccountId,
        demoAccountsReducer(state[action.payload.demoAccountId], action),
      )(state);
    }
    default: {
      return state;
    }
  }
}

export const demoAccountsSelector = (state) => state.demoAccounts;

export const demoAccountsArraySelector = createSelector(
  demoAccountsSelector,
  (demoAccounts) =>
    R.filter(
      (_) => !_.isDeleted && _._id && _._id !== 'new',
      R.values(demoAccounts),
    ),
);

// на всякий случай, если нам понадобится удалять демо-каналы
export const deletedDemoAccountsArraySelector = createSelector(
  demoAccountsSelector,
  (demoAccounts) => R.filter((_) => _.isDeleted, R.values(demoAccounts)),
);

export const demoAccountIdSelector = (state, ownProps) => {
  const { params: { channelId } = {} } = ownProps.match;
  return channelId || ownProps.id || ownProps._id;
};

export const demoAccountSelector = createSelector(
  demoAccountsSelector,
  demoAccountIdSelector,
  (accounts, id) => R.propOr({}, id, accounts),
);
