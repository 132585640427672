/* eslint-disable no-console */
import fetchJWT from '../../utils/fetchJWT';
import * as ac from '../../actions';
import backend from '../../configs/backend';

const getFileLink =
  ({ key, origin, messageId, mode }, cb) =>
  async (dispatch) =>
    fetchJWT(
      backend.applyBaseUrl(
        `/api/messages/file/link/${key}?origin=${origin}&messageId=${messageId}`,
      ),
    )
      .then((res) => {
        if (res.ok) {
          return res.text();
        }
        if (res.status === 401) {
          dispatch(ac.logout());
        }
        return null;
      })
      .then((res) => {
        if (res) {
          if (mode && (mode === 'forward' || mode === 'src')) {
            if (cb) {
              cb({ url: res });
            }
            return res;
          }
          window.open(res, '_blank');
        } else {
          console.error(`We can't get the link`); // TODO
        }
        cb(true);
      })
      .catch((err) => {
        console.error(err);
        if (mode && (mode === 'forward' || mode === 'src')) {
          if (cb) {
            cb({});
          }
        }
      });

export default getFileLink;
