import fetchJWT from '../../utils/fetchJWT';
import { logout } from '../../actions';
import { recieveSkypeAccounts } from '../../reducers/skype-accounts';
import backend from '../../configs/backend';

const getSkypeAccounts = () => (dispatch) => {
  const request = new Request(
    backend.applyBaseUrl('/api/skype-channel/accounts/read'),
    {
      method: 'GET',
      mode: 'cors',
      cache: 'default',
    },
  );
  fetchJWT(request)
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
      if (res.status === 401) {
        dispatch(logout());
      }
      return [];
    })
    .then((res) => dispatch(recieveSkypeAccounts(res)))
    .catch((err) => console.error(err));
};

export default getSkypeAccounts;
