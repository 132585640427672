import { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  dialogsListItemCountSelector,
  dialogsListPositionsSelector,
} from '../../reducers/dialogsList';
import { getDialogsListPositions } from '../../api/dialogs';
import { components as componentsConf } from '../../configs';
import throttle from '../../utils/throttle';
import usePrevious from '../usePrevious';

const {
  pages: {
    dialogs: {
      list: { itemCountRefreshTimeout },
    },
  },
} = componentsConf;

const useDialogsListPositions = ({ handleReqError } = {}) => {
  const dispatch = useDispatch();

  /**
   * @type {number|null}
   */
  const itemCount = useSelector(dialogsListItemCountSelector);

  /**
   * Dialogs list positions refresh state
   */
  const [
    needToRefreshDialogsListPositions,
    setNeedToRefreshDialogsListPositions,
  ] = useState(itemCount === null);

  const requestDialogsListPositionsRefresh = useCallback(() => {
    if (!needToRefreshDialogsListPositions) {
      setNeedToRefreshDialogsListPositions(true);
    }
  }, [needToRefreshDialogsListPositions]);

  /**
   * Dialogs list positions refresh api wrappers
   */
  const refreshDialogsListPositions = useCallback(
    (cb) => dispatch(getDialogsListPositions(cb)),
    [dispatch],
  );
  const throttledRefreshDialogsListPositions = throttle(
    refreshDialogsListPositions,
    itemCountRefreshTimeout,
  );
  const handleDialogsListPositionsRefresh = useCallback(() => {
    setNeedToRefreshDialogsListPositions(false);
    throttledRefreshDialogsListPositions(({ err }) => {
      if (handleReqError) {
        handleReqError(err);
      }
    });
  }, [throttledRefreshDialogsListPositions, handleReqError]);

  /**
   * Handle dialogs list positions refresh
   */
  const prevNeedToRefreshDialogsListPosition = usePrevious(
    needToRefreshDialogsListPositions,
  );

  useEffect(() => {
    if (
      needToRefreshDialogsListPositions &&
      prevNeedToRefreshDialogsListPosition !== needToRefreshDialogsListPositions
    ) {
      handleDialogsListPositionsRefresh();
    }
  }, [
    needToRefreshDialogsListPositions,
    prevNeedToRefreshDialogsListPosition,
    handleDialogsListPositionsRefresh,
  ]);

  /**
   * Dialogs list positions and index/id (and reverse) mappers
   */
  const dialogsListPositions = useSelector(dialogsListPositionsSelector);

  const mapItemIdToIndex = useCallback(
    /**
     * @param {string|number} itemId
     * @param {string|number} fallbackIndex Defaults to 0.
     * @returns {number} Item or fallback index.
     */
    (itemId, fallbackIndex = 0) => {
      const itemIndex = dialogsListPositions
        ? dialogsListPositions.indexOf(itemId)
        : -1;

      return itemIndex !== -1 ? itemIndex : fallbackIndex;
    },
    [dialogsListPositions],
  );
  const mapIndexToItemId = useCallback(
    /**
     * @param {number} itemIndex
     * @param {number|string} fallbackItemId Defaults to item index.
     * @returns {number|string} Item id or passed index as a fallback.
     */
    (itemIndex, fallbackItemId = itemIndex) => {
      const itemId = dialogsListPositions
        ? dialogsListPositions[itemIndex]
        : fallbackItemId;

      return itemId || fallbackItemId;
    },
    [dialogsListPositions],
  );

  return {
    itemCount,
    requestDialogsListPositionsRefresh,
    dialogsListPositions,
    mapItemIdToIndex,
    mapIndexToItemId,
  };
};

export default useDialogsListPositions;
