import { useCallback } from 'react';
import { createSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import { getContacts } from '../../../api/contacts';

import { getMessages } from '../../../api/messages';

const useDialogMessagesListLoadMore = ({ dialogId, handleReqError } = {}) => {
  const dispatch = useDispatch();

  const clientsSelector = createSelector(
    (state) => state.clients,
    (clients) => Object.values(clients),
  );
  const clients = useSelector(clientsSelector);

  const dialogMessagesSelector = createSelector(
    (state) => state.messages,
    (_, id) => id,
    (messages, id) => Object.values(messages).filter((m) => m.dialogId === id),
  );
  const dialogMessages = useSelector((state) =>
    dialogMessagesSelector(state, dialogId),
  );

  const getContactsByIdArray = useCallback(
    (contactIds) => dispatch(getContacts('', contactIds)),
    [dispatch],
  );

  const fetchMessagesContacts = useCallback(
    (messageIds, messages) => {
      const targetMessages = (messages || dialogMessages).filter((m) =>
        messageIds.includes(m._id),
      );

      const contactIdsToLoad = targetMessages.reduce((contactIds, message) => {
        const contactIdsSet = new Set(contactIds);

        const {
          contactsId,
          reply_to_client_id: replyToClientId,
          metadata: {
            forwardContactIds = [],
            mailToContactIds = [],
            ccContactIds = [],
          } = {},
        } = message;

        if (replyToClientId) {
          contactIdsSet.add(replyToClientId);
        }

        forwardContactIds.forEach((cId) => contactIdsSet.add(cId));
        mailToContactIds.forEach((cId) => contactIdsSet.add(cId));
        ccContactIds.forEach((cId) => contactIdsSet.add(cId));

        if (contactsId) {
          contactIdsSet.add(contactsId);
        }

        return [...contactIdsSet];
      }, []);

      if (contactIdsToLoad.length) {
        // filter for non-cached contacts
        const nonCachedContactIds = contactIdsToLoad.filter(
          (contactsId) =>
            !clients.find(
              ({ _id: clientId, contacts = [] }) =>
                clientId === contactsId ||
                contacts.find((c) => c.id === contactsId),
            ),
        );

        // request non-cached contacts
        if (nonCachedContactIds.length) {
          getContactsByIdArray(nonCachedContactIds);
        }
      }
    },
    [clients, dialogMessages, getContactsByIdArray],
  );

  const getMessagesByIdArray = useCallback(
    (messagesIdArray, cb) => {
      const nonCachedMessagesIds = messagesIdArray.filter(
        (id) => !dialogMessages.find((m) => m._id === id),
      );
      if (nonCachedMessagesIds.length) {
        dispatch(getMessages({ ids: nonCachedMessagesIds, noLimit: true }, cb));
      } else if (cb) {
        cb({});
      }
    },
    [dispatch, dialogMessages],
  );

  const loadMore = useCallback(
    /**
     * @param {string[]} messageIds
     */
    (messageIds, cb) => {
      if (messageIds.length) {
        getMessagesByIdArray(messageIds, ({ err, messages }) => {
          handleReqError(err);
          if (!err) {
            fetchMessagesContacts(messageIds, messages);
          }
          if (cb) {
            cb();
          }
        });
      }
    },
    [getMessagesByIdArray, handleReqError, fetchMessagesContacts],
  );

  return { loadMore };
};

export default useDialogMessagesListLoadMore;
