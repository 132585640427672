import { createSelector } from 'reselect';
import { getChannelIdFromDialogSelector } from '../reducers/dialogs';

const getMailAccountsFromState = (state) => state.mailAccounts;
const getChatraAccountsFromState = (state) => state.chatraAccounts;
const getDemoAccountsFromState = (state) => state.demoAccounts;
const getTelegramAccountsFromState = (state) => state.telegramAccounts;
const getWAAccountsFromState = (state) => state.whatsAppAccounts;
const getVKAccountsFromState = (state) => state.vkAccounts;
const getSkypeAccountsFromState = (state) => state.skypeAccounts;
const getPbxAccountsFromState = (state) => state.pbxAccounts;
const getFbAccountsFromState = (state) => state.fbAccounts;
const getIgAccountsFromState = (state) => state.igAccounts;
const getCustomAccountsFromState = (state) => state.customAccounts;

const dialogFormChannelSelector = createSelector(
  [
    getChannelIdFromDialogSelector,
    getMailAccountsFromState,
    getChatraAccountsFromState,
    getDemoAccountsFromState,
    getTelegramAccountsFromState,
    getWAAccountsFromState,
    getVKAccountsFromState,
    getSkypeAccountsFromState,
    getPbxAccountsFromState,
    getFbAccountsFromState,
    getIgAccountsFromState,
    getCustomAccountsFromState,
  ],
  (
    channelId = '',
    mailAccs = {},
    chatraAccs = {},
    demoAccs = {},
    telegramAccs = {},
    whatsappAccs = {},
    vkAccs = {},
    skypeAccs = {},
    pbxAccs = {},
    fbAccs = {},
    igAccs = {},
    customAccs = {},
  ) =>
    mailAccs[channelId] ||
    chatraAccs[channelId] ||
    demoAccs[channelId] ||
    telegramAccs[channelId] ||
    whatsappAccs[channelId] ||
    vkAccs[channelId] ||
    skypeAccs[channelId] ||
    pbxAccs[channelId] ||
    fbAccs[channelId] ||
    igAccs[channelId] ||
    customAccs[channelId],
);

export default dialogFormChannelSelector;
