import { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  dialogsListSortSelector,
  setSort,
} from '../../reducers/dialogsFilters';
import {
  callsListSortSelector,
  setCallsSort,
} from '../../reducers/callsFilters';
import usePrevious from '../usePrevious';
import useDialogsListMode from './useDialogsListMode';

const useDialogsListSort = ({ onSortChange } = {}) => {
  const dispatch = useDispatch();

  const { dialogsListMode = 'dialogs' } = useDialogsListMode();

  const sortUpdateAction = dialogsListMode === 'calls' ? setCallsSort : setSort;
  const updateSort = useCallback(
    (status) => {
      dispatch(sortUpdateAction(status));
    },
    [dispatch, sortUpdateAction],
  );

  const sortSelector =
    dialogsListMode === 'calls'
      ? callsListSortSelector
      : dialogsListSortSelector;
  const sort = useSelector(sortSelector);
  const prevSort = usePrevious(sort);

  useEffect(() => {
    if (onSortChange && prevSort && prevSort !== sort) {
      onSortChange();
    }
  }, [prevSort, sort, onSortChange]);

  return { sort, updateSort };
};

export default useDialogsListSort;
