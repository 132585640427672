import { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { initCounters } from '../../api/dialogs';
import {
  SHOW_ALL,
  SHOW_CLOSED,
  SHOW_MINE,
  SHOW_NOT_ASSIGNED_RESP,
  SHOW_OPENED,
  SHOW_WAITING,
} from '../../reducers/dialogsFilters';
import currentListModeDialogsStatusCountersSelector from '../../selectors/currentListModeDialogsStatusCountersSelector';
import useLoggedUser from '../auth/useLoggedUser';

import usePrevious from '../usePrevious';

const useDialogsListStatusCounters = ({
  onStatusCountersChange,
  currentFilterStatus = SHOW_ALL,
} = {}) => {
  const statusCounters = useSelector(
    currentListModeDialogsStatusCountersSelector,
  );
  const prevStatusCounters = usePrevious(statusCounters);

  const dispatch = useDispatch();

  const getInitialDialogsListCounters = useCallback(
    () => dispatch(initCounters()),
    [dispatch],
  );

  const { user: loggedUser = {} } = useLoggedUser();

  /**
   * Get initial status counters.
   */
  useEffect(() => {
    const countersAreEmpty = !Object.keys(statusCounters).length;

    if (countersAreEmpty && !prevStatusCounters) {
      getInitialDialogsListCounters();
    }
  }, [statusCounters, prevStatusCounters, getInitialDialogsListCounters]);

  /**
   * TODO -> make a better checker to avoid unnecessary requests
   * React to dialogs list status counters update.
   * They are updated on message creation/dialog creation/dialog update
   * and usually it affects dialogs list positions.
   */
  useEffect(() => {
    if (
      onStatusCountersChange &&
      prevStatusCounters &&
      prevStatusCounters !== statusCounters
    ) {
      let needToTriggerChangeCb = false;

      if (
        currentFilterStatus === SHOW_ALL ||
        currentFilterStatus === SHOW_NOT_ASSIGNED_RESP
      ) {
        if (
          prevStatusCounters.closed !== statusCounters.closed ||
          prevStatusCounters.opened !== statusCounters.opened ||
          prevStatusCounters.waiting !== statusCounters.waiting
        ) {
          needToTriggerChangeCb = true;
        }
      }

      if (currentFilterStatus === SHOW_OPENED) {
        if (prevStatusCounters.opened !== statusCounters.opened) {
          needToTriggerChangeCb = true;
        }
      }

      if (currentFilterStatus === SHOW_WAITING) {
        if (prevStatusCounters.waiting !== statusCounters.waiting) {
          needToTriggerChangeCb = true;
        }
      }

      if (currentFilterStatus === SHOW_CLOSED) {
        if (prevStatusCounters.closed !== statusCounters.closed) {
          needToTriggerChangeCb = true;
        }
      }

      if (currentFilterStatus === SHOW_MINE) {
        if (
          prevStatusCounters[loggedUser._id] !== statusCounters[loggedUser._id]
        ) {
          needToTriggerChangeCb = true;
        }
      }

      if (needToTriggerChangeCb) {
        onStatusCountersChange();
      }
    }
  }, [
    prevStatusCounters,
    statusCounters,
    onStatusCountersChange,
    currentFilterStatus,
    loggedUser._id,
  ]);

  return { statusCounters };
};

export default useDialogsListStatusCounters;
