import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Avatar } from 'antd-mobile';
import { UserOutline, FaceRecognitionOutline } from 'antd-mobile-icons';
import { useTranslation } from 'react-i18next';

import generateAvatarForEntity from '../../../../utils/generateAvatarForEntity';
import isMessageAuthorAgent from '../../../../utils/isMessageAuthorAgent';
import { IframeWrapper } from '../../../../components/ContentWrappers';
import Attachments from '../attachments';
import MessageQuote from './MessageQuote';

const Message = ({
  item,
  dialog,
  dialogAuthor,
  dialogClient,
  messageAuthor,
  messageAuthorDisplay,
}) => {
  const messageAuthorForDisplay = messageAuthor || {
    ...(item.contactsValue
      ? { accountId: item.accountId, name: item.contactsValue }
      : {}),
  };

  if (!item && (!item.agent || !messageAuthorForDisplay)) {
    return null;
  }

  const isMessageFromClient =
    !item.agent &&
    (item.direction === 'in' ||
      (item.authorId && dialogAuthor._id === item.authorId) ||
      (item.contactsId &&
        dialogAuthor.contacts.find(
          (cData) => cData.id === dialog.contactsId,
        )) ||
      messageAuthorForDisplay.service);

  // вложения для телеграма
  const tgDocuments = [];
  if (
    item.metadata &&
    item.metadata.originalMsg &&
    item.metadata.originalMsg.document
  ) {
    tgDocuments.push(item.metadata.originalMsg.document);
  }

  return (
    <div style={{ padding: '6px 5px', display: 'flex', gap: '6px' }}>
      <MessageAvatar
        isMessageFromClient={isMessageFromClient}
        item={item}
        dialogAuthor={dialogAuthor}
        dialog={dialog}
        messageAuthorForDisplay={messageAuthorForDisplay}
      />
      <div style={{ width: '100%' }}>
        <MessageAuthor
          isMessageFromClient={isMessageFromClient}
          item={item}
          dialog={dialog}
          messageAuthorDisplay={messageAuthorDisplay}
          messageAuthorForDisplay={messageAuthorForDisplay}
        />
        <div style={{ wordBreak: 'break-word', whiteSpace: 'pre-wrap' }}>
          {item.metadata && item.metadata.quotedMessage ? (
            <MessageQuote
              quotedMessage={item.metadata.quotedMessage}
              dialog={dialog}
              messageId={item._id}
            />
          ) : null}
          <MessageContent origin={dialog?.origin} item={item} />
          {(item.text || item.metadata.html || item.metadata.cleanHtml) &&
          (tgDocuments.length || item.attachments.length) ? (
            <br />
          ) : null}
          <Attachments
            attachments={
              tgDocuments.length >= 1 ? tgDocuments : item.attachments
            }
            status={item.status}
            dialog={dialog}
            messageId={item._id}
          />
        </div>
      </div>
    </div>
  );
};

Message.propTypes = {
  dialog: PropTypes.object,
  item: PropTypes.object,
  dialogAuthor: PropTypes.object,
  dialogClient: PropTypes.object,
  messageAuthor: PropTypes.object,
  messageAuthorDisplay: PropTypes.string,
};

export default Message;

function MessageAvatar({
  isMessageFromClient,
  item,
  dialog,
  messageAuthorForDisplay,
}) {
  const generatedAvatar = messageAuthorForDisplay
    ? generateAvatarForEntity(messageAuthorForDisplay, dialog)
    : null;

  return (
    <div
      className="DialogListItemAvatar"
      style={{
        ...(isMessageFromClient ? { borderRadius: '50%' } : {}),
        ...(messageAuthorForDisplay && messageAuthorForDisplay.avatarBgColor
          ? {
              backgroundColor: messageAuthorForDisplay.avatarBgColor,
            }
          : {}),
        ...(item?.authorId === 'bot' ? { backgroundColor: 'pink' } : {}),
      }}
    >
      {isMessageFromClient ? (
        generatedAvatar
      ) : item?.authorId === 'bot' ? (
        <FaceRecognitionOutline fontSize={22} />
      ) : messageAuthorForDisplay.avatar ? (
        <Avatar
          src={messageAuthorForDisplay.avatar}
          style={{ '--size': '36px' }}
          fit="cover"
        />
      ) : (
        <UserOutline fontSize={20} />
      )}
    </div>
  );
}

MessageAvatar.propTypes = {
  isMessageFromClient: PropTypes.bool,
  item: PropTypes.object,
  dialog: PropTypes.object,
  messageAuthorForDisplay: PropTypes.object,
};

function MessageAuthor({
  isMessageFromClient,
  item,
  dialog,
  messageAuthorDisplay,
  messageAuthorForDisplay,
}) {
  const { t } = useTranslation();
  const agentOriginByDialog = {
    chatra: t('Message.chatra'),
    vk: t('Message.vk'),
    telegram: t('Message.telegram'),
    whatsapp: t('Message.whatsapp'),
    mail: t('Message.mail'),
    skype: t('Message.skype'),
    pbx: t('Message.pbx'),
    phone: t('Message.phone'),
    fb: t('Message.fb'),
    ig: t('Message.ig'),
    custom: t('Message.custom'),
  };

  return (
    <div style={{ marginBottom: '5px' }}>
      <b>
        {isMessageFromClient
          ? messageAuthorDisplay
          : isMessageAuthorAgent(messageAuthorForDisplay || {}) || item.agent
            ? `(${t('Message.theResponseFromTheInterface')} ${agentOriginByDialog[dialog.origin]})`
            : messageAuthorDisplay}
      </b>{' '}
      {moment(item.createdAt).format('DD.MM.YYYY • HH:mm')}
    </div>
  );
}

MessageAuthor.propTypes = {
  isMessageFromClient: PropTypes.bool,
  item: PropTypes.object,
  dialog: PropTypes.object,
  messageAuthorDisplay: PropTypes.string,
  messageAuthorForDisplay: PropTypes.object,
};

const wrapLinksWithATag = (text) =>
  `${text} `.replace(
    /(?<!<a\s+href=["']|<a\s[^>]*>|<a href=)(https?:\/\/[^\s]+?)(?=\s|<|>|{|}|&lt;|&gt;|\\|\||\^|"|`)/g,
    '<a href="$1" target="_blank" rel="noopener noreferrer">$1</a>',
  );

function MessageContent({ origin = 'mail', item }) {
  const { _id: msgId, text = '', metadata: { html, cleanHtml } = {} } = item;

  if (origin === 'mail') {
    const iframeTitle = `iframe-${msgId}`;
    return cleanHtml || html ? (
      <IframeWrapper
        title={iframeTitle}
        src={cleanHtml || html}
        type="document"
        withPreviewOverlay
      />
    ) : (
      text
    );
  } else {
    if (html || cleanHtml) {
      return (
        <span
          dangerouslySetInnerHTML={{
            __html: wrapLinksWithATag(html || cleanHtml),
          }}
        />
      );
    }
    if (text && text.length) {
      if (/(https?:\/\/[^\s]+?)/.test(text)) {
        return (
          <span
            dangerouslySetInnerHTML={{
              __html: wrapLinksWithATag(text),
            }}
          />
        );
      }
      return text;
    }
  }
  return null;
}

MessageContent.propTypes = {
  origin: PropTypes.string,
  item: PropTypes.object,
};
