import { AnyAction } from 'redux';
import { createSelector } from 'reselect';

export const SET_DASHBOARD_RESPONSIBLE = 'SET_DASHBOARD_RESPONSIBLE';
export const SET_DASHBOARD_EVENT_TYPES = 'SET_DASHBOARD_EVENT_TYPES';

interface IDashboardEventsFilters {
  responsibleUsers: any[];
  eventTypes: any[];
}

const initialDashboardEventsFilters = Object.freeze({
  responsibleUsers: [],
  eventTypes: [],
});

function responsibleUserReducer(state: any, payload: any) {
  return {
    ...state,
    responsibleUsers: payload,
  };
}

function eventTypesReducer(state: any, payload: any) {
  return {
    ...state,
    eventTypes: payload,
  };
}

export function setDashboardResponsibleUsers(responsibleUsers: any) {
  return {
    type: SET_DASHBOARD_RESPONSIBLE,
    payload: { responsibleUsers },
  };
}

export function setDashboardEventTypes(eventTypes: any) {
  return {
    type: SET_DASHBOARD_EVENT_TYPES,
    payload: { eventTypes },
  };
}

export default function reducer(
  state: IDashboardEventsFilters = initialDashboardEventsFilters,
  action: AnyAction,
) {
  switch (action.type) {
    case SET_DASHBOARD_RESPONSIBLE: {
      return {
        ...state,
        ...responsibleUserReducer(state, action.payload.responsibleUsers),
      };
    }
    case SET_DASHBOARD_EVENT_TYPES: {
      return {
        ...state,
        ...eventTypesReducer(state, action.payload.eventTypes),
      };
    }
    default: {
      return state;
    }
  }
}

export const getDashboardEventsFilters = (state: any) =>
  state.dashboardEventsFilters || initialDashboardEventsFilters;

export const dashboardEventsEventTypesSelector = createSelector(
  getDashboardEventsFilters,
  (dashboardEventsFilters) =>
    dashboardEventsFilters.eventTypes ||
    initialDashboardEventsFilters.eventTypes,
);

export const dashboardEventsResponsibleUsersSelector = createSelector(
  getDashboardEventsFilters,
  (dashboardEventsFilters) =>
    dashboardEventsFilters.responsibleUsers ||
    initialDashboardEventsFilters.responsibleUsers,
);
