import { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  dialogsListSearchSelector,
  setSearch,
} from '../../reducers/dialogsFilters';
import {
  callsListSearchSelector,
  setCallsSearch,
} from '../../reducers/callsFilters';
import usePrevious from '../usePrevious';
import useDialogsListMode from './useDialogsListMode';
import useDialogsListContentSearchMode from './useDialogsListContentSearchMode';

const useDialogsListSearch = ({ onSearchChange } = {}) => {
  const dispatch = useDispatch();

  const { dialogsListMode = 'dialogs' } = useDialogsListMode();

  /**
   * Handle content search mode changes
   */
  useDialogsListContentSearchMode({
    onContentSearchModeChange: onSearchChange,
  });

  const searchSelector =
    dialogsListMode === 'calls'
      ? callsListSearchSelector
      : dialogsListSearchSelector;
  const search = useSelector(searchSelector);
  const prevSearch = usePrevious(search);

  const searchUpdateAction =
    dialogsListMode === 'calls' ? setCallsSearch : setSearch;
  const updateSearch = useCallback(
    (searchValue) => {
      dispatch(searchUpdateAction(searchValue));
    },
    [dispatch, searchUpdateAction],
  );

  useEffect(() => {
    if (onSearchChange && prevSearch !== undefined && prevSearch !== search) {
      onSearchChange();
    }
  }, [prevSearch, search, onSearchChange]);

  return { search, updateSearch };
};

export default useDialogsListSearch;
