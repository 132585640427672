/**
 * @description This function returns next monday date in ISO string format.
 * @param {Date} currentDate Defaults to new Date().
 * @returns {string} Date in ISO format.
 */
const getNextMondayMorningDate = (currentDate = new Date()) => {
  const currentWeekDay = currentDate.getDay();
  const currentDay = currentDate.getDate();

  const todayIsSunday = currentWeekDay === 0;

  const prevSunday = todayIsSunday
    ? currentDay - 7
    : currentDay - currentWeekDay;
  const prevMonday = prevSunday + 1;

  const nextMonday = prevMonday + 7;

  const nextMondayMorningDate = new Date(currentDate);
  nextMondayMorningDate.setDate(nextMonday);
  nextMondayMorningDate.setHours(8, 0);

  return nextMondayMorningDate.toISOString();
};

export default getNextMondayMorningDate;
