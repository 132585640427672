/* eslint-disable no-console */
import {
  recieveChatraAccount,
  enableChatraAccount,
} from '../../reducers/chatra-accounts';
import fetchJWT from '../../utils/fetchJWT';
import { logout } from '../../actions';
import backend from '../../configs/backend';

const getChatraAccount = (id) => async (dispatch) => {
  const request = new Request(
    backend.applyBaseUrl(`/api/chatra/accounts/read/${id}`),
    {
      method: 'GET',
      cache: 'default',
      mode: 'cors',
    },
  );
  fetchJWT(request)
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
      if (res.status === 401) {
        dispatch(logout());
      }
      return [];
    })
    .then((res) => {
      dispatch(recieveChatraAccount(res[0]));
      dispatch(enableChatraAccount(res[0]._id));
    })
    .catch((err) => console.error(err));
};

export default getChatraAccount;
