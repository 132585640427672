import * as R from 'ramda';

export const ADD_FILE = 'ADD_FILE';
export const RECEIVE_FILES = 'RECEIVE_FILES';

const NewFile = {};

function fileFactory(file) {
  return { [file._id]: file };
}

function filesReducer(state = NewFile, action = {}) {
  switch (action.type) {
    case ADD_FILE: {
      return { ...state, ...action.payload.file };
    }
    case RECEIVE_FILES: {
      return R.mergeAll(R.map(fileFactory, action.payload.files));
    }
    default: {
      return state;
    }
  }
}

export const receiveFiles = (files) => ({
  type: RECEIVE_FILES,
  payload: { files },
});

// запись файла в стейт === получение файла с бэка
export const addFile = (file) => ({
  type: ADD_FILE,
  payload: { file },
});

export default function reducer(state = {}, action = { type: '' }) {
  switch (action.type) {
    case ADD_FILE: {
      return R.assoc(
        action.payload._id,
        filesReducer(state[action.payload.id], action),
      )(state);
    }
    case RECEIVE_FILES: {
      return R.mergeRight(state, filesReducer(state, action));
    }
    default: {
      return state;
    }
  }
}
