import { createSelector } from 'reselect';
import { usersArraySelectorIsActive } from '../reducers/users';

const getCurrentUser = (state) => state.auth.user;

const getPossibleUsersSelector = (state) => usersArraySelectorIsActive(state);

const messageCreatorSelector = createSelector(
  [getCurrentUser, getPossibleUsersSelector],
  (currentUser, users) => {
    const [dialogClient] = users.filter(
      (userN) => userN._id === currentUser._id,
    );
    return dialogClient;
  },
);

export default messageCreatorSelector;
