import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import usePrevious from '../usePrevious';

const useDialogMessagesCount = ({ dialogId, onCountChange }) => {
  const dialogMessagesCountSelector = useCallback(
    (state) =>
      Object.values(state.messages).filter((m) => m.dialogId === dialogId)
        .length,
    [dialogId],
  );
  const dialogMessagesCount = useSelector(dialogMessagesCountSelector);

  const prevDialogMessagesCount = usePrevious(dialogMessagesCount);
  useEffect(() => {
    if (
      onCountChange &&
      prevDialogMessagesCount &&
      prevDialogMessagesCount !== dialogMessagesCount
    ) {
      onCountChange();
    }
  }, [prevDialogMessagesCount, dialogMessagesCount, onCountChange]);

  return { dialogMessagesCount };
};

export default useDialogMessagesCount;
