const integrationsConfig = {
  amoCrm: {
    items: {
      availableTypes: ['contact', 'company', 'lead'],
      amoCrmKeyByType: {
        contact: 'contacts',
        company: 'companies',
        lead: 'leads',
      },
      emptyListTextByType: {
        contact: 'нет контактов из интеграции',
        company: 'нет компаний из интеграции',
        lead: 'нет сделок из интеграции',
      },
    },
    v2: {
      oauth: {
        integration: {
          baseRedirectUrl: 'https://www.amocrm.ru/oauth',
          clientId: '9392fb8f-e331-4799-8d57-aec0fc9afabb',
        },
        button: {
          title: {
            install: 'Установить интеграцию',
            reconnect: 'Переподключить интеграцию',
          },
        },
        window: {
          width: 750,
          height: 580,
          title: 'Авторизация в Амоцрм',
        },
      },
    },
  },
};

export default integrationsConfig;
