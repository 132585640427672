/* eslint-disable no-underscore-dangle  */
import * as R from 'ramda';
import { createSelector } from 'reselect';

export const RECIEVE_WHATSAPP_ACCOUNTS = 'RECIEVE_WHATSAPP_ACCOUNTS';
export const RECIEVE_WHATSAPP_ACCOUNT = 'RECIEVE_WHATSAPP_ACCOUNT';
export const DELETE_WHATSAPP_ACCOUNT = 'DELETE_WHATSAPP_ACCOUNT';
export const UPDATE_WHATSAPP_ACCOUNT = 'UPDATE_WHATSAPP_ACCOUNT';
export const ENABLE_WHATSAPP_ACCOUNT = 'ENABLE_WHATSAPP_ACCOUNT';
export const DISABLE_WHATSAPP_ACCOUNT = 'DISABLE_WHATSAPP_ACCOUNT';
export const SET_WHATSAPP_CH_NAME = 'SET_WHATSAPP_CH_NAME';
export const SET_WHATSAPP_TOKEN = 'SET_WHATSAPP_TOKEN';
export const SET_WHATSAPP_API_URL = 'SET_WHATSAPP_API_URL';
export const DELETE_WHATSAPP_ACCOUNT_DRAFT = 'DELETE_WHATSAPP_ACCOUNT_DRAFT';

function whatsAppAccountFactory(account) {
  return { [account._id]: account };
}

function whatsAppAccountsReducer(state = {}, action = { type: '' }) {
  switch (action.type) {
    case RECIEVE_WHATSAPP_ACCOUNTS: {
      return R.mergeAll(
        R.map(whatsAppAccountFactory, action.payload.whatsAppAccounts),
      );
    }
    case RECIEVE_WHATSAPP_ACCOUNT: {
      return { ...state, ...action.payload.whatsAppAccount };
    }
    case UPDATE_WHATSAPP_ACCOUNT: {
      return {
        ...state,
        ...(action.payload.token !== undefined
          ? { token: action.payload.token }
          : {}),
        ...(action.payload.name !== undefined
          ? { name: action.payload.name }
          : {}),
        ...(action.payload.apiUrl !== undefined
          ? { apiUrl: action.payload.apiUrl }
          : {}),
        ...(action.payload.instanceId !== undefined
          ? {
              integration: {
                ...(state.integration || {}),
                instanceId: action.payload.instanceId,
              },
            }
          : {}),
        ...(action.payload.apiToken !== undefined
          ? {
              integration: {
                ...(state.integration || {}),
                apiToken: action.payload.apiToken,
              },
            }
          : {}),
        ...(action.payload.msgArchiveStatus !== undefined
          ? { msgArchiveStatus: action.payload.msgArchiveStatus }
          : {}),
      };
    }
    case DISABLE_WHATSAPP_ACCOUNT: {
      return { ...state, isEnabled: false };
    }
    case ENABLE_WHATSAPP_ACCOUNT: {
      return { ...state, isEnabled: true };
    }
    case SET_WHATSAPP_CH_NAME: {
      return { ...state, name: action.payload.name };
    }
    case SET_WHATSAPP_TOKEN: {
      return { ...state, token: action.payload.token };
    }
    case SET_WHATSAPP_API_URL: {
      return { ...state, apiUrl: action.payload.apiUrl };
    }
    case DELETE_WHATSAPP_ACCOUNT: {
      return { ...state, isDeleted: true };
    }
    default: {
      return state;
    }
  }
}

export const recieveWhatsAppAccounts = (whatsAppAccounts) => ({
  type: RECIEVE_WHATSAPP_ACCOUNTS,
  payload: { whatsAppAccounts },
});

export const recieveWhatsAppAccount = (whatsAppAccount) => ({
  type: RECIEVE_WHATSAPP_ACCOUNT,
  payload: { whatsAppAccount },
});

export const renewWhatsAppAccount = ({
  whatsAppAccountId,
  token,
  name,
  apiUrl,
  msgArchiveStatus,
  apiToken,
  instanceId,
}) => ({
  type: UPDATE_WHATSAPP_ACCOUNT,
  payload: {
    whatsAppAccountId,
    token,
    name,
    apiUrl,
    msgArchiveStatus,
    apiToken,
    instanceId,
  },
});

export const removeWhatsAppAccount = (_id) => ({
  type: DELETE_WHATSAPP_ACCOUNT,
  payload: { _id },
});

export const enableWhatsAppAccount = (whatsAppAccountId) => ({
  type: ENABLE_WHATSAPP_ACCOUNT,
  payload: { whatsAppAccountId },
});

export const disableWhatsAppAccount = (whatsAppAccountId) => ({
  type: DISABLE_WHATSAPP_ACCOUNT,
  payload: { whatsAppAccountId },
});

export const setWhatsAppName = (_id, name) => ({
  type: SET_WHATSAPP_CH_NAME,
  payload: { _id, name },
});

export const setWhatsAppToken = (_id, token) => ({
  type: SET_WHATSAPP_TOKEN,
  payload: { _id, token },
});

export const setWhatsAppApiUrl = (_id, apiUrl) => ({
  type: SET_WHATSAPP_API_URL,
  payload: { _id, apiUrl },
});

export const deleteWhatsAppAccountDraft = () => ({
  type: DELETE_WHATSAPP_ACCOUNT_DRAFT,
});

export default function reducer(state = {}, action = { type: '' }) {
  switch (action.type) {
    case RECIEVE_WHATSAPP_ACCOUNTS: {
      return R.mergeRight(state, whatsAppAccountsReducer(state, action));
    }
    case RECIEVE_WHATSAPP_ACCOUNT: {
      if (action.payload.whatsAppAccount) {
        return R.assoc(
          action.payload.whatsAppAccount._id,
          whatsAppAccountsReducer(
            state[action.payload.whatsAppAccount._id],
            action,
          ),
        )(state);
      }
      return state;
    }
    case ENABLE_WHATSAPP_ACCOUNT:
    case DISABLE_WHATSAPP_ACCOUNT:
    case UPDATE_WHATSAPP_ACCOUNT: {
      return R.assoc(
        action.payload.whatsAppAccountId,
        whatsAppAccountsReducer(
          state[action.payload.whatsAppAccountId],
          action,
        ),
      )(state);
    }
    case SET_WHATSAPP_CH_NAME:
    case SET_WHATSAPP_TOKEN:
    case SET_WHATSAPP_API_URL:
    case DELETE_WHATSAPP_ACCOUNT: {
      return R.assoc(
        action.payload._id,
        whatsAppAccountsReducer(state[action.payload._id], action),
      )(state);
    }
    case DELETE_WHATSAPP_ACCOUNT_DRAFT: {
      return R.dissoc('new')(state);
    }
    default: {
      return state;
    }
  }
}

export const whatsAppAccountsSelector = (state) => state.whatsAppAccounts;

export const whatsAppAccountsArraySelector = createSelector(
  whatsAppAccountsSelector,
  (whatsAppAccounts) =>
    R.filter(
      (_) => !_.isDeleted && _._id && _._id !== 'new',
      R.values(whatsAppAccounts),
    ),
);

export const deletedWhatsAppAccountsArraySelector = createSelector(
  whatsAppAccountsSelector,
  (whatsAppAccounts) =>
    R.filter((_) => _.isDeleted, R.values(whatsAppAccounts)),
);

export const whatsAppAccountIdSelector = (state, ownProps) => {
  const { params: { channelId } = {} } = ownProps.match;
  return channelId || ownProps.id || ownProps._id;
};

export const whatsAppAccountSelector = createSelector(
  whatsAppAccountsSelector,
  whatsAppAccountIdSelector,
  (accounts, id) => R.propOr({}, id, accounts),
);
