import { search as searchConfig } from '../configs';

/**
 * @description This function replaces special characters in a string (ex: for a search)
 * @param {string} text
 * @returns {string} String with replaced special characters.
 */

const replaceSpecChars = (text = '') =>
  text.replace(searchConfig.findSpecCharsRegEx, '\\$&');

export default replaceSpecChars;
