import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import generateAvatarForEntity from '../../../../utils/generateAvatarForEntity';
import isMessageAuthorAgent from '../../../../utils/isMessageAuthorAgent';
import { AudioWrapper } from '../../../../components/ContentWrappers';
// import { messages as msgConf } from '../../../../configs';
import { UserOutline } from 'antd-mobile-icons';
import { withTranslation } from 'react-i18next';
import { Avatar } from 'antd-mobile';

class Call extends Component {
  static propTypes = {
    dialog: PropTypes.object,
    item: PropTypes.object,
    dialogAuthor: PropTypes.object,
    dialogClient: PropTypes.object,
    messageAuthor: PropTypes.object,
    messageAuthorDisplay: PropTypes.string,
    t: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    this.state = {
      hover: false,
    };
  }

  handleAgentOrigin = () => {
    const agentOriginByDialog = {
      chatra: this.props.t('Call.chatra'),
      vk: this.props.t('Call.vk'),
      telegram: this.props.t('Call.telegram'),
      whatsapp: this.props.t('Call.whatsapp'),
      mail: this.props.t('Call.mail'),
      skype: this.props.t('Call.skype'),
      pbx: this.props.t('Call.pbx'),
      phone: this.props.t('Call.phone'),
      custom: this.props.t('Call.custom'),
      ig: this.props.t('Call.ig'),
    };
    return agentOriginByDialog[this.props.dialog.origin];
  };

  toggleHover = () => this.setState({ hover: !this.state.hover });

  render() {
    const { item, dialogAuthor, messageAuthor, messageAuthorDisplay, dialog } =
      this.props;

    // const { statusLabels } = msgConf;

    const generatedAvatar = messageAuthor
      ? generateAvatarForEntity(messageAuthor, dialog)
      : null;
    // const dialogClient = this.props.dialogClient || '';

    // const itemRejectedMailCase = item.code
    //   ? `${item.code} ${item.errno}`
    //   : statusLabels.rejected;

    const messageAuthorForDisplay = messageAuthor || {
      ...(item.contactsValue
        ? { accountId: item.accountId, name: item.contactsValue }
        : {}),
    };

    const isMessageFromClient =
      !item.agent &&
      (item.direction === 'in' ||
        (item.authorId && dialogAuthor._id === item.authorId) ||
        (item.contactsId &&
          dialogAuthor.contacts.find(
            (cData) => cData.id === dialog.contactsId,
          )) ||
        messageAuthor.service) &&
      (!item.event || (item.event && item.event !== 'call_answered'));

    if (item && messageAuthor) {
      return (
        <div style={{ padding: '6px 5px', display: 'flex', gap: '6px' }}>
          <div
            className="DialogListItemAvatar"
            style={{
              ...(isMessageFromClient ? { borderRadius: '50%' } : {}),
              ...(messageAuthorForDisplay &&
              messageAuthorForDisplay.avatarBgColor
                ? {
                    backgroundColor: messageAuthorForDisplay.avatarBgColor,
                  }
                : {}),
              ...(item?.authorId === 'bot' ? { backgroundColor: 'pink' } : {}),
            }}
          >
            {isMessageFromClient ? null : messageAuthorForDisplay.avatar ? (
              <Avatar
                src={messageAuthorForDisplay.avatar}
                style={{ '--size': '36px' }}
                fit="cover"
              />
            ) : (
              <UserOutline fontSize={20} />
            )}
            {!item.agent &&
            ((item.authorId && dialogAuthor._id === item.authorId) ||
              (item.contactsId &&
                dialogAuthor.contacts.find(
                  (cData) => cData.id === dialog.contactsId,
                )) ||
              messageAuthor.service) &&
            (!item.event ||
              (item.event && item.event !== 'call_answered') ||
              item.direction === 'in')
              ? generatedAvatar
              : null}
          </div>
          <div>
            <div style={{ marginBottom: '5px' }}>
              <b>
                {isMessageFromClient ? messageAuthorDisplay : null}
                {isMessageFromClient
                  ? null
                  : isMessageAuthorAgent(messageAuthor) || item.agent
                    ? `(${this.props.t('Call.theResponseFromTheInterface')} ${this.handleAgentOrigin()})`
                    : messageAuthorDisplay}
              </b>{' '}
              {moment(item.createdAt).format('DD.MM.YYYY • HH:mm')}
            </div>
            {item.event &&
            item.event === 'call_end' &&
            item.call_duration &&
            item.call_duration !== 0 ? (
              <div>
                <AudioWrapper
                  file={{ key: this.props.dialog._id }}
                  dialog={this.props.dialog}
                  needProxy={false}
                  messageId={item._id}
                />
              </div>
            ) : null}
            <div style={{ wordBreak: 'break-word', whiteSpace: 'pre-wrap' }}>
              {item.text || this.props.t('Call.aCallTookPlace')}
            </div>
          </div>
        </div>
      );
    }
    return null;
  }
}

export default withTranslation()(Call);
