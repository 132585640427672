import { receiveTemplate } from '../../reducers/templates';
import fetchJWT from '../../utils/fetchJWT';
import { logout } from '../../actions';
import backend from '../../configs/backend';

const getTemplate = (id, cb) => async (dispatch) => {
  const request = new Request(
    backend.applyBaseUrl(`/api/messages/read/template/${id}`),
    {
      method: 'GET',
      cache: 'default',
      mode: 'cors',
    },
  );
  fetchJWT(request)
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
      if (res.status === 401) {
        dispatch(logout());
      }
      if (res.status === 500) {
        if (cb) {
          cb();
        }
      }
      return {};
    })
    .then((res) => {
      dispatch(receiveTemplate(res));
    })
    .catch((err) => console.error(err));
};

export default getTemplate;
