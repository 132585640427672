import { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  getDialogsChannelFilters,
  setFilterChannels,
} from '../../reducers/dialogsFilters';
import {
  getCallsChannelFilters,
  setCallsFilterChannels,
} from '../../reducers/callsFilters';
import usePrevious from '../usePrevious';
import useDialogsListMode from './useDialogsListMode';
import areTwoArraysContentEqual from '../../utils/areTwoArraysContentEqual';
import useLoggedUser from '../auth/useLoggedUser';

const useDialogsListChannelFilters = ({ onChannelFiltersChange } = {}) => {
  const dispatch = useDispatch();

  const { user: loggedUser } = useLoggedUser();

  const { dialogsListMode = 'dialogs' } = useDialogsListMode();

  const channelFiltersUpdateAction =
    dialogsListMode === 'calls' ? setCallsFilterChannels : setFilterChannels;
  const updateChannelFilters = useCallback(
    (channel) => {
      dispatch(channelFiltersUpdateAction(channel, loggedUser));
    },
    [dispatch, channelFiltersUpdateAction, loggedUser],
  );

  const channelFiltersSelector =
    dialogsListMode === 'calls'
      ? getCallsChannelFilters
      : getDialogsChannelFilters;
  const channelFilters = useSelector(channelFiltersSelector);
  const prevChannelFilters = usePrevious(channelFilters);

  useEffect(() => {
    if (
      onChannelFiltersChange &&
      prevChannelFilters &&
      !areTwoArraysContentEqual(prevChannelFilters, channelFilters)
    ) {
      onChannelFiltersChange();
    }
  }, [prevChannelFilters, channelFilters, onChannelFiltersChange]);

  return { channelFilters, updateChannelFilters };
};

export default useDialogsListChannelFilters;
