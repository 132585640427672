import PropTypes from 'prop-types';

import withInfiniteLoading from '../../../with/virtualList/withInfiniteLoading';
import DynamicVirtualList from '../DynamicVirtualList';

const InfiniteDynamicVirtualList = withInfiniteLoading(DynamicVirtualList);

InfiniteDynamicVirtualList.displayName = 'InfiniteDynamicVirtualList';
InfiniteDynamicVirtualList.propTypes = {
  loadMoreFn: PropTypes.func.isRequired,
  loadMoreDebounceTimeout: PropTypes.number.isRequired,
  recalcThrottleTimeout: PropTypes.number.isRequired,
  fallbackItemSize: PropTypes.number.isRequired,
  mapItemIdToIndex: PropTypes.func.isRequired,
  mapIndexToItemId: PropTypes.func.isRequired,
  itemCount: PropTypes.number.isRequired,
  overscanCount: PropTypes.number,
  ListItem: PropTypes.elementType.isRequired,
  itemData: PropTypes.object,
  onItemsRendered: PropTypes.func,
  onScroll: PropTypes.func,
  customClassnames: PropTypes.arrayOf(PropTypes.string),
};

export default InfiniteDynamicVirtualList;
