import React, { useRef } from 'react';
import { List, Ellipsis } from 'antd-mobile';
import PropTypes from 'prop-types';
import { AddCircleOutline } from 'antd-mobile-icons';
import { useTranslation } from 'react-i18next';

const DialogFormFile = ({ uploadFile, onClose }) => {
  const { t } = useTranslation();
  const fileInputRef = useRef(null);

  const onUpload = (event) => {
    const files = event.target.files;
    if (files.length) {
      const fileList = [];
      for (let i = 0; i < files.length; i += 1) {
        const item = files[i];
        const file = {
          lastModified: item.lastModified,
          lastModifiedDate: item.lastModifiedDate,
          name: item.name,
          originFileObj: item,
          percent: 0,
          size: item.size,
          status: 'uploading',
          type: item.type,
        };
        const reader = new FileReader();
        reader.onloadend = () => {
          file.content = reader.result;
          fileList.push(file);
          if (fileList.length === files.length) {
            uploadFile(fileList);
            event.target.value = '';
            onClose();
          }
        };
        reader.readAsArrayBuffer(file.originFileObj);
      }
    }
  };

  return (
    <>
      <input
        ref={fileInputRef}
        type="file"
        accept=""
        multiple={true}
        style={{ display: 'none' }}
        onInput={onUpload}
      />
      <List.Item
        arrowIcon={[]}
        prefix={<AddCircleOutline fontSize={24} style={{ display: 'block' }} />}
        onClick={() => fileInputRef.current?.click()}
      >
        <Ellipsis direction="end" content={t('DialogFormFile.files')} />
      </List.Item>
    </>
  );
};

DialogFormFile.propTypes = {
  uploadFile: PropTypes.func,
  onClose: PropTypes.func,
};

export default DialogFormFile;
