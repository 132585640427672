import fetchJWT from '../../utils/fetchJWT';
import { logout } from '../../actions';
import { receiveDialogMessagesListPositions } from '../../reducers/dialogMessagesList';
import backend from '../../configs/backend';

const getDialogMessagesListPositions = (dialogId, cb) => async (dispatch) => {
  const request = new Request(
    backend.applyBaseUrl(`/api/messages/list/positions/${dialogId}`),
    {
      method: 'GET',
    },
  );

  fetchJWT(request)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      if (response.status === 401) {
        dispatch(logout());
      }

      throw new Error(
        `Cannot get dialog messages list positions: ${
          response.err || response.status
        }`,
      );
    })
    .then((positions) => {
      if (cb) {
        cb({});
      }
      dispatch(receiveDialogMessagesListPositions(dialogId, positions));
    })
    .catch((err) => {
      console.error(err);

      if (cb) {
        cb({ err });
      }
    });
};

export default getDialogMessagesListPositions;
