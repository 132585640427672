import * as R from 'ramda';
import { createSelector } from 'reselect';

export const RECIEVE_MAIL_ACCOUNT = 'RECIEVE_MAIL_ACCOUNT';
export const RECIEVE_MAIL_ACCOUNTS = 'RECIEVE_MAIL_ACCOUNTS';
export const DELETE_MAIL_ACCOUNT = 'DELETE_MAIL_ACCOUNT';
export const SET_EMAIL = 'SET_EMAIL';
export const SET_FROM = 'SET_FROM';
export const SET_PASSWORD = 'SET_PASSWORD';
export const SET_SMTP_SERVER = 'SET_SMTP_SERVER';
export const SET_SMTP_PORT = 'SET_SMTP_PORT';
export const SET_IMAP_SERVER = 'SET_IMAP_SERVER';
export const SET_IMAP_PORT = 'SET_IMAP_PORT';
export const SET_IS_ENABLED = 'SET_IS_ENABLED';
export const UPDATE_MAIL_ACCOUNT = 'UPDATE_MAIL_ACCOUNT';
export const DELETE_MAIL_ACCOUNT_DRAFT = 'DELETE_MAIL_ACCOUNT_DRAFT';
export const RESET_MAIL_ACCOUNT = 'RESET_MAIL_ACCOUNT';

const MailAccount = {
  _id: null,
  isEnabled: false,
  isDeleted: false,
  email: null,
  from: null,
  password: null,
  separateSmtpAuth: false,
  smtp: {
    server: null,
    port: null,
    secure: null,
    login: null,
    password: null,
  },
  imap: {
    server: null,
    port: null,
    secure: null,
  },
};

function mailAccountFactory(mailAccount) {
  return { [mailAccount._id]: { ...MailAccount, ...mailAccount } };
}

function mailAccountsReducer(state = MailAccount, action = {}) {
  if (state._id && state._id !== action.payload._id) {
    return state;
  }
  switch (action.type) {
    case SET_EMAIL: {
      return { ...state, email: action.payload.email };
    }
    case SET_FROM: {
      return { ...state, from: action.payload.from };
    }
    case SET_PASSWORD: {
      return { ...state, password: action.payload.password };
    }
    case SET_SMTP_SERVER: {
      return R.assocPath(['smtp', 'server'], action.payload.server, state);
    }
    case SET_SMTP_PORT: {
      return R.assocPath(['smtp', 'port'], action.payload.port, state);
    }
    case SET_IMAP_SERVER: {
      return R.assocPath(['imap', 'server'], action.payload.server, state);
    }
    case SET_IMAP_PORT: {
      return R.assocPath(['imap', 'port'], action.payload.port, state);
    }
    case SET_IS_ENABLED: {
      return { ...state, isEnabled: action.payload.isEnabled };
    }
    case UPDATE_MAIL_ACCOUNT: {
      const { email, password, separateSmtpAuth, smtp, imap, from, name } =
        action.payload;

      return {
        ...state,
        ...(email !== undefined ? { email } : {}),
        ...(password !== undefined ? { password } : {}),
        ...(separateSmtpAuth !== undefined ? { separateSmtpAuth } : {}),
        ...(smtp !== undefined ? { smtp } : {}),
        ...(imap !== undefined ? { imap } : {}),
        ...(from !== undefined ? { from } : {}),
        ...(name !== undefined ? { name } : {}),
      };
    }
    case RECIEVE_MAIL_ACCOUNT: {
      return { ...state, ...action.payload.mailAccount };
    }
    case RECIEVE_MAIL_ACCOUNTS: {
      return R.mergeAll(R.map(mailAccountFactory, action.payload.mailAccounts));
    }
    case DELETE_MAIL_ACCOUNT: {
      return { ...state, isDeleted: true };
    }
    default: {
      return state;
    }
  }
}

export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case SET_EMAIL:
    case SET_FROM:
    case SET_PASSWORD:
    case SET_SMTP_SERVER:
    case SET_SMTP_PORT:
    case SET_IMAP_SERVER:
    case SET_IMAP_PORT:
    case SET_IS_ENABLED:
    case DELETE_MAIL_ACCOUNT:
    case UPDATE_MAIL_ACCOUNT: {
      return R.assoc(
        action.payload._id,
        mailAccountsReducer(state[action.payload._id], action),
      )(state);
    }
    case RESET_MAIL_ACCOUNT: {
      // reseting state = no choice but to overwrite field!
      return R.assoc(
        action.payload.mailAccount._id,
        action.payload.mailAccount,
      )(state);
    }
    case RECIEVE_MAIL_ACCOUNT: {
      return R.assoc(
        action.payload.mailAccount._id,
        mailAccountsReducer(state[action.payload.mailAccount._id], action),
      )(state);
    }
    case RECIEVE_MAIL_ACCOUNTS: {
      return R.mergeRight(state, mailAccountsReducer(state, action));
    }
    case DELETE_MAIL_ACCOUNT_DRAFT: {
      const cleanedState = R.dissoc('new', state);
      return R.dissoc('null', cleanedState);
    }
    default: {
      return state;
    }
  }
}

export const setEmail = (_id, email) => ({
  type: SET_EMAIL,
  payload: { _id, email },
});

export const setFrom = (_id, from) => ({
  type: SET_FROM,
  payload: { _id, from },
});

export const setPassword = (_id, password) => ({
  type: SET_PASSWORD,
  payload: { _id, password },
});

export const setSmtpServer = (_id, server) => ({
  type: SET_SMTP_SERVER,
  payload: { _id, server },
});

export const setSmtpPort = (_id, port) => ({
  type: SET_SMTP_PORT,
  payload: { _id, port },
});

export const setImapServer = (_id, server) => ({
  type: SET_IMAP_SERVER,
  payload: { _id, server },
});

export const setImapPort = (_id, port) => ({
  type: SET_IMAP_PORT,
  payload: { _id, port },
});

export const setIsEnabled = (_id, isEnabled) => ({
  type: SET_IS_ENABLED,
  payload: { _id, isEnabled },
});

export const renewMailAccount = ({
  _id,
  email,
  password,
  separateSmtpAuth,
  smtp,
  imap,
  from,
  name,
}) => ({
  type: UPDATE_MAIL_ACCOUNT,
  payload: { _id, email, password, separateSmtpAuth, smtp, imap, from, name },
});

export const recieveMailAccount = (mailAccount) => ({
  type: RECIEVE_MAIL_ACCOUNT,
  payload: { mailAccount },
});

export const recieveMailAccounts = (mailAccounts) => ({
  type: RECIEVE_MAIL_ACCOUNTS,
  payload: { mailAccounts },
});

export const deleteMailAccount = (_id) => ({
  type: DELETE_MAIL_ACCOUNT,
  payload: { _id },
});

export const deleteMailAccountDraft = () => ({
  type: DELETE_MAIL_ACCOUNT_DRAFT,
});

export const resetMailAccount = (mailAccount) => ({
  type: RESET_MAIL_ACCOUNT,
  payload: { mailAccount },
});

export const mailAccountsSelector = (state) => state.mailAccounts;

export const mailAccountsArraySelector = createSelector(
  mailAccountsSelector,
  (mailAccounts) =>
    R.filter(
      (_) => !_.isDeleted && _._id && _._id !== 'new',
      R.values(mailAccounts),
    ),
);

export const deletedMailAccountsArraySelector = createSelector(
  mailAccountsSelector,
  (mailAccounts) => R.filter((_) => _.isDeleted, R.values(mailAccounts)),
);

export const mailAccountIdSelector = (state, ownProps) => {
  const { params: { channelId } = {} } = ownProps.match;
  return channelId || ownProps.id || ownProps._id;
};

export const mailAccountSelector = createSelector(
  mailAccountsSelector,
  mailAccountIdSelector,
  (accounts, id) => R.propOr(MailAccount, id, accounts),
);
