export const SET_CLIENTS_FILTER = 'SET_CLIENTS_FILTER';
export const CLEAR_CLIENTS_FILTER = 'CLEAR_CLIENTS_FILTER';

export const setClientsFilter = (value) => ({
  type: SET_CLIENTS_FILTER,
  payload: value,
});

export const clearClientsFilter = () => ({
  type: CLEAR_CLIENTS_FILTER,
});

export default function reducer(state = '', action = {}) {
  switch (action.type) {
    case SET_CLIENTS_FILTER: {
      return action.payload;
    }
    case CLEAR_CLIENTS_FILTER: {
      return '';
    }
    default: {
      return state;
    }
  }
}

export const getClientsFilter = (state) => state.clientsFilter;
