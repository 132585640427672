import { createSelector } from 'reselect';
import { dialogMessagesListPositionsSelector } from '../reducers/dialogMessagesList';

const getDialogIdFromProps = (_, props) => props.dialogId;

const dialogMessagesListDialogPositionsSelector = createSelector(
  [getDialogIdFromProps, dialogMessagesListPositionsSelector],
  (dialogId, dialogMessagesListPositions) =>
    dialogMessagesListPositions[dialogId],
);

export default dialogMessagesListDialogPositionsSelector;
