/*
 * Function to proxy Telegram and Skype attachments due to Russian Federation net restrictions
 * May return 404 while file is being fetched (works for big files)
 * We assume that 5 tries for 3 sec each are good enough for UX
 * */
/* eslint-disable no-console */
import { logout } from '../../actions';
import fetchJWT from '../../utils/fetchJWT';
import backend from '../../configs/backend';

const proxyAttachments =
  (
    {
      channelType,
      channelId,
      Key,
      name,
      messageId,
      setNameFromHeader,
      tries = 5,
      mode = 'newWindow',
    },
    cb,
  ) =>
  (dispatch) => {
    if (channelType === 'telegram') {
      const urlRequest = new Request(
        backend.applyBaseUrl(
          `/api/messages/file/link/${Key}?origin=${channelType}&messageId=${messageId}`,
        ),
      );

      let fileName = null;

      fetchJWT(urlRequest)
        .then((res) => {
          if (res.ok) {
            return res.text();
          }
          if (res.status === 401) {
            dispatch(logout());
          }
          return null;
        })
        .then((url) => {
          if (url) {
            const request = new Request(
              backend.applyBaseUrl(
                `/api/${channelType}-channel/proxy_attachment?channelId=${channelId}&uri=${url}`,
              ),
              { method: 'GET', mode: 'cors' },
            );

            fetchJWT(request)
              .then((res) => {
                if (res.ok) {
                  if (setNameFromHeader) {
                    const dispositionHeader = res.headers.get(
                      'content-disposition',
                    );

                    if (
                      dispositionHeader &&
                      dispositionHeader.indexOf('attachment') !== -1
                    ) {
                      const filenameRegex =
                        /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                      const matches = filenameRegex.exec(dispositionHeader);
                      if (matches != null && matches[1]) {
                        fileName = matches[1].replace(/['"]/g, '');
                      }
                    }
                  }

                  return res.blob();
                }
                if (res.status === '401') {
                  dispatch(logout());
                } else if (res.status === 404 && tries > 0) {
                  setTimeout(() => {
                    dispatch(
                      proxyAttachments(
                        {
                          channelType,
                          channelId,
                          Key,
                          name,
                          messageId,
                          setNameFromHeader,
                          tries: tries - 1,
                        },
                        cb,
                      ),
                    );
                  }, 3000);
                }
                return null;
              })
              .then((res) => {
                if (res) {
                  // const blob = new Blob([res], { type: 'octet/stream' });
                  const fileUrl = window.URL.createObjectURL(res);

                  if (cb && mode === 'src') {
                    cb({ url: fileUrl, originalFile: res });
                  } else {
                    const linkElement = document.createElement('a');
                    document.body.appendChild(linkElement);

                    linkElement.style = 'display: none';
                    linkElement.href = fileUrl;
                    linkElement.target = '_blank';
                    linkElement.download = fileName || name;
                    linkElement.click();
                    window.URL.revokeObjectURL(res);
                    cb(true);
                  }
                } else if (tries === 0) {
                  cb(true);
                } else {
                  console.log(
                    `cannot get link for proxied attachment from ${channelType} channel, ${tries} left`,
                  );
                }
              })
              .catch((err) => {
                console.error(err);
                if (cb && mode === 'src') {
                  cb({});
                }
              });
          }
        });
    } else {
      const request = new Request(
        backend.applyBaseUrl(
          `/api/${channelType}-channel/proxy_attachment?channelId=${channelId}&uri=${Key}`,
        ),
        { method: 'GET', mode: 'cors' },
      );

      fetchJWT(request)
        .then((res) => {
          if (res.ok) {
            return res.blob();
          }
          if (res.status === '401') {
            dispatch(logout());
          } else if (res.status === 404 && tries > 0) {
            setTimeout(() => {
              dispatch(
                proxyAttachments(
                  {
                    channelType,
                    channelId,
                    Key,
                    name,
                    messageId,
                    tries: tries - 1,
                  },
                  cb,
                ),
              );
            }, 3000);
          }
          return null;
        })
        .then((res) => {
          if (res) {
            const fileUrl = window.URL.createObjectURL(res);

            if (cb && mode === 'src') {
              cb({ url: fileUrl });
            } else {
              const linkElement = document.createElement('a');
              document.body.appendChild(linkElement);

              linkElement.style = 'display: none';
              linkElement.href = fileUrl;
              linkElement.target = '_blank';
              linkElement.download = name;
              linkElement.click();
              window.URL.revokeObjectURL(res);
              cb(true);
            }
          } else if (tries === 0) {
            cb(true);
          } else {
            console.log(
              `cannot get link for proxied attachment from ${channelType} channel, ${tries} left`,
            );
          }
        })
        .catch((err) => {
          console.error(err);
          if (cb && mode === 'src') {
            cb({});
          }
        });
    }
  };

export default proxyAttachments;
