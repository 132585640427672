/*
 * Import all active channels from state and gather them into single array
 */
import { createSelector } from 'reselect';
import { pbxAccountsArraySelector } from '../reducers/pbx-accounts';
import { demoAccountsArraySelector } from '../reducers/demo-accounts';
import { chatraAccountsArraySelector } from '../reducers/chatra-accounts';
import { telegramAccountsArraySelector } from '../reducers/telegram-accounts';
import { mailAccountsArraySelector } from '../reducers/mail-accounts';
import { whatsAppAccountsArraySelector } from '../reducers/whatsappAccounts';
import { vkAccountsArraySelector } from '../reducers/vk-accounts';
import { skypeAccountsArraySelector } from '../reducers/skype-accounts';
import { fbAccountsArraySelector } from '../reducers/fb-accounts';
import { igAccountsArraySelector } from '../reducers/ig-accounts';
import { customAccountsArraySelector } from '../reducers/custom-accounts';

const getActiveChannelsFromState = createSelector(
  [
    pbxAccountsArraySelector,
    demoAccountsArraySelector,
    chatraAccountsArraySelector,
    telegramAccountsArraySelector,
    mailAccountsArraySelector,
    whatsAppAccountsArraySelector,
    vkAccountsArraySelector,
    skypeAccountsArraySelector,
    fbAccountsArraySelector,
    igAccountsArraySelector,
    customAccountsArraySelector,
  ],
  (
    demoAccounts,
    chatraAccounts,
    telegramAccounts,
    mailAccounts,
    whatsAppAccounts,
    vkAccounts,
    skypeAccounts,
    fbAccounts,
    igAccounts,
    customAccounts,
  ) =>
    [
      ...demoAccounts,
      ...chatraAccounts,
      ...telegramAccounts,
      ...mailAccounts,
      ...whatsAppAccounts,
      ...vkAccounts,
      ...skypeAccounts,
      ...fbAccounts,
      ...igAccounts,
      ...customAccounts,
    ].map((acc) => {
      if (demoAccounts.includes(acc)) {
        return { ...acc, channelType: 'demo' };
      }
      if (chatraAccounts.includes(acc)) {
        return { ...acc, channelType: 'chatra' };
      }
      if (telegramAccounts.includes(acc)) {
        return { ...acc, channelType: 'telegram' };
      }
      if (mailAccounts.includes(acc)) {
        return { ...acc, channelType: 'email' };
      }
      if (whatsAppAccounts.includes(acc)) {
        return { ...acc, channelType: 'whatsapp' };
      }
      if (vkAccounts.includes(acc)) {
        return { ...acc, channelType: 'vk' };
      }
      if (skypeAccounts.includes(acc)) {
        return { ...acc, channelType: 'skype' };
      }
      if (fbAccounts.includes(acc)) {
        return { ...acc, channelType: 'fb' };
      }
      if (igAccounts.includes(acc)) {
        return { ...acc, channelType: 'ig' };
      }
      if (customAccounts.includes(acc)) {
        return { ...acc, channelType: 'custom' };
      }
      return acc;
    }),
);

export default getActiveChannelsFromState;
