import fetchJWT from '../../utils/fetchJWT';
import { logout } from '../../actions';
import { recieveIgAccount, enableIgAccount } from '../../reducers/ig-accounts';
import backend from '../../configs/backend';

const getIgAccount =
  (id, type = 'ig') =>
  (dispatch) => {
    const request = new Request(
      backend.applyBaseUrl(
        `/api/ig-channel/${type === 'ig2' ? 'v2/' : ''}accounts/read/${id}`,
      ),
      {
        method: 'GET',
        mode: 'cors',
        cache: 'default',
      },
    );

    fetchJWT(request)
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        if (res.status === 401) {
          dispatch(logout());
        }
        return [];
      })
      .then((res) => {
        dispatch(recieveIgAccount(res[0]));
        dispatch(enableIgAccount(res[0]._id));
      })
      .catch((err) => console.error(err));
  };

export default getIgAccount;
