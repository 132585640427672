import { analytics as analyticsConf } from '../configs';
import findElBySelectorType from './findElBySelectorType';

const { sdks } = analyticsConf;

const setupAnalyticsSdks = async () =>
  Promise.all(
    sdks.map(
      ({ type: sdkType, elements = [], postInsertAction }) =>
        new Promise((resolve) => {
          let error;

          for (let i = 0; i < elements.length; i += 1) {
            const { type, innerHtml, attributes = [], insertTo } = elements[i];

            const newEl = document.createElement(type);

            if (innerHtml) {
              newEl.innerHtml = innerHtml;
            }

            if (attributes.length) {
              for (let a = 0; a < attributes.length; a += 1) {
                const [key, value] = attributes[a];
                newEl.setAttribute(key, value);
              }
            }

            const { selectorType, queryValue } = insertTo;

            const parentEl = findElBySelectorType(selectorType, queryValue);

            if (!parentEl) {
              error = new Error(
                `Cannot locate parent element for analytics ${sdkType} sdk element: ${queryValue} found by ${selectorType}`,
              );
              break;
            }

            parentEl.appendChild(newEl);

            if (postInsertAction) {
              postInsertAction();
            }
          }

          // eslint-disable-next-line no-promise-executor-return
          return resolve({ error });
        }),
    ),
  ).then((results) =>
    // eslint-disable-next-line no-console
    results.forEach(({ error }) => (error ? console.error(error) : null)),
  );

export default setupAnalyticsSdks;
