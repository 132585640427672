import * as R from 'ramda';
import { createSelector } from 'reselect';

import dialogsConf from '../configs/dialogs';

const {
  search: { contentSearchMode: contentSearchModeConf },
} = dialogsConf;

/**
 * Setter constants
 */
export const SET_FILTER_CHANNELS = 'SET_FILTER_CHANNELS';
export const SET_FILTER_STATUSES = 'SET_FILTER_STATUSES';
export const SET_SORT = 'SET_SORT';
export const SET_STATUS = 'SET_STATUS';
export const SET_SEARCH = 'SET_SEARCH';
export const SET_CONTENT_SEARCH_MODE = 'SET_CONTENT_SEARCH_MODE';
export const SET_RESPONSIBLE_USERS = 'SET_RESPONSIBLE_USERS';
/**
 * Sort type constants
 */
export const SHOW_NEW = 'SHOW_NEW';
export const SHOW_OLD = 'SHOW_OLD';
export const SHOW_RECENT_UPDATED = 'SHOW_RECENT_UPDATED';
export const SHOW_OLDEST_UPDATED = 'SHOW_OLDEST_UPDATED';
/**
 * Status filter constants
 */
export const SHOW_ALL = 'SHOW_ALL';
export const SHOW_MINE = 'SHOW_MINE';
export const SHOW_NOT_ASSIGNED_RESP = 'SHOW_NOT_ASSIGNED_RESP';
export const SHOW_BOT_ASSIGNED = 'SHOW_BOT_ASSIGNED';
export const SHOW_MISSED = 'SHOW_MISSED';
export const SHOW_WAITING = 'SHOW_WAITING';
export const SHOW_OPENED = 'SHOW_OPENED';
export const SHOW_CLOSED = 'SHOW_CLOSED';

const initialDialogFilters = Object.freeze({
  search: '',
  contentSearchMode: contentSearchModeConf.defaultMode,
  filterStatus: SHOW_ALL,
  sortBy: SHOW_RECENT_UPDATED,
  responsibleUsers: [],
});

function sortReducer(state = initialDialogFilters, sortBy = '') {
  switch (sortBy) {
    case SHOW_NEW:
    case SHOW_OLD:
    case SHOW_RECENT_UPDATED:
    case SHOW_OLDEST_UPDATED: {
      return { ...state, sortBy };
    }
    default: {
      return state;
    }
  }
}

function filterStatusReducer(state = initialDialogFilters, status = '') {
  switch (status) {
    case SHOW_ALL:
    case SHOW_MINE:
    case SHOW_NOT_ASSIGNED_RESP:
    case SHOW_MISSED:
    case SHOW_WAITING:
    case SHOW_OPENED:
    case SHOW_CLOSED:
    case SHOW_BOT_ASSIGNED: {
      return { ...state, filterStatus: status };
    }
    default: {
      return state;
    }
  }
}

function channelReducer(state, payload) {
  const { filter, user } = payload;
  return {
    ...state,
    [user.account]: { [user._id]: { filterChannels: [...new Set(filter)] } },
  };
}

function responsibleUserReducer(state, payload) {
  return {
    ...state,
    responsibleUsers: payload,
  };
}

export function setSearch(search) {
  return {
    type: SET_SEARCH,
    payload: { search },
  };
}

export function setContentSearchMode(contentSearchMode) {
  return {
    type: SET_CONTENT_SEARCH_MODE,
    payload: { contentSearchMode },
  };
}

export function setSort(sortBy) {
  return {
    type: SET_SORT,
    payload: { sortBy },
  };
}

export function setFilterChannels(filter, user) {
  return {
    type: SET_FILTER_CHANNELS,
    payload: { filter, user },
  };
}

export function setFilterStatuses(filter) {
  return {
    type: SET_FILTER_STATUSES,
    payload: { filter },
  };
}

export function setFilterResponsibleUsers(responsibleUsers) {
  return {
    type: SET_RESPONSIBLE_USERS,
    payload: { responsibleUsers },
  };
}

export default function reducer(state = initialDialogFilters, action) {
  switch (action.type) {
    case SET_SEARCH: {
      return { ...state, search: action.payload.search };
    }
    case SET_CONTENT_SEARCH_MODE: {
      return { ...state, contentSearchMode: action.payload.contentSearchMode };
    }
    case SET_SORT: {
      return {
        ...state,
        ...sortReducer(state, action.payload.sortBy),
      };
    }
    case SET_FILTER_STATUSES: {
      return {
        ...state,
        ...filterStatusReducer(state, action.payload.filter),
      };
    }
    case SET_FILTER_CHANNELS: {
      return { ...state, ...channelReducer(state, action.payload) };
    }
    case SET_RESPONSIBLE_USERS: {
      return {
        ...state,
        ...responsibleUserReducer(state, action.payload.responsibleUsers),
      };
    }
    default: {
      return state;
    }
  }
}

export const getDialogsFilters = (state) =>
  state.dialogsFilters || initialDialogFilters;

const getAuthUser = (state) => state.auth.user;

export const dialogsListSearchSelector = createSelector(
  getDialogsFilters,
  (dialogsFilters) => dialogsFilters.search || initialDialogFilters.search,
);

export const dialogsListContentSearchModeSelector = createSelector(
  getDialogsFilters,
  (dialogsFilters) =>
    dialogsFilters.contentSearchMode || initialDialogFilters.contentSearchMode,
);

export const dialogsListFilterStatusSelector = createSelector(
  getDialogsFilters,
  (dialogsFilters) =>
    dialogsFilters.filterStatus || initialDialogFilters.filterStatus,
);

export const dialogsListSortSelector = createSelector(
  getDialogsFilters,
  (dialogsFilters) => dialogsFilters.sortBy || initialDialogFilters.sortBy,
);

export const getDialogsChannelFilters = createSelector(
  [getDialogsFilters, getAuthUser],
  (filter, user) =>
    R.pathOr([], [user.account, user._id, 'filterChannels'], filter),
);

export const dialogsListFilterResponsibleUsersSelector = createSelector(
  getDialogsFilters,
  (dialogsFilters) =>
    dialogsFilters.responsibleUsers || initialDialogFilters.responsibleUsers,
);
