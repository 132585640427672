/**
 * @description This function generates telegram-like avatar for provided title.
 * @param {string} title
 * @returns {string} Generated avatar.
 */
const generateAvatarFromTitle = (title = '') => {
  const words = title.trim().split(/\s+/);

  const [firstWord, secondWord] = words;

  const firstAvatarLetter = firstWord.charAt(0);

  if (secondWord) {
    const secondAvatarLetter = secondWord.charAt(0);

    return `${firstAvatarLetter}${secondAvatarLetter}`.toUpperCase();
  }

  return firstAvatarLetter.toUpperCase();
};

export default generateAvatarFromTitle;
