/* eslint-disable no-console */
import fetchJWT from '../../../utils/fetchJWT';
import { logout } from '../../../actions';
import { recieveAmoCrmIntegration } from '../../../reducers/amocrm-integrations';
import backend from '../../../configs/backend';

const getAmoсrmIntegration = (id) => async (dispatch) => {
  const request = new Request(
    backend.applyBaseUrl(`/api/amocrm/v2/read/${id}`),
    {
      method: 'GET',
      cache: 'default',
      mode: 'cors',
    },
  );

  fetchJWT(request)
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
      if (res.status === 401) {
        dispatch(logout());
      }
      throw new Error('Cannot get amocrm integration');
    })
    .then((res) => {
      if (res) {
        dispatch(recieveAmoCrmIntegration(res));
      }
    })
    .catch((e) => console.error(e));
};

export default getAmoсrmIntegration;
