/**
 * @description This funtion checks user rights for channel.
 * @param {object} objectParams
 * @param {object} objectParams.entity Channel object.
 * @param {object} objectParams.user
 * @returns {boolean} Returns true if user has rights for channel, else returns false.
 */
const checkUserRightsForChannelEntity = ({
  entity: { _id: chId },
  user: { channels: enabledChannelsByType = {} },
}) =>
  Object.values(enabledChannelsByType || {})
    .reduce(
      (acc, enabledChannels) => [...acc, ...Object.keys(enabledChannels)],
      [],
    )
    .includes(chId);

/**
 * @description This function checks user rights for dialog.
 * @param {object} objectParams
 * @param {object} objectParams.entity Dialog object.
 * @param {object} objectParams.user
 * @returns {boolean} Returns true if user has rights for dialog, else returns false.
 */
const checkUserRightsForDialogEntity = ({
  entity: { channelId = '', origin = 'mail', responsibleId = '' },
  user: { _id: userId },
  user,
}) => {
  // Demo origin marks that dialog was sent from Yeahdesk service and is available to anyone.
  // In addition to this, user has access to the dialog if he is responsible for it.
  if (origin === 'demo' || responsibleId === userId) {
    return true;
  }
  return checkUserRightsForChannelEntity({ entity: { _id: channelId }, user });
};

const checkFunctionByEntityType = {
  dialog: checkUserRightsForDialogEntity,
  channel: checkUserRightsForChannelEntity,
};

/**
 * @description This function checks if entity has id and user has rights for this entity.
 * @param {object} objectParams
 * @param {object} objectParams.entity
 * @param {string} objectParams.entityType
 * @param {object} objectParams.user
 * @returns {boolean} Returns true if entity has id and user has rights for this entity, else returns false.
 */
const checkUserRightsForEntity = ({ entity = {}, entityType, user = {} }) => {
  if (!entity._id || entity._id === 'new') {
    return false;
  }
  if (user.admin) {
    return true;
  }
  return checkFunctionByEntityType[entityType]({ entity, user }) || false;
};

export default checkUserRightsForEntity;
