import * as R from 'ramda';
import { createSelector } from 'reselect';
import { getDialogIdSelector } from './dialogs';

export const SET_DIALOG_IN_REPLY_TO = 'SET_DIALOG_IN_REPLY_TO';
export const RESET_DIALOG_IN_REPLY_TO = 'RESET_DIALOG_IN_REPLY_TO';

function inReplyToReducer(state = {}, action = { type: '' }) {
  switch (action.type) {
    case SET_DIALOG_IN_REPLY_TO: {
      const { messageId, dialogId, id, clientId, contactsId, text, createdAt } =
        action.payload;

      return R.assoc(dialogId, {
        ...(messageId ? { messageId } : {}),
        ...(id ? { id } : {}),
        ...(clientId ? { clientId } : {}),
        ...(contactsId ? { contactsId } : {}),
        ...(text ? { text } : {}),
        ...(createdAt ? { createdAt } : {}),
      })(state);
    }
    case RESET_DIALOG_IN_REPLY_TO: {
      return R.dissoc(action.payload.dialogId, state);
    }
    default: {
      return state;
    }
  }
}

export const setDialogInReplyTo = ({
  userId,
  dialogId,
  messageId,
  id,
  clientId,
  contactsId,
  text,
  createdAt,
}) => ({
  type: SET_DIALOG_IN_REPLY_TO,
  payload: {
    userId,
    dialogId,
    messageId,
    id,
    clientId,
    contactsId,
    text,
    createdAt,
  },
});

export const resetDialogInReplyTo = ({ userId, dialogId }) => ({
  type: RESET_DIALOG_IN_REPLY_TO,
  payload: {
    userId,
    dialogId,
  },
});

export default function reducer(state = {}, action = { type: '' }) {
  switch (action.type) {
    case SET_DIALOG_IN_REPLY_TO:
    case RESET_DIALOG_IN_REPLY_TO: {
      return R.assoc(
        action.payload.userId,
        inReplyToReducer(state[action.payload.userId], action),
      )(state);
    }
    default: {
      return state;
    }
  }
}

export const getInReplyTo = (state) => state.inReplyTo;

export const getAuthUserId = (state) => state.auth.user._id;

export const getDialogId = (state, props) => getDialogIdSelector(state, props);

export const getDialogInReplyTo = createSelector(
  [getInReplyTo, getAuthUserId, getDialogId],
  (inReplyTo, userId, dialogId) =>
    R.pathOr(null, [userId, dialogId], inReplyTo),
);
