import * as R from 'ramda';
import { createSelector } from 'reselect';

export const RECEIVE_USERS = 'RECEIVE_USERS';
export const RECEIVE_USER = 'RECEIVE_USER';
export const DELETE_USER = 'DELETE_USER';
export const UPDATE_USER_RECORD = 'UPDATE_USER_RECORD';

function userFactory(user) {
  return { [user._id]: user };
}

function usersReducer(state = {}, action = { type: '' }) {
  switch (action.type) {
    case RECEIVE_USERS: {
      return R.mergeAll(R.map(userFactory, action.payload.users));
    }
    case RECEIVE_USER: {
      return { ...state, ...action.payload.user };
    }
    case DELETE_USER: {
      return { ...state, status: 'deleted' };
    }
    case UPDATE_USER_RECORD: {
      return {
        ...state,
        ...(action.payload.defaultOutcomingChannels
          ? {
              defaultOutcomingChannels: action.payload.defaultOutcomingChannels,
            }
          : {}),
        ...(action.payload.name !== undefined
          ? {
              name: action.payload.name,
            }
          : {}),
        ...(action.payload.signature !== undefined
          ? {
              signature: action.payload.signature,
            }
          : {}),
        ...(action.payload.channels !== undefined
          ? {
              channels: action.payload.channels,
            }
          : {}),
        ...(action.payload.admin !== undefined
          ? {
              admin: action.payload.admin,
            }
          : {}),
      };
    }
    default: {
      return state;
    }
  }
}

export const receiveUser = (user) => ({
  type: RECEIVE_USER,
  payload: { user },
});

export const receiveUsers = (users) => ({
  type: RECEIVE_USERS,
  payload: { users },
});

export const deleteUser = (_id) => ({
  type: DELETE_USER,
  payload: { _id },
});

export const renewUser = ({
  _id,
  defaultOutcomingChannels,
  name,
  signature,
  channels,
  admin,
}) => ({
  type: UPDATE_USER_RECORD,
  payload: { _id, defaultOutcomingChannels, name, signature, channels, admin },
});

export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case RECEIVE_USERS: {
      return R.mergeRight(state, usersReducer(state, action));
    }
    case RECEIVE_USER: {
      return R.assoc(
        action.payload.user._id,
        usersReducer(state[action.payload.user._id], action),
      )(state);
    }
    case DELETE_USER:
    case UPDATE_USER_RECORD: {
      return R.assoc(
        action.payload._id,
        usersReducer(state[action.payload._id], action),
      )(state);
    }
    default:
      return state;
  }
}

export const usersSelector = (state) => R.propOr({}, 'users', state);

export const usersArraySelector = createSelector(usersSelector, (users) =>
  R.values(users),
);

export const usersArraySelectorIsActive = createSelector(
  usersSelector,
  (users) => R.filter((_) => _.status !== 'deleted', R.values(users)),
);

export const firstAccountUserSelector = createSelector(
  usersArraySelectorIsActive,
  (users) => R.sortBy(R.path(['createdAt']))(users)[0],
);
