import * as R from 'ramda';
import { createSelector } from 'reselect';

export default function reducer(state = {}, action = {}) {
  const jwtStorage = state.dontRemember ? sessionStorage : localStorage;

  switch (action.type) {
    case 'NETWORK_ERR': {
      return { ...state, err: action.err.toString() };
    }
    case 'LOGGED_IN': {
      // refresh_token передается когда пользователь логинится по имени/паролю
      if (action.refresh_token) {
        jwtStorage.setItem('refresh_token', action.refresh_token);
        jwtStorage.removeItem('jwt');
      }
      /* TODO -> find a way to subscribe to websockets store
       * without waiting for page requests to exec.
       * (we are not subscribed by default and,
       * therefore, will not receive updates until
       * subscription by any of the requests)
       */

      return { ...state, user: action.user };
    }
    case 'UPDATE_REFRESH': {
      jwtStorage.setItem('refresh_token', action.refresh_token);
      jwtStorage.removeItem('jwt');
      return state;
    }
    case 'NOT_LOGGED': {
      window.webSocketsConn.emit('JWT', null);

      return { ...state, user: false };
    }
    case 'DONT_REMEMBER': {
      return { ...state, dontRemember: action.value };
    }
    case 'USER_INPUT': {
      return { ...state, userInput: action.value };
    }
    case 'LOGOUT': {
      /* мы не можем полагаться на состояние стора. пользователь может нажать F5 когда он залогинен
       * если в сторе и была галочка "не запоминать меня", она не сохраниться
       */
      window.webSocketsConn.emit('JWT', null);

      sessionStorage.removeItem('refresh_token');
      sessionStorage.removeItem('jwt');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('jwt');

      return { ...state, user: {} };
    }
    case 'UPDATE_USER': {
      return R.mergeDeepRight(state, action.payload);
    }
    default:
      return state;
  }
}

const getLoggedUser = (state) => R.pathOr({}, ['auth', 'user'], state);

export const loggedUserSelector = createSelector(
  getLoggedUser,
  (loggedUser) => loggedUser,
);
