import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'antd-mobile';
import { components as componentsConf } from '../../../../../configs';
import './index.css';

const {
  pages: {
    dialogs: {
      list: {
        item: { typeOfMessages },
      },
    },
  },
} = componentsConf;

const DialogsListItemDescription = ({
  lastMessage = {},
  isDialogAnswered = true,
  labels = [],
  dialogStatus,
  lastMessageDate,
}) => (
  <div className={`dialogsListItemDescription--${dialogStatus}`}>
    <div className="dialogsListItemDescription__lastMessage">
      {!isDialogAnswered ? (
        <Badge
          color="#1890FF"
          content={Badge.dot}
          style={{ marginRight: '4px' }}
        />
      ) : null}
      <div className="dialogsListItemDescription__lastMessageText">
        {!lastMessage.type || lastMessage.type === 'incoming' ? (
          lastMessage.text
        ) : (
          <span>
            <span
              className={`dialogListItemDescription__lastMessageType ${lastMessage.type}`}
            >
              {`${typeOfMessages[lastMessage.type]}: `}
            </span>
            {lastMessage.text}
          </span>
        )}
      </div>
      <div className="dialogsListItemDescription__lastMessageDate">
        {lastMessageDate}
      </div>
    </div>
  </div>
);

DialogsListItemDescription.propTypes = {
  lastMessage: PropTypes.object,
  isDialogAnswered: PropTypes.bool,
  labels: PropTypes.arrayOf(PropTypes.string),
  dialogStatus: PropTypes.string.isRequired,
  unreadCount: PropTypes.number,
  lastMessageDate: PropTypes.string.isRequired,
};

export default memo(DialogsListItemDescription);
