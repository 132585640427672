import * as R from 'ramda';
import { createSelector } from 'reselect';

export const ADD_DRAFT = 'ADD_DRAFT';
export const UPDATE_DRAFT = 'UPDATE_DRAFT';
export const DELETE_DRAFT = 'DELETE_DRAFT';

const Draft = {};

function draftReducer(state = Draft, action = { type: '' }) {
  switch (action.type) {
    case ADD_DRAFT:
    case UPDATE_DRAFT: {
      const { dialogId, html, text, forward, forwardedMsgDetails, cc, mailTo } =
        action.payload;

      const draft = {
        ...(state[dialogId] || {}),
        dialogId,
        ...(html ? { html } : {}),
        ...(text ? { text } : {}),
        ...(forward ? { forward } : {}),
        ...(forwardedMsgDetails ? { forwardedMsgDetails } : {}),
        ...(cc ? { cc } : {}),
        ...(mailTo ? { mailTo } : {}),
      };

      // TODO: Incorrect data structure -> change draft state object from { [id]: { [id]: {} } } to { [id]: {} }
      return { [dialogId]: draft };
    }
    default: {
      return state;
    }
  }
}

export const addDraft = ({
  dialogId,
  text,
  html,
  forward,
  forwardedMsgDetails,
  cc,
  mailTo,
}) => ({
  type: ADD_DRAFT,
  payload: { dialogId, text, html, forward, forwardedMsgDetails, cc, mailTo },
});

export const renewDraft = ({
  dialogId,
  text,
  html,
  forward,
  forwardedMsgDetails,
  cc,
  mailTo,
}) => ({
  type: UPDATE_DRAFT,
  payload: { dialogId, text, html, forward, forwardedMsgDetails, cc, mailTo },
});

export const removeDraft = ({ dialogId }) => ({
  type: DELETE_DRAFT,
  payload: { dialogId },
});

export default function reducer(state = {}, action = { type: '' }) {
  switch (action.type) {
    case ADD_DRAFT:
    case UPDATE_DRAFT: {
      return R.assoc(
        action.payload.dialogId,
        draftReducer(state[action.payload.dialogId], action),
      )(state);
    }
    case DELETE_DRAFT: {
      return R.dissoc(action.payload.dialogId, state);
    }
    default: {
      return state;
    }
  }
}

export const draftsSelector = (state) => state.drafts;

export const dialogIdSelector = (_, props) => props.dialogId;

export const draftSelector = createSelector(
  draftsSelector,
  dialogIdSelector,
  (drafts, dialogId) =>
    // TODO: Incorrect data structure -> change draft state object from { [id]: { [id]: {} } } to { [id]: {} }
    drafts[dialogId] ? drafts[dialogId][dialogId] : undefined,
);
