import { tasks as tasksConf } from '../configs';

const {
  filters: { filterTypeByConstant },
} = tasksConf;

const taskSearchParamsAdapter = ({
  offset,
  limit,
  search,
  notDeleted,
  sort = {},
  filter: { filterType = '' } = {},
}) => ({
  offset,
  limit,
  search,
  notDeleted,
  sort: JSON.stringify(sort),
  filter: JSON.stringify({ filterType: filterTypeByConstant[filterType] }),
});

export default taskSearchParamsAdapter;
