import { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  dialogsListContentSearchModeSelector,
  setContentSearchMode,
} from '../../reducers/dialogsFilters';
import {
  callsListContentSearchModeSelector,
  setCallsContentSearchMode,
} from '../../reducers/callsFilters';
import usePrevious from '../usePrevious';
import useDialogsListMode from './useDialogsListMode';

const useDialogsListContentSearchMode = ({
  onContentSearchModeChange,
} = {}) => {
  const dispatch = useDispatch();

  const { dialogsListMode = 'dialogs' } = useDialogsListMode();

  const contentSearchModeSelector =
    dialogsListMode === 'calls'
      ? callsListContentSearchModeSelector
      : dialogsListContentSearchModeSelector;
  const contentSearchMode = useSelector(contentSearchModeSelector);
  const prevContentSearchMode = usePrevious(contentSearchMode);

  const contentSearchModeUpdateAction =
    dialogsListMode === 'calls'
      ? setCallsContentSearchMode
      : setContentSearchMode;

  const updateContentSearchMode = useCallback(
    /**
     * @param {'dialogClient'|'dialogTitle'|'dialogMessagesText'} mode
     */
    (mode) => {
      dispatch(contentSearchModeUpdateAction(mode));
    },
    [dispatch, contentSearchModeUpdateAction],
  );

  useEffect(() => {
    if (
      onContentSearchModeChange &&
      prevContentSearchMode !== undefined &&
      prevContentSearchMode !== contentSearchMode
    ) {
      onContentSearchModeChange();
    }
  }, [prevContentSearchMode, contentSearchMode, onContentSearchModeChange]);

  return { contentSearchMode, updateContentSearchMode };
};

export default useDialogsListContentSearchMode;
