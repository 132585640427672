import fetchJWT from '../../utils/fetchJWT';
import { logout } from '../../actions';
import backend from '../../configs/backend';

const createComment = (comment) => (dispatch) => {
  comment.text = comment.text.replace(/[\\n,\s]*$/g, '');
  const request = new Request(
    backend.applyBaseUrl(`/api/messages/create/comment`),
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      mode: 'cors',
      cache: 'default',
      body: JSON.stringify(comment),
    },
  );
  fetchJWT(request)
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
      if (res.status === 401) {
        dispatch(logout());
      }
      return {};
    })
    .then((res) => console.log('comment sent', res))
    .catch((err) => console.error(err));
};

export default createComment;
