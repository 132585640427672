import { tasksFilters as tasksFilterConstants } from '../constants';

const {
  SET_TASKS_FILTER,
  SHOW_ALL_TASKS,
  SHOW_MINE_TASKS,
  SHOW_CREATED_BY_ME_TASKS,
  SHOW_ARCHIVE_TASKS,
} = tasksFilterConstants;

const initialTasksFilters = {
  filterType: SHOW_MINE_TASKS,
};

const tasksFiltersReducer = (
  state = initialTasksFilters,
  filterType = SHOW_MINE_TASKS,
) => {
  switch (filterType) {
    case SHOW_ALL_TASKS:
    case SHOW_MINE_TASKS:
    case SHOW_CREATED_BY_ME_TASKS:
    case SHOW_ARCHIVE_TASKS: {
      return { ...state, filterType };
    }
    default: {
      return state;
    }
  }
};

export const setTasksFilter = (filterType) => ({
  type: SET_TASKS_FILTER,
  payload: { filterType },
});

export default function reducer(
  state = initialTasksFilters,
  action = { type: '', payload: {} },
) {
  switch (action.type) {
    case SET_TASKS_FILTER: {
      return {
        ...state,
        ...tasksFiltersReducer(state, action.payload.filterType),
      };
    }
    default: {
      return state;
    }
  }
}

export const getTaskFilters = (state) => state.tasksFilters;
