export const FINISH_ADMIN_FIRST_STEP = 'FINISH_ADMIN_ONBOARDING';
export const FINISH_ADMIN_SECOND_STEP = 'FINISH_ADMIN_SECOND_STEP';
export const FINISH_ADMIN_THIRD_STEP = 'FINISH_ADMIN_THIRD_STEP';
export const SET_USER_ONBOARDING = 'SET_USER_ONBOARDING';
export const SET_ADMIN_ONBOARDING = 'SET_ADMIN_ONBOARDING';

const initialOnboardingState = {
  isUserOnboardingStarted: false,
  isAdminOnboardingStarted: false,
  adminFirstStepCompleted: true,
  adminSecondStepCompleted: true,
  adminThirdStepCompleted: true,
};

export const setUserOnboardingStarted = (status) => ({
  type: SET_USER_ONBOARDING,
  payload: status,
});

export const setAdminOnboardingStarted = (status) => ({
  type: SET_ADMIN_ONBOARDING,
  payload: status,
});

export const finishAdminFirstStep = () => ({
  type: FINISH_ADMIN_FIRST_STEP,
});

export const finishAdminSecondStep = () => ({
  type: FINISH_ADMIN_SECOND_STEP,
});

export const finishAdminThirdStep = () => ({
  type: FINISH_ADMIN_THIRD_STEP,
});

export default function reducer(state = initialOnboardingState, action = {}) {
  switch (action.type) {
    case SET_USER_ONBOARDING: {
      if (action.payload) {
        return {
          isUserOnboardingStarted: true,
          isAdminOnboardingStarted: false,
          adminFirstStepCompleted: true,
          adminSecondStepCompleted: true,
          adminThirdStepCompleted: true,
        };
      }
      return {
        ...state,
        isUserOnboardingStarted: action.payload,
      };
    }
    case SET_ADMIN_ONBOARDING: {
      if (action.payload) {
        return {
          ...state,
          isUserOnboardingStarted: false,
          isAdminOnboardingStarted: true,
          adminFirstStepCompleted: false,
          adminSecondStepCompleted: false,
          adminThirdStepCompleted: false,
        };
      }
      return { ...state, isAdminOnboardingStarted: action.payload };
    }
    case FINISH_ADMIN_FIRST_STEP: {
      return { ...state, adminFirstStepCompleted: true };
    }
    case FINISH_ADMIN_SECOND_STEP: {
      return { ...state, adminSecondStepCompleted: true };
    }
    case FINISH_ADMIN_THIRD_STEP: {
      return { ...state, adminThirdStepCompleted: true };
    }
    default: {
      return state;
    }
  }
}

export const onboardingSelector = (state) => state.onboardingInfo;
