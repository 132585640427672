import { useRef, useCallback } from 'react';

/**
 * @description Hook to handle dynamic items size state.
 * @param {number} [fallbackItemSize]
 */
const useSizeMap = (fallbackItemSize = 0) => {
  const initalSizeMap = new Map();
  const sizeMap = useRef(initalSizeMap);

  const setSize = useCallback(
    /**
     * @param {string|number} id
     * @param {number} size
     */
    (id, size) => {
      sizeMap.current.set(id, size);
    },
    [],
  );

  const getSize = useCallback(
    /**
     * @param {string|number} id
     * @returns {number} Item size from map or fallback size
     */
    (id) => {
      const itemSize = sizeMap.current.get(id);

      return itemSize || fallbackItemSize;
    },
    [fallbackItemSize],
  );

  const clearSizeMap = useCallback(() => sizeMap.current.clear(), []);

  const isItemInSizeMap = useCallback(
    /**
     * @param {string|number} id
     * @returns {boolean}
     */
    (id) => {
      const item = sizeMap.current.get(id);

      if (item) {
        return true;
      }

      return false;
    },
    [],
  );

  return { setSize, getSize, clearSizeMap, isItemInSizeMap };
};

export default useSizeMap;
