import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { TextArea, Button } from 'antd-mobile';
import { PlayOutline } from 'antd-mobile-icons';
import { useTranslation } from 'react-i18next';

import { api as apiConf } from '../../../../configs';
import AttachmentsList from './AttachmentsList';
import { draftSelector } from '../../../../reducers/drafts';
import { createMessage } from '../../../../api/messages';
import { updateDialog } from '../../../../api/dialogs';
import { addAttachments } from '../../../../api/attachments';
import DialogFormMenu from './DialogFormMenu';

const {
  messages: {
    originsThatAllowSendingAttachmentsWithText,
    apiProvidersThatAllowSendingAttachmentsWithText,
  },
} = apiConf;

const DialogForm = ({
  authorId,
  dialogId,
  dialog,
  channelId,
  // metadata,
  responsibleId,
  channel,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const draft = useSelector((state) => draftSelector(state, { dialogId }));
  const user = useSelector((state) => state.auth.user);

  const { isDeleted = false, isEnabled = true } = channel;
  const { origin = 'mail' } = dialog;
  const [loading, setLoading] = useState(false);
  const [valueTextArea, setValueTextArea] = useState('');
  const [attachments, setAttachments] = useState([]);
  const [type] = useState(
    isDeleted || !isEnabled || origin === 'pbx' || origin === 'phone'
      ? 'comment'
      : 'mail',
  );
  const textAreaRef = useRef(null);

  const sendMessage = async () => {
    textAreaRef.current?.focus();

    if (!loading) {
      setLoading(true);
    }

    const {
      channel: { provider: channelApiProvider = 'chatApi' } = {},
      status,
    } = dialog;

    const { forward, cc, mailTo } = draft || {};

    if (type === 'comment') {
      //'send comment'
    }

    if (type === 'note') {
      //'send note'
    }

    if (type === 'mail' || type === 'forward') {
      const message = {
        metadata: {
          html: `<p class="md-block-unstyled" style="margin: 0px !important;">${valueTextArea}</p>`,
          ...(type === 'forward' && forward ? { forward } : {}),
          ...(type === 'mail' && cc ? { cc } : {}),
          ...(type === 'mail' && mailTo ? { mailTo } : {}),
        },
        dialogId,
        channelId,
        text: valueTextArea,
        attachments,
        authorId,
      };

      try {
        const originsThatDoNotNeedBaseMsgForAttachments = [
          'whatsapp',
          'telegram',
        ];

        const isMsgTextNonEmpty =
          message.text &&
          message.text.trim().length !== 0 &&
          message.text.trim() !== '\n';

        const result =
          originsThatDoNotNeedBaseMsgForAttachments.includes(origin) &&
          !isMsgTextNonEmpty &&
          message.attachments &&
          message.attachments.length !== 0
            ? { _id: origin }
            : await dispatch(
                createMessage({
                  message,
                  src: origin,
                  apiProvider: channelApiProvider,
                }),
              ); // Returns result._id of inserted message

        if (
          !(
            originsThatAllowSendingAttachmentsWithText.includes(origin) ||
            apiProvidersThatAllowSendingAttachmentsWithText.includes(
              channelApiProvider,
            )
          ) &&
          attachments.length
        ) {
          await dispatch(addAttachments(message, result._id, origin));
        }

        if (status !== 'opened') {
          dispatch(
            updateDialog({
              id: dialogId,
              channelId,
              status: 'opened',
            }),
          );
        }
      } catch (e) {
        console.error(e);
      }

      if (!responsibleId || responsibleId === null) {
        dispatch(
          updateDialog({
            id: dialogId,
            channelId,
            responsibleUserName: user.name || user.email,
            responsibleId: user._id,
          }),
        );
      }
    }

    setLoading(false);
    setValueTextArea('');
    setAttachments([]);
  };

  const uploadFile = (fileList) => {
    setAttachments([...attachments, ...fileList]);
  };

  const onDeleteFile = (index) => {
    setAttachments(attachments.filter((_, i) => i !== index));
  };

  return (
    <div className="dialog-opened-bottom">
      <div style={{ display: 'flex', alignItems: 'flex-end' }}>
        <DialogFormMenu
          disabled={loading}
          uploadFile={uploadFile}
          insertTemplateText={setValueTextArea}
        />
        <TextArea
          ref={textAreaRef}
          value={valueTextArea}
          onChange={setValueTextArea}
          placeholder={t('DialogForm.textArea')}
          rows={1}
          autoSize={{ minRows: 1, maxRows: 6 }}
          className="dialog-opened-bottom__textarea"
        />
        <Button
          fill="none"
          onClick={sendMessage}
          disabled={loading || !(attachments.length || valueTextArea.length)}
          style={{ padding: '11px 12px' }}
        >
          <PlayOutline fontSize={24} style={{ display: 'block' }} />
        </Button>
      </div>
      {attachments.length ? (
        <AttachmentsList
          attachments={attachments}
          onDeleteFile={onDeleteFile}
        />
      ) : null}
    </div>
  );
};

DialogForm.propTypes = {
  authorId: PropTypes.string,
  dialogId: PropTypes.string,
  channelId: PropTypes.string,
  channel: PropTypes.object,
  // metadata: PropTypes.object,
  responsibleId: PropTypes.string,
  dialog: PropTypes.object,
};

export default DialogForm;
