import fetchJWT from '../../utils/fetchJWT';
import { logout } from '../../actions';
import { recieveIgAccounts } from '../../reducers/ig-accounts';
import backend from '../../configs/backend';

const getIgAccounts =
  (type = 'ig') =>
  (dispatch) => {
    const request = new Request(
      backend.applyBaseUrl(
        `/api/ig-channel/${type === 'ig2' ? 'v2/' : ''}accounts/read/`,
      ),
      {
        method: 'GET',
        mode: 'cors',
        cache: 'default',
      },
    );

    fetchJWT(request)
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        if (res.status === 401) {
          dispatch(logout());
        }
        return [];
      })
      .then((res) => dispatch(recieveIgAccounts(res)))
      .catch((err) => console.error(err));
  };

export default getIgAccounts;
