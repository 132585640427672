// reducer for defining current dialog actions - who is typing/reading
import * as R from 'ramda';
import { createSelector } from 'reselect';
import { getDialogIdSelector } from './dialogs';

const RECIEVE_DIALOGS_ACTIONS_READING = 'RECIEVE_DIALOGS_ACTIONS_READING';
const RECIEVE_DIALOGS_ACTIONS_TYPING = 'RECIEVE_DIALOGS_ACTIONS_TYPING';

function dialogsActionsReducer(state = {}, action = { type: '' }) {
  switch (action.type) {
    case RECIEVE_DIALOGS_ACTIONS_TYPING:
    case RECIEVE_DIALOGS_ACTIONS_READING: {
      return R.mergeAll(action.payload.actionState);
    }
    default: {
      return state;
    }
  }
}

export const proceedDialogActionsReading = (dialogActions) => ({
  type: RECIEVE_DIALOGS_ACTIONS_READING,
  payload: { dialogActions },
});

export const proceedDialogActionsTyping = (dialogActions) => ({
  type: RECIEVE_DIALOGS_ACTIONS_TYPING,
  payload: { dialogActions },
});

export default function reducer(state = {}, action = { type: '' }) {
  switch (action.type) {
    case RECIEVE_DIALOGS_ACTIONS_READING: {
      const actionState = {};
      // eslint-disable-next-line no-restricted-syntax
      for (const userId in action.payload.dialogActions) {
        if (userId) {
          const socketsUser = action.payload.dialogActions[userId];
          // eslint-disable-next-line no-restricted-syntax
          for (const socket in socketsUser) {
            if (socket) {
              const socketObj = socketsUser[socket];
              if (socketObj.reading) {
                // eslint-disable-next-line no-restricted-syntax
                for (const dialogId in socketObj.reading) {
                  if (dialogId) {
                    if (!actionState[dialogId]) {
                      actionState[dialogId] = {};
                    }
                    actionState[dialogId][userId] = true;
                  }
                }
              }
            }
          }
        }
      }

      const newAction = { ...action };

      newAction.payload.actionState = actionState;

      return R.assoc(
        'reading',
        dialogsActionsReducer(state.reading, newAction),
      )(state);
    }
    case RECIEVE_DIALOGS_ACTIONS_TYPING: {
      const actionState = {};
      // eslint-disable-next-line no-restricted-syntax
      for (const userId in action.payload.dialogActions) {
        if (userId) {
          const socketsUser = action.payload.dialogActions[userId];
          // eslint-disable-next-line no-restricted-syntax
          for (const socket in socketsUser) {
            if (socket) {
              const socketObj = socketsUser[socket];
              if (socketObj.typing) {
                // eslint-disable-next-line no-restricted-syntax
                for (const dialogId in socketObj.typing) {
                  if (dialogId) {
                    if (!actionState[dialogId]) {
                      actionState[dialogId] = {};
                    }
                    actionState[dialogId][userId] = true;
                  }
                }
              }
            }
          }
        }
      }

      const newAction = { ...action };

      newAction.payload.actionState = actionState;

      return R.assoc(
        'typing',
        dialogsActionsReducer(state.typing, newAction),
      )(state);
    }
    default: {
      return state;
    }
  }
}

export const dialogActionsSelector = (state) => state.dialogActions;

export const currentAuthSelector = (state) => state.auth.user;

export const dialogActionsTypingSelector = createSelector(
  dialogActionsSelector,
  (dialogActions) => dialogActions.typing,
);

export const dialogActionsReadingSelector = createSelector(
  dialogActionsSelector,
  (dialogActions) => dialogActions.reading,
);

export const typingActionsForDialogSelector = createSelector(
  [dialogActionsTypingSelector, getDialogIdSelector],
  (typingActions = {}, dialogId) =>
    typingActions[dialogId] ? Object.keys(typingActions[dialogId]) : [],
);

export const readingActionsForDialogSelector = createSelector(
  [dialogActionsReadingSelector, getDialogIdSelector],
  (readingActions = {}, dialogId) =>
    readingActions[dialogId] ? Object.keys(readingActions[dialogId]) : [],
);
