import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { components as componentsConf } from '../../../../../configs';
import './index.css';

const {
  pages: {
    dialogs: {
      list: {
        item: { defaultAvatarBgColor },
      },
    },
  },
} = componentsConf;

const DialogsListItemAvatar = ({ avatar = {}, dialogStatus }) => (
  <div
    className="DialogsListItemAvatar"
    style={{
      backgroundColor:
        avatar.type === 'color' && avatar.value
          ? `${avatar.value}`
          : defaultAvatarBgColor,
    }}
  >
    {avatar.content}
  </div>
);

DialogsListItemAvatar.propTypes = {
  avatar: PropTypes.shape({
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    type: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  dialogStatus: PropTypes.string.isRequired,
};

export default memo(DialogsListItemAvatar);
