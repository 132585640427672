import * as R from 'ramda';
import { createSelector } from 'reselect';

export const SET_FILTER_CHANNELS = 'SET_FILTER_CHANNELS';
export const SET_FILTER_STATUSES = 'SET_FILTER_STATUSES';
export const SET_STATUS = 'SET_STATUS';
export const GET_DIALOG_ITEMS = 'GET_DIALOG_ITEMS';
export const GET_DIALOG_ITEMS_SORT = 'GET_DIALOG_ITEMS_SORT';
export const GET_DIALOG_ITEM = 'GET_DIALOG_ITEM';
export const UPDATE_DIALOG = 'UPDATE_DIALOG';
export const DELETE_DIALOG = 'DELETE_DIALOG';
export const SET_RESPONSIBLE = 'SET_RESPONSIBLE';
export const CLEAR_DIALOG_ITEMS_STATE = 'CLEAR_DIALOG_ITEMS_STATE';

function dialogFactory(dialog) {
  return { [dialog._id]: dialog };
}

function statusReducer(state = {}, action = {}) {
  if (state.status === action.payload.status) {
    return state;
  }
  return { ...state, status: action.payload.status };
}

function dialogReducer(state = {}, action = {}) {
  switch (action.type) {
    case GET_DIALOG_ITEMS: {
      return R.mergeAll(R.map(dialogFactory, action.payload.dialogs));
    }
    case GET_DIALOG_ITEM: {
      return { ...state, ...action.payload.dialog };
    }
    case GET_DIALOG_ITEMS_SORT: {
      return R.mergeAll(R.map(dialogFactory, action.payload.dialogs));
    }
    case UPDATE_DIALOG: {
      const { title, responsibleId, status, labels } = action.payload.body;
      return {
        ...state,
        ...(title ? { title } : {}),
        ...(responsibleId ? { responsibleId } : {}),
        ...(status ? { status } : {}),
        ...(labels ? { labels } : {}),
      };
    }
    case SET_RESPONSIBLE: {
      return { ...state, responsibleId: action.payload.responsibleId };
    }
    default: {
      return state;
    }
  }
}

export const getDialogItems = (dialogs) => ({
  type: GET_DIALOG_ITEMS,
  payload: { dialogs },
});

export const getDialogItem = (dialog) => ({
  type: GET_DIALOG_ITEM,
  payload: { dialog },
});

export const renewDialog = (dialogId, body) => ({
  type: UPDATE_DIALOG,
  payload: { dialogId, body },
});

export const removeDialog = (dialogId) => ({
  type: DELETE_DIALOG,
  payload: { dialogId },
});

export const getDialogItemsSort = (dialogs) => ({
  type: GET_DIALOG_ITEMS_SORT,
  payload: { dialogs },
});

export function setResponsible(id, responsibleId) {
  return {
    type: 'SET_RESPONSIBLE',
    payload: {
      id,
      responsibleId,
    },
  };
}

export function setStatus(id, status) {
  return {
    type: 'SET_STATUS',
    payload: {
      id,
      status,
    },
  };
}

export const clearDialogItemsState = () => ({
  type: CLEAR_DIALOG_ITEMS_STATE,
  payload: {},
});

export default function reducer(state = {}, action = { type: '' }) {
  switch (action.type) {
    case GET_DIALOG_ITEMS: {
      return R.mergeRight(state, dialogReducer(state, action));
    }
    case GET_DIALOG_ITEMS_SORT: {
      return R.mergeRight({}, dialogReducer(state, action));
    }
    case GET_DIALOG_ITEM: {
      return R.assoc(
        action.payload.dialog._id,
        dialogReducer(state[action.payload.dialog._id], action),
      )(state);
    }
    case SET_STATUS: {
      return R.assoc(
        action.payload.id,
        statusReducer(state[action.payload.id], action),
      )(state);
    }
    case UPDATE_DIALOG: {
      return R.assoc(
        action.payload.dialogId,
        dialogReducer(state[action.payload.dialogId], action),
      )(state);
    }
    case DELETE_DIALOG: {
      return {
        ...state,
        ...state.filter((dialog) => dialog._id !== action.payload.dialogId),
      };
    }
    case SET_RESPONSIBLE: {
      return R.assoc(
        action.payload.id,
        dialogReducer(state[action.payload.id], action),
      )(state);
    }
    case CLEAR_DIALOG_ITEMS_STATE: {
      return {};
    }
    default: {
      return state;
    }
  }
}

export const getDialogsSelector = (state) => state.dialogs;

export const getDialogsArraySelector = createSelector(
  getDialogsSelector,
  (dialogs) => R.values(dialogs),
);

export const getDialogIdSelector = (state, ownProps) => {
  // R.pathOr(null, ['match', 'params', 'dialogId'], ownProps) ||
  // R.propOr(null, 'dialogId', ownProps);
  return ownProps?.dialogId;
};

export const getDialogSelector = createSelector(
  getDialogsSelector,
  getDialogIdSelector,
  (dialogs, dialogId) => R.propOr(null, dialogId, dialogs),
);

export const getChannelIdFromDialogSelector = createSelector(
  getDialogSelector,
  (dialog) => R.propOr(null, 'channelId', dialog),
);

export const getResponsibleIdFromDialogSelector = createSelector(
  getDialogSelector,
  (dialog) => R.propOr(null, 'responsibleId', dialog),
);

export const getMetadataFromDialogSelector = createSelector(
  getDialogSelector,
  (dialog) => R.propOr(null, 'metadata', dialog),
);
