import fetchJWT from '../../utils/fetchJWT';
import { logout } from '../../actions';
import createOutcomingMsgCreationBody from '../../utils/createOutcomingMsgCreationBody';
import { backend, api as apiConf } from '../../configs';

const {
  messages: {
    originsThatAllowSendingAttachmentsWithText,
    apiProvidersThatAllowSendingAttachmentsWithText,
  },
} = apiConf;

/*
 * Выбрасывает ошибку только в случае сетевой ошибки
 * в любом другом случае возвращает json c ответом -
 * результатом отправки (может содержать err:)
 *
 */
const createMessage =
  ({ message, src, apiProvider }) =>
  (dispatch) => {
    message.text = message.text.replace(/[\\n,\s]*$/g, '');
    const request = new Request(
      backend.applyBaseUrl(`/api/messages/create/${src}`),
      {
        ...(originsThatAllowSendingAttachmentsWithText.includes(src) ||
        apiProvidersThatAllowSendingAttachmentsWithText.includes(apiProvider)
          ? {}
          : {
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
            }),
        method: 'POST',
        body: createOutcomingMsgCreationBody({
          messageData: message,
          origin: src,
          apiProvider,
        }),
      },
    );

    return fetchJWT(request)
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        if (res.status === 401) {
          dispatch(logout());
          return {};
        }
        // Сетевая ошибка
        throw new Error(res.status);
      })
      .then(
        (res) =>
          // remove useless query, anyway we get this from socket
          // dispatch(getMessage(res._id));
          res,
      );
  };

export default createMessage;
