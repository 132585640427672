import { createSelector } from 'reselect';

const getDialogs = createSelector([(state) => state.dialogs], (dialogs) =>
  Object.values(dialogs),
);

const getId = (_, dialogId) => dialogId;

const dialogOpenedSelector = createSelector(
  [getDialogs, getId],
  (dialogs, dialogId) => {
    const [openedDialog] = dialogs.filter((dialoG) => dialoG._id === dialogId);
    return openedDialog;
  },
);

export default dialogOpenedSelector;
