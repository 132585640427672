import fetchJWT from '../../utils/fetchJWT';
import { logout } from '../../actions';
import { recieveWebhook } from '../../reducers/webhooks';
import backend from '../../configs/backend';

const getWebhook = (id) => (dispatch) => {
  const request = new Request(
    backend.applyBaseUrl(`/api/rest-api/webhooks/read/${id}`),
    {
      method: 'GET',
      mode: 'cors',
      cache: 'default',
    },
  );
  fetchJWT(request)
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
      if (res.status === 401) {
        dispatch(logout());
      }
      return [];
    })
    .then((res) => dispatch(recieveWebhook(res[0])))
    .catch((err) => console.error(err));
};

export default getWebhook;
