import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ErrorBlock, Button } from 'antd-mobile';
import { useTranslation } from 'react-i18next';

const Error403 = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate(`/conversations`);
  };

  return (
    <ErrorBlock fullPage title="403" description={t('Errors.Error403')}>
      <Button color="primary" onClick={handleOnClick}>
        {t('Errors.btn')}
      </Button>
    </ErrorBlock>
  );
};

export default Error403;
