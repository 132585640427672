import * as R from 'ramda';
import { createSelector } from 'reselect';

export const RECIEVE_API_TOKENS = 'RECIEVE_API_TOKENS';
export const RECIEVE_API_TOKEN = 'RECIEVE_API_TOKEN';
export const UPDATE_API_TOKEN = 'UPDATE_API_TOKEN';
export const SET_API_TOKEN_NAME = 'SET_API_TOKEN_NAME';
export const DELETE_API_TOKEN = 'DELETE_API_TOKEN';
export const DELETE_API_TOKEN_DRAFT = 'DELETE_API_TOKEN_DRAFT';

function apiTokensFactory(apiToken) {
  return { [apiToken._id]: apiToken };
}

function apiTokensReducer(state = {}, action = { type: '' }) {
  switch (action.type) {
    case RECIEVE_API_TOKENS: {
      return R.mergeAll(R.map(apiTokensFactory, action.payload.apiTokens));
    }
    case RECIEVE_API_TOKEN: {
      return { ...state, ...action.payload.apiToken };
    }
    case DELETE_API_TOKEN: {
      return { ...state, isDeleted: true };
    }
    case SET_API_TOKEN_NAME: {
      return { ...state, name: action.payload.name };
    }
    case UPDATE_API_TOKEN: {
      return {
        ...state,
        ...(action.payload.name ? { name: action.payload.name } : {}),
      };
    }
    default: {
      return state;
    }
  }
}

export const recieveApiTokens = (apiTokens) => ({
  type: RECIEVE_API_TOKENS,
  payload: { apiTokens },
});

export const recieveApiToken = (apiToken) => ({
  type: RECIEVE_API_TOKEN,
  payload: { apiToken },
});

export const setApiTokenName = (_id, name) => ({
  type: SET_API_TOKEN_NAME,
  payload: {
    _id,
    name,
  },
});

export const renewApiToken = ({ apiTokenId, name, conf }) => ({
  type: UPDATE_API_TOKEN,
  payload: {
    apiTokenId,
    name,
    conf,
  },
});

export const removeApiToken = (_id) => ({
  type: DELETE_API_TOKEN,
  payload: {
    _id,
  },
});

export const deleteApiTokenDraft = () => ({
  type: DELETE_API_TOKEN_DRAFT,
});

export default function reducer(state = {}, action = { type: '' }) {
  switch (action.type) {
    case RECIEVE_API_TOKENS: {
      return R.mergeRight(state, apiTokensReducer(state, action));
    }
    case RECIEVE_API_TOKEN: {
      return R.assoc(
        action.payload.apiToken._id,
        apiTokensReducer(state[action.payload.apiToken._id], action),
      )(state);
    }
    case DELETE_API_TOKEN:
    case SET_API_TOKEN_NAME: {
      return R.assoc(
        action.payload._id,
        apiTokensReducer(state[action.payload._id], action),
      )(state);
    }
    case UPDATE_API_TOKEN: {
      return R.assoc(
        action.payload.apiTokenId,
        apiTokensReducer(state[action.payload.apiTokenId], action),
      )(state);
    }
    case DELETE_API_TOKEN_DRAFT: {
      return R.dissoc('new')(state);
    }
    default: {
      return state;
    }
  }
}

export const apiTokensSelector = (state) => state.apiTokens;

export const apiTokensArraySelector = createSelector(
  apiTokensSelector,
  (apiTokens) => R.filter((_) => !_.isDeleted, R.values(apiTokens)),
);

export const deletedApiTokensArraySelector = createSelector(
  apiTokensSelector,
  (apiTokens) => R.filter((_) => _.isDeleted, R.values(apiTokens)),
);

export const apiTokenIdSelector = (state, ownProps) => {
  const { params: { id } = {} } = ownProps.match;
  return id || ownProps.id || ownProps._id;
};

export const apiTokenSelector = createSelector(
  apiTokensSelector,
  apiTokenIdSelector,
  (apiTokens, id) => R.propOr({}, id, apiTokens),
);
