import * as R from 'ramda';
import { createSelector } from 'reselect';

export const RECIEVE_TASK_ITEMS = 'RECIEVE_TASK_ITEMS';
export const RECIEVE_TASK_ITEM = 'RECIEVE_TASK_ITEM';
export const UPDATE_TASK = 'UPDATE_TASK';
export const DELETE_TASK = 'DELETE_TASK';
export const DELETE_TASK_DRAFT = 'DELETE_TASK_DRAFT';
export const CLEAR_TASK_ITEMS_STATE = 'CLEAR_TASK_ITEMS_STATE';

const taskFactory = (task) => ({ [task._id]: task });

const taskReducer = (state = {}, action = {}) => {
  switch (action.type) {
    case RECIEVE_TASK_ITEMS: {
      return R.mergeAll(R.map(taskFactory, action.payload.tasks));
    }
    case RECIEVE_TASK_ITEM: {
      return { ...state, ...action.payload.task };
    }
    case UPDATE_TASK: {
      const {
        title,
        description,
        access,
        dueDate,
        status,
        responsibleId,
        connections,
        labels,
      } = action.payload.body;
      return {
        ...state,
        ...(title ? { title } : {}),
        ...(description ? { description } : {}),
        ...(access ? { access } : {}),
        ...(dueDate ? { dueDate } : {}),
        ...(status ? { status } : {}),
        ...(responsibleId ? { responsibleId } : {}),
        ...(connections ? { connections } : {}),
        ...(labels ? { labels } : {}),
      };
    }
    case DELETE_TASK: {
      return { ...state, isDeleted: true };
    }
    default: {
      return state;
    }
  }
};

export const recieveTaskItems = (tasks) => ({
  type: RECIEVE_TASK_ITEMS,
  payload: { tasks },
});

export const recieveTaskItem = (task) => ({
  type: RECIEVE_TASK_ITEM,
  payload: { task },
});

export const renewTask = (taskId, body) => ({
  type: UPDATE_TASK,
  payload: { taskId, body },
});

export const removeTask = (taskId) => ({
  type: DELETE_TASK,
  payload: { taskId },
});

export const deleteTaskDraft = () => ({
  type: DELETE_TASK_DRAFT,
});

export const clearTaskItemsState = () => ({
  type: CLEAR_TASK_ITEMS_STATE,
  payload: {},
});

export default function reducer(state = {}, action = { type: '' }) {
  switch (action.type) {
    case RECIEVE_TASK_ITEMS: {
      return R.mergeRight(state, taskReducer(state, action));
    }
    case RECIEVE_TASK_ITEM: {
      return R.assoc(
        action.payload.task._id,
        taskReducer(state[action.payload.task._id], action),
      )(state);
    }
    case UPDATE_TASK:
    case DELETE_TASK: {
      return R.assoc(
        action.payload.taskId,
        taskReducer(state[action.payload.taskId], action),
      )(state);
    }
    case DELETE_TASK_DRAFT: {
      return R.dissoc('new')(state);
    }
    case CLEAR_TASK_ITEMS_STATE: {
      return {};
    }
    default: {
      return state;
    }
  }
}

export const tasksSelector = (state) => state.tasks;

export const tasksArraySelector = createSelector(tasksSelector, (tasks) =>
  R.values(tasks),
);

export const tasksArraySelectorActive = createSelector(tasksSelector, (tasks) =>
  R.values(tasks).filter((t) => !t.isDeleted),
);

export const taskIdSelector = (state, ownProps) =>
  R.pathOr(null, ['match', 'params', 'taskId'], ownProps) ||
  R.propOr(null, 'taskId', ownProps);

export const taskNumberSelector = (state, ownProps) =>
  R.pathOr(null, ['match', 'params', 'taskNumber'], ownProps);

export const taskByNumberSelector = createSelector(
  tasksArraySelector,
  taskNumberSelector,
  (tasks, taskNumber) => tasks.find((t) => t.taskNumber === taskNumber) || null,
);

export const taskSelector = createSelector(
  tasksSelector,
  taskIdSelector,
  (tasks, taskId) => R.propOr(null, taskId, tasks),
);
