import fetchJWT from '../../utils/fetchJWT';
import { logout, loggedIn } from '../../actions';
import { receiveUser } from '../../reducers/users';
import backend from '../../configs/backend';

const getUser = (id) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const request = new Request(
      backend.applyBaseUrl(`/api/users/read?id=${id}`),
      {
        method: 'GET',
        cache: 'default',
        mode: 'cors',
      },
    );

    fetchJWT(request)
      .then((res) => {
        if (res.ok) {
          return res.json();
        }

        if (res.status === 401) {
          dispatch(logout());
        }

        return null;
      })
      .then((res) => {
        if (res && res[0]) {
          const { auth: { user: { _id: loggedUserId } = {} } = {} } =
            getState();

          if (loggedUserId === id) {
            dispatch(loggedIn(res[0]));
          }

          dispatch(receiveUser(res[0]));

          return resolve(res[0]);
        }

        return resolve(false);
      })
      .catch((err) => {
        reject(err);
      });
  });

export default getUser;
