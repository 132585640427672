import { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  callsListFilterResponsibleUsersSelector,
  setCallsFilterResponsibleUsers,
} from '../../reducers/callsFilters';
import {
  dialogsListFilterResponsibleUsersSelector,
  setFilterResponsibleUsers,
} from '../../reducers/dialogsFilters';
import areTwoArraysContentEqual from '../../utils/areTwoArraysContentEqual';
import usePrevious from '../usePrevious';
import useDialogsListMode from './useDialogsListMode';

const useDialogsListResponsibleUsersFilter = ({
  onResponsibleUsersFilterChange,
} = {}) => {
  const dispatch = useDispatch();
  const { dialogsListMode = 'dialogs' } = useDialogsListMode();

  const responsibleUsersFilterUpdateAction =
    dialogsListMode === 'calls'
      ? setCallsFilterResponsibleUsers
      : setFilterResponsibleUsers;

  const updateResponsibleUsersFilter = useCallback(
    (users) => {
      dispatch(responsibleUsersFilterUpdateAction(users));
    },
    [dispatch, responsibleUsersFilterUpdateAction],
  );

  const responsibleUsersFilterSelector =
    dialogsListMode === 'calls'
      ? callsListFilterResponsibleUsersSelector
      : dialogsListFilterResponsibleUsersSelector;

  const responsibleUsersFilter = useSelector(responsibleUsersFilterSelector);
  const prevResponsibleUsersFilter = usePrevious(responsibleUsersFilter);

  useEffect(() => {
    if (
      onResponsibleUsersFilterChange &&
      prevResponsibleUsersFilter &&
      !areTwoArraysContentEqual(
        prevResponsibleUsersFilter,
        responsibleUsersFilter,
      )
    ) {
      onResponsibleUsersFilterChange();
    }
  }, [
    prevResponsibleUsersFilter,
    responsibleUsersFilter,
    onResponsibleUsersFilterChange,
  ]);

  return { responsibleUsersFilter, updateResponsibleUsersFilter };
};

export default useDialogsListResponsibleUsersFilter;
