import * as R from 'ramda';
import { search as searchConfig } from '../configs';
import replaceSpecChars from './replaceSpecChars';

/**
 * @description Function to check filter search conditon.
 * @param {object} searchParams
 * @param {string} searchParams.search Search string.
 * @param {object} searchParams.item Filter iteration item.
 * @param {string} searchParams.entityType Entity type.
 * @param {array} searchParams.clients Clients array.
 * @returns {boolean} Returns true if search condition was successful, else returns false.
 */

const checkFilterSearchCond = ({
  search = '',
  item = {},
  entityType = 'dialog',
  clients = [],
}) => {
  const { searchByLabelRegex, excludeLabelsSearchValue } = searchConfig;

  const searchMode =
    search.match(searchByLabelRegex) && entityType === 'dialog'
      ? 'labels'
      : 'content';

  const regExpSearch = new RegExp(
    // remove # from search string to match labels values
    replaceSpecChars(searchMode === 'labels' ? search.slice(1) : search),
    'i',
  );

  if (searchMode === 'labels') {
    // remove # from search string to match labels values
    const excludeLabels = search.slice(1) === excludeLabelsSearchValue;

    const { labels = [], systemLabels = [] } = item;

    const doesNotIncludeLabels = !labels.length && !systemLabels.length;
    const matchedLabels =
      labels.find((v) => v.match(regExpSearch)) ||
      systemLabels.find((v) => v.match(regExpSearch));

    return excludeLabels ? doesNotIncludeLabels : matchedLabels;
  }

  const { containsContactIds = [], containsMessage = '' } = item;

  const matchedClient = clients.find(({ contacts = [] }) =>
    contacts.find(
      ({ id: cId, value }) =>
        (value && typeof value === 'string' && value.match(regExpSearch)) ||
        containsContactIds.includes(cId),
    ),
  );
  const matchedItemTitle =
    (R.propOr('Без темы', 'title', item) || 'Без темы')
      .toLowerCase()
      .search(regExpSearch) > -1;
  const matchedItemMessages = containsMessage.match(regExpSearch);

  return matchedItemTitle || matchedItemMessages || matchedClient;
};

export default checkFilterSearchCond;
