import { api as apiConf } from '../configs';

const {
  messages: {
    originsThatAllowSendingAttachmentsWithText,
    apiProvidersThatAllowSendingAttachmentsWithText,
  },
} = apiConf;

/**
 * @description This function creates body content for outcoming message creation request.
 * @param {object} messageData Message data (with/without attachments).
 * @param {string} origin Conversation origin.
 * @returns {string} Body content in JSON format.
 */
const createOutcomingMsgCreationBody = ({
  messageData = {},
  origin = 'mail',
  apiProvider,
}) => {
  if (
    originsThatAllowSendingAttachmentsWithText.includes(origin) ||
    apiProvidersThatAllowSendingAttachmentsWithText.includes(apiProvider)
  ) {
    // send real files in request
    /**
     * Send real files in the request by using form-data
     */
    const formData = new FormData();

    // eslint-disable-next-line no-restricted-syntax
    for (const index in messageData.attachments) {
      if (index) {
        const att = messageData.attachments[index];

        if (!att.Key && !att.key && !att.file_id) {
          formData.append(index, new Blob([att.content]));
        }
        // eslint-disable-next-line no-param-reassign
        messageData.attachments[index] = {
          type: 'attachment',
          contentType:
            att.Key || att.key || att.file_id ? att.contentType : att.type,
          size: `${att.size}`,
          filename:
            att.Key || att.key || att.file_id
              ? att.filename || att.file_name || att.title
              : att.name,
          ...(att.Key || att.key || att.file_id
            ? { Key: att.Key || att.key || att.file_id }
            : { contentDisposition: 'attachment' }),
        };
      }
    }

    formData.append('json_string', JSON.stringify(messageData));

    return formData;
  }

  return JSON.stringify({
    ...messageData,
    attachments: (messageData.attachments || []).map((att) => ({
      contentDisposition: 'attachment',
      type: 'attachment',
      contentType: att.contentType || att.type,
      size: `${att.size}`,
      filename: att.filename || att.file_name || att.title || att.name,
      Key: att.Key || att.key || att.file_id || null,
    })),
  });
};

export default createOutcomingMsgCreationBody;
