import React, { useRef, useCallback } from 'react';
import PropTypes from 'prop-types';

import useSizeMap from '../../hooks/useSizeMap';
import useRecalcStore from '../../hooks/useRecalcStore';
import throttle from '../../utils/throttle';
import mergeComponentRefs from '../../utils/mergeComponentRefs';

function withDynamicItems(VirtualListComponent) {
  function DynamicItemsVirtualList(props, ref) {
    const {
      itemData = {},
      mapItemIdToIndex,
      mapIndexToItemId,
      recalcThrottleTimeout = 50,
      fallbackItemSize,
      ...otherProps
    } = props;

    const listRef = useRef();

    const recalcItems = useCallback(
      (itemIds) => {
        if (listRef.current && itemIds.length) {
          const [highestItemIndex] = itemIds.map(mapItemIdToIndex).sort();

          listRef.current.resetItemsHeightCacheAfterIndex(highestItemIndex);
        }
      },
      [mapItemIdToIndex],
    );

    const { addToRecalcStore, recalcItemsInStore } =
      useRecalcStore(recalcItems);
    const { setSize, getSize, isItemInSizeMap } = useSizeMap(fallbackItemSize);

    const throttledItemsRecalc = throttle(
      recalcItemsInStore,
      recalcThrottleTimeout,
    );

    const getSizeByIndex = useCallback(
      (itemIndex) => {
        const itemId = mapIndexToItemId(itemIndex);

        const itemSize = getSize(itemId);

        return itemSize;
      },
      [getSize, mapIndexToItemId],
    );

    const setSizeAndRecalcItem = useCallback(
      (id, size) => {
        setSize(id, size);
        addToRecalcStore(id);
        throttledItemsRecalc();
      },
      [addToRecalcStore, setSize, throttledItemsRecalc],
    );

    const itemDataWithSetSize = {
      ...itemData,
      setSize: setSizeAndRecalcItem,
      getSize,
      isSizeCached: isItemInSizeMap,
    };

    return (
      <VirtualListComponent
        // TODO -> deal with object fields copy on each render
        {...otherProps}
        ref={mergeComponentRefs([listRef, ref])}
        fallbackItemSize={fallbackItemSize}
        mapIndexToItemId={mapIndexToItemId}
        mapItemIdToIndex={mapItemIdToIndex}
        getSize={getSizeByIndex}
        itemData={itemDataWithSetSize}
      />
    );
  }

  DynamicItemsVirtualList.displayName = `withDynamicItems(${
    VirtualListComponent.displayName || VirtualListComponent.name
  })`;

  DynamicItemsVirtualList.propTypes = {
    mapItemIdToIndex: PropTypes.func.isRequired,
    mapIndexToItemId: PropTypes.func.isRequired,
    itemData: PropTypes.object,
    recalcThrottleTimeout: PropTypes.number,
    fallbackItemSize: PropTypes.number,
  };

  return React.forwardRef(DynamicItemsVirtualList);
}

withDynamicItems.propTypes = {
  VirtualListComponent: PropTypes.node,
};

export default withDynamicItems;
