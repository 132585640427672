import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { List, Toast } from 'antd-mobile';
import { DownlandOutline } from 'antd-mobile-icons';

const styles = {
  '--adm-color-background': '#f2f5f7',
};

const DialogOpenedInfo = ({ dialogContact, dialog }) => {
  const { t } = useTranslation();
  const clientContact = dialogContact?.contacts.filter(
    (contact) => contact.id === dialog.contactsId,
  )[0];

  const renderClientContact = useCallback((contact) => {
    if (contact) {
      return contact.value;
    }
    return '';
  }, []);

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        Toast.show({
          content: t('DialogOpenedInfo.copySuccess'),
          position: 'bottom',
        });
      })
      .catch((err) => {
        Toast.show({
          content: t('DialogOpenedInfo.copyError'),
          position: 'bottom',
        });
      });
  };

  return (
    <>
      <List.Item
        description={t('DialogOpenedInfo.customerName')}
        style={styles}
      >
        <b style={{ fontSize: '13px' }}>{dialogContact.name}</b>
      </List.Item>
      <List.Item
        description={t('DialogOpenedInfo.contact')}
        arrowIcon={<DownlandOutline />}
        style={styles}
        onClick={() =>
          copyToClipboard(
            clientContact
              ? renderClientContact(clientContact)
              : renderClientContact(dialogContact?.contacts[0]),
          )
        }
      >
        <b style={{ fontSize: '13px' }}>
          {clientContact
            ? renderClientContact(clientContact)
            : renderClientContact(dialogContact?.contacts[0])}
        </b>
      </List.Item>
    </>
  );
};

export default DialogOpenedInfo;
