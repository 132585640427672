import { createSelector } from 'reselect';
import { usersArraySelectorIsActive } from '../reducers/users';

const getPossibleUsersSelector = (state) => usersArraySelectorIsActive(state);

const getPossibleClientsSelector = (state) => Object.values(state.clients);

const getOurDialog = (state, props) => props.dialog;

const dialogClientSelector = createSelector(
  [getPossibleUsersSelector, getPossibleClientsSelector, getOurDialog],
  (users, clients, dialog) => {
    let dialogClient = users.filter(
      (userM) => userM._id === dialog.authorId,
    )[0];
    if (!dialogClient && clients.length) {
      dialogClient = clients.filter(
        (client) =>
          client.contacts &&
          client.contacts.find((cData) => cData.id === dialog.contactsId),
      ).length
        ? clients.filter(
            (client) =>
              client.contacts &&
              client.contacts.find((cData) => cData.id === dialog.contactsId),
          )[0]
        : {};
    }
    return dialogClient;
  },
);

export default dialogClientSelector;
