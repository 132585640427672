import { useSelector } from 'react-redux';
import {
  deletedPbxAccountsArraySelector,
  pbxAccountsArraySelector,
} from '../../../reducers/pbx-accounts';
import getActiveChannelsFromState from '../../../selectors/getActiveChannelsFromState';
import getDeletedChannelsFromState from '../../../selectors/getDeletedChannelsFromState';
import checkUserRightsForEntity from '../../../utils/checkUserRightsForEntity';
import useLoggedUser from '../../auth/useLoggedUser';
import useDialogsListMode from '../useDialogsListMode';

const useDialogChannelsFilter = () => {
  const { user } = useLoggedUser();
  const { dialogsListMode: listMode = 'dialogs' } = useDialogsListMode();

  const channelsSelector =
    listMode === 'calls'
      ? pbxAccountsArraySelector
      : getActiveChannelsFromState;

  const channels = useSelector(channelsSelector);

  const deletedChannelsSeleсtor =
    listMode === 'calls'
      ? deletedPbxAccountsArraySelector
      : getDeletedChannelsFromState;

  const deletedChannels = useSelector(deletedChannelsSeleсtor);

  const channelsForDisplay = channels.filter((ch) =>
    checkUserRightsForEntity({
      entity: ch,
      entityType: 'channel',
      user,
    }),
  );

  const deletedChannelsForDisplay = deletedChannels.filter((ch) =>
    checkUserRightsForEntity({
      entity: ch,
      entityType: 'channel',
      user,
    }),
  );

  return {
    channelsForDisplay,
    deletedChannelsForDisplay,
  };
};

export default useDialogChannelsFilter;
