import { AnyAction } from 'redux';

export const SET_EXPORT_EVENTS = 'SET_EXPORT_EVENTS';
export const CLEAN_EXPORT_EVENTS = 'CLEAN_EXPORT_EVENTS';

export function setExportEvents(data: any) {
  return {
    type: SET_EXPORT_EVENTS,
    payload: data,
  };
}

export function cleanExportEvents() {
  return {
    type: CLEAN_EXPORT_EVENTS,
  };
}

export default function reducer(state = {}, action: AnyAction) {
  switch (action.type) {
    case SET_EXPORT_EVENTS: {
      return action.payload;
    }
    case CLEAN_EXPORT_EVENTS: {
      return {};
    }
    default: {
      return state;
    }
  }
}

export const getExportEvents = (state: any) => state.exportEvents || {};
