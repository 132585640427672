import React, { memo } from 'react';
import { ErrorBlock } from 'antd-mobile';
import { useTranslation } from 'react-i18next';

const EmptyDialogMessagesList = () => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ErrorBlock
        status="empty"
        title={t('EmptyDialogMessagesListTitle')}
        description=""
      />
    </div>
  );
};

EmptyDialogMessagesList.displayName = 'EmptyDialogMessagesList';

export default memo(EmptyDialogMessagesList);
