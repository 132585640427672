import fetchJWT from '../../utils/fetchJWT';
import { logout } from '../../actions';
import backend from '../../configs/backend';

const getDialogsCountOfStatus = (type) => async (dispatch) => {
  const url = backend.applyBaseUrl(
    type ? `/api/dialogs/read?filteredByStatus=${type}` : `/api/dialogs/read`,
  );
  const request = new Request(url, {
    method: 'GET',
    cache: 'default',
    mode: 'cors',
  });

  return fetchJWT(request)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      if (response.status === 401) {
        dispatch(logout());
      }
      return {};
    })
    .then((response) => response.length)
    .catch((err) => console.error(err));
};

export default getDialogsCountOfStatus;
