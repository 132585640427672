import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { components as componentsConf } from '../../../../configs';
import useDialogChannelsFilter from '../../../../hooks/dialogs/dialogFilters/useDialogChannelsFilter';
import useDialogsListChannelFilters from '../../../../hooks/dialogs/useDialogsListChannelFilters';
import useDialogsListMode from '../../../../hooks/dialogs/useDialogsListMode';
import useDialogsListResponsibleUsersFilter from '../../../../hooks/dialogs/useDialogsListResponsibleUsersFilter';
import { setCallsFilterStatuses } from '../../../../reducers/callsFilters';
import { setFilterStatuses } from '../../../../reducers/dialogsFilters';
import EmptyDialogsListView from './EmptyDialogsListView';

const {
  pages: {
    dialogs: {
      filter: {
        availableStatusFilters: {
          dialogs: { SHOW_ALL },
          calls: { SHOW_ALL_CALLS },
        },
      },
    },
  },
} = componentsConf;

const EmptyDialogsList = () => {
  const dispatch = useDispatch();

  const { updateChannelFilters } = useDialogsListChannelFilters();

  const { channelsForDisplay, deletedChannelsForDisplay } =
    useDialogChannelsFilter();

  const { dialogsListMode: listMode = 'dialogs' } = useDialogsListMode();

  const targetShowAllStatus =
    listMode === 'dialogs' ? SHOW_ALL : SHOW_ALL_CALLS;

  const { updateResponsibleUsersFilter } =
    useDialogsListResponsibleUsersFilter();

  const handleOnClick = useCallback(() => {
    if (listMode === 'dialogs') {
      dispatch(setFilterStatuses(targetShowAllStatus));
      updateChannelFilters(channelsForDisplay.map((ch) => ch._id));
    } else {
      dispatch(setCallsFilterStatuses(targetShowAllStatus));
      updateChannelFilters(
        [...channelsForDisplay, ...deletedChannelsForDisplay].map(
          (ch) => ch._id,
        ),
      );
    }

    updateResponsibleUsersFilter([]);
  }, [
    channelsForDisplay,
    deletedChannelsForDisplay,
    dispatch,
    listMode,
    targetShowAllStatus,
    updateChannelFilters,
    updateResponsibleUsersFilter,
  ]);

  return (
    <EmptyDialogsListView handleOnClick={handleOnClick} listMode={listMode} />
  );
};

export default React.memo(EmptyDialogsList);
