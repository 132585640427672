import * as R from 'ramda';
import { createSelector } from 'reselect';

export const RECIEVE_BILLING_REQUESTS = 'RECIEVE_BILLING_REQUESTS';
export const RECIEVE_BILLING_REQUEST = 'RECIEVE_BILLING_REQUEST';

function billingRequestFactory(request) {
  return { [request._id]: request };
}

function billingRequestsReducer(state = {}, action = { type: '' }) {
  switch (action.type) {
    case RECIEVE_BILLING_REQUESTS: {
      return R.mergeAll(
        R.map(billingRequestFactory, action.payload.billingRequests),
      );
    }
    case RECIEVE_BILLING_REQUEST: {
      return { ...state, ...action.payload.billingRequest };
    }
    default: {
      return state;
    }
  }
}

export const recieveBillingRequests = (billingRequests) => ({
  type: RECIEVE_BILLING_REQUESTS,
  payload: { billingRequests },
});

export const recieveBillingRequest = (billingRequest) => ({
  type: RECIEVE_BILLING_REQUEST,
  payload: { billingRequest },
});

export default function reducer(state = {}, action = { type: '' }) {
  switch (action.type) {
    case RECIEVE_BILLING_REQUESTS: {
      return R.mergeRight(state, billingRequestsReducer(state, action));
    }
    case RECIEVE_BILLING_REQUEST: {
      return R.assoc(
        action.payload.billingRequest._id,
        billingRequestsReducer(
          state[action.payload.billingRequest._id],
          action,
        ),
      )(state);
    }
    default: {
      return state;
    }
  }
}

export const billingRequestsSelector = (state) => state.billingRequests;

export const billingRequestsArraySelector = createSelector(
  billingRequestsSelector,
  (requests) => R.filter((req) => !req.isDeleted, R.values(requests)),
);

export const billingRequestIdSelector = (state, ownProps) => {
  const {
    params: { requestId },
  } = ownProps.match;
  return requestId || ownProps.id || ownProps._id;
};

export const billingRequestSelector = createSelector(
  [billingRequestsSelector, billingRequestIdSelector],
  (requests, id) => R.propOr({}, id, requests),
);
