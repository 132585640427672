import { tasksFilters as tasksFiltersConstants } from '../constants';

const {
  SHOW_ALL_TASKS,
  SHOW_MINE_TASKS,
  SHOW_CREATED_BY_ME_TASKS,
  SHOW_ARCHIVE_TASKS,
} = tasksFiltersConstants;

const tasksConfig = {
  table: { pageSize: 20 },
  filters: {
    filterTypeByConstant: {
      SHOW_ALL_TASKS: 'all',
      SHOW_MINE_TASKS: 'mine',
      SHOW_CREATED_BY_ME_TASKS: 'createdByMe',
      SHOW_ARCHIVE_TASKS: 'archive',
    },
    filterOptions: [
      SHOW_MINE_TASKS,
      SHOW_CREATED_BY_ME_TASKS,
      SHOW_ALL_TASKS,
      SHOW_ARCHIVE_TASKS,
    ],
    filterOptionsLabels: {
      SHOW_MINE_TASKS: 'Мои задачи',
      SHOW_ALL_TASKS: 'Все',
      SHOW_CREATED_BY_ME_TASKS: 'Созданы мной',
      SHOW_ARCHIVE_TASKS: 'Архив',
    },
  },
  labels: {
    status: {
      opened: {
        text: 'Открыта',
        cssClass: 'statusLabel-opened',
        customAttributes: {
          color: 'green',
        },
      },
      closed: {
        text: 'Закрыта',
        cssClass: 'statusLabel-closed',
        customAttributes: {
          color: 'gray',
        },
      },
      overdue: {
        text: 'Просрочена',
        cssClass: 'statusLabel-overdue',
        customAttributes: {
          color: 'red',
        },
      },
    },
    statusChangeAction: {
      opened: 'Завершить',
      overdue: 'Завершить',
      closed: 'Открыть',
    },
    viewLimits: {
      dialogClientTask: 5,
    },
  },
  connections: {
    types: {
      dialogClient: 'dialog-client',
    },
    cannotSetConnectionWarning: 'Нельзя установить связь',
  },
  explanations: {
    connections: 'Связанные с задачей диалог и клиент',
  },
  messagesList: {
    sortOrder: 'desc',
  },
  commentsEditor: {
    mentions: {
      prefix: '@',
      emptyText: 'Не могу найти такого пользователя',
    },
  },
  limits: {
    infiniteLoadingBatchSize: 50,
  },
};

export default tasksConfig;
