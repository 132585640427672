import React from 'react';
import { ErrorBlock } from 'antd-mobile';
import { useTranslation } from 'react-i18next';

const Dashbord = () => {
  const { t } = useTranslation();
  return <ErrorBlock fullPage title={t('inDevelopment')} description="" />;
};

export default Dashbord;
