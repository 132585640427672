/* eslint-disable no-console */
import fetchJWT from '../../utils/fetchJWT';
import { logout } from '../../actions';
import { renewDialog } from '../../reducers/dialogs';
import backend from '../../configs/backend';

const updateDialog =
  ({
    id,
    channelId,
    responsibleUserName,
    title,
    responsibleId,
    responsibleType,
    status,
    readAt,
    waitingTill,
    labels,
  }) =>
  async (dispatch, getState) => {
    const { dialogs } = await getState();
    if (
      dialogs[id] &&
      responsibleId &&
      dialogs[id].responsibleId === responsibleId
    ) {
      return;
    }
    const body = JSON.stringify({
      channelId,
      title,
      status,
      responsibleId,
      responsibleUserName,
      responsibleType,
      readAt,
      waitingTill,
      labels,
    });

    dispatch(
      renewDialog(id, {
        channelId,
        title,
        status,
        responsibleId,
        responsibleUserName,
        readAt,
        waitingTill,
        labels,
      }),
    );

    const request = new Request(
      backend.applyBaseUrl(`/api/dialogs/update/${id}`),
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        mode: 'cors',
        cache: 'default',
        body,
      },
    );
    fetchJWT(request)
      .then((res) => {
        if (res.status === 401) {
          dispatch(logout());
        }
      })
      .catch((err) => console.error(err));
  };

export default updateDialog;
