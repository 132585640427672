import { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  dialogsListFilterStatusSelector,
  setFilterStatuses,
} from '../../reducers/dialogsFilters';
import {
  callsListFilterStatusSelector,
  setCallsFilterStatuses,
} from '../../reducers/callsFilters';
import usePrevious from '../usePrevious';
import useDialogsListMode from './useDialogsListMode';

const useDialogsListFilterStatus = ({ onFilterStatusChange } = {}) => {
  const dispatch = useDispatch();

  const { dialogsListMode = 'dialogs' } = useDialogsListMode();

  const filterStatusUpdateAction =
    dialogsListMode === 'calls' ? setCallsFilterStatuses : setFilterStatuses;
  const updateFilterStatus = useCallback(
    (status) => {
      dispatch(filterStatusUpdateAction(status));
    },
    [dispatch, filterStatusUpdateAction],
  );

  const filterStatusSelector =
    dialogsListMode === 'calls'
      ? callsListFilterStatusSelector
      : dialogsListFilterStatusSelector;
  const filterStatus = useSelector(filterStatusSelector);
  const prevFilterStatus = usePrevious(filterStatus);

  useEffect(() => {
    if (
      onFilterStatusChange &&
      prevFilterStatus &&
      prevFilterStatus !== filterStatus
    ) {
      onFilterStatusChange();
    }
  }, [prevFilterStatus, filterStatus, onFilterStatusChange]);

  return { filterStatus, updateFilterStatus };
};

export default useDialogsListFilterStatus;
