import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SpinLoading } from 'antd-mobile';
import PropTypes from 'prop-types';

import { getFileLink } from '../../../api/files';
import { proxyAttachments } from '../../../api/attachments';
import {
  // StickerWrapper,
  LightBoxWrapper,
  AudioWrapper,
} from '../../../components/ContentWrappers';

class Attachments extends Component {
  static propTypes = {
    attachments: PropTypes.array,
    getFileLink: PropTypes.func,
    proxyAttachments: PropTypes.func,
    status: PropTypes.string,
    messageId: PropTypes.string,
    dialog: PropTypes.object,
    type: PropTypes.string,
    text: PropTypes.string,
  };

  constructor() {
    super();
    this.state = {
      readingFile: {},
    };
  }

  getAttachmentLinkHandler = (Key, name, setNameFromHeader) => {
    const {
      dialog: { channelId, origin = 'mail' },
      messageId,
    } = this.props;

    this.setState({ readingFile: { [Key]: true } });

    if (origin === 'telegram' || origin === 'skype') {
      // we need proxy for this channel types
      this.props.proxyAttachments(
        {
          channelType: origin,
          channelId,
          Key,
          name,
          messageId,
          setNameFromHeader,
        },
        (res) => {
          if (res) {
            this.setState({ readingFile: { [Key]: false } });
          }
        },
      );
    } else {
      this.props.getFileLink({ key: Key, origin, messageId }, (res) => {
        if (res) {
          this.setState({ readingFile: { [Key]: false } });
        }
      });
    }
  };

  renderAttachment = (att = {}) => {
    const {
      dialog: { origin = 'mail' },
    } = this.props;

    const file = { ...att };

    // we cannot access skype file url from client
    // so we need to fetch it via backend
    if (origin === 'skype') {
      delete file.url;
    }

    const uniqueFileKey =
      file.Key ||
      file.key ||
      file.file_id ||
      file.filename ||
      file.file_name ||
      file.title;

    if (file.contentType === 'sticker') {
      // TODO -> rewrite to a common link getter wrapper component!
      return (
        <span
          key={`${uniqueFileKey}${
            this.props.type ? `_${this.props.type}` : ''
          }`}
        >
          Sticker
          {/* <StickerWrapper
            sticker={file}
            dialog={this.props.dialog}
            needProxy={
              this.props.dialog.origin === 'telegram' ||
              this.props.dialog.origin === 'skype'
            }
            messageId={this.props.messageId}
          /> */}
        </span>
      );
    }
    const imageContentTypesThatShouldBeTreatedAsFile = [
      'image/svg+xml',
      'image/vnd.adobe.photoshop',
      'image/psd',
      'image/cdr',
      'image/x-cdr',
    ];
    if (['image/x-coreldraw'].every((type) => type !== file.contentType)) {
      if (
        !imageContentTypesThatShouldBeTreatedAsFile.includes(
          file.contentType || file.mimeType || '',
        ) &&
        ((file.contentType && file.contentType.match(/image/i)) ||
          file.isImage ||
          (file.mimeType && file.mimeType.match(/image/i)))
      ) {
        // TODO -> rewrite to a common link getter wrapper component!
        return (
          <div
            key={`${uniqueFileKey}${
              this.props.type ? `_${this.props.type}` : ''
            }`}
          >
            <LightBoxWrapper
              image={file}
              dialog={this.props.dialog}
              needProxy={
                this.props.dialog.origin === 'telegram' ||
                this.props.dialog.origin === 'skype'
              }
              messageId={this.props.messageId}
              type={this.props.type}
              text={this.props.text}
            />
          </div>
        );
      }
      if (file.contentType && file.contentType.match(/audio/i)) {
        // TODO -> rewrite to a common link getter wrapper component!
        return (
          <div
            key={`${uniqueFileKey}${
              this.props.type ? `_${this.props.type}` : ''
            }`}
          >
            <AudioWrapper
              file={file}
              dialog={this.props.dialog}
              needProxy={
                this.props.dialog.origin === 'telegram' ||
                this.props.dialog.origin === 'skype'
              }
              messageId={this.props.messageId}
              type={this.props.type}
              text={this.props.text}
            />
          </div>
        );
      }
    }
    return (
      <div
        className={`attachmentWrapper ${
          this.props.type === 'quoted' ? 'attachmentWrapperQuote' : ''
        }`}
        key={`${uniqueFileKey}${this.props.type ? `_${this.props.type}` : ''}`}
      >
        <a
          className="attachmentWrapper__file"
          href={file.url || null}
          download={file.url || false}
          target="_blank"
          rel="noopener noreferrer"
          onClick={
            file.Key || file.key || file.file_id
              ? () =>
                  this.getAttachmentLinkHandler(
                    file.Key || file.key || file.file_id,
                    file.filename || file.file_name || file.title,
                    file.setNameFromHeader,
                  )
              : null
          }
        >
          {/* {this.state.readingFile[file.Key || file.key || file.file_id] ? (
           <SpinLoading color='default' style={{ '--size': '15px' }} />
          ) : (
            <Icon type="paper-clip" style={{ marginRight: '4px' }} />
          )} */}
          <span style={{ wordBreak: 'break-word' }}>
            {file.filename || file.fileName || file.file_name || file.title}
          </span>
        </a>
        {this.props.text ? (
          <div
            className="attachmentWrapper__text"
            dangerouslySetInnerHTML={{ __html: this.props.text }}
          />
        ) : null}
      </div>
    );
  };

  render() {
    if (!this.props.attachments || !this.props.dialog) {
      return null;
    }
    return this.props.attachments.map(this.renderAttachment);
  }
}

// eslint-disable-next-line no-unused-vars
const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getFileLink: ({ key, origin, messageId }, cb) =>
    dispatch(getFileLink({ key, origin, messageId }, cb)),
  proxyAttachments: (
    { channelType, channelId, Key, name, messageId, setNameFromHeader },
    cb,
  ) =>
    dispatch(
      proxyAttachments(
        { channelType, channelId, Key, name, messageId, setNameFromHeader },
        cb,
      ),
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Attachments);
