import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Routes, Route, Navigate } from 'react-router-dom';
import * as Auth from './pages/Auth';
import { Dialogs, DialogOpened } from './pages/Dialogs';
import Profile from './pages/Profile';
import Dashbord from './pages/Dashbord';
import Customers from './pages/Customers';
import Error404 from './pages/Errors/Error404';
import Error403 from './pages/Errors/Error403';
import Layout from './components/Layout/Layout';
import { getUserData } from './api/users';
import AlertBar from './components/AlertBar';
import { ConfigProvider } from 'antd-mobile';
import enUS from 'antd-mobile/es/locales/en-US';
import './i18n/i18n';

const RootRouter = () => {
  const userLoggedIn = useSelector((state) => state.auth.user?.email);
  const authCompleted = useSelector((state) =>
    Object.prototype.hasOwnProperty.call(state.auth, 'user'),
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserData());

    function updateHeight() {
      document.documentElement.style.setProperty(
        '--app-height',
        `${window.innerHeight}px`,
      );
    }
    updateHeight();
    window.addEventListener('resize', updateHeight);
    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, [dispatch]);

  const ProtectedRoute = ({ element, hasAccess }) => {
    return hasAccess ? element : <Error403 />;
  };

  if (userLoggedIn) {
    return (
      <>
        <AlertBar onOpen={() => {}} onClose={() => {}} />
        <Routes>
          <Route
            path="/profile"
            element={
              <Layout>
                <Profile />
              </Layout>
            }
          />
          <Route
            path="/customers"
            element={
              <Layout>
                <Customers />
              </Layout>
            }
          />
          <Route
            path="/dashbord"
            element={
              <Layout>
                <Dashbord />
              </Layout>
            }
          />
          <Route
            path="/conversations"
            element={
              <Layout>
                <Dialogs />
              </Layout>
            }
          />
          <Route path="/conversations/:dialogId" element={<DialogOpened />} />
          <Route path="/" element={<Navigate to="/conversations" />} />
          <Route path="*" element={<Error404 />} />
          <Route
            path="/403"
            element={<ProtectedRoute element={<div></div>} hasAccess={false} />}
          />
        </Routes>
      </>
    );
  } else if (authCompleted) {
    return (
      <Routes>
        <Route exact path="/signin" element={<Auth.SignIn />} />
        <Route path="/signup" element={<Auth.SignUp />} />
        <Route path="/" element={<Navigate to="/signin" />} />
        <Route path="*" element={<Navigate to="/signin" />} />
      </Routes>
    );
  }
  return null;
};

function App() {
  return (
    <ConfigProvider locale={enUS}>
      <RootRouter />
    </ConfigProvider>
  );
}

export default App;
