import { receiveUsers } from '../../reducers/users';
import fetchJWT from '../../utils/fetchJWT';
import { logout } from '../../actions';
import backend from '../../configs/backend';

const getUsers = () => async (dispatch) => {
  const request = new Request(backend.applyBaseUrl('/api/users/read'), {
    method: 'GET',
    cache: 'default',
    mode: 'cors',
  });
  fetchJWT(request)
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
      if (res.status === 401) {
        dispatch(logout());
      }
      return [];
    })
    .then((res) => dispatch(receiveUsers(res)))
    .catch((err) => console.error(err));
};

export default getUsers;
