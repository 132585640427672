import { receiveMessages } from '../../reducers/messages';
import fetchJWT from '../../utils/fetchJWT';
import { logout } from '../../actions';
import backend from '../../configs/backend';

const getMessages =
  ({ offset, limit, dialogId, taskId, createdAt, ids, noLimit = false }, cb) =>
  async (dispatch) =>
    new Promise((resolve, reject) => {
      const url = backend.applyBaseUrl('/api/messages/read');
      const urlSearchParams = new URLSearchParams({
        ...(offset !== undefined ? { offset } : {}),
        ...(limit !== undefined ? { limit } : {}),
        ...(dialogId !== undefined ? { dialogId } : {}),
        ...(taskId !== undefined ? { taskId } : {}),
        ...(createdAt !== undefined ? { createdAt } : {}),
        ...(ids ? { id: ids.join(',') } : {}),
        ...(noLimit ? { noLimiters: true } : {}),
      });
      url.search = urlSearchParams;

      const request = new Request(url, {
        method: 'GET',
        cache: 'default',
        mode: 'cors',
      });

      fetchJWT(request)
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          if (res.status === 401) {
            dispatch(logout());
          }
          return [];
        })
        .then((res) => {
          dispatch(receiveMessages(res));

          if (cb) {
            cb({ messages: res });
          }

          return resolve(res);
        })
        .catch((err) => {
          console.error(err);

          if (cb) {
            cb({ err });
          }

          reject(err);
        });
    });

export default getMessages;
