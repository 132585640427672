/* eslint-disable no-console */
import { getDialogItems } from '../../reducers/dialogs';
import fetchJWT from '../../utils/fetchJWT';
import searchParamsAdapter from '../../utils/searchParamsAdapter';
import { logout } from '../../actions';
import backend from '../../configs/backend';

const getDialogs =
  (
    offset,
    limit,
    search = '',
    sortBy = null,
    filterStatus = null,
    filterChannels = null,
    filterResponsibleUsers = null,
  ) =>
  async (dispatch) => {
    const url = backend.applyBaseUrl('/api/dialogs/read');
    const params = searchParamsAdapter({
      offset,
      limit,
      search,
      filterStatus,
      filterChannels,
      sortBy,
      filterResponsibleUsers,
    });
    const urlSearchParams = new URLSearchParams(params);
    url.search = urlSearchParams;
    const request = new Request(url, {
      method: 'GET',
      cache: 'default',
      mode: 'cors',
    });

    fetchJWT(request)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        if (response.status === 401) {
          dispatch(logout());
        }
        return {};
      })
      .then((response) => {
        dispatch(getDialogItems(response));
      })
      .catch((err) => console.error(err));
  };

export default getDialogs;
