import { useRef, useCallback } from 'react';

/**
 * @description Hook to handle items recalculations (ex: size).
 * @param {function(string[]) => void} recalcFn
 */
const useRecalcStore = (recalcFn) => {
  const initalRecalcStore = [];
  const recalcStore = useRef(initalRecalcStore);

  const isItemInRecalcStore = useCallback(
    /**
     * @param {string|number} id
     */
    (id) => recalcStore.current.includes(id),
    [],
  );

  const addToRecalcStore = useCallback(
    /**
     * @param {string|number} id
     */
    (id) => {
      if (!isItemInRecalcStore(id)) {
        recalcStore.current.push(id);
      }
    },
    [isItemInRecalcStore],
  );

  const clearRecalcStore = useCallback(() => {
    recalcStore.current = initalRecalcStore;
  }, [initalRecalcStore]);

  const recalcItemsInStore = useCallback(() => {
    recalcFn(recalcStore.current);
    clearRecalcStore();
  }, [recalcFn, clearRecalcStore]);

  return {
    isItemInRecalcStore,
    addToRecalcStore,
    clearRecalcStore,
    recalcItemsInStore,
  };
};

export default useRecalcStore;
