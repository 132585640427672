import * as R from 'ramda';
import { createSelector } from 'reselect';

export const RECIEVE_WEBHOOKS = 'RECIEVE_WEBHOOKS';
export const RECIEVE_WEBHOOK = 'RECIEVE_WEBHOOK';
export const UPDATE_WEBHOOK = 'UPDATE_WEBHOOK';
export const SET_WEBHOOK_NAME = 'SET_WEBHOOK_NAME';
export const SET_WEBHOOK_URL = 'SET_WEBHOOK_URL';
export const DELETE_WEBHOOK = 'DELETE_WEBHOOK';
export const DELETE_WEBHOOK_DRAFT = 'DELETE_WEBHOOK_DRAFT';

function webhooksFactory(webhook) {
  return { [webhook._id]: webhook };
}

function webhooksReducer(state = {}, action = { type: '' }) {
  switch (action.type) {
    case RECIEVE_WEBHOOKS: {
      return R.mergeAll(R.map(webhooksFactory, action.payload.webhooks));
    }
    case RECIEVE_WEBHOOK: {
      return { ...state, ...action.payload.webhook };
    }
    case DELETE_WEBHOOK: {
      return { ...state, isDeleted: true };
    }
    case SET_WEBHOOK_NAME: {
      return { ...state, name: action.payload.name };
    }
    case SET_WEBHOOK_URL: {
      return { ...state, url: action.payload.url };
    }
    case UPDATE_WEBHOOK: {
      return {
        ...state,
        ...(action.payload.name ? { name: action.payload.name } : {}),
        ...(action.payload.url ? { url: action.payload.url } : {}),
        ...(action.payload.conf ? { conf: action.payload.conf } : {}),
      };
    }
    default: {
      return state;
    }
  }
}

export const recieveWebhooks = (webhooks) => ({
  type: RECIEVE_WEBHOOKS,
  payload: { webhooks },
});

export const recieveWebhook = (webhook) => ({
  type: RECIEVE_WEBHOOK,
  payload: { webhook },
});

export const setWebhookName = (_id, name) => ({
  type: SET_WEBHOOK_NAME,
  payload: {
    _id,
    name,
  },
});

export const setWebhookUrl = (_id, url) => ({
  type: SET_WEBHOOK_URL,
  payload: {
    _id,
    url,
  },
});

export const renewWebhook = ({ webhookId, name, url, conf }) => ({
  type: UPDATE_WEBHOOK,
  payload: {
    webhookId,
    name,
    url,
    conf,
  },
});

export const removeWebhook = (_id) => ({
  type: DELETE_WEBHOOK,
  payload: {
    _id,
  },
});

export const deleteWebhookDraft = () => ({
  type: DELETE_WEBHOOK_DRAFT,
});

export default function reducer(state = {}, action = { type: '' }) {
  switch (action.type) {
    case RECIEVE_WEBHOOKS: {
      return R.mergeRight(state, webhooksReducer(state, action));
    }
    case RECIEVE_WEBHOOK: {
      return R.assoc(
        action.payload.webhook._id,
        webhooksReducer(state[action.payload.webhook._id], action),
      )(state);
    }
    case DELETE_WEBHOOK:
    case SET_WEBHOOK_NAME:
    case SET_WEBHOOK_URL: {
      return R.assoc(
        action.payload._id,
        webhooksReducer(state[action.payload._id], action),
      )(state);
    }
    case UPDATE_WEBHOOK: {
      return R.assoc(
        action.payload.webhookId,
        webhooksReducer(state[action.payload.webhookId], action),
      )(state);
    }
    case DELETE_WEBHOOK_DRAFT: {
      return R.dissoc('new')(state);
    }
    default: {
      return state;
    }
  }
}

export const webhooksSelector = (state) => state.webhooks;

export const webhooksArraySelector = createSelector(
  webhooksSelector,
  (webhooks) => R.filter((_) => !_.isDeleted, R.values(webhooks)),
);

export const deletedWebhooksArraySelector = createSelector(
  webhooksSelector,
  (webhooks) => R.filter((_) => _.isDeleted, R.values(webhooks)),
);

export const webhookIdSelector = (state, ownProps) => {
  const { params: { id } = {} } = ownProps.match;
  return id || ownProps.id || ownProps._id;
};

export const webhookSelector = createSelector(
  webhooksSelector,
  webhookIdSelector,
  (webhooks, id) => R.propOr({}, id, webhooks),
);
