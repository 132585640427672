/**
 * @description Function that determines if 2 provided arrays have equal content.
 * @param {array} arr1
 * @param {array} arr2
 * @returns {boolean} Comparation result.
 */
const areTwoArraysContentEqual = (arr1, arr2) => {
  if (!arr1 || !arr2) {
    return false;
  }

  if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
    throw new TypeError('Arguments arr1 and arr2 must be of type Array!');
  }

  if (arr1.length !== arr2.length) {
    return false;
  }

  const arraysAreEqual = arr1.every((elArr1) => arr2.includes(elArr1));

  return arraysAreEqual;
};

export default areTwoArraysContentEqual;
