import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { getDialogsByArray } from '../../api/dialogs';

const useDialogsListLoadMore = ({ handleReqError } = {}) => {
  const dispatch = useDispatch();

  const getDialogsByIdArray = useCallback(
    (dialogsIdArray, cb) => dispatch(getDialogsByArray({ dialogsIdArray }, cb)),
    [dispatch],
  );

  const loadMore = useCallback(
    /**
     * @param {string[]} dialogIds
     */
    (dialogIds) => {
      if (dialogIds.length) {
        getDialogsByIdArray(dialogIds, ({ err }) => {
          handleReqError(err);
        });
      }
    },
    [getDialogsByIdArray, handleReqError],
  );

  return { loadMore };
};

export default useDialogsListLoadMore;
