import React from 'react';
import { NoticeBar } from 'antd-mobile';
import { ClockCircleOutline } from 'antd-mobile-icons';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { updateDialog } from '../../../../api/dialogs';

const DialogNoticeBarWaiting = ({ openedDialog }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const openDialog = () => {
    dispatch(
      updateDialog({
        id: openedDialog._id,
        channelId: openedDialog.channelId,
        status: 'opened',
      }),
    );
  };

  return openedDialog.status === 'waiting' && openedDialog.waitingTill ? (
    <NoticeBar
      icon={<ClockCircleOutline />}
      extra={
        <span style={{ cursor: 'pointer' }} onClick={openDialog}>
          {t('DialogNoticeBarWaiting.cancel')}
        </span>
      }
      content={
        new Date(openedDialog.waitingTill).getFullYear() < 3000
          ? `${t('DialogNoticeBarWaiting.dialoguePostponedUntil')} ${moment(openedDialog.waitingTill).format('DD.MM.YYYY • HH:mm')}`
          : t('DialogNoticeBarWaiting.dialoguePostponedIndefinitely')
      }
      color="alert"
    />
  ) : null;
};

export default DialogNoticeBarWaiting;
