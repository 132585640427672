import { receiveInvites } from '../../reducers/invites';
import fetchJWT from '../../utils/fetchJWT';
import { logout } from '../../actions';
import backend from '../../configs/backend';

const getInvites = () => async (dispatch) => {
  const request = new Request(backend.applyBaseUrl('/api/users/read/invites'), {
    method: 'GET',
    cache: 'default',
    mode: 'cors',
  });
  fetchJWT(request)
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
      if (res.status === 401) {
        dispatch(logout());
      }
      return [];
    })
    .then((res) => dispatch(receiveInvites(res)))
    .catch((err) => console.error(err));
};

export default getInvites;
