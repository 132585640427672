import fetchJWT from '../../../utils/fetchJWT';
import { logout } from '../../../actions';
import { recieveWhatsAppAccount } from '../../../reducers/whatsappAccounts';
import backend from '../../../configs/backend';

const getGreenApiAccount = (id) => (dispatch) => {
  const request = new Request(
    backend.applyBaseUrl(`/api/whatsapp-channel/green-api/v1/read/${id}`),
    {
      method: 'GET',
      mode: 'cors',
    },
  );

  fetchJWT(request)
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
      if (res.status === 401) {
        dispatch(logout());
      }
      return [];
    })
    .then((res) => {
      dispatch(recieveWhatsAppAccount(res[0]));
    })
    .catch((err) => console.error(err));
};

export default getGreenApiAccount;
