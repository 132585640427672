import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Space, Avatar } from 'antd-mobile';
import {
  ClockCircleOutline,
  UserOutline,
  FaceRecognitionOutline,
} from 'antd-mobile-icons';
import DialogsListItemOriginIcon from './DialogsListItemOriginIcon';
import './index.css';

const DialogsListItemTitle = ({
  origin = 'mail',
  text,
  dialogStatus,
  waitingTill,
  showResponsible,
  responsible,
}) => (
  <div className="dialogsListItemTitle">
    <DialogsListItemOriginIcon origin={origin} />
    <div className="dialogsListItemTitle__text">{text}</div>
    <Space align="center" style={{ marginLeft: '5px' }}>
      {dialogStatus === 'waiting' && waitingTill ? (
        <ClockCircleOutline
          color="#FA8C16"
          fontSize={18}
          style={{ display: 'block' }}
        />
      ) : null}
      {showResponsible && responsible ? (
        <>
          {responsible.avatar ? (
            <Avatar
              src={responsible.avatar}
              className="dialogsListItemTitle__responsibleAvatar"
              fit="cover"
            />
          ) : (
            <div className="dialogsListItemTitle__responsibleIcon">
              {responsible.email ? (
                <UserOutline fontSize={16} />
              ) : (
                <FaceRecognitionOutline fontSize={16} />
              )}
            </div>
          )}
        </>
      ) : null}
    </Space>
  </div>
);

DialogsListItemTitle.propTypes = {
  origin: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  dialogStatus: PropTypes.string.isRequired,
  waitingTill: PropTypes.string,
  showResponsible: PropTypes.bool.isRequired,
  responsible: PropTypes.object,
};

export default memo(DialogsListItemTitle);
