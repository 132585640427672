import React, { useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';

import useSelectedDialogsListItem from './useSelectedDialogsListItem';
import dialogResponsibleSelector from '../../selectors/dialogResponsibleSelector';
import {
  dialogsListFilterStatusSelector,
  SHOW_MINE,
} from '../../reducers/dialogsFilters';
import { SHOW_MINE_CALLS } from '../../reducers/callsFilters';
import generateAvatarFromTitle from '../../utils/generateAvatarFromTitle';
import getDialogsListItemByPosition from '../../selectors/getDialogsListItemByPosition';
import formatDateForDisplay from '../../utils/formatDateForDisplay';
// import callOutIcon from '../../assets/images/call-made.svg';
// import callInIcon from '../../assets/images/call-received.svg';
// import callMissedIcon from '../../assets/images/call-missed.svg';
import { components as componentsConf } from '../../configs';

const {
  pages: {
    dialogs: {
      list: { callAvatarIconAltByStatus },
    },
  },
} = componentsConf;

/**
 * @typedef {Object} DialogsListItemAvatar
 * @property {string} type
 * @property {string} value
 */

/**
 * @typedef {Object} DialogsListItemMetadata
 * @property {Date|string|number} lastMessageDate
 * @property {string} lastMessagePreview
 */

/**
 * @typedef {Object} DialogsListItem
 * @property {string} _id
 * @property {string|null} responsibleId
 * @property {string} title
 * @property {string} status
 * @property {string} [event]
 * @property {string} origin
 * @property {DialogsListItemAvatar} avatar
 * @property {DialogsListItemMetadata} metadata
 * @property {string[]} labels
 */

/**
 * @typedef {Object} DialogsListItemLastMessage
 * @property {string|null} date
 * @property {string} text
 */

// TODO -> split grouped logic into separate hooks
/**
 * @description Hook that provides all necessary data and functions for dialogs list item.
 */
const useDialogsListItemData = (itemIndex) => {
  const getItemFromState = useCallback(
    (state) =>
      getDialogsListItemByPosition(state, { index: itemIndex }) || null,
    [itemIndex],
  );

  const rawItem = useSelector(getItemFromState);
  const item = useMemo(() => rawItem || {}, [rawItem]);

  /**
   * @type {DialogsListItem}
   */
  const {
    _id: itemId,
    responsibleId = '',
    title = '',
    status = 'opened',
    event = '',
    origin = 'mail',
    avatar: dialogAvatar = { type: '' },
    metadata: {
      lastMessageDate = new Date(),
      lastMessagePreview = '',
      lastMessageType,
    } = {},
    isDialogAnswered = true,
    labels = [],
    waitingTill = '',
  } = item;

  const itemStatus = event === 'call_missed' ? 'missed' : status;

  const getCallAvatarContent = useCallback(() => {
    const iconAlt = callAvatarIconAltByStatus[itemStatus];
    const iconSrc = {
      missed: 'callMissedIcon',
      inbound: 'callInIcon',
      outbound: 'callOutIcon',
    };

    return (
      <img src={iconSrc[itemStatus]} alt={iconAlt} width="20px" height="20px" />
    );
  }, [itemStatus]);
  const generateAvatar = useCallback(() => {
    const content =
      origin === 'pbx'
        ? getCallAvatarContent()
        : generateAvatarFromTitle(title);

    return { ...dialogAvatar, content };
  }, [origin, dialogAvatar, title, getCallAvatarContent]);
  const avatar = generateAvatar();

  const filterStatus = useSelector(dialogsListFilterStatusSelector);
  const isResponsibleVisible = useCallback(
    () =>
      responsibleId !== undefined &&
      responsibleId !== null &&
      filterStatus !== SHOW_MINE &&
      filterStatus !== SHOW_MINE_CALLS,
    [responsibleId, filterStatus],
  );
  const showResponsible = isResponsibleVisible();

  const getResponsibleFromState = useCallback(
    (state) =>
      dialogResponsibleSelector(Object.assign({}, state, { dialog: item })),
    [item],
  );
  const responsible = useSelector(getResponsibleFromState);

  const isSelected = useSelectedDialogsListItem(itemId);

  const formattedLastMessageDate = formatDateForDisplay(lastMessageDate);
  /**
   * @type {DialogsListItemLastMessage}
   */
  const lastMessage = useMemo(
    () => ({
      date: formattedLastMessageDate,
      text: lastMessagePreview,
      type: lastMessageType,
    }),
    [formattedLastMessageDate, lastMessagePreview, lastMessageType],
  );

  return {
    itemId,
    isSelected,
    showResponsible,
    responsible,
    itemStatus,
    origin,
    avatar,
    lastMessage,
    title,
    labels,
    waitingTill,
    isDialogAnswered,
  };
};

export default useDialogsListItemData;
