import React from 'react';
import DialogFilter from './DialogFilter';

const DialogsListHeader = () => (
  <div className="dialogs-list-header">
    <DialogFilter />
  </div>
);

export default DialogsListHeader;
