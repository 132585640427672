import * as R from 'ramda';
import { createSelector } from 'reselect';

export const RECIEVE_CUSTOM_ACCOUNTS = 'RECIEVE_CUSTOM_ACCOUNTS';
export const RECIEVE_CUSTOM_ACCOUNT = 'RECIEVE_CUSTOM_ACCOUNT';
export const UPDATE_CUSTOM_ACCOUNT = 'UPDATE_CUSTOM_ACCOUNT';
export const SET_CUSTOM_ACC_NAME = 'SET_CUSTOM_ACC_NAME';
export const SET_CUSTOM_ACC_PAGES = 'SET_CUSTOM_ACC_PAGES';
export const DELETE_CUSTOM_ACCOUNT = 'DELETE_CUSTOM_ACCOUNT';
export const DELETE_CUSTOM_ACCOUNT_DRAFT = 'DELETE_CUSTOM_ACCOUNT_DRAFT';
export const ENABLE_CUSTOM_ACCOUNT = 'ENABLE_CUSTOM_ACCOUNT';
export const DISABLE_CUSTOM_ACCOUNT = 'DISABLE_CUSTOM_ACCOUNT';

function customAccountsFactory(account) {
  return { [account._id]: account };
}

function customAccountsReducer(state = {}, action = { type: '' }) {
  switch (action.type) {
    case RECIEVE_CUSTOM_ACCOUNTS: {
      return R.mergeAll(
        R.map(customAccountsFactory, action.payload.customAccounts),
      );
    }
    case RECIEVE_CUSTOM_ACCOUNT: {
      return { ...state, ...action.payload.customAccount };
    }
    case ENABLE_CUSTOM_ACCOUNT: {
      return { ...state, isEnabled: true };
    }
    case DISABLE_CUSTOM_ACCOUNT: {
      return { ...state, isEnabled: false };
    }
    case DELETE_CUSTOM_ACCOUNT: {
      return { ...state, isDeleted: true };
    }
    case SET_CUSTOM_ACC_NAME: {
      return { ...state, name: action.payload.name };
    }
    case UPDATE_CUSTOM_ACCOUNT: {
      return {
        ...state,
        ...(action.payload.name ? { name: action.payload.name } : {}),
      };
    }
    default: {
      return state;
    }
  }
}

export const recieveCustomAccounts = (customAccounts) => ({
  type: RECIEVE_CUSTOM_ACCOUNTS,
  payload: { customAccounts },
});

export const recieveCustomAccount = (customAccount) => ({
  type: RECIEVE_CUSTOM_ACCOUNT,
  payload: { customAccount },
});

export const setCustomAccName = (_id, name) => ({
  type: SET_CUSTOM_ACC_NAME,
  payload: {
    _id,
    name,
  },
});

export const setCustomAccPages = (_id, pages) => ({
  type: SET_CUSTOM_ACC_PAGES,
  payload: {
    _id,
    pages,
  },
});

export const renewCustomAccount = ({ customAccountId, pages, name }) => ({
  type: UPDATE_CUSTOM_ACCOUNT,
  payload: {
    customAccountId,
    pages,
    name,
  },
});

export const removeCustomAccount = (_id) => ({
  type: DELETE_CUSTOM_ACCOUNT,
  payload: {
    _id,
  },
});

export const deleteCustomAccountDraft = () => ({
  type: DELETE_CUSTOM_ACCOUNT_DRAFT,
});

export const enableCustomAccount = (_id) => ({
  type: ENABLE_CUSTOM_ACCOUNT,
  payload: { _id },
});

export const disableCustomAccount = (_id) => ({
  type: DISABLE_CUSTOM_ACCOUNT,
  payload: { _id },
});

export default function reducer(state = {}, action = { type: '' }) {
  switch (action.type) {
    case RECIEVE_CUSTOM_ACCOUNTS: {
      return R.mergeRight(state, customAccountsReducer(state, action));
    }
    case RECIEVE_CUSTOM_ACCOUNT: {
      return R.assoc(
        action.payload.customAccount._id,
        customAccountsReducer(state[action.payload.customAccount._id], action),
      )(state);
    }
    case ENABLE_CUSTOM_ACCOUNT:
    case DISABLE_CUSTOM_ACCOUNT:
    case DELETE_CUSTOM_ACCOUNT:
    case SET_CUSTOM_ACC_NAME: {
      return R.assoc(
        action.payload._id,
        customAccountsReducer(state[action.payload._id], action),
      )(state);
    }
    case UPDATE_CUSTOM_ACCOUNT: {
      return R.assoc(
        action.payload.customAccountId,
        customAccountsReducer(state[action.payload.customAccountId], action),
      )(state);
    }
    case DELETE_CUSTOM_ACCOUNT_DRAFT: {
      return R.dissoc('new')(state);
    }
    default: {
      return state;
    }
  }
}

export const customAccountsSelector = (state) => state.customAccounts;

export const customAccountsArraySelector = createSelector(
  customAccountsSelector,
  (customAccounts) =>
    R.filter(
      (_) => !_.isDeleted && _._id && _._id !== 'new',
      R.values(customAccounts),
    ),
);

export const deletedCustomAccountsArraySelector = createSelector(
  customAccountsSelector,
  (customAccounts) => R.filter((_) => _.isDeleted, R.values(customAccounts)),
);

export const customAccountIdSelector = (state, ownProps) => {
  const { params: { channelId } = {} } = ownProps.match;
  return channelId || ownProps.id || ownProps._id;
};

export const customAccountSelector = createSelector(
  customAccountsSelector,
  customAccountIdSelector,
  (accounts, id) => R.propOr({}, id, accounts),
);
