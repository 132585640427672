import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from 'antd-mobile';
import useDialogMessagesListData from '../../../../hooks/dialogs/dialogMessagesList/useDialogMessagesListData';
import DialogMessagesListView from './DialogMessagesListView';
import EmptyDialogMessagesList from './EmptyDialogMessagesList';
import './index.css';

const DialogOpenedMessagesList = ({ dialog = {} }) => {
  const listRef = useRef();

  const {
    loadedItemIds,
    loadMore,
    hasMoreToLoad,
    hasContent,
    loading,
    error,
    canMessageBeRead,
    isMessageRead,
    dialogAuthor,
    dialogClient,
    userName,
    userEmail,
    userId,
  } = useDialogMessagesListData({ dialog });

  useEffect(() => {
    if (error) {
      /**
       * Throw error to nearest Error Boundary
       */
      throw error;
    }
  }, [error]);

  const itemData = {
    canMessageBeRead,
    isMessageRead,
    dialogAuthor,
    dialogClient,
    dialog,
    userName,
    userEmail,
    userId,
  };

  const [scrollToEndOptionActive, setScrollToEndOptionActive] = useState(false);
  const handleOnScrollStop = useCallback(
    ({
      scrollOffset: topScrollOffset,
      scrollHeight,
      viewportHeight,
      visibleListItemIds,
    }) => {
      const availableOffsetHeight = scrollHeight - viewportHeight;
      const bottomScrollOffset = availableOffsetHeight - topScrollOffset;
      const scrollToEndHighlightHeightThreshold = viewportHeight / 2;
      const needToHighlightScrollToEnd =
        bottomScrollOffset >= scrollToEndHighlightHeightThreshold;

      if (needToHighlightScrollToEnd !== scrollToEndOptionActive) {
        setScrollToEndOptionActive(needToHighlightScrollToEnd);
      }
    },
    [scrollToEndOptionActive],
  );

  return (
    <div className="dialog-opened-messages-list">
      {loading ? (
        <Skeleton.Paragraph lineCount={10} animated />
      ) : hasContent ? (
        <DialogMessagesListView
          ref={listRef}
          renderedItemIds={loadedItemIds}
          itemData={itemData}
          onScrollStop={handleOnScrollStop}
          loadMore={loadMore}
          hasMoreToLoad={hasMoreToLoad}
        />
      ) : (
        <EmptyDialogMessagesList />
      )}
    </div>
  );
};

DialogOpenedMessagesList.propTypes = {
  dialog: PropTypes.object.isRequired,
};

DialogOpenedMessagesList.displayName = 'DialogOpenedMessagesList';

export default memo(DialogOpenedMessagesList);
