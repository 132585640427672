import { getDialogItem } from '../../reducers/dialogs';
import { getContact, getContacts } from '../contacts';
import fetchJWT from '../../utils/fetchJWT';
import { logout } from '../../actions';
// import { getDialogParticipants } from '../messages';
import backend from '../../configs/backend';

const getDialog = (dialogId, cb) => async (dispatch, getState) => {
  const request = new Request(
    backend.applyBaseUrl(`/api/dialogs/read/${dialogId}`),
    {
      method: 'GET',
      cache: 'default',
      mode: 'cors',
    },
  );

  fetchJWT(request)
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
      if (res.status === 401) {
        dispatch(logout());
      }
      throw new Error(res.err || 'Ошибка сервера');
    })
    .then(async (res) => {
      if (!res) {
        throw new Error(res.err || 'Ошибка сервера');
      }
      const { clients } = await getState();

      if (res._id) {
        dispatch(getDialogItem(res));
        //   dispatch(getDialogParticipants({ dialogIdArray: [res._id] }));
      }

      if (res.authorId && !clients[res.authorId]) {
        await dispatch(getContact(res.authorId));
      }

      if (
        res.contactsId &&
        !Object.values(clients).find(
          (c) =>
            c.contacts &&
            c.contacts.find((contact) => contact.id === res.contactsId),
        )
      ) {
        await dispatch(getContacts('', [res.contactsId]));
      }
      if (cb) {
        cb(res);
      }
    })
    .catch((err) => {
      if (cb) {
        cb({ err });
      }
    });
};

export default getDialog;
