import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { readTask } from '../../../../api/tasks';
import ServiceEvent from './ServiceEvent';
import { withTranslation } from 'react-i18next';

const futureDate = moment(Date.now()).add('500', 'years').valueOf();

class Event extends Component {
  static propTypes = {
    dialog: PropTypes.object,
    item: PropTypes.object,
    name: PropTypes.string,
    email: PropTypes.string,
    eventAuthor: PropTypes.object,
    getTask: PropTypes.func.isRequired,
    task: PropTypes.object,
    pbxAccounts: PropTypes.object,
    users: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { item } = this.props;
    if (
      item.metadata &&
      item.metadata.eventType &&
      item.metadata.eventType.match(/task/i) &&
      !this.props.task
    ) {
      this.props.getTask(item.taskId);
    }
  }

  handleCalleeDisplay = () => {
    const {
      dialog: { direction, callee, channelId } = {},
      pbxAccounts = {},
      users = {},
    } = this.props;
    if (direction === 'inbound') {
      const connectedChannel = pbxAccounts[channelId];
      const { match = {} } = connectedChannel;
      const matchedUserId = Object.keys(match).find(
        (key) => match[key] === callee && users[key],
      );
      return matchedUserId
        ? users[matchedUserId].name || users[matchedUserId].email
        : `Добавочный ${callee}`;
    }
    return `номер ${callee}`;
  };

  // TODO -> refactor
  eventTextViewHandler = () => {
    const { item, dialog, eventAuthor, name, email } = this.props;

    let eventUserForDisplay = {
      name,
      email,
    };
    if (eventAuthor) {
      eventUserForDisplay = eventAuthor;
    }

    const eventType =
      item.version && item.version === '2'
        ? item.eventType
        : item.metadata.eventType;

    let value;
    let isDateFuture = false;
    if (
      item.eventProperties &&
      item.eventProperties.targetValue &&
      item.eventProperties.targetValue.value
    ) {
      // eslint-disable-next-line prefer-destructuring
      value = item.eventProperties.targetValue.value;
    }

    if (value && moment(value, moment.ISO_8601, true).isValid()) {
      isDateFuture = moment(value).valueOf() > futureDate;
    }

    const href =
      (item.metadata && item.metadata.href) ||
      (item.eventProperties &&
        item.eventProperties.targetValue &&
        item.eventProperties.targetValue.href);

    const eventTextByType = (type) => {
      return {
        'dialog creation': href ? (
          <a href={href} target="_blank" rel="noopener noreferrer">{`«${
            value || item.title || dialog.title
          }»`}</a>
        ) : (
          `«${value || item.title || dialog.title}»`
        ),
        'dialog reopening': href ? (
          <a href={href} target="_blank" rel="noopener noreferrer">{`«${
            value || dialog.title
          }»`}</a>
        ) : (
          `«${value || dialog.title}»`
        ),
        'status opened': this.props.t('Event.statusOpened'),
        'status closed': this.props.t('Event.statusClosed'),
        'status waiting': value
          ? isDateFuture
            ? this.props.t('Event.statusWaitingV1')
            : this.props.t('Event.statusWaitingV2', {
                date: moment(value).format('DD.MM.YYYY • HH:mm'),
              })
          : '',
        'responsible assignment': this.props.t('Event.responsibleAssignment', {
          name: value || item.responsibleUserName,
        }),
        'responsible reset': this.props.t('Event.responsibleReset'),
        'responsible change': this.props.t('Event.responsibleChange', {
          name: value || item.responsibleUserName,
        }),
        'title change': this.props.t('Event.titleChange', {
          title: value || dialog.title,
        }),
        'label added': this.props.t('Event.labelAdded', {
          addedLabel: value || item.addedLabel,
        }),
        'label removed': this.props.t('Event.labelRemoved', {
          removedLabel: value || item.removedLabel,
        }),
        'call answered': this.handleCalleeDisplay(),
        'sla violation': this.props.t('Event.slaViolation', { value }),
      }[type];
    };

    if (eventType === 'dialog creation' || eventType === 'dialog reopening') {
      return (
        <span className="event">
          {`${moment(item.createdAt).format('DD.MM.YYYY • HH:mm')} · ${
            item.metadata.userId === 'bot' || !item.metadata.userId
              ? this.props.t('Event.robot')
              : eventUserForDisplay.name || eventUserForDisplay.email
          }: ${
            eventType === 'dialog creation'
              ? this.props.t('Event.dialogCreation')
              : this.props.t('Event.dialogReopening')
          } `}
          {eventTextByType(eventType)}
        </span>
      );
    }

    return (
      <span className="message-event">
        {`${moment(item.createdAt).format('DD.MM.YYYY • HH:mm')} · ${
          item.metadata.userId === 'bot' || !item.metadata.userId
            ? this.props.t('Event.robot')
            : eventUserForDisplay.name || eventUserForDisplay.email
        }: `}
        {eventTextByType(eventType)}
      </span>
    );
  };

  render() {
    const { item, dialog, task } = this.props;

    const eventType =
      item.version && item.version === '2'
        ? item.eventType
        : item.metadata.eventType;
    if (item) {
      if (
        (item.metadata &&
          item.metadata.eventType &&
          item.metadata.eventType.match(/transcript|task/i)) ||
        eventType.match(/transcript|task/i)
      ) {
        return (
          <ServiceEvent
            item={item}
            moment={moment}
            dialog={dialog}
            task={task}
          />
        );
      }
      return (
        <div
          style={{
            padding: '6px 5px 6px 0',
            marginLeft: '47px',
            color: '#7d8d9a',
          }}
          id={item._id}
        >
          {this.eventTextViewHandler()}
        </div>
      );
    }
    return null;
  }
}

const mapStateToProps = (state, ownProps) => ({
  eventAuthor:
    state.users[ownProps.item.metadata.userId] ||
    state.chatBots[ownProps.item.metadata.userId],
  task:
    ownProps.item.metadata && ownProps.item.taskId
      ? state.tasks[ownProps.item.taskId]
      : null,
  pbxAccounts: state.pbxAccounts,
  users: state.users,
});

const mapDispatchToProps = (dispatch) => ({
  getTask: (id) => dispatch(readTask({ id })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Event));
