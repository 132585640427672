import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Radio, List, Button, NavBar } from 'antd-mobile';
import * as ac from '../../actions'; // ac - stands for 'Action Creator'

const Profile = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const changeLanguage = async (lang) => {
    await i18n.changeLanguage(lang);
  };

  const handleLogout = useCallback(() => {
    dispatch(ac.logout());
  }, [dispatch]);

  return (
    <>
      <NavBar back={null}>{t('Layout.profile')}</NavBar>
      <Radio.Group value={i18n.language}>
        <List>
          <List.Item
            prefix={<Radio value="en-US" />}
            onClick={() => changeLanguage('en-US')}
            arrowIcon={false}
          >
            en
          </List.Item>
          <List.Item
            prefix={<Radio value="ru-RU" />}
            onClick={() => changeLanguage('ru-RU')}
            arrowIcon={false}
          >
            ru
          </List.Item>
        </List>
      </Radio.Group>
      <div style={{ padding: '12px' }}>
        <Button block color="primary" size="large" onClick={handleLogout}>
          {t('logout')}
        </Button>
      </div>
    </>
  );
};

export default Profile;
