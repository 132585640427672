import { createSelector } from 'reselect';

const SET_DIALOGS_LIST_MODE = 'SET_DIALOGS_LIST_MODE';

const defaultState = { currentMode: 'dialogs' };

export default function reducer(state = defaultState, action = { type: '' }) {
  switch (action.type) {
    case SET_DIALOGS_LIST_MODE: {
      return { ...state, currentMode: action.payload.mode };
    }
    default: {
      return state;
    }
  }
}

export const setDialogsListMode = (mode) => ({
  type: SET_DIALOGS_LIST_MODE,
  payload: {
    mode,
  },
});

const getDialogsListMode = (state) => state.dialogsListMode;

export const dialogsListModeSelector = createSelector(
  getDialogsListMode,
  (dialogsListMode) => dialogsListMode.currentMode,
);
