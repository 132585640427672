import React from 'react';
import { NavBar, Space } from 'antd-mobile';
import { SearchOutline } from 'antd-mobile-icons';
import { useTranslation } from 'react-i18next';
import DialogsList from './DialogsList';
import './Dialogs.css';

const Dialogs = () => {
  const { t } = useTranslation();

  const right = (
    <div style={{ fontSize: 24 }}>
      <Space style={{ '--gap': '16px' }}>
        <SearchOutline />
      </Space>
    </div>
  );

  return (
    <div className="dialogs">
      <NavBar right={right} back={null}>
        <span className="dialogs-title">{t('Layout.conversations')}</span>
      </NavBar>
      <DialogsList />
    </div>
  );
};

export default Dialogs;
