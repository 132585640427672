import React, { useState } from 'react';
import { Modal, TextArea } from 'antd-mobile';

const TextAreaModal = ({
  title = '',
  value = '',
  placeholder = '',
  save = () => {},
  isOpen = false,
  onClose = () => {},
}) => {
  const [text, setText] = useState(value);

  return (
    <Modal
      visible={isOpen}
      onClose={onClose}
      title={title}
      destroyOnClose
      closeOnAction
      content={
        <TextArea
          value={text}
          onChange={(val) => setText(val)}
          placeholder={placeholder}
          autoSize={{ minRows: 4, maxRows: 10 }}
        />
      }
      actions={[
        {
          key: 'cancel',
          text: 'Cancel',
          onClick: () => setText(value),
        },
        {
          key: 'submit',
          text: 'Save',
          disabled: !text.length,
          onClick: () => {
            save(text);
            setText(value);
          },
        },
      ]}
    />
  );
};

export default TextAreaModal;
