import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { dialogsListModeSelector } from '../../reducers/dialogsListMode';
import usePrevious from '../usePrevious';

const useDialogsListMode = ({ onModeChange } = {}) => {
  const dialogsListMode = useSelector(dialogsListModeSelector);
  const prevDialogsListMode = usePrevious(dialogsListMode);

  useEffect(() => {
    if (
      onModeChange &&
      prevDialogsListMode &&
      prevDialogsListMode !== dialogsListMode
    ) {
      onModeChange(dialogsListMode);
    }
  }, [prevDialogsListMode, dialogsListMode, onModeChange]);

  return { dialogsListMode };
};

export default useDialogsListMode;
