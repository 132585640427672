import fetchJWT from '../../../../utils/fetchJWT';
import { logout } from '../../../../actions';
import { recieveWhatsAppAccounts } from '../../../../reducers/whatsappAccounts';
import backend from '../../../../configs/backend';

const getWabaAccounts = () => (dispatch) => {
  const request = new Request(
    backend.applyBaseUrl(`/api/whatsapp-channel/i2crm/waba/v1/read/`),
    {
      method: 'GET',
      mode: 'cors',
    },
  );

  fetchJWT(request)
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
      if (res.status === 401) {
        dispatch(logout());
      }
      return [];
    })
    .then((res) => dispatch(recieveWhatsAppAccounts(res)))
    .catch((err) => console.error(err));
};

export default getWabaAccounts;
