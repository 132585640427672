import * as ac from '../../actions';
import fetchJWT from '../../utils/fetchJWT';
import backend from '../../configs/backend';

const getUserData = () => async (dispatch) => {
  fetchJWT(backend.applyBaseUrl('/api/users/get_user_data'))
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      if (response.status === 401) {
        dispatch(ac.logout());
      }
      return {};
    })
    .then((response) => {
      if (response.user_data) {
        dispatch(ac.loggedIn(response.user_data));
      } else {
        dispatch(ac.notLogged());
      }
    })
    .catch((err) => {
      dispatch(ac.networkError(err));
    });
};

export default getUserData;
