import { useCallback, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * @description Hook that provides dialogs list item selected state.
 * @param {string|number} itemId
 * @returns {boolean} If provided itemId is selected, returns true.
 * Else returns false.
 */
const useSelectedDialogsListItem = (itemId) => {
  const [isSelected, setIsSelected] = useState(false);
  const isLocation = useLocation();

  const determineSelectedItemId = useCallback((location = window.location) => {
    const dialogItemMatch = location.pathname.match(/^\/conversations\/(.*)/);

    if (dialogItemMatch) {
      const selectedItemId = dialogItemMatch[1];

      return selectedItemId;
    }

    return undefined;
  }, []);

  const refreshIsSelectedIfNeeded = useCallback(
    (location) => {
      const selectedItemId = determineSelectedItemId(location);

      if (selectedItemId && selectedItemId === itemId) {
        if (!isSelected) {
          setIsSelected(true);
        }

        return;
      }

      if (isSelected) {
        setIsSelected(false);
      }
    },
    [determineSelectedItemId, isSelected, itemId],
  );

  useEffect(() => {
    if (itemId) {
      refreshIsSelectedIfNeeded(window.location);
    }
  }, [itemId, refreshIsSelectedIfNeeded]);

  const listenForSelectedItemChange = useCallback(
    (location) => {
      const selectedItemId = determineSelectedItemId(location);

      if (selectedItemId && selectedItemId === itemId) {
        if (!isSelected) {
          setIsSelected(true);
        }

        return;
      }

      if (isSelected) {
        setIsSelected(false);
      }
    },
    [itemId, isSelected, determineSelectedItemId],
  );

  useEffect(() => {
    listenForSelectedItemChange(isLocation);
  }, [isLocation, listenForSelectedItemChange]);

  return isSelected;
};

export default useSelectedDialogsListItem;
