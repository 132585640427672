import { useRef, useCallback } from 'react';

/**
 * @description Hook to handle cached infinite loading. It caches requested item ids so they will not be requested again.
 * @param {function(string[]) => void} loadMoreFn Function that is called with not cached item ids string array as an argument.
 * Should load more content based on provided argument.
 */
const useCachedInfiniteLoader = (loadMoreFn) => {
  const initialLoadedItemsStore = [];
  const loadedItemsStore = useRef(initialLoadedItemsStore);

  const loadMore = useCallback(
    /**
     * @param {string[]} itemIds
     */
    (itemIds) => {
      const notLoadedItemIds = itemIds.filter(
        (itemId) => !loadedItemsStore.current.includes(itemId),
      );

      if (notLoadedItemIds.length) {
        loadedItemsStore.current.push(...notLoadedItemIds);

        loadMoreFn(notLoadedItemIds);
      }
    },
    [loadMoreFn],
  );

  return { loadMore };
};

export default useCachedInfiniteLoader;
