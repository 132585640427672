/* eslint-disable no-console */
import fetchJWT from '../../utils/fetchJWT';
import { logout } from '../../actions';
import {
  recieveAmoCrmIntegration,
  enableAmoCrmIntegration,
} from '../../reducers/amocrm-integrations';
import backend from '../../configs/backend';

const getAmoCrmIntegration = (id) => async (dispatch) => {
  const request = new Request(backend.applyBaseUrl(`/api/amocrm/read/${id}`), {
    method: 'GET',
    cache: 'default',
    mode: 'cors',
  });
  fetchJWT(request)
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
      if (res.status === 401) {
        dispatch(logout());
      }
      return [];
    })
    .then((res) => {
      if (res[0]) {
        dispatch(recieveAmoCrmIntegration(res[0]));
        dispatch(enableAmoCrmIntegration(res[0]._id));
      }
    })
    .catch((e) => console.error(e));
};

export default getAmoCrmIntegration;
