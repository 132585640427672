import { recieveMailAccount, setIsEnabled } from '../../reducers/mail-accounts';
import fetchJWT from '../../utils/fetchJWT';
import { logout } from '../../actions';
import backend from '../../configs/backend';

const readMailAccount =
  ({ _id }) =>
  async (dispatch) => {
    if (_id) {
      const request = new Request(
        backend.applyBaseUrl(`/api/mail/accounts/read/${_id}`),
        {
          method: 'GET',
          cache: 'default',
          mode: 'cors',
        },
      );

      fetchJWT(request)
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          if (res.status === 401) {
            dispatch(logout());
          }
          return {};
        })
        .then((res) => {
          dispatch(recieveMailAccount(res));

          dispatch(setIsEnabled(res._id, res.isEnabled));
        })
        .catch((err) => console.error(err));
    }
  };

export default readMailAccount;
