import * as R from 'ramda';

export const SET_OUTCOMING_DIALOG_ORIGIN = 'SET_OUTCOMING_DIALOG_ORIGIN';
export const SET_OUTCOMING_DIALOG_TITLE = 'SET_OUTCOMING_DIALOG_TITLE';
export const SET_OUTCOMING_DIALOG_MAIL_TO = 'SET_OUTCOMING_DIALOG_MAIL_TO';
export const SET_OUTCOMING_DIALOG_CC_DETAILS =
  'SET_OUTCOMING_DIALOG_CC_DETAILS';
export const SET_OUTCOMING_DIALOG_CHANNEL_ID =
  'SET_OUTCOMING_DIALOG_CHANNEL_ID';
export const SET_OUTCOMING_DIALOG_TEXT = 'SET_OUTCOMING_DIALOG_TEXT';
export const SET_OUTCOMING_DIALOG_HTML = 'SET_OUTCOMING_DIALOG_HTML';
export const SET_OUTCOMING_DIALOG_DRAWER_VISIBILITY =
  'SET_OUTCOMING_DIALOG_DRAWER_VISIBILITY';
export const REMOVE_OUTCOMING_DIALOG_DETAILS =
  'REMOVE_OUTCOMING_DIALOG_DETAILS';
export const REMOVE_OUTCOMING_DIALOG_DRAFT = 'REMOVE_OUTCOMING_DIALOG_DRAFT';

function dialogOutReducer(state = {}, action = { type: '' }) {
  switch (action.type) {
    case SET_OUTCOMING_DIALOG_CHANNEL_ID: {
      return { ...state, channelId: action.payload.channelId };
    }
    case SET_OUTCOMING_DIALOG_MAIL_TO: {
      return { ...state, mailTo: action.payload.mailTo };
    }
    case SET_OUTCOMING_DIALOG_CC_DETAILS: {
      return { ...state, ccDetails: action.payload.ccDetails };
    }
    case SET_OUTCOMING_DIALOG_TITLE: {
      return { ...state, title: action.payload.title };
    }
    case SET_OUTCOMING_DIALOG_TEXT: {
      return { ...state, text: action.payload.text };
    }
    case SET_OUTCOMING_DIALOG_HTML: {
      return { ...state, html: action.payload.html };
    }
    default: {
      return state;
    }
  }
}

export const setOutcomingDialogChannelId = (channelId) => ({
  type: SET_OUTCOMING_DIALOG_CHANNEL_ID,
  payload: { channelId },
});

export const setOutcomingDialogMailTo = (mailTo) => ({
  type: SET_OUTCOMING_DIALOG_MAIL_TO,
  payload: { mailTo },
});

export const setOutcomingDialogCcDetails = (ccDetails) => ({
  type: SET_OUTCOMING_DIALOG_CC_DETAILS,
  payload: { ccDetails },
});

export const setOutcomingDialogTitle = (title) => ({
  type: SET_OUTCOMING_DIALOG_TITLE,
  payload: { title },
});

export const setOutcomingDialogOrigin = (origin) => ({
  type: SET_OUTCOMING_DIALOG_ORIGIN,
  payload: { origin },
});

export const setOutcomingDialogHtml = (html) => ({
  type: SET_OUTCOMING_DIALOG_HTML,
  payload: { html },
});

export const setOutcomingDialogText = (text) => ({
  type: SET_OUTCOMING_DIALOG_TEXT,
  payload: { text },
});

/**
 * @param {boolean} visible
 */
export const setOutcomingDialogDrawerVisibility = (visible) => ({
  type: SET_OUTCOMING_DIALOG_DRAWER_VISIBILITY,
  payload: { visible },
});

export const removeOutcomingDialogDraft = () => ({
  type: REMOVE_OUTCOMING_DIALOG_DRAFT,
});

export const removeOutcomingDialogDetails = () => ({
  type: REMOVE_OUTCOMING_DIALOG_DETAILS,
});

const initialState = Object.freeze({
  origin: 'mail',
  drawerVisible: false,
});

export const outcomingDialogDataInitialState = initialState;

export default function reducer(state = initialState, action = { type: '' }) {
  const { origin } = state;

  switch (action.type) {
    case SET_OUTCOMING_DIALOG_ORIGIN: {
      return { ...state, origin: action.payload.origin };
    }
    case SET_OUTCOMING_DIALOG_CHANNEL_ID:
    case SET_OUTCOMING_DIALOG_MAIL_TO:
    case SET_OUTCOMING_DIALOG_CC_DETAILS:
    case SET_OUTCOMING_DIALOG_TITLE:
    case SET_OUTCOMING_DIALOG_HTML:
    case SET_OUTCOMING_DIALOG_TEXT: {
      return R.assoc(origin, dialogOutReducer(state[origin], action))(state);
    }
    case SET_OUTCOMING_DIALOG_DRAWER_VISIBILITY: {
      return { ...state, drawerVisible: action.payload.visible };
    }
    case REMOVE_OUTCOMING_DIALOG_DRAFT: {
      // clear current html and text details
      if (state[origin]) {
        const originObj = { ...state[origin] };
        const cleanedFromHtml = R.dissoc('html')(originObj);
        const cleanedFromTextAndHtml = R.dissoc('text')(cleanedFromHtml);

        return R.assoc(origin, cleanedFromTextAndHtml)(state);
      }

      return state;
    }
    case REMOVE_OUTCOMING_DIALOG_DETAILS: {
      // clear all details
      return R.dissoc(origin)(state);
    }
    default: {
      return state;
    }
  }
}

export const getOutcomingDialogOrigin = (state) =>
  state.outcomingDialogData.origin || initialState.origin;

export const getOutcomingDialogDrawerVisible = (state) =>
  state.outcomingDialogData.drawerVisible || initialState.drawerVisible;

export const getDialogOutSelector = (state) =>
  state.outcomingDialogData[
    state.outcomingDialogData.origin || initialState.origin
  ];
