import React, { useMemo } from 'react';
import { List } from 'antd-mobile';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { StopOutline, ExclamationCircleOutline } from 'antd-mobile-icons';
import contentChannelSelector from '../../../../selectors/contentChannelSelector';

import chatraIconColored from '../../../../assets/images/chatraIconColored.png';
import mailIconColored from '../../../../assets/images/mailIconColored.png';
import pbxIcon from '../../../../assets/images/pbxIcon.png';
import skypeIconColored from '../../../../assets/images/skypeIconColored.png';
import tgIconColored from '../../../../assets/images/tgIconColored.png';
import waIconColored from '../../../../assets/images/waIconColored.png';
import vkIconColored from '../../../../assets/images/vkIconColored.png';
import fbMessengerIcon from '../../../../assets/images/fbMessengerIcon.png';
import igIcon from '../../../../assets/images/igIcon.png';
import customIcon from '../../../../assets/images/customIcon.png';

const styles = {
  '--adm-color-background': '#f2f5f7',
};

const ContentChannel = ({ dialog }) => {
  const { t } = useTranslation();
  const { origin = 'mail', gateway = t('ContentChannel.gateway') } = dialog;

  const channel = useSelector((state) =>
    contentChannelSelector(state, { dialog }),
  );

  const renderChannelIcon = useMemo(() => {
    if (channel.isDeleted) {
      return <StopOutline color="var(--adm-color-danger)" />;
    }
    if (!channel.isEnabled) {
      return <ExclamationCircleOutline color="var(--adm-color-yellow)" />;
    }
    const iconSize = '25px';
    const channelIconByOrigin = {
      chatra: <img src={chatraIconColored} alt="chatra" width={iconSize} />,
      mail: <img src={mailIconColored} alt="mail" width={iconSize} />,
      pbx: <img src={pbxIcon} alt="pbx" width={iconSize} />,
      phone: <img src={pbxIcon} alt="phone" width={iconSize} />,
      skype: <img src={skypeIconColored} alt="skype" width={iconSize} />,
      demo: <img src={mailIconColored} alt="mail" width={iconSize} />,
      telegram: <img src={tgIconColored} alt="telegram" width={iconSize} />,
      whatsapp: <img src={waIconColored} alt="whatsapp" width={iconSize} />,
      vk: <img src={vkIconColored} alt="vk" width={iconSize} />,
      fb: <img src={fbMessengerIcon} alt="fb" width={iconSize} />,
      ig: <img src={igIcon} alt="ig" width={iconSize} />,
      ig2: <img src={igIcon} alt="ig" width={iconSize} />,
      custom: <img src={customIcon} alt="custom" width={iconSize} />,
    };
    return channelIconByOrigin[origin];
  }, [channel.isDeleted, channel.isEnabled, origin]);

  const channelLabelByOrigin = useMemo(
    () => (origin === 'pbx' ? gateway : channel.name || channel.email),
    [channel.email, channel.name, gateway, origin],
  );

  return (
    <List.Item
      arrowIcon={renderChannelIcon}
      description={t('ContentChannel.channel')}
      style={styles}
      clickable
    >
      <b style={{ fontSize: '13px' }}>
        {channel ? channelLabelByOrigin : 'support'}
      </b>
    </List.Item>
  );
};

export default ContentChannel;
