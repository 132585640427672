import * as R from 'ramda';
import { createSelector } from 'reselect';

export const GET_CONTACTS_DATA = 'GET_CONTACTS_DATA';
export const GET_CONTACT_DATA = 'GET_CONTACT_DATA';
export const DELETE_CONTACT_DATA = 'DELETE_CONTACT_DATA';
export const DELETE_CONTACT_DATA_DRAFT = 'DELETE_CONTACT_DATA_DRAFT';
export const SET_CONTACT_DATA_VALUE = 'SET_CONTACT_DATA_VALUE';
export const SET_CONTACT_DATA_TYPE = 'SET_CONTACT_DATA_TYPE';
export const SET_CONTACT_DATA_SERVICE = 'SET_CONTACT_DATA_SERVICE';

function contactDataFactory(contact) {
  return { [contact._id]: contact };
}

function contactsDataReducer(state = {}, action = { type: '' }) {
  switch (action.type) {
    case GET_CONTACTS_DATA: {
      return R.mergeAll(R.map(contactDataFactory, action.payload.contacts));
    }
    case GET_CONTACT_DATA: {
      return { ...state, ...action.payload.contact };
    }
    case SET_CONTACT_DATA_SERVICE: {
      return { ...state, service: action.payload.service };
    }
    case SET_CONTACT_DATA_TYPE: {
      return { ...state, type: action.payload.type };
    }
    case SET_CONTACT_DATA_VALUE: {
      return { ...state, value: action.payload.value };
    }
    default: {
      return state;
    }
  }
}

export const getContactItem = (contact) => ({
  type: GET_CONTACT_DATA,
  payload: { contact },
});

export const getContactsItems = (contacts) => ({
  type: GET_CONTACTS_DATA,
  payload: { contacts },
});

export const removeContactItem = (contactId) => ({
  type: DELETE_CONTACT_DATA,
  payload: { contactId },
});

export const removeContactDraft = () => ({
  type: DELETE_CONTACT_DATA_DRAFT,
  payload: {},
});

export const setContactValue = (_id, value) => ({
  type: SET_CONTACT_DATA_VALUE,
  payload: { _id, value },
});

export const setContactType = (_id, type) => ({
  type: SET_CONTACT_DATA_TYPE,
  payload: { _id, type },
});

export const setContactService = (_id, service) => ({
  type: SET_CONTACT_DATA_SERVICE,
  payload: { _id, service },
});

export default function reducer(state = {}, action = { type: '' }) {
  switch (action.type) {
    case GET_CONTACTS_DATA: {
      return R.mergeRight(state, contactsDataReducer(state, action));
    }
    case GET_CONTACT_DATA: {
      return R.assoc(
        action.payload.contact.id,
        contactsDataReducer(state[action.payload.contact.id], action),
      )(state);
    }
    case DELETE_CONTACT_DATA: {
      return R.dissoc(action.payload.contactId, state);
    }
    case DELETE_CONTACT_DATA_DRAFT: {
      return R.dissoc('new', state);
    }
    case SET_CONTACT_DATA_VALUE:
    case SET_CONTACT_DATA_SERVICE:
    case SET_CONTACT_DATA_TYPE: {
      return R.assoc(
        action.payload._id,
        contactsDataReducer(state[action.payload._id], action),
      )(state);
    }
    default: {
      return state;
    }
  }
}

export const contactsDataSelector = (state) =>
  R.propOr({}, 'contactsData', state);

export const contactDataIdSelector = (state, ownProps) =>
  R.pathOr(null, ['match', 'params', 'contactId'], ownProps) ||
  R.propOr(null, 'contactId', ownProps);

export const contactsDataArraySelector = createSelector(
  contactsDataSelector,
  (contacts) => R.values(contacts),
);

export const contactDataSelector = createSelector(
  contactDataIdSelector,
  contactsDataSelector,
  (contactId, contacts) => R.propOr({}, contactId, contacts),
);
