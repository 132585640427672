import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import messageAuthorSelector from '../../../selectors/messageAuthorSelector';

const useDialogMessagesListItemData = ({ itemId, dialog }) => {
  const { origin: dialogOrigin, contactsId: dialogContactsId } = dialog;

  const itemSelector = useCallback(
    (state) => (itemId && state.messages[itemId] ? state.messages[itemId] : {}),
    [itemId],
  );
  const item = useSelector(itemSelector);

  const messageAuthorSelectorWithProps = useCallback(
    (state) => messageAuthorSelector(state, { item }),
    [item],
  );
  const messageAuthor = useSelector(messageAuthorSelectorWithProps);

  const messageAuthorDisplay = useMemo(() => {
    if (!messageAuthor) {
      return '';
    }
    // common client and user field
    if (messageAuthor.name) {
      return messageAuthor.name;
    }
    // user-specific field
    if (messageAuthor.email) {
      return messageAuthor.email;
    }
    // client-specific field
    const relevantContacts = messageAuthor.contacts.filter(
      (c) => c.service === dialogOrigin,
    );

    if (relevantContacts.length) {
      if (
        dialogContactsId &&
        relevantContacts.find((c) => c.id === dialogContactsId)
      ) {
        return relevantContacts.find((c) => c.id === dialogContactsId).value;
      }
      // return first relevant contact
      return relevantContacts[0].value;
    }
    return '';
  }, [dialogContactsId, dialogOrigin, messageAuthor]);

  return { item, messageAuthor, messageAuthorDisplay };
};

export default useDialogMessagesListItemData;
