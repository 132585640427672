import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createLogger } from 'redux-logger';
import {
  applyMiddleware,
  compose,
  legacy_createStore as createStore,
} from 'redux';
import { thunk } from 'redux-thunk';
import io from 'socket.io-client';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { PersistGate } from 'redux-persist/integration/react';

import './index.css';
import App from './App';
import rootReducer from './reducers';
import { getMessage, onReceiveMessage } from './api/messages';
import { getDialog, onReceiveDialog } from './api/dialogs';
import { readMailAccount } from './api/mail-accounts';
import { deleteMailAccount, setIsEnabled } from './reducers/mail-accounts';
import { getTemplate } from './api/templates';
import { removeTemplate } from './reducers/templates';
import { getUser, getUserData } from './api/users';
import { getContact } from './api/contacts';
import { getChatraAccount } from './api/chatra-accounts';
import {
  enableChatraAccount,
  disableChatraAccount,
} from './reducers/chatra-accounts';
import { getWhatsAppAccount } from './api/whatsapp-accounts';
import {
  enableWhatsAppAccount,
  disableWhatsAppAccount,
} from './reducers/whatsappAccounts';
import { getTelegramAccount } from './api/telegram-accounts';
import {
  enableTelegramAccount,
  disableTelegramAccount,
} from './reducers/telegram-accounts';
import { getVKAccount } from './api/vk-accounts';
import { enableVKAccount, disableVKAccount } from './reducers/vk-accounts';
import { getDemoAccount } from './api/demo-accounts';
import { getPlanRequest } from './api/billing';
import { enableDemoAccount } from './reducers/demo-accounts';
import {
  setFilterChannels,
  getDialogsChannelFilters,
} from './reducers/dialogsFilters';
import {
  proceedDialogActionsTyping,
  proceedDialogActionsReading,
} from './reducers/dialogActions';
import getInvite from './api/users/getInvite';
import { recieveDialogsStatusCounters } from './reducers/statusCounters';
import {
  enableSkypeAccount,
  disableSkypeAccount,
} from './reducers/skype-accounts';
import { getSkypeAccount } from './api/skype-accounts';
import { enablePbxAccount, disablePbxAccount } from './reducers/pbx-accounts';
import { getPbxAccount } from './api/pbx-accounts';
import {
  getCallsChannelFilters,
  setCallsFilterChannels,
} from './reducers/callsFilters';
import { getFbAccount } from './api/facebook-accounts';
import { enableFbAccount, disableFbAccount } from './reducers/fb-accounts';
import {
  disableAmoCrmIntegration,
  enableAmoCrmIntegration,
} from './reducers/amocrm-integrations';
import { getAmoCrmIntegration } from './api/amocrm';
import { getAmocrmIntegration as getAmocrmIntegrationV2 } from './api/amocrm/v2';
import { getIgAccount } from './api/instagram-accounts';
import { disableIgAccount, enableIgAccount } from './reducers/ig-accounts';
import { getAutomationAcc } from './api/automation';
import {
  disableAutomationAcc,
  enableAutomationAcc,
  recieveAutomationAcc,
} from './reducers/automation';
import {
  disableCustomAccount,
  enableCustomAccount,
} from './reducers/custom-accounts';
import { getCustomAccount } from './api/custom-accounts';
import { getWebhook } from './api/webhooks';
import { getApiToken } from './api/api-tokens';
import { readTask } from './api/tasks';
import { getChatBots } from './api/chat-bots';
import {
  recieveClientsStat,
  recieveCallsStat,
  recieveChannelsStat,
  recieveDialogsStat,
  recieveMessagesStat,
  recieveRatingStat,
  recieveTimingStat,
} from './reducers/stat';
import { updateUser as updateAuthUserState } from './actions';
import checkDialogNotificationCondition from './utils/checkDialogNotificationCondition';
import { enqueueNotification } from './reducers/notifications';
import {
  analytics as analyticsConf,
  channels as channelsConf,
} from './configs';
import setupAnalyticsSdks from './utils/setupAnalyticsSdks';
import backend from './configs/backend';
// import {
//   ErrorBoundary,
//   GenericErrorBoundaryFallback,
// } from './components/Common';
import { getWabaAccount } from './api/whatsapp-accounts/i2crm/waba';
import { setExportEvents } from './reducers/exportEvents';
import { getGreenApiAccount } from './api/whatsapp-accounts/greenApi';
import {
  recieveClientSegment,
  removeClientSegment,
} from './reducers/clientSegments';
import {
  getClientItem,
  removeClientSegmentFromClients,
} from './reducers/clients';

const needAnalyticsSetup = analyticsConf.allowedDomains.includes(
  window.location.hostname,
);

const loggerMiddleware = createLogger({
  predicate: () => window.location.hostname.search('app.yeahdesk.ru') === -1,
});

function configureStore() {
  const persistConfig = {
    key: 'root',
    storage,
    whitelist: [
      'dialogsFilters',
      'callsFilters',
      'configData',
      'templateFilter',
      'drafts',
      'cacheStatus',
      'messageActions',
      'stat',
      'dialogsListMode',
      'outcomingDialogData',
      'inReplyTo',
      'dialogInfo',
      'onboardingInfo',
    ],
  };
  const persistedReducer = persistReducer(persistConfig, rootReducer);
  const store = createStore(
    persistedReducer,
    compose(applyMiddleware(thunk, loggerMiddleware)),
  );
  const persistor = persistStore(store);
  return { store, persistor };
}

const { store, persistor } = configureStore();

const socket = io(backend.baseUrl);
window.webSocketsConn = socket;

function subscribe() {
  socket.emit({ type: 'Dialog.Counters.Init' });
  socket.on('actions', async (data) => {
    if (data.dialogActions && !Object.values(data.dialogActions).length) {
      // do nothing to avoid useless update
    } else {
      const { dialogActions, users } = await store.getState();
      let needTypingUpdate = 0;
      let needReadingUpdate = 0;

      const usersArePresent = Object.keys(data).filter(
        (userKey) => users[userKey],
      );
      if (usersArePresent.length) {
        usersArePresent.forEach((userData) => {
          const userSessions = Object.values(data[userData]);

          userSessions.forEach((session) => {
            if (session) {
              const fieldKeysForCheck = Object.keys(session);
              // user can have multiple sessions (different machines/browsers/etc.)
              if (fieldKeysForCheck.length) {
                const typingIsPresentInState = Object.values(
                  dialogActions.typing || {},
                ).filter((item) => item[userData]);

                const readingIsPresentInState = Object.values(
                  dialogActions.reading || {},
                ).filter((item) => item[userData]);
                // check for state and data difference
                if (
                  (!Object.keys(session.typing || {}).length &&
                    typingIsPresentInState.length &&
                    typingIsPresentInState.length > 0) ||
                  (Object.keys(session.typing || {}).length &&
                    !typingIsPresentInState.length)
                ) {
                  needTypingUpdate += 1;
                } else if (
                  (!Object.keys(session.reading || {}).length &&
                    readingIsPresentInState.length &&
                    readingIsPresentInState.length > 0) ||
                  (Object.keys(session.reading || {}).length &&
                    !readingIsPresentInState.length)
                ) {
                  needReadingUpdate += 1;
                }

                fieldKeysForCheck.forEach((fieldKey) => {
                  // field keys may be: "reading"/"typing"
                  const existingKeysForUser = Object.keys(session[fieldKey]);

                  if (existingKeysForUser.length) {
                    const existingKeysInState = Object.keys(
                      dialogActions[fieldKey] || {},
                    );

                    (existingKeysInState.length > existingKeysForUser.length
                      ? existingKeysInState
                      : existingKeysForUser
                    ).forEach((key) => {
                      if (
                        (!existingKeysInState.includes(key) &&
                          existingKeysForUser.includes(key)) ||
                        (existingKeysInState.includes(key) &&
                          !existingKeysForUser.includes(key))
                      ) {
                        // trigger update only if state is different!
                        if (fieldKey === 'reading') {
                          needReadingUpdate += 1;
                        } else if (fieldKey === 'typing') {
                          needTypingUpdate += 1;
                        }
                      }
                    });
                  }
                });
              }
            }
          });
        });
        // check if state is not empty while no actions were dispatched
      } else if (Object.values(dialogActions.typing || {}).length > 0) {
        needTypingUpdate += 1;
      } else if (Object.values(dialogActions.reading || {}).length > 0) {
        needReadingUpdate += 1;
      }
      // update if need to
      if (needReadingUpdate > 0) {
        await store.dispatch(proceedDialogActionsReading(data));
      } else if (needTypingUpdate > 0) {
        await store.dispatch(proceedDialogActionsTyping(data));
      }
    }
  });

  socket.on('disconnect', () => {
    const { auth: { user: { networkStatus = 'offline' } = {} } = {} } =
      store.getState();

    if (networkStatus !== 'offline') {
      store.dispatch(
        updateAuthUserState({ user: { networkStatus: 'offline' } }),
      );
    }
  });

  // Dialogs
  socket.on('Dialog.Created', async ({ data, record: dialog }) => {
    const callback = async (dialog = {}) => {
      const needNotification = await checkDialogNotificationCondition(
        dialog,
        store,
      );
      if (needNotification) {
        store.dispatch(
          enqueueNotification({
            type: dialog.origin === 'pbx' ? 'call' : 'dialog',
            data: dialog,
          }),
        );
      }
    };
    if (dialog) {
      store.dispatch(onReceiveDialog(dialog, callback));
    } else {
      store.dispatch(getDialog(data, callback));
    }
  });
  socket.on('Dialog.Updated', async ({ data, record: dialog }) => {
    if (dialog) {
      await store.dispatch(onReceiveDialog(dialog));
    } else {
      await store.dispatch(getDialog(data));
    }
  });

  // Messages
  socket.on('Message.Created', async ({ data, record: message }) => {
    if (message) {
      await store.dispatch(onReceiveMessage(message));
    } else {
      await store.dispatch(getMessage(data));
    }
  });
  socket.on('Message.Updated', async ({ data, record: message }) => {
    if (message) {
      await store.dispatch(onReceiveMessage(message));
    } else {
      await store.dispatch(getMessage(data));
    }
  });

  // Tasks
  socket.on('Task.Created', async ({ data }) => {
    await store.dispatch(readTask({ id: data }));
  });
  socket.on('Task.Updated', async ({ data }) => {
    await store.dispatch(readTask({ id: data }));
  });
  socket.on('Task.Deleted', async ({ data }) => {
    await store.dispatch(readTask({ id: data }));
  });

  // Mail Accounts
  socket.on('Mail.Account.Created', async ({ data }) => {
    // помещаем новый канал почты в фильтр
    const filterChannels = await getDialogsChannelFilters(store.getState());
    const {
      auth: { user },
    } = await store.getState();
    await store.dispatch(setFilterChannels(filterChannels.concat(data), user));
    await store.dispatch(readMailAccount({ _id: data }));
  });
  socket.on('Mail.Account.Updated', async ({ data }) => {
    await store.dispatch(readMailAccount({ _id: data }));
  });
  socket.on('Mail.Account.Deleted', async ({ data }) => {
    await store.dispatch(deleteMailAccount(data));
  });
  socket.on('Mail.Account.Enabled', async ({ data }) => {
    await store.dispatch(setIsEnabled(data, true));
  });
  socket.on('Mail.Account.Disabled', async ({ data }) => {
    await store.dispatch(setIsEnabled(data, false));
  });

  // Users
  socket.on('User.Created', async ({ data }) => {
    if (data && data.userId) {
      await store.dispatch(getUser(data.userId));
    }
  });
  socket.on('User.Updated', async ({ data }) => {
    if (data && data.userId) {
      store.dispatch(getUser(data.userId));
    }
  });
  socket.on('User.NetworkStatus.Updated', async ({ data }) => {
    const { auth: { user: { _id: loggedUserId } = {} } = {} } =
      store.getState();

    if (data && data.userId && data.userId === loggedUserId) {
      store.dispatch(
        updateAuthUserState({ user: { networkStatus: data.networkStatus } }),
      );
    }
  });

  // Contacts
  socket.on('Client.Created', async ({ data, record }) => {
    if (record) {
      store.dispatch(getClientItem(record));
    } else {
      store.dispatch(getContact(data));
    }
  });
  socket.on('Client.Updated', async ({ data, record }) => {
    if (record) {
      store.dispatch(getClientItem(record));
    } else {
      store.dispatch(getContact(data));
    }
  });

  // Client Segments
  socket.on('Client.Segment.Created', async ({ record }) => {
    store.dispatch(recieveClientSegment(record));
  });
  socket.on('Client.Segment.Deleted', async ({ data: id }) => {
    store.dispatch(removeClientSegment(id));
    store.dispatch(removeClientSegmentFromClients(id));
  });
  socket.on('Client.Segment.Updated', async ({ record }) => {
    store.dispatch(recieveClientSegment(record));
  });

  // Invites
  socket.on('Invite.Created', async ({ data }) => {
    await store.dispatch(getInvite(data));
  });

  // Templates
  socket.on('Template.Created', async ({ data }) => {
    await store.dispatch(getTemplate(data));
  });
  socket.on('Template.Updated', async ({ data }) => {
    await store.dispatch(getTemplate(data));
  });
  socket.on('Template.Deleted', async ({ data }) => {
    await store.dispatch(removeTemplate(data));
  });

  // Chatra channels
  socket.on('chatra.channel.created', async ({ data }) => {
    // помещаем новый канал чатры в фильтр
    const filterChannels = await getDialogsChannelFilters(store.getState());
    const {
      auth: { user },
    } = await store.getState();
    await store.dispatch(setFilterChannels(filterChannels.concat(data), user));
    await store.dispatch(getChatraAccount(data));
  });
  socket.on('chatra.channel.disabled', async ({ data }) => {
    await store.dispatch(disableChatraAccount(data));
  });
  socket.on('chatra.channel.enabled', async ({ data }) => {
    await store.dispatch(enableChatraAccount(data));
  });
  socket.on('chatra.channel.deleted', async ({ data }) => {
    await store.dispatch(getChatraAccount(data));
  });
  socket.on('chatra.channel.updated', async ({ data }) => {
    await store.dispatch(getChatraAccount(data));
  });

  // Telegram Channels
  socket.on('telegram.channel.created', async ({ data }) => {
    // помещаем новый канал телеграма в фильтр
    const filterChannels = await getDialogsChannelFilters(store.getState());
    const {
      auth: { user },
    } = await store.getState();
    await store.dispatch(setFilterChannels(filterChannels.concat(data), user));
    await store.dispatch(getTelegramAccount(data));
  });
  socket.on('telegram.channel.disabled', async ({ data }) => {
    await store.dispatch(disableTelegramAccount(data));
  });
  socket.on('telegram.channel.enabled', async ({ data }) => {
    await store.dispatch(enableTelegramAccount(data));
  });
  socket.on('telegram.channel.deleted', async ({ data }) => {
    await store.dispatch(getTelegramAccount(data));
  });
  socket.on('telegram.channel.updated', async ({ data }) => {
    await store.dispatch(getTelegramAccount(data));
  });

  // WhatsApp Channels
  socket.on('whatsapp.channel.created', async ({ data }) => {
    // помещаем новый канал вотсапа в фильтр
    const filterChannels = await getDialogsChannelFilters(store.getState());
    const {
      auth: { user },
    } = await store.getState();
    await store.dispatch(setFilterChannels(filterChannels.concat(data), user));
    // TODO -> add Whatsapp channel types to broadcasts
    await store.dispatch(getWhatsAppAccount(data));
    await store.dispatch(getWabaAccount(data));
    await store.dispatch(getGreenApiAccount(data));
  });
  socket.on('whatsapp.channel.disabled', async ({ data }) => {
    await store.dispatch(disableWhatsAppAccount(data));
  });
  socket.on('whatsapp.channel.enabled', async ({ data }) => {
    await store.dispatch(enableWhatsAppAccount(data));
  });
  socket.on('whatsapp.channel.deleted', async ({ data }) => {
    // TODO -> add Whatsapp channel types to broadcasts
    await store.dispatch(getWhatsAppAccount(data));
    await store.dispatch(getWabaAccount(data));
    await store.dispatch(getGreenApiAccount(data));
  });
  socket.on('whatsapp.channel.updated', async ({ data }) => {
    // TODO -> add Whatsapp channel types to broadcasts
    await store.dispatch(getWhatsAppAccount(data));
    await store.dispatch(getWabaAccount(data));
    await store.dispatch(getGreenApiAccount(data));
  });

  // VK Channels
  socket.on('vk.channel.created', async ({ data }) => {
    // помещаем новый канал вконтакте в фильтр
    const filterChannels = await getDialogsChannelFilters(store.getState());
    const {
      auth: { user },
    } = await store.getState();
    await store.dispatch(setFilterChannels(filterChannels.concat(data), user));
    await store.dispatch(getVKAccount(data));
  });
  socket.on('vk.channel.disabled', async ({ data }) => {
    await store.dispatch(disableVKAccount(data));
  });
  socket.on('vk.channel.enabled', async ({ data }) => {
    await store.dispatch(enableVKAccount(data));
  });
  socket.on('vk.channel.deleted', async ({ data }) => {
    await store.dispatch(getVKAccount(data));
  });
  socket.on('vk.channel.updated', async ({ data }) => {
    await store.dispatch(getVKAccount(data));
  });

  // Skype Channels
  socket.on('skype.channel.created', async ({ data }) => {
    // помещаем новый канал скайпа в фильтр
    const filterChannels = await getDialogsChannelFilters(store.getState());
    const {
      auth: { user },
    } = await store.getState();
    await store.dispatch(setFilterChannels(filterChannels.concat(data), user));
    await store.dispatch(getSkypeAccount(data));
  });
  socket.on('skype.channel.disabled', async ({ data }) => {
    await store.dispatch(disableSkypeAccount(data));
  });
  socket.on('skype.channel.enabled', async ({ data }) => {
    await store.dispatch(enableSkypeAccount(data));
  });
  socket.on('skype.channel.deleted', async ({ data }) => {
    await store.dispatch(getSkypeAccount(data));
  });
  socket.on('skype.channel.updated', async ({ data }) => {
    await store.dispatch(getSkypeAccount(data));
  });

  // PBX Channels
  socket.on('pbx.channel.created', async ({ data }) => {
    // помещаем новый канал телефонии в фильтр
    const filterChannels = await getCallsChannelFilters(store.getState());
    const {
      auth: { user },
    } = await store.getState();
    await store.dispatch(
      setCallsFilterChannels(filterChannels.concat(data), user),
    );
    await store.dispatch(getPbxAccount(data));
  });
  socket.on('pbx.channel.disabled', async ({ data }) => {
    await store.dispatch(disablePbxAccount(data));
  });
  socket.on('pbx.channel.enabled', async ({ data }) => {
    await store.dispatch(enablePbxAccount(data));
  });
  socket.on('pbx.channel.deleted', async ({ data }) => {
    await store.dispatch(getPbxAccount(data));
  });
  socket.on('pbx.channel.updated', async ({ data }) => {
    await store.dispatch(getPbxAccount(data));
  });

  // Facebook Channels
  socket.on('fb.channel.created', async ({ data }) => {
    // помещаем новый канал вконтакте в фильтр
    const filterChannels = await getDialogsChannelFilters(store.getState());
    const {
      auth: { user },
    } = await store.getState();
    await store.dispatch(setFilterChannels(filterChannels.concat(data), user));
    await store.dispatch(getFbAccount(data));
  });
  socket.on('fb.channel.disabled', async ({ data }) => {
    await store.dispatch(disableFbAccount(data));
  });
  socket.on('fb.channel.enabled', async ({ data }) => {
    await store.dispatch(enableFbAccount(data));
  });
  socket.on('fb.channel.deleted', async ({ data }) => {
    await store.dispatch(getFbAccount(data));
  });
  socket.on('fb.channel.updated', async ({ data }) => {
    await store.dispatch(getFbAccount(data));
  });

  // Instagram Channels
  socket.on('ig.channel.created', async ({ data }) => {
    const {
      instagram: { types: igChTypes },
    } = channelsConf;
    // помещаем новый канал в фильтр
    const filterChannels = await getDialogsChannelFilters(store.getState());
    const {
      auth: { user },
    } = await store.getState();
    await store.dispatch(setFilterChannels(filterChannels.concat(data), user));
    // TODO -> add IG channel types to broadcasts
    await Promise.all(
      igChTypes.map((type) => store.dispatch(getIgAccount(data, type))),
    );
  });
  socket.on('ig.channel.disabled', async ({ data }) => {
    await store.dispatch(disableIgAccount(data));
  });
  socket.on('ig.channel.enabled', async ({ data }) => {
    await store.dispatch(enableIgAccount(data));
  });
  socket.on('ig.channel.deleted', async ({ data }) => {
    const {
      instagram: { types: igChTypes },
    } = channelsConf;
    // TODO -> add IG channel types to broadcasts
    await Promise.all(
      igChTypes.map((type) => store.dispatch(getIgAccount(data, type))),
    );
  });
  socket.on('ig.channel.updated', async ({ data }) => {
    const {
      instagram: { types: igChTypes },
    } = channelsConf;
    // TODO -> add IG channel types to broadcasts
    await Promise.all(
      igChTypes.map((type) => store.dispatch(getIgAccount(data, type))),
    );
  });

  // Custom Channels
  socket.on('custom.channel.created', async ({ data }) => {
    // помещаем новый канал вконтакте в фильтр
    const filterChannels = await getDialogsChannelFilters(store.getState());
    const {
      auth: { user },
    } = await store.getState();
    await store.dispatch(setFilterChannels(filterChannels.concat(data), user));
    await store.dispatch(getCustomAccount(data));
  });
  socket.on('custom.channel.disabled', async ({ data }) => {
    await store.dispatch(disableCustomAccount(data));
  });
  socket.on('custom.channel.enabled', async ({ data }) => {
    await store.dispatch(enableCustomAccount(data));
  });
  socket.on('custom.channel.deleted', async ({ data }) => {
    await store.dispatch(getCustomAccount(data));
  });
  socket.on('custom.channel.updated', async ({ data }) => {
    await store.dispatch(getCustomAccount(data));
  });

  // AmoCRM integrations
  socket.on('amocrm.integration.created', async ({ data }) => {
    await store.dispatch(getAmoCrmIntegration(data));
  });
  socket.on('amocrm.integration.disabled', async ({ data }) => {
    await store.dispatch(disableAmoCrmIntegration(data));
  });
  socket.on('amocrm.integration.enabled', async ({ data }) => {
    await store.dispatch(enableAmoCrmIntegration(data));
  });
  socket.on('amocrm.integration.deleted', async ({ data }) => {
    await store.dispatch(getAmoCrmIntegration(data));
  });
  socket.on('amocrm.integration.updated', async ({ data }) => {
    await store.dispatch(getAmoCrmIntegration(data));
  });

  // Amocrm integrations v2
  socket.on('amocrm.v2.integration.created', async ({ data }) => {
    await store.dispatch(getAmocrmIntegrationV2(data));
  });
  socket.on('amocrm.v2.integration.disabled', async ({ data }) => {
    await store.dispatch(disableAmoCrmIntegration(data));
  });
  socket.on('amocrm.v2.integration.enabled', async ({ data }) => {
    await store.dispatch(enableAmoCrmIntegration(data));
  });
  socket.on('amocrm.v2.integration.deleted', async ({ data }) => {
    await store.dispatch(getAmocrmIntegrationV2(data));
  });
  socket.on('amocrm.v2.integration.updated', async ({ data }) => {
    await store.dispatch(getAmocrmIntegrationV2(data));
  });

  // Webhooks
  socket.on('webhook.created', async ({ data }) => {
    await store.dispatch(getWebhook(data));
  });
  socket.on('webhook.deleted', async ({ data }) => {
    await store.dispatch(getWebhook(data));
  });
  socket.on('webhook.updated', async ({ data }) => {
    await store.dispatch(getWebhook(data));
  });

  // Api-tokens
  socket.on('api.token.created', async ({ data }) => {
    await store.dispatch(getApiToken(data));
  });
  socket.on('api.token.deleted', async ({ data }) => {
    await store.dispatch(getApiToken(data));
  });
  socket.on('api.token.updated', async ({ data }) => {
    await store.dispatch(getApiToken(data));
  });

  // Dashboard stat
  socket.on('Dashboard.Calls.Stat.Gathered', async ({ data }) => {
    const clientsStat = JSON.parse(data);
    await store.dispatch(recieveCallsStat(clientsStat));
  });
  socket.on('Dashboard.Channels.Stat.Gathered', async ({ data }) => {
    const clientsStat = JSON.parse(data);
    await store.dispatch(recieveChannelsStat(clientsStat));
  });
  socket.on('Dashboard.Clients.Stat.Gathered', async ({ data }) => {
    const clientsStat = JSON.parse(data);
    await store.dispatch(recieveClientsStat(clientsStat));
  });
  socket.on('Dashboard.Dialogs.Stat.Gathered', async ({ data }) => {
    const clientsStat = JSON.parse(data);
    await store.dispatch(recieveDialogsStat(clientsStat));
  });
  socket.on('Dashboard.Messages.Stat.Gathered', async ({ data }) => {
    const clientsStat = JSON.parse(data);
    await store.dispatch(recieveMessagesStat(clientsStat));
  });
  socket.on('Dashboard.Rating.Stat.Gathered', async ({ data }) => {
    const clientsStat = JSON.parse(data);
    await store.dispatch(recieveRatingStat(clientsStat));
  });
  socket.on('Dashboard.Timing.Stat.Gathered', async ({ data }) => {
    const clientsStat = JSON.parse(data);
    await store.dispatch(recieveTimingStat(clientsStat));
  });
  socket.on('Dashboard.Export.Progress', async ({ data }) => {
    await store.dispatch(setExportEvents(data));
  });

  // Demo Channels - for Yeahdesk purposes only
  socket.on('example.channel.created', async ({ data }) => {
    const filter = [];
    // нужен таймаут, чтобы юзер успел авторизоваться
    setTimeout(async () => {
      const {
        auth: { user },
      } = await store.getState();
      if (user) {
        await store.dispatch(getDemoAccount(data));

        // теперь помещаем демо-канал в фильтр
        await store.dispatch(setFilterChannels(filter.concat(data)), user);
      }
    }, 4000);
  });
  socket.on('demo.channel.enabled', async ({ data }) => {
    await store.dispatch(enableDemoAccount(data));
  });

  // automation
  socket.on('automation.rule.created', async ({ data, record }) => {
    if (record) {
      store.dispatch(recieveAutomationAcc(record));
    } else {
      store.dispatch(getAutomationAcc(data));
    }
  });
  socket.on('automation.rule.disabled', async ({ data }) => {
    store.dispatch(disableAutomationAcc(data));
  });
  socket.on('automation.rule.enabled', async ({ data }) => {
    store.dispatch(enableAutomationAcc(data));
  });
  socket.on('automation.rule.deleted', async ({ data, record }) => {
    if (record) {
      store.dispatch(recieveAutomationAcc(record));
    } else {
      store.dispatch(getAutomationAcc(data));
    }
  });
  socket.on('automation.rule.updated', async ({ data, record }) => {
    if (record) {
      store.dispatch(recieveAutomationAcc(record));
    } else {
      store.dispatch(getAutomationAcc(data));
    }
  });

  // SLA
  // socket.on('sla.rule.created', async ({ record: rule }) => {
  //   store.dispatch(receiveSLARule(rule));
  // });
  // socket.on('sla.rule.disabled', async ({ record: rule }) => {
  //   store.dispatch(receiveSLARule(rule));
  // });
  // socket.on('sla.rule.enabled', async ({ record: rule }) => {
  //   store.dispatch(receiveSLARule(rule));
  // });
  // socket.on('sla.rule.deleted', async ({ data: id }) => {
  //   store.dispatch(removeSLARule(id));
  // });
  // socket.on('sla.rule.updated', async ({ record: rule }) => {
  //   store.dispatch(receiveSLARule(rule));
  // });

  // Chat-bots
  socket.on('Chat.Bot.Created', ({ data }) =>
    store.dispatch(getChatBots(data)),
  );
  socket.on('Chat.Bot.Updated', ({ data }) =>
    store.dispatch(getChatBots(data)),
  );
  socket.on('Chat.Bot.Deleted', ({ data }) =>
    store.dispatch(getChatBots(data)),
  );

  // Account actions
  socket.on('account.planchange', async () => {
    await store.dispatch(getUserData());
  });
  socket.on('account.statuschange', async () => {
    await store.dispatch(getUserData());
  });
  socket.on('account.datechange', async () => {
    await store.dispatch(getUserData());
  });

  // Billing actions
  socket.on('payment.request.created', async ({ data: { _id } }) => {
    await store.dispatch(getPlanRequest(_id));
  });
  socket.on('payment.request.updated', async ({ data: { _id } }) => {
    await store.dispatch(getPlanRequest(_id));
  });

  // Dialogs Filter Counters
  socket.on('filter.counters.updated', async ({ data }) => {
    await store.dispatch(recieveDialogsStatusCounters(data));
  });
}

const Root = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>
);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  // <React.StrictMode>
  <Root />,
  // </React.StrictMode>,
);

(async () => {
  async function authorize() {
    const {
      auth: { user },
    } = await store.getState();

    if (user && user._id) {
      subscribe();
    } else {
      setTimeout(() => authorize(), 250);
    }
  }

  if (needAnalyticsSetup) {
    await setupAnalyticsSdks();
  }

  await authorize();
})();
