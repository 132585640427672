import * as R from 'ramda';
import { createSelector } from 'reselect';

// TODO -> move here all reducers that are related to dialogs list

export const RECEIVE_DIALOGS_LIST_POSITIONS = 'RECEIVE_DIALOGS_LIST_POSITIONS';
export const RESET_DIALOGS_LIST_POSITIONS = 'RESET_DIALOGS_LIST_POSITIONS';

export const receiveDialogsListPositions = (positions) => ({
  type: RECEIVE_DIALOGS_LIST_POSITIONS,
  payload: { positions },
});

export const resetDialogsListPositions = () => ({
  type: RESET_DIALOGS_LIST_POSITIONS,
  payload: {},
});

const defaultDialogsListState = { positions: null };

export default function dialogsListReducer(
  state = defaultDialogsListState,
  action = { type: '' },
) {
  switch (action.type) {
    case RECEIVE_DIALOGS_LIST_POSITIONS: {
      return R.assoc('positions', action.payload.positions)(state);
    }
    case RESET_DIALOGS_LIST_POSITIONS: {
      const newState = { ...state };

      newState.positions = defaultDialogsListState.positions;

      return newState;
    }
    default: {
      return state;
    }
  }
}

const getDialogsListState = (state) => state.dialogsList;

export const dialogsListPositionsSelector = createSelector(
  getDialogsListState,
  (dialogsListState) => dialogsListState.positions,
);

export const dialogsListItemCountSelector = createSelector(
  dialogsListPositionsSelector,
  (dialogsListPositions) =>
    dialogsListPositions ? dialogsListPositions.length : null,
);
