import * as R from 'ramda';
import { createSelector } from 'reselect';
import { getDialogIdSelector } from './dialogs';

export const SET_FORWARD_MESSAGE = 'SET_FORWARD_MESSAGE';
export const SET_FORWARD_MESSAGE_DETAILS = 'SET_FORWARD_MESSAGE_DETAILS';
export const SET_FORWARD_MESSAGE_MODE = 'SET_FORWARD_MESSAGE_MODE';
export const SET_FORWARD_TO = 'SET_FORWARD_TO';
export const REMOVE_FORWARD_MESSAGE = 'REMOVE_FORWARD_MESSAGE';

function messageActionsReducer(state = {}, action = { type: '' }) {
  switch (action.type) {
    case SET_FORWARD_MESSAGE: {
      return {
        ...state,
        [action.payload.dialogId]: {
          ...state[action.payload.dialogId],
          message: action.payload.message,
        },
      };
    }
    case SET_FORWARD_MESSAGE_MODE: {
      return {
        ...state,
        [action.payload.dialogId]: {
          ...state[action.payload.dialogId],
          mode: action.payload.mode,
        },
      };
    }
    case SET_FORWARD_MESSAGE_DETAILS: {
      return {
        ...state,
        [action.payload.dialogId]: {
          ...state[action.payload.dialogId],
          details: action.payload.details,
        },
      };
    }
    case SET_FORWARD_TO: {
      return {
        ...state,
        [action.payload.dialogId]: {
          ...state[action.payload.dialogId],
          to: action.payload.to,
        },
      };
    }
    case REMOVE_FORWARD_MESSAGE: {
      return R.dissoc(action.payload.dialogId, state);
    }
    default: {
      return state;
    }
  }
}

export const setForwardMessage = (userId, dialogId, message) => ({
  type: SET_FORWARD_MESSAGE,
  payload: {
    userId,
    dialogId,
    message,
  },
});

export const setForwardMessageMode = (userId, dialogId, mode) => ({
  type: SET_FORWARD_MESSAGE_MODE,
  payload: {
    userId,
    dialogId,
    mode,
  },
});

export const setForwardMessageDetails = (userId, dialogId, details) => ({
  type: SET_FORWARD_MESSAGE_DETAILS,
  payload: {
    userId,
    dialogId,
    details,
  },
});

export const setForwardTo = (userId, dialogId, to) => ({
  type: SET_FORWARD_TO,
  payload: {
    userId,
    dialogId,
    to,
  },
});

export const removeForwardMessage = (userId, dialogId) => ({
  type: REMOVE_FORWARD_MESSAGE,
  payload: {
    userId,
    dialogId,
  },
});

export default function reducer(state = {}, action = { type: '' }) {
  switch (action.type) {
    case SET_FORWARD_MESSAGE:
    case SET_FORWARD_MESSAGE_DETAILS:
    case SET_FORWARD_TO:
    case SET_FORWARD_MESSAGE_MODE:
    case REMOVE_FORWARD_MESSAGE: {
      return R.assoc(
        action.payload.userId,
        messageActionsReducer(state[action.payload.userId], action),
      )(state);
    }
    default: {
      return state;
    }
  }
}

export const getMessageActionsSelector = (state, props) => state.messageActions;

export const getAuthUserId = (state) => state.auth.user._id;

export const getDialogId = (state, props) => getDialogIdSelector(state, props);

export const getForwardMessageForDialog = createSelector(
  [getMessageActionsSelector, getAuthUserId, getDialogId],
  (messageActions, userId, dialogId) => {
    const userActionsContainer = messageActions[userId];
    if (userActionsContainer) {
      return userActionsContainer[dialogId];
    }
    return null;
  },
);
