import * as R from 'ramda';
import { createSelector } from 'reselect';

export const RECEIVE_DIALOG_MESSAGES_LIST_POSITIONS =
  'RECEIVE_DIALOG_MESSAGES_LIST_POSITIONS';
export const RESET_DIALOG_MESSAGES_LIST_POSITIONS =
  'RESET_DIALOG_MESSAGES_LIST_POSITIONS';

export const receiveDialogMessagesListPositions = (dialogId, positions) => ({
  type: RECEIVE_DIALOG_MESSAGES_LIST_POSITIONS,
  payload: { dialogId, positions },
});

export const resetDialogMessagesListPositions = (dialogId) => ({
  type: RESET_DIALOG_MESSAGES_LIST_POSITIONS,
  payload: { dialogId },
});

const defaultDialogMessagesListState = { positions: {} };

export default function dialogMessagesListReducer(
  state = defaultDialogMessagesListState,
  action = { type: '' },
) {
  switch (action.type) {
    case RECEIVE_DIALOG_MESSAGES_LIST_POSITIONS: {
      return R.assocPath(
        ['positions', action.payload.dialogId],
        action.payload.positions,
        state,
      );
    }
    case RESET_DIALOG_MESSAGES_LIST_POSITIONS: {
      return R.dissocPath(['positions', action.payload.dialogId], state);
    }
    default: {
      return state;
    }
  }
}

const getDialogMessagesListState = (state) => state.dialogMessagesList;

export const dialogMessagesListPositionsSelector = createSelector(
  getDialogMessagesListState,
  (dialogMessagesListState) => dialogMessagesListState.positions,
);
