import { createSelector } from 'reselect';

const getMailAccounts = (state) => Object.values(state.mailAccounts);

const getChatraAccounts = (state) => Object.values(state.chatraAccounts);

const getTelegramAccounts = (state) => Object.values(state.telegramAccounts);

const getDemoAccounts = (state) => Object.values(state.demoAccounts);

const getWhatsAppAccounts = (state) => Object.values(state.whatsAppAccounts);

const getVKAccounts = (state) => Object.values(state.vkAccounts);

const getSkypeAccounts = (state) => Object.values(state.skypeAccounts);

const getPbxAccounts = (state) => Object.values(state.pbxAccounts);

const getFbAccounts = (state) => Object.values(state.fbAccounts);

const getIgAccounts = (state) => Object.values(state.igAccounts);

const getCustomAccounts = (state) => Object.values(state.customAccounts);

const getDialog = (state, props) => props.dialog;

// поиск канала, из которого был создан диалог
const contentChannelSelector = createSelector(
  [
    getMailAccounts,
    getChatraAccounts,
    getTelegramAccounts,
    getDemoAccounts,
    getWhatsAppAccounts,
    getVKAccounts,
    getSkypeAccounts,
    getPbxAccounts,
    getFbAccounts,
    getIgAccounts,
    getCustomAccounts,
    getDialog,
  ],
  (
    mailAccounts,
    chatraAccounts,
    telegramAccounts,
    demoAccounts,
    whatsAppAccounts,
    vkAccounts,
    skypeAccounts,
    pbxAccounts,
    fbAccounts,
    igAccounts,
    customAccounts,
    dialog,
  ) => {
    const determineChannelSourceByOrigin = {
      mail: mailAccounts,
      chatra: chatraAccounts,
      telegram: telegramAccounts,
      demo: demoAccounts,
      whatsapp: whatsAppAccounts,
      vk: vkAccounts,
      fb: fbAccounts,
      skype: skypeAccounts,
      pbx: pbxAccounts,
      ig: igAccounts,
      ig2: igAccounts,
      custom: customAccounts,
    };

    let channel;
    if (
      dialog &&
      (mailAccounts ||
        chatraAccounts ||
        telegramAccounts ||
        demoAccounts ||
        whatsAppAccounts ||
        vkAccounts ||
        skypeAccounts ||
        pbxAccounts ||
        fbAccounts ||
        igAccounts ||
        customAccounts)
    ) {
      if (dialog.channelId) {
        // ищем канал в источнике, который определяется полем origin в диалоге
        [channel] = determineChannelSourceByOrigin[dialog.origin].filter(
          (channeL) => dialog.channelId === channeL._id,
        );
      }
    }
    return channel || {};
  },
);

export default contentChannelSelector;
