import * as R from 'ramda';
import { createSelector } from 'reselect';

export const RECEIVE_MESSAGES = 'RECEIVE_MESSAGES';
export const RECEIVE_MESSAGE = 'RECEIVE_MESSAGE';
export const UPDATE_MESSAGE = 'UPDATE_MESSAGE';
export const MARK_MESSAGE_AS_READ = 'MARK_MESSAGE_AS_READ';
export const MARK_MESSAGES_AS_READ = 'MARK_MESSAGES_AS_READ';
export const DELETE_MESSAGE = 'DELETE_MESSAGE';

const Message = {};

function messageFactory(message) {
  return { [message._id]: message };
}

function messagesReducer(state = Message, action = {}) {
  switch (action.type) {
    case RECEIVE_MESSAGE: {
      return { ...state, ...action.payload.message };
    }
    case RECEIVE_MESSAGES: {
      return R.mergeAll(R.map(messageFactory, action.payload.messages));
    }
    case MARK_MESSAGE_AS_READ: {
      return {
        ...state,
        readAt: [
          ...new Set([
            ...(state.readAt || []),
            { userId: action.payload.userId, value: action.payload.readValue },
          ]),
        ],
      };
    }
    case UPDATE_MESSAGE: {
      return {
        ...state,
        text: action.payload.text,
        files: action.payload.files,
        metadata: action.payload.metadata,
      };
    }
    default: {
      return state;
    }
  }
}

export const receiveMessages = (messages) => ({
  type: RECEIVE_MESSAGES,
  payload: { messages },
});

export const renewMessage = (messageId, text, files, metadata) => ({
  type: UPDATE_MESSAGE,
  payload: { messageId, text, files, metadata },
});

export const removeMessage = (messageId) => ({
  type: DELETE_MESSAGE,
  payload: { messageId },
});

export const receiveMessage = (id, message) => ({
  type: RECEIVE_MESSAGE,
  payload: { message },
});

export const markAsReadMsg = ({ messageId, userId, readValue }) => ({
  type: MARK_MESSAGE_AS_READ,
  payload: { messageId, userId, readValue },
});

export const markMessagesAsRead = ({ messageIds, userId, readValue }) => ({
  type: MARK_MESSAGES_AS_READ,
  payload: { messageIds, userId, readValue },
});

export default function reducer(state = {}, action = { type: '' }) {
  switch (action.type) {
    case RECEIVE_MESSAGE: {
      return R.assoc(
        action.payload.message._id,
        messagesReducer(state[action.payload.message._id], action),
      )(state);
    }
    case RECEIVE_MESSAGES: {
      return R.mergeRight(state, messagesReducer(state, action));
    }
    case MARK_MESSAGE_AS_READ:
    case UPDATE_MESSAGE: {
      return R.assoc(
        action.payload.messageId,
        messagesReducer(state[action.payload.messageId], action),
      )(state);
    }
    case MARK_MESSAGES_AS_READ: {
      return R.mergeRight(
        state,
        action.payload.messageIds.reduce(
          (acc, id) => ({
            ...acc,
            [id]: {
              ...(state[id] || {}),
              readAt: [
                ...new Set([
                  ...(state[id] ? state[id].readAt || [] : []),
                  {
                    userId: action.payload.userId,
                    value: action.payload.readValue,
                  },
                ]),
              ],
            },
          }),
          {},
        ),
      );
    }
    case DELETE_MESSAGE: {
      return {
        ...state,
        ...state.filter((message) => message._id !== action.payload.messageId),
      };
    }
    default: {
      return state;
    }
  }
}

export const messagesSelector = (state) => state.messages;

export const messagesArraySelector = createSelector(
  messagesSelector,
  (messages) => Object.values(messages),
);

const taskIdSelector = (_, props) => props.taskId;

export const taskMessagesSelector = createSelector(
  messagesArraySelector,
  taskIdSelector,
  (messages, taskId) => messages.filter((t) => t.taskId === taskId),
);
