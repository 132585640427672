/* eslint-disable no-console */
import fetchJWT from '../../utils/fetchJWT';
import { getClientsItems } from '../../reducers/clients';
import { logout } from '../../actions';
import backend from '../../configs/backend';

const getContacts =
  (search, contactsArray = [], offset, limit, cb, needExistingRecords) =>
  async (dispatch) => {
    const iterationDelimiter = 150;
    let iterationsCount = 1;

    if (
      contactsArray &&
      contactsArray.length &&
      contactsArray.length > iterationDelimiter
    ) {
      iterationsCount = Math.ceil(contactsArray.length / iterationDelimiter);
    }

    const performRequest = (startIndex, endIndex) => {
      const url = backend.applyBaseUrl('/api/clients/contact/read');
      const urlSearchParams = new URLSearchParams({
        ...(offset ? { offset } : {}),
        ...(limit ? { limit } : {}),
        ...(search ? { search } : {}),
        ...(contactsArray
          ? {
              contactsArray: contactsArray
                .slice(startIndex, endIndex)
                .join(','),
            }
          : {}),
        ...(needExistingRecords ? { needExistingRecords } : {}),
      });

      url.search = urlSearchParams;

      const request = new Request(url, {
        method: 'GET',
        cache: 'default',
        mode: 'cors',
      });

      return fetchJWT(request)
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          if (res.status === 401) {
            dispatch(logout());
          }
          return {};
        })
        .then(async (res) => {
          if (res.length) {
            dispatch(getClientsItems(res));

            iterationsCount -= 1;

            if (iterationsCount > 0) {
              await performRequest(
                startIndex + iterationDelimiter,
                endIndex + iterationDelimiter,
              );
            }
          }
          if (res && typeof res === 'object') {
            if (cb) {
              cb(res.length);
            }
          }
          return res;
        })
        .catch((err) => console.error(err));
    };

    return performRequest(0, iterationDelimiter);
  };

export default getContacts;
