import * as R from 'ramda';
import { createSelector } from 'reselect';

export const RECIEVE_AUTOMATION_ACCS = 'RECIEVE_AUTOMATION_ACCS';
export const RECIEVE_AUTOMATION_ACC = 'RECIEVE_AUTOMATION_ACC';
export const UPDATE_AUTOMATION_ACC = 'UPDATE_AUTOMATION_ACC';
export const SET_AUTOMATION_ACC_DESCRIPTION = 'SET_AUTOMATION_ACC_DESCRIPTION';
export const SET_AUTOMATION_ACC_RULES = 'SET_AUTOMATION_ACC_RULES';
export const DELETE_AUTOMATION_ACC = 'DELETE_AUTOMATION_ACC';
export const ENABLE_AUTOMATION_ACC = 'ENABLE_AUTOMATION_ACC';
export const DISABLE_AUTOMATION_ACC = 'DISABLE_AUTOMATION_ACC';

function automationAccsFactory(account) {
  return { [account._id]: account };
}

function automationAccsReducer(state = {}, action = { type: '' }) {
  switch (action.type) {
    case RECIEVE_AUTOMATION_ACCS: {
      return R.mergeAll(
        R.map(automationAccsFactory, action.payload.automationAccs),
      );
    }
    case RECIEVE_AUTOMATION_ACC: {
      return { ...state, ...action.payload.automationAcc };
    }
    case ENABLE_AUTOMATION_ACC: {
      return { ...state, isEnabled: true };
    }
    case DISABLE_AUTOMATION_ACC: {
      return { ...state, isEnabled: false };
    }
    case DELETE_AUTOMATION_ACC: {
      return { ...state, isDeleted: true };
    }
    case UPDATE_AUTOMATION_ACC: {
      return {
        ...state,
        ...(action.payload.name ? { name: action.payload.name } : {}),
        ...(action.payload.description
          ? { description: action.payload.description }
          : {}),
        ...(action.payload.rules ? { rules: action.payload.rules } : {}),
      };
    }
    default: {
      return state;
    }
  }
}

export const recieveAutomationAccs = (automationAccs) => ({
  type: RECIEVE_AUTOMATION_ACCS,
  payload: { automationAccs },
});

export const recieveAutomationAcc = (automationAcc) => ({
  type: RECIEVE_AUTOMATION_ACC,
  payload: { automationAcc },
});

export const renewAutomationAcc = ({
  automationAccId,
  name,
  description,
  rules,
}) => ({
  type: UPDATE_AUTOMATION_ACC,
  payload: {
    automationAccId,
    name,
    description,
    rules,
  },
});

export const removeAutomationAcc = (_id) => ({
  type: DELETE_AUTOMATION_ACC,
  payload: {
    _id,
  },
});

export const enableAutomationAcc = (_id) => ({
  type: ENABLE_AUTOMATION_ACC,
  payload: { _id },
});

export const disableAutomationAcc = (_id) => ({
  type: DISABLE_AUTOMATION_ACC,
  payload: { _id },
});

export default function reducer(state = {}, action = { type: '' }) {
  switch (action.type) {
    case RECIEVE_AUTOMATION_ACCS: {
      return R.mergeRight(state, automationAccsReducer(state, action));
    }
    case RECIEVE_AUTOMATION_ACC: {
      return R.assoc(
        action.payload.automationAcc._id,
        automationAccsReducer(state[action.payload.automationAcc._id], action),
      )(state);
    }
    case ENABLE_AUTOMATION_ACC:
    case DISABLE_AUTOMATION_ACC:
    case DELETE_AUTOMATION_ACC: {
      return R.assoc(
        action.payload._id,
        automationAccsReducer(state[action.payload._id], action),
      )(state);
    }
    case UPDATE_AUTOMATION_ACC: {
      return R.assoc(
        action.payload.automationAccId,
        automationAccsReducer(state[action.payload.automationAccId], action),
      )(state);
    }
    default: {
      return state;
    }
  }
}

export const automationAccsSelector = (state) => state.automation;

// TODO -> add separate selectors for each type (currently, there is autoReply type only)
export const automationAccsArraySelector = createSelector(
  automationAccsSelector,
  (automationAccs) =>
    R.filter((_) => !_.isDeleted, R.values(automationAccs)).sort(
      (curr, next) => {
        const { system: currIsSystem = false } = curr;
        const { system: nextIsSystem = false } = next;

        return nextIsSystem - currIsSystem;
      },
    ),
);

export const deletedAutomationAccsArraySelector = createSelector(
  automationAccsSelector,
  (automationAccs) =>
    R.filter((_) => _.isDeleted, R.values(automationAccs)).sort(
      (curr, next) => {
        const { system: currIsSystem = false } = curr;
        const { system: nextIsSystem = false } = next;

        return nextIsSystem - currIsSystem;
      },
    ),
);

export const automationAccIdSelector = (state, ownProps) => {
  const { params: { accId } = {} } = ownProps.match;
  return accId || ownProps.id || ownProps._id;
};

export const automationAccSelector = createSelector(
  automationAccsSelector,
  automationAccIdSelector,
  (accs, id) => R.propOr({}, id, accs),
);
