import moment from 'moment';

/**
 * @description Formats passed date for display according to current date.
 * @param {Date|string|number} dateToFormat
 * @returns {string|null} If date was passed, returns formatted date string.
 * Else returns null.
 */
const formatDateForDisplay = (dateToFormat) => {
  if (dateToFormat) {
    const currentDate = new Date();
    const passedDate = new Date(dateToFormat);

    if (
      passedDate.getDate() === currentDate.getDate() &&
      passedDate.getMonth() === currentDate.getMonth() &&
      passedDate.getFullYear() === currentDate.getFullYear()
    ) {
      // current day, display only time values
      return moment(dateToFormat).format('HH:mm');
    }

    // display full date without time
    return moment(dateToFormat).format('D.MM.YY');
  }

  return null;
};

export default formatDateForDisplay;
