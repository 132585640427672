import isFunction from './isFunction';
import isObject from './isObject';

/**
 * @description
 * @param {Array.<Object|Function>} refs
 */
const mergeComponentRefs = (refs) => {
  const nonEmptyRefs = refs.filter(Boolean);
  if (!nonEmptyRefs.length) return null;
  if (nonEmptyRefs.length === 0) return nonEmptyRefs[0];

  return (componentRef) => {
    nonEmptyRefs.forEach((ref) => {
      if (isFunction(ref)) {
        ref(componentRef);
      } else if (isObject(ref)) {
        // eslint-disable-next-line no-param-reassign
        ref.current = componentRef;
      }
    });
  };
};

export default mergeComponentRefs;
