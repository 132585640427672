/*
 * Не увидел смысла вводить переменные типа LOGGED_IN = 'LOGGED_IN'
 *
 */
/* eslint-disable camelcase */

export function loggedIn(user, refresh_token) {
  return {
    type: 'LOGGED_IN',
    user,
    refresh_token,
  };
}

export function updateRefresh(refresh_token) {
  return {
    type: 'UPDATE_REFRESH',
    refresh_token,
  };
}

export const updateUser = (user) => ({
  type: 'UPDATE_USER',
  payload: user,
});

export function notLogged() {
  return {
    type: 'NOT_LOGGED',
  };
}

export function dontRemember(value) {
  return {
    type: 'DONT_REMEMBER',
    value,
  };
}

export function userInput(value) {
  return {
    type: 'USER_INPUT',
    value,
  };
}

export function networkError(err) {
  return {
    type: 'NETWORK_ERR',
    err,
  };
}

export function logout() {
  return {
    type: 'LOGOUT',
  };
}
