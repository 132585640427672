/* Вычисление автора сообщения через селектор */
import { createSelector } from 'reselect';

const getUsersFromState = (state) => state.users;

const getClientsFromState = (state) => Object.values(state.clients);

const getContactsIdFromProps = (state, props) => props.item.contactsId;

const getAuthorIdFromProps = (state, props) => props.item.authorId;

const messageAuthorSelector = createSelector(
  [
    getUsersFromState,
    getClientsFromState,
    getContactsIdFromProps,
    getAuthorIdFromProps,
  ],
  // в клиентах может быть Агент, ответивший на диалог в Чатре, поэтому ищем и в них тоже
  (users, clients, contactsId, authorId) => {
    if (authorId === 'bot') {
      return { _id: 'bot', name: 'Робот' };
    }
    if (users[authorId]) {
      return users[authorId];
    }
    if (clients.find((c) => c._id === authorId)) {
      return clients.find((c) => c._id === authorId);
    }
    if (
      clients.filter(
        (client) =>
          client.contacts &&
          client.contacts.find((cData) => cData.id === contactsId),
      ).length
    ) {
      return clients.filter(
        (client) =>
          client.contacts &&
          client.contacts.find((cData) => cData.id === contactsId),
      )[0];
    }
    return null;
  },
);

export default messageAuthorSelector;
