import React, { memo } from 'react';
import PropTypes from 'prop-types';

import chatraIcon from '../../../../../assets/images/chatraIconColored.png';
import mailIcon from '../../../../../assets/images/mailIconColored.png';
import pbxIcon from '../../../../../assets/images/pbxIcon.png';
import skypeIcon from '../../../../../assets/images/skypeIconColored.png';
import telegramIcon from '../../../../../assets/images/tgIconColored.png';
import vkIcon from '../../../../../assets/images/vkIconColored.png';
import whatsAppIcon from '../../../../../assets/images/waIconColored.png';
import fbIcon from '../../../../../assets/images/fbMessengerIcon.png';
import igIcon from '../../../../../assets/images/igIcon.png';
import customIcon from '../../../../../assets/images/customIcon.png';

import './DialogsListItemOriginIcon.css';

const DialogsListItemOriginIcon = ({ origin, iconPosition = 'left' }) => {
  const iconByOrigin = {
    chatra: (
      <img
        className="dialogsListItemOriginIcon__icon"
        src={chatraIcon}
        alt="chatra"
        width="20px"
        height="20px"
        loading="lazy"
      />
    ),
    mail: (
      <img
        className="dialogsListItemOriginIcon__icon"
        src={mailIcon}
        alt="mail"
        width="20px"
        height="20px"
        loading="lazy"
      />
    ),
    pbx: (
      <img
        className="dialogsListItemOriginIcon__icon"
        src={pbxIcon}
        alt="pbx"
        width="20px"
        height="20px"
        loading="lazy"
      />
    ),
    phone: (
      <img
        className="dialogsListItemOriginIcon__icon"
        src={pbxIcon}
        alt="phone"
        width="20px"
        height="20px"
        loading="lazy"
      />
    ),
    skype: (
      <img
        className="dialogsListItemOriginIcon__icon"
        src={skypeIcon}
        alt="skype"
        width="20px"
        height="20px"
        loading="lazy"
      />
    ),
    fb: (
      <img
        className="dialogsListItemOriginIcon__icon"
        src={fbIcon}
        alt="fb"
        width="20px"
        height="20px"
        loading="lazy"
      />
    ),
    ig: (
      <img
        className="dialogsListItemOriginIcon__icon"
        src={igIcon}
        alt="ig"
        width="20px"
        height="20px"
        loading="lazy"
      />
    ),
    ig2: (
      <img
        className="dialogsListItemOriginIcon__icon"
        src={igIcon}
        alt="ig"
        width="20px"
        height="20px"
        loading="lazy"
      />
    ),
    custom: (
      <img
        className="dialogsListItemOriginIcon__icon"
        src={customIcon}
        alt="custom"
        width="20px"
        height="20px"
        loading="lazy"
      />
    ),
    demo: (
      <img
        className="dialogsListItemOriginIcon__icon"
        src={mailIcon}
        alt="demo"
        width="20px"
        height="20px"
        loading="lazy"
      />
    ),
    telegram: (
      <img
        className="dialogsListItemOriginIcon__icon"
        src={telegramIcon}
        alt="telegram"
        width="20px"
        height="20px"
        loading="lazy"
      />
    ),
    whatsapp: (
      <img
        className="dialogsListItemOriginIcon__icon"
        src={whatsAppIcon}
        alt="whatsapp"
        width="20px"
        height="20px"
        loading="lazy"
      />
    ),
    vk: (
      <img
        className="dialogsListItemOriginIcon__icon"
        src={vkIcon}
        alt="vk"
        width="20px"
        height="20px"
        loading="lazy"
      />
    ),
  };

  return (
    <div
      className={`dialogsListItemOriginIcon dialogsListItemOriginIcon-${
        iconPosition === 'left' ? 'marginRight' : 'marginLeft'
      }`}
    >
      {iconByOrigin[origin]}
    </div>
  );
};

DialogsListItemOriginIcon.propTypes = {
  origin: PropTypes.string.isRequired,
  iconPosition: PropTypes.string,
};

export default memo(DialogsListItemOriginIcon);
