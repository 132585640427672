/**
 * @description This function secures iframe inner html links by adding target=_blank attribute.
 * This way every link will open in a new tab to grant user safety (and stable https for our service).
 * @param {Node} iframeRef Iframe reference.
 * @returns {boolean} Returns true if the function was able to secure the inner links, else returns false.
 */

const secureIframeLinks = (iframeRef) => {
  if (!iframeRef.contentDocument) {
    return false;
  }

  const links = iframeRef.contentDocument.getElementsByTagName('a');

  if (Object.values(links).length) {
    Object.values(links).forEach((link) => {
      // eslint-disable-next-line no-param-reassign
      link.target = '_blank';
      // eslint-disable-next-line no-param-reassign
      link.rel = 'noopener noreferrer';
    });
    return true;
  }

  return false;
};

export default secureIframeLinks;
