import { createSelector } from 'reselect';
import dialogMessagesListDialogPositionsSelector from './dialogMessagesListDialogPositionsSelector';

const dialogMessagesListItemCountSelector = createSelector(
  [dialogMessagesListDialogPositionsSelector],
  (dialogMessagesListPositions) =>
    dialogMessagesListPositions ? dialogMessagesListPositions.length : null,
);

export default dialogMessagesListItemCountSelector;
