/* eslint-disable no-console */
import fetchJWT from '../../utils/fetchJWT';
import { logout } from '../../actions';
import { recieveChatBot, recieveChatBots } from '../../reducers/chat-bots';
import backend from '../../configs/backend';

const getChatBots = (id, cb) => (dispatch) => {
  const request = new Request(
    backend.applyBaseUrl(`/api/chat-bot/bots/read/${id || ''}`),
    {
      method: 'GET',
      mode: 'cors',
    },
  );
  fetchJWT(request)
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
      if (res.status === 401) {
        dispatch(logout());
      }
      return [];
    })
    .then((res) => {
      if (res && res.length) {
        dispatch(id ? recieveChatBot(res[0]) : recieveChatBots(res));
      }
      if (cb) {
        cb();
      }
    })
    .catch((err) => console.error(err));
};

export default getChatBots;
