import { AnyAction, combineReducers } from 'redux';
import messages from './messages';
import dialogsFilters from './dialogsFilters';
import users from './users';
import tickets from './tickets';
import mailAccounts from './mail-accounts';
import dialogs from './dialogs';
import files from './files';
import auth from './auth';
import clients from './clients';
import clientsFilter from './clients-filter';
import clientEvents from './client-events';
import responsibleFilter from './responsible-filter';
import templates from './templates';
import templateFilter from './templateFilter';
import configData from './configuration';
import chatraAccounts from './chatra-accounts';
import telegramAccounts from './telegram-accounts';
import demoAccounts from './demo-accounts';
import drafts from './drafts';
import dialogActions from './dialogActions';
import invites from './invites';
import billingRequests from './billing-requests';
import whatsAppAccounts from './whatsappAccounts';
import cacheStatus from './cache-status';
import vkAccounts from './vk-accounts';
import skypeAccounts from './skype-accounts';
import statusCounters from './statusCounters';
import contactsData from './contactsData';
import messageActions from './messageActions';
import messageCounters from './messageCounters';
import stat from './stat';
import pbxAccounts from './pbx-accounts';
import dialogsListMode from './dialogsListMode';
import callsFilters from './callsFilters';
import outcomingDialogData, {
  outcomingDialogDataInitialState,
} from './outcoming-dialog-data';
import inReplyTo from './inReplyTo';
import fbAccounts from './fb-accounts';
import amoCrmIntegrations from './amocrm-integrations';
import amoCrmData from './amocrm-data';
import igAccounts from './ig-accounts';
import clientMessages from './client-messages';
import automation from './automation';
import customAccounts from './custom-accounts';
import webhooks from './webhooks';
import apiTokens from './api-tokens';
import dialogParticipants from './dialog-participants';
import tasks from './tasks';
import chatBots from './chat-bots';
import notifications from './notifications';
import tasksFilters from './tasksFilters';
import labels from './labels';
import dialogMessagesList from './dialogMessagesList';
import dialogsList from './dialogsList';
import dialogInfo from './dialogInfo';
import onboardingInfo from './onboarding-process';
import templateFromEditor from './templateFromEditor';
import dashboardEventsFilters from './dashboardEventsFilters';
import exportEvents from './exportEvents';
import clientSegments from './clientSegments';

/*
 * https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store
 */
const appReducer = combineReducers({
  messages,
  users,
  tickets,
  mailAccounts,
  dialogs,
  clients,
  clientEvents,
  auth,
  dialogsFilters,
  files,
  configData,
  clientsFilter,
  responsibleFilter,
  templates,
  templateFilter,
  chatraAccounts,
  telegramAccounts,
  demoAccounts,
  drafts,
  dialogActions,
  invites,
  billingRequests,
  whatsAppAccounts,
  cacheStatus,
  vkAccounts,
  statusCounters,
  contactsData,
  messageActions,
  skypeAccounts,
  messageCounters,
  stat,
  pbxAccounts,
  dialogsListMode,
  callsFilters,
  outcomingDialogData,
  inReplyTo,
  fbAccounts,
  amoCrmIntegrations,
  amoCrmData,
  igAccounts,
  clientMessages,
  automation,
  customAccounts,
  webhooks,
  apiTokens,
  dialogParticipants,
  tasks,
  chatBots,
  notifications,
  tasksFilters,
  labels,
  dialogsList,
  dialogMessagesList,
  dialogInfo,
  onboardingInfo,
  templateFromEditor,
  dashboardEventsFilters,
  exportEvents,
  clientSegments,
});

const rootReducer = (state: any, action: AnyAction) => {
  if (action.type === 'LOGOUT') {
    // теперь мы сохраняем настройки фильтров диалогов для юзера
    const previousDialogsFilters = state.dialogsFilters;
    // теперь мы сохраняем настройки фильтров звонков для юзера
    const previousCallsFilters = state.callsFilters;
    // теперь мы сохраняем настройки фильтров звонков для юзера
    const previousTasksFilters = state.tasksFilters;
    // сохраняем черновики для того, чтобы они были доступны после перелогина
    const savedDrafts = state.drafts;
    // сохраняем статус кэширования
    const savedCacheStatus = state.cacheStatus;
    // сохраняем действия с сообщениями (форвард)
    const savedMessageActions = state.messageActions;
    // сохраняем данные и фильтр для дашборда
    // const savedStat = state.stat;
    // сохраняем выбранный режим листа
    const savedListMode = state.dialogsListMode;
    // сохраняем действия с исходящими диалогами
    const savedOutcomingDialogData = {
      ...(state.outcomingDialogData || {}),
      drawerVisible: outcomingDialogDataInitialState.drawerVisible,
    };
    const savedDialogInfo = state.dialogInfo;
    const savedOnboardingInfo = state.onboardingInfo;
    const savedDashboardEventsFilters = state.dashboardEventsFilters;

    // стираем стейт
    // eslint-disable-next-line no-param-reassign
    state = undefined;

    // пустой стейт нужно заполнить настройками фильтров, если такие есть
    // черновики и статус кэширования сохраняются всегда
    // в ином случае полагаемся на initialState
    if (
      previousDialogsFilters.length !== 0 ||
      previousCallsFilters.length !== 0
    ) {
      // eslint-disable-next-line no-param-reassign
      state = {
        ...state,
        dialogsFilters: previousDialogsFilters,
        callsFilters: previousCallsFilters,
        tasksFilters: previousTasksFilters,
        drafts: savedDrafts,
        cacheStatus: savedCacheStatus,
        messageActions: savedMessageActions,
        dialogsListMode: savedListMode,
        ...(savedOutcomingDialogData.text &&
        savedOutcomingDialogData.text.length
          ? { outcomingDialogData: savedOutcomingDialogData }
          : {}),
        dialogInfo: savedDialogInfo,
        onboardingInfo: savedOnboardingInfo,
        dashboardEventsFilters: savedDashboardEventsFilters,
      };
    } else {
      // eslint-disable-next-line no-param-reassign
      state = {
        ...state,
        drafts: savedDrafts,
        cacheStatus: savedCacheStatus,
        messageActions: savedMessageActions,
        dialogsListMode: savedListMode,
        ...(savedOutcomingDialogData.text &&
        savedOutcomingDialogData.text.length
          ? { outcomingDialogData: savedOutcomingDialogData }
          : {}),
        dialogInfo: savedDialogInfo,
        onboardingInfo: savedOnboardingInfo,
        dashboardEventsFilters: savedDashboardEventsFilters,
      };
    }
  }
  return appReducer(state, action);
};

export default rootReducer;
