import fetchJWT from '../../utils/fetchJWT';
import searchParamsAdapter from '../../utils/searchParamsAdapter';
import callsParamsAdapter from '../../utils/callsParamsAdapter';
import { logout } from '../../actions';
import { receiveDialogsListPositions } from '../../reducers/dialogsList';
import {
  getDialogsChannelFilters,
  dialogsListFilterResponsibleUsersSelector,
} from '../../reducers/dialogsFilters';
import {
  getCallsChannelFilters,
  callsListFilterResponsibleUsersSelector,
} from '../../reducers/callsFilters';
import backend from '../../configs/backend';
import { dialogs as dialogsConf } from '../../configs';

const {
  search: { contentSearchMode: contentSearchModeConf },
} = dialogsConf;

const getDialogsListPositions = (cb) => async (dispatch, getState) => {
  const reduxState = getState();

  const {
    dialogsFilters = {},
    callsFilters = {},
    dialogsListMode: { currentMode: currentListMode = 'dialogs' },
  } = reduxState;

  const filterChannelsSelectorByMode =
    currentListMode === 'calls'
      ? getCallsChannelFilters
      : getDialogsChannelFilters;
  const filterChannels = filterChannelsSelectorByMode(reduxState);

  const filterResponsibleUsersSelectorByMode =
    currentListMode === 'calls'
      ? callsListFilterResponsibleUsersSelector
      : dialogsListFilterResponsibleUsersSelector;
  const filterResponsibleUsers =
    filterResponsibleUsersSelectorByMode(reduxState);

  const listFiltersByMode =
    currentListMode === 'calls' ? callsFilters : dialogsFilters;
  const {
    search = '',
    contentSearchMode = contentSearchModeConf.defaultMode,
    sortBy,
    filterStatus,
  } = listFiltersByMode;

  const url = backend.applyBaseUrl('/api/dialogs/read/list/positions');

  const searchParamsData = {
    search,
    contentSearchMode,
    filterStatus,
    filterChannels,
    sortBy,
    filterResponsibleUsers,
  };

  const searchParamsAdapterByMode =
    currentListMode === 'calls' ? callsParamsAdapter : searchParamsAdapter;
  const adaptedSearchParams = searchParamsAdapterByMode(searchParamsData);
  const urlSearchParams = new URLSearchParams(adaptedSearchParams);
  url.search = urlSearchParams;

  const request = new Request(url, {
    method: 'GET',
    cache: 'default',
    mode: 'cors',
  });

  fetchJWT(request)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      if (response.status === 401) {
        dispatch(logout());
      }

      throw new Error(
        `Cannot get dialogs list positions: ${response.err || response.status}`,
      );
    })
    .then((positions) => {
      if (cb) {
        cb({});
      }
      dispatch(receiveDialogsListPositions(positions));
    })
    .catch((err) => {
      console.error(err);

      if (cb) {
        cb({ err });
      }
    });
};

export default getDialogsListPositions;
