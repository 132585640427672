import * as R from 'ramda';
import { createSelector } from 'reselect';

export const RECIEVE_UNREAD_MESSAGES_COUNTERS =
  'RECIEVE_UNREAD_MESSAGES_COUNTERS';
export const UPDATE_UNREAD_MESSAGES_COUNTERS =
  'UPDATE_UNREAD_MESSAGES_COUNTERS';
export const ADD_ITEMS_TO_UNREAD_REFRESH_STORE =
  'ADD_ITEMS_TO_UNREAD_REFRESH_STORE';
export const REMOVE_ITEMS_FROM_UNREAD_REFRESH_STORE =
  'REMOVE_ITEMS_FROM_UNREAD_REFRESH_STORE';

function messagesCountersFactory(messagesCounter) {
  return { [messagesCounter._id]: messagesCounter };
}

function unreadMessagesCountersReducer(state = {}, action = { type: '' }) {
  switch (action.type) {
    case RECIEVE_UNREAD_MESSAGES_COUNTERS: {
      const { payload: { messagesCounters = [] } = {} } = action;
      return {
        ...state,
        ...R.mergeAll(messagesCounters.map(messagesCountersFactory)),
      };
    }
    case UPDATE_UNREAD_MESSAGES_COUNTERS: {
      const { total } = state;
      if (action.payload.updateType === 'decrement') {
        return { ...state, ...state, total: total === 0 ? 0 : total - 1 };
      }
      return { ...state, ...state, total: total + 1 };
    }
    default: {
      return state;
    }
  }
}

function unreadRefreshStoreReducer(state = [], action = { type: '' }) {
  switch (action.type) {
    case ADD_ITEMS_TO_UNREAD_REFRESH_STORE: {
      const { payload: { items = [] } = {} } = action;

      const updatedItems = [...state, ...items];

      return [...new Set(updatedItems)];
    }
    case REMOVE_ITEMS_FROM_UNREAD_REFRESH_STORE: {
      const { payload: { items = [] } = {} } = action;

      const updatedItems = state.filter((item) => !items.includes(item));

      return [...updatedItems];
    }
    default: {
      return state;
    }
  }
}

export const recieveUnreadMessagesCounters = (messagesCounters) => ({
  type: RECIEVE_UNREAD_MESSAGES_COUNTERS,
  payload: {
    messagesCounters,
  },
});

export const updateUnreadMessagesCounters = (dialogId, updateType) => ({
  type: UPDATE_UNREAD_MESSAGES_COUNTERS,
  payload: {
    dialogId,
    updateType,
  },
});

export const addItemsToUnreadRefreshStore = (items) => ({
  type: ADD_ITEMS_TO_UNREAD_REFRESH_STORE,
  payload: {
    items,
  },
});

export const removeItemsFromUnreadRefreshStore = (items) => ({
  type: REMOVE_ITEMS_FROM_UNREAD_REFRESH_STORE,
  payload: {
    items,
  },
});

const initialState = { unread: {}, unreadRefreshStore: [] };

export default function reducer(state = initialState, action = { type: '' }) {
  switch (action.type) {
    case RECIEVE_UNREAD_MESSAGES_COUNTERS: {
      return R.assoc(
        'unread',
        unreadMessagesCountersReducer(state.unread, action),
      )(state);
    }
    case UPDATE_UNREAD_MESSAGES_COUNTERS: {
      return R.assocPath(
        ['unread', action.payload.dialogId],
        unreadMessagesCountersReducer(
          state.unread[action.payload.dialogId],
          action,
        ),
      )(state);
    }
    case ADD_ITEMS_TO_UNREAD_REFRESH_STORE:
    case REMOVE_ITEMS_FROM_UNREAD_REFRESH_STORE: {
      return R.assoc(
        'unreadRefreshStore',
        unreadRefreshStoreReducer(state.unreadRefreshStore, action),
      )(state);
    }
    default: {
      return state;
    }
  }
}

export const getMessageCounters = (state) => state.messageCounters || {};

export const getUnreadMessageCounters = createSelector(
  getMessageCounters,
  (counterObject) => R.propOr([], 'unread', counterObject),
);

export const unreadMessageCountersRefreshStoreSelector = createSelector(
  getMessageCounters,
  (messageCounters) => R.propOr([], 'unreadRefreshStore', messageCounters),
);

export const getUnreadMessageCountersValues = createSelector(
  getMessageCounters,
  (counterObject) => {
    if (counterObject && counterObject.unread) {
      let unreadAmount = 0;
      Object.values(counterObject.unread).forEach((cO) => {
        unreadAmount += cO.total;
      });

      return unreadAmount;
    }
    return 0;
  },
);
