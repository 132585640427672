import fetchJWT from '../../utils/fetchJWT';
import { logout } from '../../actions';
import { recieveVKAccount, enableVKAccount } from '../../reducers/vk-accounts';
import backend from '../../configs/backend';

const getVKAccount = (id) => (dispatch) => {
  const request = new Request(
    backend.applyBaseUrl(`/api/vk-channel/accounts/read/${id}`),
    {
      method: 'GET',
      mode: 'cors',
      cache: 'default',
    },
  );
  fetchJWT(request)
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
      if (res.status === 401) {
        dispatch(logout());
      }
      return [];
    })
    .then((res) => {
      dispatch(recieveVKAccount(res[0]));

      dispatch(enableVKAccount(res[0]._id));
    })
    .catch((err) => console.error(err));
};

export default getVKAccount;
