import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import useLoggedUser from '../../hooks/auth/useLoggedUser';
import getActiveChannelsFromState from '../../selectors/getActiveChannelsFromState';
// import AlertBarOnboarding, {
//   showAddChannelSuccessModal,
//   showSettingsCompleteModal,
// } from './AlertBarOnboarding';
// import AlertBarDisabledAccount from './AlertBarDisabledAccount';
// import AlertBarTestPeriod from './AlertBarTestPeriod';
import { usersArraySelectorIsActive } from '../../reducers/users';
import { getInvites, getUsers } from '../../api/users';
import readMailAccounts from '../../api/mail-accounts/readMailAccounts';
import { getChatraAccounts } from '../../api/chatra-accounts';
import { getTelegramAccounts } from '../../api/telegram-accounts';
import { getDemoAccounts } from '../../api/demo-accounts';
import { getWhatsAppAccounts } from '../../api/whatsapp-accounts';
import { getWabaAccounts } from '../../api/whatsapp-accounts/i2crm/waba';
import { getVKAccounts } from '../../api/vk-accounts';
import { getSkypeAccounts } from '../../api/skype-accounts';
import { getPbxAccounts } from '../../api/pbx-accounts';
import { getFbAccounts } from '../../api/facebook-accounts';
import { getIgAccounts } from '../../api/instagram-accounts';
import { getCustomAccounts } from '../../api/custom-accounts';
import { channels as channelsConf } from '../../configs';
import { invitesArraySelector } from '../../reducers/invites';
import {
  finishAdminSecondStep,
  finishAdminThirdStep,
  setAdminOnboardingStarted,
} from '../../reducers/onboarding-process';
import { getGreenApiAccounts } from '../../api/whatsapp-accounts/greenApi';

const {
  instagram: { types: igChTypes },
} = channelsConf;

function AlertBar({ onOpen, onClose }) {
  const { user } = useLoggedUser();
  const [isInfoReady, setIsInfoReady] = useState(false);
  const [addedUsers, setAddedUsers] = useState(null);
  const [addedChannels, setAddedChannels] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUsers());
    dispatch(getInvites());
    dispatch(readMailAccounts());
    dispatch(getChatraAccounts());
    dispatch(getTelegramAccounts());
    dispatch(getDemoAccounts());
    dispatch(getWhatsAppAccounts());
    dispatch(getWabaAccounts());
    dispatch(getGreenApiAccounts());
    dispatch(getVKAccounts());
    dispatch(getSkypeAccounts());
    dispatch(getPbxAccounts());
    dispatch(getFbAccounts());
    dispatch(getCustomAccounts());
    igChTypes.forEach((type) => dispatch(getIgAccounts(type)));
  }, [dispatch]);

  const onboarding = useSelector((state) => state.onboardingInfo);
  const {
    isAdminOnboardingStarted,
    adminSecondStepCompleted,
    adminThirdStepCompleted,
  } = onboarding;

  const {
    account_info: { status = 'enabled', plan_id: planId = '100' } = {},
    admin = false,
  } = user || {};

  const isAuth = useSelector(
    (state) => state.auth.user && state.auth.user.email,
  );

  const users = useSelector(usersArraySelectorIsActive);

  const usersAmount = Object.keys(users).length;

  const invites = useSelector(invitesArraySelector).length;

  const channels = useSelector(getActiveChannelsFromState);

  const channelsAmount = channels.filter((ch) => ch._id).length;

  useEffect(() => {
    if (!isAuth || (Object.keys(user).length === 0 && isInfoReady)) {
      setIsInfoReady(false);
    } else if (
      isAuth &&
      Object.keys(user).length !== 0 &&
      channelsAmount >= 1 &&
      usersAmount >= 1 &&
      !isInfoReady
    ) {
      setIsInfoReady(true);
    }
  }, [user, channelsAmount, usersAmount, isInfoReady, isAuth]);

  useEffect(() => {
    if (isInfoReady && (status === 'disabled' || planId === '100')) {
      onOpen();
    } else {
      onClose();
    }
  }, [status, planId, isInfoReady, onOpen, onClose]);

  useEffect(() => {
    if (!adminSecondStepCompleted && isAdminOnboardingStarted) {
      if (isInfoReady && channelsAmount === 1) {
        setAddedChannels(1);
      }
      if (addedChannels === 1 && channelsAmount === 2) {
        setAddedChannels(2);
        dispatch(finishAdminSecondStep());
        if (invites >= 1 || usersAmount > 1) {
          dispatch(finishAdminThirdStep());
          dispatch(setAdminOnboardingStarted(false));
          // showAddChannelSuccessModal(showSettingsCompleteModal);
        } else {
          // showAddChannelSuccessModal();
        }
      }
    }
  }, [
    channelsAmount,
    addedChannels,
    isInfoReady,
    invites,
    usersAmount,
    dispatch,
    isAdminOnboardingStarted,
    adminSecondStepCompleted,
  ]);

  useEffect(() => {
    if (
      adminSecondStepCompleted &&
      !adminThirdStepCompleted &&
      isAdminOnboardingStarted
    ) {
      if (isInfoReady && invites === 0) {
        setAddedUsers(1);
      }
      if (addedUsers === 1 && invites === 1 && channelsAmount >= 2) {
        setAddedUsers(2);
        dispatch(finishAdminThirdStep());
        dispatch(setAdminOnboardingStarted(false));
        // showSettingsCompleteModal();
      }
    }
  }, [
    invites,
    addedUsers,
    isInfoReady,
    dispatch,
    isAdminOnboardingStarted,
    adminThirdStepCompleted,
    channelsAmount,
    adminSecondStepCompleted,
  ]);

  // if (isInfoReady) {
  //   if (status === 'disabled') {
  //     return <AlertBarDisabledAccount onClose={onClose} />;
  //   }
  //   if (planId === '100') {
  //     if (!admin || !isAdminOnboardingStarted) {
  //       return <AlertBarTestPeriod onClose={onClose} />;
  //     }
  //     if (isAdminOnboardingStarted && admin) {
  //       return <AlertBarOnboarding onClose={onClose} />;
  //     }
  //   }
  // }

  return null;
}

AlertBar.propTypes = {
  onOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default React.memo(AlertBar);
