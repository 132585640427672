export const SET_TEMPLATE_FILTER = 'SET_TEMPLATE_FILTER';
export const CLEAR_TEMPLATE_FILTER = 'CLEAR_TEMPLATE_FILTER';

export const setTemplateFilter = (value) => ({
  type: SET_TEMPLATE_FILTER,
  payload: value,
});

export const clearTemplateFilter = () => ({
  type: CLEAR_TEMPLATE_FILTER,
});

export default function reducer(state = 'private', action = {}) {
  switch (action.type) {
    case SET_TEMPLATE_FILTER: {
      return action.payload;
    }
    case CLEAR_TEMPLATE_FILTER: {
      return '';
    }
    default: {
      return state;
    }
  }
}

export const getTemplateFilter = (state) => state.templateFilter;
