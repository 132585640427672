import React, { memo, useRef, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { areEqual } from 'react-window';
import useDialogsListItemData from '../../../../hooks/dialogs/useDialogsListItemData';
import usePrevious from '../../../../hooks/usePrevious';
import DialogsListItemView from './DialogsListItemView';

const DialogsListItem = ({ index, style, data: { setSize, getSize } }) => {
  const {
    itemId,
    unreadMessagesCount,
    isSelected,
    showResponsible,
    responsible,
    itemStatus,
    origin,
    avatar,
    lastMessage,
    title,
    labels,
    waitingTill,
    isDialogAnswered,
  } = useDialogsListItemData(index);

  const elRef = useRef();

  const cachedSize = useMemo(() => getSize(itemId), [getSize, itemId]);

  const prevLabels = usePrevious(labels);

  const updateSize = useCallback(() => {
    if (itemId && elRef.current) {
      const actualSize = Math.ceil(
        elRef.current.getBoundingClientRect().height,
      );

      setSize(itemId, actualSize);
    }
  }, [itemId, setSize]);

  useEffect(() => {
    if (itemId && elRef.current) {
      const actualSize = Math.ceil(
        elRef.current.getBoundingClientRect().height,
      );

      /**
       * Update size if:
       * - actual/cached sizes are different
       * - labels have appeared/disappeared
       * (we render them in the separate row,
       * so they are the primary reason
       * for dynamic size changes)
       */
      if (
        actualSize !== cachedSize ||
        (prevLabels &&
          ((!prevLabels.length && labels.length) ||
            (prevLabels.length && !labels.length)))
      ) {
        updateSize();
      }
    }
  }, [itemId, setSize, cachedSize, prevLabels, labels, updateSize]);

  return (
    <DialogsListItemView
      itemStyle={style}
      elRef={elRef}
      itemId={itemId}
      unreadMessagesCount={unreadMessagesCount}
      isSelected={isSelected}
      showResponsible={showResponsible}
      responsible={responsible}
      itemStatus={itemStatus}
      origin={origin}
      avatar={avatar}
      lastMessage={lastMessage}
      isDialogAnswered={isDialogAnswered}
      title={title}
      labels={labels}
      waitingTill={waitingTill}
    />
  );
};

DialogsListItem.propTypes = {
  index: PropTypes.number.isRequired,
  style: PropTypes.object.isRequired,
  data: PropTypes.shape({
    setSize: PropTypes.func.isRequired,
    getSize: PropTypes.func.isRequired,
  }).isRequired,
};

export default memo(DialogsListItem, areEqual);
