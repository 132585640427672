import React, {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import { VariableSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import usePrevious from '../../../hooks/usePrevious';
import './style.css';

const VirtualList = forwardRef(
  (
    {
      itemCount,
      getSize,
      overscanCount = 5,
      ListItem,
      itemData,
      onItemsRendered,
      onScroll,
      customClassnames = [],
      fallbackItemSize,
    },
    ref,
  ) => {
    const innerRef = useRef();
    const listRef = useRef();

    /**
     * Public methods
     */
    useImperativeHandle(ref, () => ({
      /**
       * @param {number} itemIndex
       * @param {'auto'|'smart'|'center'|'end'|'start'} align
       */
      scrollToItem(itemIndex = 0, align = 'auto') {
        if (listRef.current) {
          listRef.current.scrollToItem(itemIndex, align);
        }
      },
      /**
       * @param {number} itemIndex
       */
      resetItemsHeightCacheAfterIndex(itemIndex = 0) {
        if (listRef.current) {
          listRef.current.resetAfterIndex(itemIndex);
        }
      },
    }));

    /**
     * Handle sudden item count increases (>1 at a time)
     */
    const prevItemCount = usePrevious(itemCount);
    useEffect(() => {
      if (
        listRef.current &&
        prevItemCount !== undefined &&
        itemCount - prevItemCount > 1
      ) {
        listRef.current.resetAfterIndex(0);
      }
    }, [itemCount, prevItemCount]);

    const getScrollHeight = useCallback(() => {
      if (innerRef.current) {
        return innerRef.current.offsetHeight;
      }
      return null;
    }, []);

    const handleOnScroll = useCallback(
      function handleOnScrollWithViewportHeight({
        scrollDirection,
        scrollOffset,
      }) {
        const viewportHeight = this.height;
        const scrollHeight = getScrollHeight();

        onScroll({
          scrollDirection,
          scrollHeight,
          scrollOffset,
          viewportHeight,
        });
      },
      [onScroll, getScrollHeight],
    );

    return (
      <div
        className={`virtualListWrapper${
          customClassnames.length ? ` ${customClassnames.join(' ')}` : ''
        }`}
      >
        <AutoSizer>
          {({ height, width }) => (
            <VariableSizeList
              height={height}
              width={width}
              itemCount={itemCount || 0}
              itemSize={getSize}
              overscanCount={overscanCount}
              ref={listRef}
              innerRef={innerRef}
              {...(fallbackItemSize
                ? {
                    estimatedItemSize: fallbackItemSize,
                  }
                : {})}
              {...(itemData
                ? {
                    itemData,
                  }
                : {})}
              {...(onItemsRendered
                ? {
                    onItemsRendered,
                  }
                : {})}
              {...(onScroll
                ? {
                    onScroll: handleOnScroll,
                  }
                : {})}
            >
              {ListItem}
            </VariableSizeList>
          )}
        </AutoSizer>
      </div>
    );
  },
);

VirtualList.displayName = 'VirtualList';

VirtualList.propTypes = {
  itemCount: PropTypes.number.isRequired,
  getSize: PropTypes.func.isRequired,
  overscanCount: PropTypes.number,
  ListItem: PropTypes.elementType.isRequired,
  itemData: PropTypes.object,
  onItemsRendered: PropTypes.func,
  onScroll: PropTypes.func,
  customClassnames: PropTypes.arrayOf(PropTypes.string),
  fallbackItemSize: PropTypes.number,
};

export default memo(VirtualList);
