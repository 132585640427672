import { AnyAction } from 'redux';

export const SAVE_TEMPLATE = 'SAVE_TEMPLATE';
export const OPEN_TEMPLATE_DRAWER = 'OPEN_TEMPLATE_DRAWER';
export const CLOSE_TEMPLATE_DRAWER = 'CLOSE_TEMPLATE_DRAWER';

export interface ITemplateFromEditor {
  savedTemplate: string;
  isTemplateDrawerOpened: boolean;
}

const initialState: ITemplateFromEditor = {
  savedTemplate: '',
  isTemplateDrawerOpened: false,
};

// eslint-disable-next-line default-param-last
export default function reducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case SAVE_TEMPLATE: {
      return { ...state, savedTemplate: action.payload };
    }
    case OPEN_TEMPLATE_DRAWER: {
      return { ...state, isTemplateDrawerOpened: true };
    }
    case CLOSE_TEMPLATE_DRAWER: {
      return initialState;
    }
    default: {
      return state;
    }
  }
}

export const saveTemplate = (template: string) => ({
  type: SAVE_TEMPLATE,
  payload: template,
});

export const openTemplateDrawer = () => ({
  type: OPEN_TEMPLATE_DRAWER,
});

export const closeTemplateDrawer = () => ({
  type: CLOSE_TEMPLATE_DRAWER,
});

export const getTemplateFromEditor = (state: any) => state.templateFromEditor;

export const getTemplateDrawerOpened = (state: any) =>
  state.templateFromEditor.isTemplateDrawerOpened;
