import backend from '../../configs/backend';

const loginUser = (user) => () =>
  new Promise((resolve) => {
    fetch(backend.applyBaseUrl('/api/users/log_in'), {
      method: 'POST',
      body: JSON.stringify(user),
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => resolve(response));
  });

export default loginUser;
