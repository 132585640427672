import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { ActionSheet, DatePicker } from 'antd-mobile';
import { CalendarOutline } from 'antd-mobile-icons';
import { useTranslation } from 'react-i18next';
import { updateDialog } from '../../../../api/dialogs';
import getNextMondayMorningDate from '../../../../utils/getNextMondayMorningDate';

const ActionSheetWaiting = ({ visible, onClose, dialog }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [customPeriodModalVisible, setCustomPeriodModalVisible] =
    useState(false);

  const setWaiting = (date) => {
    const { _id, channelId } = dialog;

    dispatch(
      updateDialog({
        id: _id,
        channelId,
        status: 'waiting',
        waitingTill: new Date(date).toISOString(),
      }),
    );
  };

  const Actions = [
    { text: t('ActionSheetWaiting.actions.monday'), key: 'monday' },
    { text: t('ActionSheetWaiting.actions.tomorrow'), key: 'tomorrow' },
    { text: t('ActionSheetWaiting.actions.fourHours'), key: 'fourHours' },
    { text: t('ActionSheetWaiting.actions.twoHours'), key: 'twoHours' },
    { text: t('ActionSheetWaiting.actions.oneHour'), key: 'oneHour' },
    { text: t('ActionSheetWaiting.actions.longPeriod'), key: 'longPeriod' },
    {
      text: (
        <>
          <CalendarOutline /> {t('ActionSheetWaiting.actions.customPeriod')}
        </>
      ),
      key: 'customPeriod',
    },
  ];

  return (
    <>
      <ActionSheet
        extra={t('ActionSheetWaiting.extra')}
        destroyOnClose
        closeOnAction
        visible={visible}
        actions={Actions}
        onClose={onClose}
        onAction={(action) => {
          switch (action.key) {
            case 'oneHour':
              setWaiting(moment(Date.now()).add('1', 'hours').format());
              break;
            case 'twoHours':
              setWaiting(moment(Date.now()).add('2', 'hours').format());
              break;
            case 'fourHours':
              setWaiting(moment(Date.now()).add('4', 'hours').format());
              break;
            case 'tomorrow':
              setWaiting(
                moment(new Date().setHours(8, 0)).add('1', 'days').format(),
              );
              break;
            case 'monday':
              setWaiting(getNextMondayMorningDate());
              break;
            case 'longPeriod':
              setWaiting(moment(Date.now()).add('1000', 'years').format());
              break;
            case 'customPeriod':
              setCustomPeriodModalVisible(true);
              break;
            default:
              break;
          }
        }}
      />
      <DatePicker
        cancelText={t('ActionSheetWaiting.cancel')}
        confirmText={t('ActionSheetWaiting.confirm')}
        destroyOnClose
        visible={customPeriodModalVisible}
        onClose={() => setCustomPeriodModalVisible(false)}
        precision="minute"
        min={new Date()}
        onConfirm={(val) => setWaiting(moment(val).format())}
      />
    </>
  );
};

export default ActionSheetWaiting;
