import fetchJWT from '../../utils/fetchJWT';
import { logout, updateUser as updateAuthUserState } from '../../actions';
import { renewUser } from '../../reducers/users';
import backend from '../../configs/backend';

const updateUser =
  (user, immediateStateUpdate = true, cb) =>
  async (dispatch, getState) => {
    const request = new Request(backend.applyBaseUrl('/api/users/'), {
      method: 'PUT',
      cache: 'default',
      mode: 'cors',
      body: JSON.stringify(user),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (immediateStateUpdate && user._id) {
      const { auth } = getState();

      if (auth.user._id === user._id) {
        // TODO -> remove inner state structure knowledge requirement from action interface
        dispatch(updateAuthUserState({ user }));
      }

      dispatch(renewUser(user));
    }

    return fetchJWT(request)
      .then((res) => {
        if (res.ok) {
          return {};
        }
        if (res.status === 401) {
          if (dispatch) {
            dispatch(logout());
          }
        }
        throw new Error('Network response was not ok.');
      })
      .then((res) => {
        if (cb) {
          cb({});
        }

        return res;
      })
      .catch((err) => {
        console.error(err);

        if (cb) {
          cb({ err });
        }

        return { err };
      });
  };
export default updateUser;
