import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { areEqual } from 'react-window';
import { Skeleton, ErrorBlock } from 'antd-mobile';
import { useTranslation } from 'react-i18next';
import { InfiniteDynamicVirtualList } from '../../../components/Common';
import DialogsListItem from './DialogsListItem';
import { components as componentsConf } from '../../../configs';
import EmptyDialogsList from './EmptyDialgosList';

const {
  pages: {
    dialogs: {
      list: {
        overscanCount,
        fallbackItemSize,
        recalcThrottleTimeout,
        loadMoreDebounceTimeout,
        skeletonParagraphConfig,
      },
    },
  },
} = componentsConf;

const DialogsListView = ({
  loadMore,
  mapItemIdToIndex,
  mapIndexToItemId,
  itemCount,
  hasContent,
  loading,
}) => {
  const { t } = useTranslation();

  return (
    <div style={{ display: 'flex', flex: 1 }}>
      {loading ? (
        <div style={{ width: '100%' }}>
          <Skeleton.Paragraph lineCount={5} animated />
        </div>
      ) : hasContent ? (
        <InfiniteDynamicVirtualList
          loadMoreFn={loadMore}
          itemCount={itemCount || 0}
          overscanCount={overscanCount}
          ListItem={DialogsListItem}
          mapItemIdToIndex={mapItemIdToIndex}
          mapIndexToItemId={mapIndexToItemId}
          fallbackItemSize={fallbackItemSize}
          recalcThrottleTimeout={recalcThrottleTimeout}
          loadMoreDebounceTimeout={loadMoreDebounceTimeout}
        />
      ) : (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <ErrorBlock
            status="empty"
            title={t('EmptyDialogMessagesListTitle')}
            description=""
          >
            <EmptyDialogsList />
          </ErrorBlock>
        </div>
      )}
    </div>
  );
};

DialogsListView.propTypes = {
  loadMore: PropTypes.func.isRequired,
  mapItemIdToIndex: PropTypes.func.isRequired,
  mapIndexToItemId: PropTypes.func.isRequired,
  itemCount: PropTypes.number,
  hasContent: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default memo(DialogsListView, areEqual);
