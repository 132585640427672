import React, { useState, useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Ellipsis, Dropdown, List, Avatar } from 'antd-mobile';
import {
  LeftOutline,
  CloseCircleFill,
  CheckCircleFill,
  FileOutline,
  FillinOutline,
  ClockCircleOutline,
  EditSOutline,
  UserOutline,
  FaceRecognitionOutline,
} from 'antd-mobile-icons';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as R from 'ramda';
import generateAvatarFromTitle from '../../../../utils/generateAvatarFromTitle';
import { updateDialog } from '../../../../api/dialogs';
import { createNote, createComment } from '../../../../api/messages';
import { getChannelIdFromDialogSelector } from '../../../../reducers/dialogs';
import ActionSheetWaiting from './ActionSheetWaiting';
import TextAreaModal from './TextAreaModal';
import ContentChannel from './ContentChannel';
import DialogOpenedInfo from './DialogOpenedInfo';
import ResponsiblePopup from './ResponsiblePopup';
import styles from './index.module.css';

const DialogOpenedHeader = ({
  dialog,
  title,
  users,
  dialogId,
  dialogContact,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getUserIdSelector = (state) =>
    R.pathOr(null, ['auth', 'user', '_id'], state);

  const authorId = useSelector((state) => getUserIdSelector(state));
  const channelId = useSelector((state) =>
    getChannelIdFromDialogSelector(state, { dialogId }),
  );

  const [activeKey, setActiveKey] = useState(null);
  const [isOpenResponsiblePopup, setIsOpenResponsiblePopup] = useState(false);
  const [snoozeConversation, setSnoozeConversation] = useState(false);
  const [isOpenNoteTextAreaModal, setIsOpenNoteTextAreaModal] = useState(false);
  const [isOpenCommentTextAreaModal, setIsOpenCommentTextAreaModal] =
    useState(false);
  const [isOpenTopicTextAreaModal, setIsOpenTopicTextAreaModal] =
    useState(false);

  const responsibleUser = useMemo(() => {
    if (dialog?.responsibleId) {
      return users[dialog?.responsibleId]?.name;
    } else {
      return t('DialogOpenedHeader.thereIsNoResponsiblePerson');
    }
  }, [dialog?.responsibleId, users, t]);

  const toggleStatus = () => {
    const newStatus =
      {
        opened: 'closed',
        closed: 'opened',
        waiting: 'closed',
      }[dialog.status] || 'closed';

    dispatch(
      updateDialog({
        id: dialog._id,
        channelId: dialog.channelId,
        status: newStatus,
      }),
    );
  };

  const toggleDropdown = () => {
    setActiveKey(activeKey ? null : 'content');
  };

  const responsibleAvatar = useCallback(() => {
    if (dialog?.responsibleId) {
      if (users[dialog?.responsibleId]?.avatar) {
        return (
          <Avatar
            src={users[dialog?.responsibleId]?.avatar}
            style={{ '--size': '25px' }}
            fit="cover"
          />
        );
      }
      return (
        <div className={styles.responsibleAvatar}>
          {users[dialog?.responsibleId]?.email ? (
            <UserOutline fontSize={19} />
          ) : (
            <FaceRecognitionOutline fontSize={19} />
          )}
        </div>
      );
    }
    return null;
  }, [dialog?.responsibleId, users]);

  return (
    <div className="dialog-opened-header">
      <div className={styles.wrapper}>
        <div>
          <Button onClick={() => navigate('/conversations')}>
            <LeftOutline />
          </Button>
        </div>
        <div className={styles.user} onClick={toggleDropdown}>
          <div
            className="DialogsListItemAvatar"
            style={{
              backgroundColor:
                dialog?.avatar?.type === 'color' && dialog?.avatar?.value
                  ? dialog?.avatar?.value
                  : '#65aadd',
            }}
          >
            {generateAvatarFromTitle(title)}
          </div>
          <div className={styles.userInfo}>
            <b>
              <Ellipsis direction="end" content={title} />
            </b>
            <div>
              <Ellipsis direction="end" content={responsibleUser} />
            </div>
          </div>
        </div>
        <div>
          {dialog?.origin !== 'pbx' ? (
            <Button onClick={toggleStatus}>
              {dialog?.status !== 'closed' ? (
                <CheckCircleFill />
              ) : (
                <CloseCircleFill />
              )}
            </Button>
          ) : null}
        </div>
      </div>
      <Dropdown activeKey={activeKey} onChange={() => setActiveKey(null)}>
        <Dropdown.Item key="content" arrowIcon={null} destroyOnClose={true}>
          <List>
            <ContentChannel dialog={dialog} />
            <DialogOpenedInfo dialogContact={dialogContact} dialog={dialog} />
            <List.Item
              description={t('DialogOpenedHeader.responsible')}
              arrowIcon={[]}
              className={styles.detailsItem}
              extra={responsibleAvatar()}
              onClick={() => {
                setIsOpenResponsiblePopup(true);
                toggleDropdown();
              }}
            >
              <b style={{ fontSize: '13px' }}>{responsibleUser}</b>
            </List.Item>
          </List>
          <div className={styles.line} />
          <List>
            <List.Item
              prefix={<FileOutline />}
              arrowIcon={[]}
              className={styles.detailsItem}
              onClick={() => {
                setIsOpenNoteTextAreaModal(true);
                toggleDropdown();
              }}
            >
              {t('DialogOpenedHeader.addNote')}
            </List.Item>
            <List.Item
              prefix={<FillinOutline />}
              arrowIcon={[]}
              className={styles.detailsItem}
              onClick={() => {
                setIsOpenCommentTextAreaModal(true);
                toggleDropdown();
              }}
            >
              {t('DialogOpenedHeader.addComment')}
            </List.Item>
            <List.Item
              prefix={<EditSOutline />}
              arrowIcon={[]}
              className={styles.detailsItem}
              onClick={() => {
                setIsOpenTopicTextAreaModal(true);
                toggleDropdown();
              }}
            >
              {t('DialogOpenedHeader.changeTopic')}
            </List.Item>
            {dialog?.origin !== 'pbx' ? (
              <List.Item
                prefix={<ClockCircleOutline />}
                arrowIcon={[]}
                className={styles.detailsItem}
                onClick={() => {
                  setSnoozeConversation(true);
                  toggleDropdown();
                }}
              >
                {t('DialogOpenedHeader.snoozeConversation')}
              </List.Item>
            ) : null}
          </List>
        </Dropdown.Item>
      </Dropdown>
      <ResponsiblePopup
        dialog={dialog}
        users={users}
        visible={isOpenResponsiblePopup}
        onClose={setIsOpenResponsiblePopup}
      />
      <ActionSheetWaiting
        visible={snoozeConversation}
        onClose={() => setSnoozeConversation(false)}
        dialog={dialog}
      />
      <TextAreaModal
        isOpen={isOpenNoteTextAreaModal}
        title={t('DialogOpenedHeader.note.title')}
        placeholder={t('DialogOpenedHeader.note.placeholder')}
        save={(text) => {
          dispatch(
            createNote({
              authorId,
              dialogId,
              channelId,
              text: text,
              metadata: {
                text: text,
                html: `<p class="md-block-unstyled">${text}</p>`,
              },
            }),
          );
        }}
        onClose={() => setIsOpenNoteTextAreaModal(false)}
      />
      <TextAreaModal
        isOpen={isOpenCommentTextAreaModal}
        title={t('DialogOpenedHeader.comment.title')}
        placeholder={t('DialogOpenedHeader.comment.placeholder')}
        save={(text) => {
          dispatch(
            createComment({
              authorId,
              dialogId,
              channelId,
              text: text,
              metadata: {
                text: text,
                html: `<p class="md-block-unstyled">${text}</p>`,
              },
            }),
          );
        }}
        onClose={() => setIsOpenCommentTextAreaModal(false)}
      />
      <TextAreaModal
        isOpen={isOpenTopicTextAreaModal}
        title={t('DialogOpenedHeader.topic.title')}
        value={title}
        save={(text) => {
          dispatch(updateDialog({ title: text, id: dialogId }));
        }}
        onClose={() => setIsOpenTopicTextAreaModal(false)}
      />
    </div>
  );
};

export default DialogOpenedHeader;
