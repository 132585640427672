import React, { useRef, useEffect } from 'react';
// eslint-disable-next-line import/no-named-as-default
import Hls from 'hls.js';

const AudioM3u8 = ({ audioSrc, uniqueId }) => {
  const audioRef = useRef(null);

  useEffect(() => {
    if (Hls.isSupported()) {
      const hls = new Hls();
      hls.loadSource(audioSrc);
      hls.attachMedia(audioRef.current);
    }
  }, [audioSrc]);

  return (
    <audio
      ref={audioRef}
      id="audioPlayer__main"
      preload="metadata"
      data-unique-id={uniqueId}
      controls
    >
      Your browser does not support the
      <code>audio</code> element.
    </audio>
  );
};

function AudioWrapper({
  audioSrc,
  uniqueId,
  fileName = 'Аудиозапись',
  contentType = 'audio/mp3',
  styles = {},
}) {
  return (
    <>
      {/\.m3u8(\?|$)/.test(audioSrc) ? (
        <AudioM3u8 audioSrc={audioSrc} uniqueId={uniqueId} />
      ) : (
        <audio
          id="audioPlayer__main"
          preload="metadata"
          src={audioSrc}
          data-unique-id={uniqueId}
          controls
        >
          Your browser does not support the
          <code>audio</code> element.
        </audio>
      )}
    </>
  );
}

export default AudioWrapper;
