import fetchJWT from '../../utils/fetchJWT';
import { logout } from '../../actions';
import backend from '../../configs/backend';

const getCallAttachment = (id, cb) => async (dispatch) => {
  const request = new Request(
    backend.applyBaseUrl(
      `/api/messages/get_record_link?origin=pbx&messageId=${id}`,
    ),
    {
      method: 'GET',
      mode: 'cors',
      cache: 'default',
    },
  );
  fetchJWT(request)
    .then((res) => {
      if (res.ok) {
        return res.text();
      }
      if (res.status === 401) {
        dispatch(logout());
      }
      return null;
    })
    .then((res) => {
      if (res) {
        cb({ url: res });
      }
    })
    .catch((err) => console.error(err));
};

export default getCallAttachment;
