import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { areEqual } from 'react-window';
import { Link } from 'react-router-dom';
import { List, Skeleton } from 'antd-mobile';
import DialogsListItemAvatar from './DialogsListItemAvatar';
import DialogsListItemDescription from './DialogsListItemDescription';
import DialogsListItemTitle from './DialogsListItemTitle';
import './DialogsListItemView.css';

const DialogsListItemView = ({
  itemStyle,
  elRef,
  itemId,
  isSelected,
  showResponsible,
  responsible,
  itemStatus,
  origin,
  avatar,
  lastMessage,
  isDialogAnswered,
  title,
  labels,
  waitingTill,
}) => (
  <div style={itemStyle} className="dialogsListItem">
    {!itemId ? (
      <List.Item className="dialogsListItem__wrapper">
        <Skeleton animated className="dialogsListItemSkeleton" />
      </List.Item>
    ) : (
      <Link to={`/conversations/${itemId}`}>
        <List.Item
          ref={elRef}
          className={`dialogsListItem__wrapper dialogsListItem__wrapper-${itemStatus}`}
          key={itemId}
          prefix={
            <DialogsListItemAvatar avatar={avatar} dialogStatus={itemStatus} />
          }
          description={
            <DialogsListItemDescription
              lastMessage={lastMessage || {}}
              isDialogAnswered={isDialogAnswered}
              labels={labels}
              dialogStatus={itemStatus}
              lastMessageDate={lastMessage.date || ''}
            />
          }
        >
          <DialogsListItemTitle
            origin={origin}
            text={title}
            dialogStatus={itemStatus}
            showResponsible={showResponsible}
            responsible={responsible}
            waitingTill={waitingTill}
          />
        </List.Item>
      </Link>
    )}
  </div>
);

DialogsListItemView.propTypes = {
  itemStyle: PropTypes.object.isRequired,
  elRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.oneOfType([
        PropTypes.instanceOf(Element),
        PropTypes.any,
      ]),
    }),
  ]),
  itemId: PropTypes.string,
  isSelected: PropTypes.bool.isRequired,
  showResponsible: PropTypes.bool.isRequired,
  responsible: PropTypes.object,
  itemStatus: PropTypes.string.isRequired,
  origin: PropTypes.string.isRequired,
  avatar: PropTypes.object.isRequired,
  lastMessage: PropTypes.shape({
    text: PropTypes.string.isRequired,
    date: PropTypes.string,
  }).isRequired,
  isDialogAnswered: PropTypes.bool,
  title: PropTypes.string.isRequired,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  waitingTill: PropTypes.string,
  showAvatar: PropTypes.bool,
};

export default memo(DialogsListItemView, areEqual);
