/* eslint-disable no-console */
import fetchJWT from '../../utils/fetchJWT';
import { logout } from '../../actions';
import { recieveAutomationAcc } from '../../reducers/automation';
import backend from '../../configs/backend';

const getAutomationAcc = (id) => (dispatch) => {
  const request = new Request(
    backend.applyBaseUrl(`/api/automation/read/${id}`),
    {
      method: 'GET',
      mode: 'cors',
      cache: 'default',
    },
  );
  fetchJWT(request)
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
      if (res.status === 401) {
        dispatch(logout());
      }
      return [];
    })
    .then((res) => {
      if (res.length) {
        dispatch(recieveAutomationAcc(res[0]));
      }
    })
    .catch((e) => console.error(e));
};

export default getAutomationAcc;
