import React from 'react';
import PropTypes from 'prop-types';
import { FileOutline, DeleteOutline } from 'antd-mobile-icons';
import './index.css';

const AttachmentsList = ({ attachments, onDeleteFile }) => {
  return (
    <div className="attachments-list">
      {attachments.map((_, index) => (
        <div key={index} className="attachments-list-item">
          <div className="attachments-list-item__name">
            <FileOutline fontSize={24} style={{ flexShrink: 0 }} />
            <div style={{ wordBreak: 'break-all' }}>{_.name || _.filename}</div>
          </div>
          <div className="attachments-list-item__delete">
            <DeleteOutline fontSize={24} onClick={() => onDeleteFile(index)} />
          </div>
        </div>
      ))}
    </div>
  );
};

AttachmentsList.propTypes = {
  attachments: PropTypes.array,
  onDeleteFile: PropTypes.func,
};

export default AttachmentsList;
