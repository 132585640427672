const statusesMap = {
  SHOW_OPENED: 'opened',
  SHOW_CLOSED: 'closed',
  SHOW_MINE: 'responsibleId',
  SHOW_NOT_ASSIGNED_RESP: 'noResponsible',
  SHOW_WAITING: 'waiting',
  SHOW_BOT_ASSIGNED: 'botResponsible',
};

const sortOrderByType = {
  SHOW_OLD: 'asc',
  SHOW_NEW: 'desc',
  SHOW_OLDEST_UPDATED: 'asc',
  SHOW_RECENT_UPDATED: 'desc',
};

const searchParamsAdapter = ({
  offset,
  limit,
  search = '',
  contentSearchMode,
  filterStatus,
  filterChannels,
  sortBy,
  filterResponsibleUsers,
}) => ({
  ...(offset !== undefined ? { offset } : {}),
  ...(limit !== undefined ? { limit } : {}),
  search,
  ...(search && contentSearchMode ? { contentSearchMode } : {}),
  filter: JSON.stringify({
    channelId: filterChannels,
    ...(filterResponsibleUsers
      ? { responsibleIds: filterResponsibleUsers }
      : {}),
  }),
  ...(filterStatus === 'SHOW_ALL'
    ? {}
    : { filteredByStatus: statusesMap[filterStatus] }),
  sort: JSON.stringify({ type: sortBy, order: sortOrderByType[sortBy] }),
});

export default searchParamsAdapter;
