import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { MessageOutline } from 'antd-mobile-icons';
import { useTranslation } from 'react-i18next';

const Comment = ({ item, messageAuthor, messageAuthorDisplay }) => {
  const { t } = useTranslation();
  if (!messageAuthor || !item) {
    return null;
  }

  return (
    <div
      id={item._id}
      style={{
        padding: '6px 5px',
        display: 'flex',
        gap: '6px',
        backgroundColor: 'rgb(255, 248, 220)',
      }}
    >
      <div>
        <MessageOutline fontSize={38} />
      </div>
      <div>
        <div style={{ marginBottom: '5px' }}>
          <b>{messageAuthorDisplay}</b>{' '}
          {moment(item.createdAt).format('DD.MM.YYYY • HH:mm')}
        </div>
        <div style={{ marginBottom: '5px', color: 'rgb(125, 141, 154)' }}>
          {t('Comment.description')}
        </div>
        <div
          style={{ wordBreak: 'break-word' }}
          dangerouslySetInnerHTML={{
            __html:
              item.metadata && item.metadata.html
                ? item.metadata.html
                : item.text,
          }}
        />
      </div>
    </div>
  );
};

Comment.propTypes = {
  item: PropTypes.object,
  messageAuthor: PropTypes.object,
  messageAuthorDisplay: PropTypes.string,
};

export default Comment;
