import fetchJWT from '../../utils/fetchJWT';
import { logout } from '../../actions';
import backend from '../../configs/backend';

/*
 * Выбрасывает ошибку только в случае сетевой ошибки
 * в любом другом случае возвращает json c ответом -
 * результатом отправки (может содержать err:)
 *
 */

const addAttachments = (message, id, origin) => async (dispatch) => {
  const formData = new FormData();

  for (const index in message.attachments) {
    if (index) {
      const att = message.attachments[index];

      if (att.Key || att.key || att.file_id) {
        message.attachments[index] = {
          type: 'attachment',
          contentType: att.contentType,
          size: `${att.size}`,
          filename: att.filename || att.file_name || att.title,
          Key: att.Key || att.key || att.file_id,
        };
      } else {
        formData.append(index, new Blob([att.content]));
        message.attachments[index] = {
          contentDisposition: 'attachment',
          type: 'attachment',
          contentType: att.type,
          size: `${att.size}`,
          filename: att.name,
        };
      }
    }
  }

  formData.append('json_string', JSON.stringify(message));

  const request = new Request(
    backend.applyBaseUrl(
      `/api/messages/create/mail/addAttachments/${id}?origin=${origin}`,
    ),
    {
      method: 'POST',
      body: formData,
    },
  );

  return fetchJWT(request).then((res) => {
    if (res.ok) {
      return res.json();
    }
    if (res.status === 401) {
      dispatch(logout());
      return {};
    }
    throw new Error(res.status);
  });
};

export default addAttachments;
