import * as R from 'ramda';
import { createSelector } from 'reselect';

export const GET_CLIENT_MESSAGES = 'GET_CLIENT_MESSAGES';

function clientMessagesFactory(message) {
  return { [message._id]: message };
}

function clientMessagesReducer(state = {}, action = { type: '' }) {
  switch (action.type) {
    case GET_CLIENT_MESSAGES: {
      return R.mergeAll(R.map(clientMessagesFactory, action.payload.messages));
    }
    default: {
      return state;
    }
  }
}

export const getClientMessagesItems = (messages) => ({
  type: GET_CLIENT_MESSAGES,
  payload: { messages },
});

export default function reducer(state = {}, action = { type: '' }) {
  switch (action.type) {
    case GET_CLIENT_MESSAGES: {
      return R.mergeRight(state, clientMessagesReducer(state, action));
    }
    default: {
      return state;
    }
  }
}

export const clientMessagesSelector = (state) =>
  R.propOr({}, 'clientMessages', state);

export const clientMessagesToArraySelector = createSelector(
  clientMessagesSelector,
  (messages) => R.values(messages),
);
