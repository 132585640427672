import { useCallback, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';

import useLoggedUser from '../../auth/useLoggedUser';
import throttle from '../../../utils/throttle';
import { markMessagesAsRead } from '../../../api/messages';
import { markMessagesAsRead as markMessagesAsReadAction } from '../../../reducers/messages';

const useDialogMessagesListReadState = ({
  dialogId,
  readThrottleTimeout = 500,
}) => {
  const dispatch = useDispatch();
  const { user: { _id: loggedUserId } = {} } = useLoggedUser();

  const canMessageBeRead = useCallback(
    (message = { type: 'message', direction: 'out' }) =>
      (message.type === 'message' &&
        (message.direction === 'in' || message.agent)) ||
      (message.type === 'comment' && message.authorId !== loggedUserId),
    [loggedUserId],
  );
  const isMessageRead = useCallback(
    (message = {}) =>
      message.readAt &&
      Array.isArray(message.readAt) &&
      message.readAt.find((r) => r.userId === loggedUserId),
    [loggedUserId],
  );

  /**
   * Unread messages determining logic
   */
  // TODO -> find more elegant decision
  const unreadMessageIds = useRef([]);

  const markMessagesAsReadApi = useCallback(
    (ids, dialogIds = []) =>
      dispatch(
        markMessagesAsRead({
          ids,
          userId: loggedUserId,
          dialogIds,
          readAt: Date.now(),
        }),
      ),
    [dispatch, loggedUserId],
  );

  const messageIdsMarkedAsRead = useRef([]);

  const readMessages = useCallback(() => {
    const messagesToRead = messageIdsMarkedAsRead.current.slice();
    // clean up state
    messageIdsMarkedAsRead.current = messageIdsMarkedAsRead.current.filter(
      (id) => !messagesToRead.includes(id),
    );

    markMessagesAsReadApi(messagesToRead, [dialogId]);
  }, [messageIdsMarkedAsRead, markMessagesAsReadApi, dialogId]);
  const throttledReadMessages = useMemo(
    () => throttle(readMessages, readThrottleTimeout),
    [readMessages, readThrottleTimeout],
  );

  const markMessagesAsReadInStore = useCallback(
    (messageIds) => {
      dispatch(
        markMessagesAsReadAction({
          messageIds,
          userId: loggedUserId,
          readValue: Date.now(),
        }),
      );
    },
    [dispatch, loggedUserId],
  );

  const processUnreadMessages = useCallback(
    (messageIds) => {
      const idsToRead = messageIds.filter(
        (id) =>
          !messageIdsMarkedAsRead.current.includes(id) &&
          unreadMessageIds.current.includes(id),
      );
      if (idsToRead.length) {
        messageIdsMarkedAsRead.current.push(...idsToRead);
        markMessagesAsReadInStore(idsToRead);
        throttledReadMessages();
      }
    },
    [
      markMessagesAsReadInStore,
      messageIdsMarkedAsRead,
      throttledReadMessages,
      unreadMessageIds,
    ],
  );

  return {
    canMessageBeRead,
    isMessageRead,
    processUnreadMessages,
  };
};

export default useDialogMessagesListReadState;
