const SET_TASKS_FILTER = 'SET_TASKS_FILTER';
const SHOW_ALL_TASKS = 'SHOW_ALL_TASKS';
const SHOW_MINE_TASKS = 'SHOW_MINE_TASKS';
const SHOW_CREATED_BY_ME_TASKS = 'SHOW_CREATED_BY_ME_TASKS';
const SHOW_ARCHIVE_TASKS = 'SHOW_ARCHIVE_TASKS';

export {
  SET_TASKS_FILTER,
  SHOW_ALL_TASKS,
  SHOW_MINE_TASKS,
  SHOW_CREATED_BY_ME_TASKS,
  SHOW_ARCHIVE_TASKS,
};
