import * as R from 'ramda';
import { createSelector } from 'reselect';

export const RECIEVE_VK_ACCOUNTS = 'RECIEVE_VK_ACCOUNTS';
export const RECIEVE_VK_ACCOUNT = 'RECIEVE_VK_ACCOUNT';
export const UPDATE_VK_ACCOUNT = 'UPDATE_VK_ACCOUNT';
export const SET_VK_ACC_NAME = 'SET_VK_ACC_NAME';
export const SET_VK_ACC_ACCESS_TOKEN = 'SET_VK_ACC_ACCESS_TOKEN';
export const SET_VK_ACC_GROUP_ID = 'SET_VK_ACC_GROUP_ID';
export const DELETE_VK_ACCOUNT = 'DELETE_VK_ACCOUNT';
export const DELETE_VK_ACCOUNT_DRAFT = 'DELETE_VK_ACCOUNT_DRAFT';
export const ENABLE_VK_ACCOUNT = 'ENABLE_VK_ACCOUNT';
export const DISABLE_VK_ACCOUNT = 'DISABLE_VK_ACCOUNT';

function vkAccountsFactory(account) {
  return { [account._id]: account };
}

function vkAccountsReducer(state = {}, action = { type: '' }) {
  switch (action.type) {
    case RECIEVE_VK_ACCOUNTS: {
      return R.mergeAll(R.map(vkAccountsFactory, action.payload.vkAccounts));
    }
    case RECIEVE_VK_ACCOUNT: {
      return { ...state, ...action.payload.vkAccount };
    }
    case ENABLE_VK_ACCOUNT: {
      return { ...state, isEnabled: true };
    }
    case DISABLE_VK_ACCOUNT: {
      return { ...state, isEnabled: false };
    }
    case DELETE_VK_ACCOUNT: {
      return { ...state, isDeleted: true };
    }
    case SET_VK_ACC_NAME: {
      return { ...state, name: action.payload.name };
    }
    case SET_VK_ACC_ACCESS_TOKEN: {
      return { ...state, accessToken: action.payload.accessToken };
    }
    case SET_VK_ACC_GROUP_ID: {
      return { ...state, groupId: action.payload.groupId };
    }
    case UPDATE_VK_ACCOUNT: {
      return {
        ...state,
        ...(action.payload.name ? { name: action.payload.name } : {}),
        ...(action.payload.accessToken
          ? { accessToken: action.payload.accessToken }
          : {}),
        ...(action.payload.groupId ? { groupId: action.payload.groupId } : {}),
        ...(action.payload.conf ? { conf: action.payload.conf } : {}),
      };
    }
    default: {
      return state;
    }
  }
}

export const recieveVKAccounts = (vkAccounts) => ({
  type: RECIEVE_VK_ACCOUNTS,
  payload: { vkAccounts },
});

export const recieveVKAccount = (vkAccount) => ({
  type: RECIEVE_VK_ACCOUNT,
  payload: { vkAccount },
});

export const setVkAccName = (_id, name) => ({
  type: SET_VK_ACC_NAME,
  payload: {
    _id,
    name,
  },
});

export const setVkAccAccessToken = (_id, accessToken) => ({
  type: SET_VK_ACC_ACCESS_TOKEN,
  payload: {
    _id,
    accessToken,
  },
});

export const setVkGroupId = (_id, groupId) => ({
  type: SET_VK_ACC_GROUP_ID,
  payload: {
    _id,
    groupId,
  },
});

export const renewVKAccount = ({
  vkAccountId,
  accessToken,
  name,
  groupId,
  conf,
}) => ({
  type: UPDATE_VK_ACCOUNT,
  payload: {
    vkAccountId,
    accessToken,
    name,
    groupId,
    conf,
  },
});

export const removeVKAccount = (_id) => ({
  type: DELETE_VK_ACCOUNT,
  payload: {
    _id,
  },
});

export const deleteVKAccountDraft = () => ({
  type: DELETE_VK_ACCOUNT_DRAFT,
});

export const enableVKAccount = (_id) => ({
  type: ENABLE_VK_ACCOUNT,
  payload: { _id },
});

export const disableVKAccount = (_id) => ({
  type: DISABLE_VK_ACCOUNT,
  payload: { _id },
});

export default function reducer(state = {}, action = { type: '' }) {
  switch (action.type) {
    case RECIEVE_VK_ACCOUNTS: {
      return R.mergeRight(state, vkAccountsReducer(state, action));
    }
    case RECIEVE_VK_ACCOUNT: {
      return R.assoc(
        action.payload.vkAccount._id,
        vkAccountsReducer(state[action.payload.vkAccount._id], action),
      )(state);
    }
    case ENABLE_VK_ACCOUNT:
    case DISABLE_VK_ACCOUNT:
    case DELETE_VK_ACCOUNT:
    case SET_VK_ACC_GROUP_ID:
    case SET_VK_ACC_NAME:
    case SET_VK_ACC_ACCESS_TOKEN: {
      return R.assoc(
        action.payload._id,
        vkAccountsReducer(state[action.payload._id], action),
      )(state);
    }
    case UPDATE_VK_ACCOUNT: {
      return R.assoc(
        action.payload.vkAccountId,
        vkAccountsReducer(state[action.payload.vkAccountId], action),
      )(state);
    }
    case DELETE_VK_ACCOUNT_DRAFT: {
      return R.dissoc('new')(state);
    }
    default: {
      return state;
    }
  }
}

export const vkAccountsSelector = (state) => state.vkAccounts;

export const vkAccountsArraySelector = createSelector(
  vkAccountsSelector,
  (vkAccounts) =>
    R.filter(
      (_) => !_.isDeleted && _._id && _._id !== 'new',
      R.values(vkAccounts),
    ),
);

export const deletedVKAccountsArraySelector = createSelector(
  vkAccountsSelector,
  (vkAccounts) => R.filter((_) => _.isDeleted, R.values(vkAccounts)),
);

export const vkAccountIdSelector = (state, ownProps) => {
  const { params: { channelId } = {} } = ownProps.match;
  return channelId || ownProps.id || ownProps._id;
};

export const vkAccountSelector = createSelector(
  vkAccountsSelector,
  vkAccountIdSelector,
  (accounts, id) => R.propOr({}, id, accounts),
);
