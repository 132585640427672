import React from 'react';
import { NoticeBar } from 'antd-mobile';
import { useTranslation } from 'react-i18next';

const DialogOpenedChannelEnabled = () => {
  const { t } = useTranslation();
  return (
    <NoticeBar
      icon={null}
      wrap
      color="alert"
      content={t('DialogOpenedChannelEnabled.content')}
    />
  );
};

export default DialogOpenedChannelEnabled;
