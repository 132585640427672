import React, {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useRef,
  useImperativeHandle,
  useState,
  useLayoutEffect,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroller';
import { SpinLoading } from 'antd-mobile';
import debounce from '../../../../utils/debounce';
import usePrevious from '../../../../hooks/usePrevious';
import DialogMessagesListItem from './DialogMessagesListItem';
import './DialogMessagesListView.css';

const DialogMessagesListView = forwardRef(
  (
    {
      renderedItemIds = [],
      itemData = {},
      onScrollStop,
      onScrollStopDebounceTimeout = 150,
      loadMore,
      hasMoreToLoad,
    },
    ref,
  ) => {
    const listRef = useRef();

    useImperativeHandle(ref, () => ({
      scrollToEnd() {
        if (listRef.current) {
          listRef.current.scrollTo(0, listRef.current.scrollHeight);
        }
      },
    }));

    const handleOnScrollStop = useCallback(() => {
      if (listRef.current) {
        const listContainerRect = listRef.current.getBoundingClientRect();

        const viewportHeight = listContainerRect.height;
        const containerViewportTopOffset = listContainerRect.top;

        const innerScrollContainer = listRef.current.children[0];

        const visibleListItemIds =
          innerScrollContainer && innerScrollContainer.children
            ? [...innerScrollContainer.children]
                .filter((item) => item.getAttribute('data-item-id'))
                .filter((item) => {
                  const itemViewportTopOffset =
                    item.getBoundingClientRect().top -
                    containerViewportTopOffset;
                  const itemIsVisible =
                    itemViewportTopOffset >= 0 &&
                    itemViewportTopOffset <= viewportHeight;

                  return itemIsVisible;
                })
                .map((item) => item.getAttribute('data-item-id'))
            : [];

        onScrollStop({
          scrollHeight: listRef.current.scrollHeight,
          scrollOffset: listRef.current.scrollTop,
          viewportHeight,
          visibleListItemIds,
        });
      }
    }, [onScrollStop]);

    const debouncedHandleOnScrollStop = useMemo(
      () => debounce(handleOnScrollStop, onScrollStopDebounceTimeout),
      [handleOnScrollStop, onScrollStopDebounceTimeout],
    );

    const handleOnScroll = useCallback(() => {
      if (onScrollStop) {
        debouncedHandleOnScrollStop();
      }
    }, [onScrollStop, debouncedHandleOnScrollStop]);

    /**
     * Trigger scroll stop event on mount
     * in order to process initial list position
     */
    useEffect(() => {
      handleOnScroll();
    }, [handleOnScroll]);

    /**
     * Start-at-bottom logic
     */
    const [startedAtBottom, setStartedAtBottom] = useState(false);

    const prevItemIdsLength = usePrevious(renderedItemIds.length);

    useLayoutEffect(() => {
      if (
        prevItemIdsLength !== renderedItemIds.length &&
        !prevItemIdsLength &&
        listRef.current
      ) {
        setStartedAtBottom(true);
        listRef.current.scrollTop = listRef.current.scrollHeight;
      }
    }, [prevItemIdsLength, renderedItemIds.length]);

    /**
     * Sometimes list shakes after scrollTop change,
     * so we need to adjust it by changing it again
     * for several times
     */
    // TODO -> remove extra scrollTop setup steps
    const prevStartedAtBottom = usePrevious(startedAtBottom);

    useLayoutEffect(() => {
      if (
        prevStartedAtBottom !== startedAtBottom &&
        startedAtBottom &&
        listRef.current
      ) {
        listRef.current.scrollTop = listRef.current.scrollHeight;

        setTimeout(() => {
          if (listRef.current) {
            listRef.current.scrollTop = listRef.current.scrollHeight;
          }
        }, 50);
      }
    }, [prevStartedAtBottom, startedAtBottom]);

    /**
     * We cannot let list to load more items until we move scroll position to bottom
     */
    const canLoadMore = useCallback(
      () => hasMoreToLoad && startedAtBottom,
      [hasMoreToLoad, startedAtBottom],
    );

    const handleLoadMore = useCallback(() => loadMore(), [loadMore]);

    return (
      <div
        ref={listRef}
        className="DialogMessagesListView"
        onScroll={handleOnScroll}
      >
        <InfiniteScroll
          isReverse
          loadMore={handleLoadMore}
          hasMore={canLoadMore()}
          loader={<SpinLoading key="spinLoading" />}
          useWindow={false}
          threshold={15}
          className="DialogMessagesListViewInfiniteScroll"
        >
          {renderedItemIds.map((itemId) => (
            <div
              style={{ overflowAnchor: 'none', margin: '3px 0' }}
              key={`dialogMessagesListItem-${itemId}`}
              data-item-id={itemId}
            >
              <DialogMessagesListItem itemId={itemId} data={itemData} />
            </div>
          ))}
          <div
            key="overflowAnchor"
            style={{ overflowAnchor: 'auto', height: '1px' }}
          />
        </InfiniteScroll>
      </div>
    );
  },
);

DialogMessagesListView.propTypes = {
  renderedItemIds: PropTypes.array.isRequired,
  itemData: PropTypes.object,
  onScrollStop: PropTypes.func,
  onScrollStopDebounceTimeout: PropTypes.number,
  loadMore: PropTypes.func.isRequired,
  hasMoreToLoad: PropTypes.bool.isRequired,
};

DialogMessagesListView.displayName = 'DialogMessagesListView';

export default memo(DialogMessagesListView);
