export const RECIEVE_LABELS = 'RECIEVE_LABELS';
export const CLEAR_LABELS = 'CLEAR_LABELS';

export const recieveLabels = (labels) => ({
  type: RECIEVE_LABELS,
  payload: {
    labels,
  },
});

export const clearLabels = () => ({
  type: CLEAR_LABELS,
  payload: {},
});

const initialLabelsState = [];

export default function reducer(
  state = initialLabelsState,
  action = { type: '' },
) {
  switch (action.type) {
    case RECIEVE_LABELS: {
      return action.payload.labels;
    }
    case CLEAR_LABELS: {
      return [];
    }
    default: {
      return state;
    }
  }
}

export const labelsSelector = (state) => state.labels || initialLabelsState;

export const systemLabelsSelector = (state) =>
  state.labels.filter((label) => label.system);

export const nonSystemLabelsSelector = (state) =>
  state.labels.filter((label) => !label.system);
