import * as R from 'ramda';

export const RECIEVE_DIALOGS_STAT_DATA = 'RECIEVE_DIALOGS_STAT_DATA';
export const RECIEVE_MESSAGES_STAT_DATA = 'RECIEVE_MESSAGES_STAT_DATA';
export const RECIEVE_CALLS_STAT_DATA = 'RECIEVE_CALLS_STAT_DATA';
export const RECIEVE_CHANNELS_STAT_DATA = 'RECIEVE_CHANNELS_STAT_DATA';
export const RECIEVE_RATING_STAT_DATA = 'RECIEVE_RATING_STAT_DATA';
export const RECIEVE_TIMING_STAT_DATA = 'RECIEVE_TIMING_STAT_DATA';
export const RECIEVE_CLIENTS_STAT_DATA = 'RECIEVE_CLIENTS_STAT_DATA';
export const RECIEVE_EVENTS_STAT_DATA = 'RECIEVE_EVENTS_STAT_DATA';
export const SET_CURRENT_STAT_FILTER = 'SET_CURRENT_STAT_FILTER';
export const SET_STAT_DATA_LOADING_ON = 'SET_STAT_DATA_LOADING_ON';

function statReducer(state = {}, action = { type: '' }) {
  switch (action.type) {
    case RECIEVE_EVENTS_STAT_DATA: {
      return {
        ...state,
        eventsStat: action.payload.data,
        ...(state.loading.events
          ? { loading: { ...state.loading, events: false } }
          : {}),
      };
    }
    case RECIEVE_DIALOGS_STAT_DATA: {
      return {
        ...state,
        dialogsStat: action.payload.data,
        ...(state.loading.dialogs
          ? { loading: { ...state.loading, dialogs: false } }
          : {}),
      };
    }
    case RECIEVE_MESSAGES_STAT_DATA: {
      return {
        ...state,
        messagesStat: action.payload.data,
        ...(state.loading.messages
          ? { loading: { ...state.loading, messages: false } }
          : {}),
      };
    }
    case RECIEVE_CHANNELS_STAT_DATA: {
      return {
        ...state,
        channelsStat: action.payload.data,
        ...(state.loading.channels
          ? { loading: { ...state.loading, channels: false } }
          : {}),
      };
    }
    case RECIEVE_CALLS_STAT_DATA: {
      return {
        ...state,
        callsStat: action.payload.data,
        ...(state.loading.calls
          ? { loading: { ...state.loading, calls: false } }
          : {}),
      };
    }
    case RECIEVE_RATING_STAT_DATA: {
      return {
        ...state,
        ratingStat: action.payload.data,
        ...(state.loading.rating
          ? { loading: { ...state.loading, rating: false } }
          : {}),
      };
    }
    case RECIEVE_TIMING_STAT_DATA: {
      return {
        ...state,
        timingStat: action.payload.data,
        ...(state.loading.timing
          ? { loading: { ...state.loading, timing: false } }
          : {}),
      };
    }
    case RECIEVE_CLIENTS_STAT_DATA: {
      return {
        ...state,
        clientsStat: action.payload.data,
        ...(state.loading.clients
          ? { loading: { ...state.loading, clients: false } }
          : {}),
      };
    }
    case SET_CURRENT_STAT_FILTER: {
      return { ...state, filter: action.payload.filter };
    }
    case SET_STAT_DATA_LOADING_ON: {
      return {
        ...state,
        loading: {
          ...(state.loading || {}),
          [action.payload.statDataType]: true,
        },
      };
    }
    default: {
      return state;
    }
  }
}

export const recieveEventsStat = (data) => ({
  type: RECIEVE_EVENTS_STAT_DATA,
  payload: {
    data,
  },
});

export const recieveDialogsStat = (data) => ({
  type: RECIEVE_DIALOGS_STAT_DATA,
  payload: {
    data,
  },
});

export const recieveMessagesStat = (data) => ({
  type: RECIEVE_MESSAGES_STAT_DATA,
  payload: {
    data,
  },
});

export const recieveChannelsStat = (data) => ({
  type: RECIEVE_CHANNELS_STAT_DATA,
  payload: {
    data,
  },
});

export const recieveCallsStat = (data) => ({
  type: RECIEVE_CALLS_STAT_DATA,
  payload: {
    data,
  },
});

export const recieveRatingStat = (data) => ({
  type: RECIEVE_RATING_STAT_DATA,
  payload: {
    data,
  },
});

export const recieveTimingStat = (data) => ({
  type: RECIEVE_TIMING_STAT_DATA,
  payload: {
    data,
  },
});

export const recieveClientsStat = (data) => ({
  type: RECIEVE_CLIENTS_STAT_DATA,
  payload: {
    data,
  },
});

export const setCurrentStatFilter = (filter) => ({
  type: SET_CURRENT_STAT_FILTER,
  payload: {
    filter,
  },
});

export const setStatItemsLoadingOn = (statDataType) => ({
  type: SET_STAT_DATA_LOADING_ON,
  payload: {
    statDataType,
  },
});

export default function reducer(
  state = {
    loading: {
      clients: true,
      messages: true,
      dialogs: true,
      channels: true,
      rating: true,
      timing: true,
      calls: true,
      events: true,
    },
  },
  action = { type: '' },
) {
  switch (action.type) {
    case RECIEVE_DIALOGS_STAT_DATA:
    case RECIEVE_CHANNELS_STAT_DATA:
    case RECIEVE_CALLS_STAT_DATA:
    case RECIEVE_RATING_STAT_DATA:
    case RECIEVE_TIMING_STAT_DATA:
    case RECIEVE_MESSAGES_STAT_DATA:
    case RECIEVE_CLIENTS_STAT_DATA:
    case RECIEVE_EVENTS_STAT_DATA:
    case SET_STAT_DATA_LOADING_ON:
    case SET_CURRENT_STAT_FILTER: {
      return R.mergeRight(state, statReducer(state, action));
    }
    default: {
      return state;
    }
  }
}

export const statSelector = (state) => state.stat;
