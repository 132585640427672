import * as R from 'ramda';
import { createSelector } from 'reselect';
import { getDialogsArraySelector } from '../reducers/dialogs';
import { clientsArraySelector } from '../reducers/clients';
import {
  getCallsFilters,
  getCallsChannelFilters,
  SHOW_NEW_CALLS,
  SHOW_OLD_CALLS,
  SHOW_RECENT_UPDATED_CALLS,
} from '../reducers/callsFilters';
import checkFilterSearchCond from '../utils/checkFilterSearchCond';

const getUserIdSelector = (state) =>
  R.pathOr({}, ['auth', 'user', '_id'], state);

const sortByParams = (params) => {
  const sortingFunction = R.sortBy(
    R.path(
      params === SHOW_NEW_CALLS || params === SHOW_OLD_CALLS
        ? ['metadata', 'lastMessageDate']
        : ['updatedAt'],
    ),
  );
  if (params === SHOW_NEW_CALLS || params === SHOW_RECENT_UPDATED_CALLS) {
    return R.compose(R.reverse, sortingFunction);
  }
  return sortingFunction;
};

const statusesMap = {
  SHOW_IN_CALLS: 'inbound',
  SHOW_OUT_CALLS: 'outbound',
};

const filterByParams = (params) => {
  const {
    search,
    filterStatus,
    filterChannels = [],
    userId,
    clients,
    responsibleUsers = [],
  } = params;

  if (filterStatus === 'SHOW_MINE_CALLS') {
    return R.filter(
      (_) =>
        checkFilterSearchCond({
          search,
          entityType: 'call',
          item: _,
          clients,
        }) &&
        R.prop('responsibleId', _) === userId &&
        R.prop('origin', _) === 'pbx' &&
        (filterChannels.includes(R.prop('channelId', _)) ||
          R.prop('responsibleId', _) === userId),
    );
  }

  if (filterStatus === 'SHOW_ALL_CALLS') {
    if (responsibleUsers.length) {
      return R.filter(
        (_) =>
          checkFilterSearchCond({
            search,
            entityType: 'call',
            item: _,
            clients,
          }) &&
          R.prop('origin', _) === 'pbx' &&
          responsibleUsers.includes(R.prop('responsibleId', _)) &&
          filterChannels.includes(R.prop('channelId', _)),
      );
    }
    return R.filter(
      (_) =>
        checkFilterSearchCond({
          search,
          entityType: 'call',
          item: _,
          clients,
        }) &&
        R.prop('origin', _) === 'pbx' &&
        filterChannels.includes(R.prop('channelId', _)),
    );
  }

  if (filterStatus === 'SHOW_NOT_ASSIGNED_RESP_CALLS') {
    return R.filter(
      (_) =>
        checkFilterSearchCond({
          search,
          entityType: 'call',
          item: _,
          clients,
        }) &&
        R.prop('origin', _) === 'pbx' &&
        !R.prop('responsibleId', _) &&
        R.prop('status', _) === 'opened' &&
        filterChannels.includes(R.prop('channelId', _)),
    );
  }
  if (responsibleUsers.length) {
    return R.filter(
      (_) =>
        checkFilterSearchCond({
          search,
          entityType: 'call',
          item: _,
          clients,
        }) &&
        R.prop('origin', _) === 'pbx' &&
        (filterStatus === 'SHOW_MISSED_CALLS'
          ? R.prop('event', _) === 'call_missed'
          : R.prop('direction', _) === statusesMap[filterStatus]) &&
        filterChannels.includes(R.prop('channelId', _)) &&
        responsibleUsers.includes(R.prop('responsibleId', _)),
    );
  }

  return R.filter(
    (_) =>
      checkFilterSearchCond({
        search,
        entityType: 'call',
        item: _,
        clients,
      }) &&
      R.prop('origin', _) === 'pbx' &&
      (filterStatus === 'SHOW_MISSED_CALLS'
        ? R.prop('event', _) === 'call_missed'
        : R.prop('direction', _) === statusesMap[filterStatus]) &&
      filterChannels.includes(R.prop('channelId', _)),
  );
};

const getFilteredCallsDialogs = createSelector(
  getDialogsArraySelector,
  getCallsFilters,
  getCallsChannelFilters,
  getUserIdSelector,
  clientsArraySelector,
  (dialogs, filter, filterChannels, userId, clients) => {
    const { search = '', filterStatus, sortBy, responsibleUsers } = filter;
    const sortFn = sortByParams(sortBy);
    const filterFn = filterByParams({
      search,
      filterStatus,
      filterChannels,
      userId,
      clients,
      responsibleUsers,
    });
    const filteredDialogs = sortFn(
      filterFn(dialogs.filter((d) => d.origin === 'pbx')),
    );

    return filteredDialogs;
  },
);

export default getFilteredCallsDialogs;
