const isMessageAuthorAgent = (messageAuthor) => {
  if (!messageAuthor || !messageAuthor.contacts) {
    return false;
  }
  let guess = false;

  messageAuthor.contacts.forEach((contact) => {
    if (contact.agent) {
      guess = true;
    }
  });

  return guess;
};

export default isMessageAuthorAgent;
