import fetchJWT from '../../utils/fetchJWT';
import { logout } from '../../actions';
import backend from '../../configs/backend';
import { addItemsToUnreadRefreshStore } from '../../reducers/messageCounters';

const markMessagesAsRead =
  ({ ids = [], dialogIds = [], userId, readAt }) =>
  (dispatch) => {
    const request = new Request(
      backend.applyBaseUrl(`/api/messages/mark/read`),
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ids,
          userId,
          readAt,
        }),
      },
    );

    fetchJWT(request)
      .then((res) => {
        if (res.ok) {
          return {};
        }
        if (res.status === 401) {
          dispatch(logout());
        }
        return {};
      })
      .then(() => {
        dispatch(addItemsToUnreadRefreshStore(dialogIds));
      })
      .catch((err) => console.error(err));
  };

export default markMessagesAsRead;
