/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Popup,
  DotLoading,
  List,
  SearchBar,
  Ellipsis,
  ErrorBlock,
} from 'antd-mobile';
import { GlobalOutline } from 'antd-mobile-icons';
import { useTranslation } from 'react-i18next';

import messageCreatorSelector from '../../../../selectors/messageCreatorSelector';
import { getTemplates } from '../../../../api/templates';

const TemplateListSelectionView = ({
  insertTemplateText,
  searchTemplate = '',
  onClose,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const mainUser = useSelector((state) => messageCreatorSelector(state));
  const templates = useSelector((state) => Object.values(state.templates));
  const [loadingTemplate, setLoadingTemplate] = useState(false);

  useEffect(() => {
    if (!templates.length) {
      setLoadingTemplate(true);
      dispatch(getTemplates(() => setLoadingTemplate(false)));
    }
  }, [dispatch]);

  if (loadingTemplate) {
    return <DotLoading />;
  }

  const handleTemplateItemClick = (text) => {
    insertTemplateText(text);
    onClose();
  };

  const templatesArr = templates.filter(
    (i) =>
      i.type === 'public' ||
      (mainUser && i.type === 'private' && i.userId === mainUser._id),
  );

  if (!templatesArr.length) {
    return (
      <div style={{ padding: '15px' }}>
        <ErrorBlock
          status="empty"
          title={t('TemplateListSelection.empty')}
          description=""
        />
      </div>
    );
  }

  const filteredTemplates = searchTemplate
    ? templatesArr.filter(
        (i) => i.title.toLowerCase().indexOf(searchTemplate.toLowerCase()) >= 0,
      )
    : templatesArr;

  if (!filteredTemplates.length) {
    return (
      <div style={{ padding: '15px' }}>
        <ErrorBlock
          status="empty"
          title={t('TemplateListSelection.notFound')}
          description=""
        />
      </div>
    );
  }

  return (
    <List>
      {filteredTemplates.map(({ _id, title, type, text }) => (
        <List.Item
          key={_id}
          arrowIcon={
            type === 'public' ? (
              <GlobalOutline fontSize={25} style={{ display: 'block' }} />
            ) : (
              []
            )
          }
          onClick={() => handleTemplateItemClick(text)}
        >
          <Ellipsis direction="end" content={title} />
        </List.Item>
      ))}
    </List>
  );
};

const TemplateListSelection = ({ insertTemplateText, visible, onClose }) => {
  const { t } = useTranslation();
  const [searchTemplate, setSearchTemplate] = useState('');

  return (
    <>
      <Popup
        visible={visible}
        destroyOnClose
        onMaskClick={onClose}
        afterClose={() => setSearchTemplate('')}
      >
        <div style={{ maxHeight: '50vh', overflowY: 'auto' }}>
          <TemplateListSelectionView
            insertTemplateText={insertTemplateText}
            searchTemplate={searchTemplate}
            onClose={onClose}
          />
        </div>
        <div
          style={{
            padding: '12px',
            borderTop: 'solid 1px var(--adm-color-border)',
          }}
        >
          <SearchBar
            placeholder={t('TemplateListSelection.search')}
            value={searchTemplate}
            onChange={setSearchTemplate}
          />
        </div>
      </Popup>
    </>
  );
};

export default TemplateListSelection;
