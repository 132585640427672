import { useCallback, useState, useEffect } from 'react';

import useErrorState from '../useErrorState';
import useDialogsListPositions from './useDialogsListPositions';
import useDialogsListMode from './useDialogsListMode';
import useDialogsListSearch from './useDialogsListSearch';
import useDialogsListChannelFilters from './useDialogsListChannelFilters';
import useDialogsListFilterStatus from './useDialogsListFilterStatus';
import useDialogsListSort from './useDialogsListSort';
import useDialogsListStatusCounters from './useDialogsListStatusCounters';
import useDialogsListLoadMore from './useDialogsListLoadMore';
import useDialogsListResponsibleUsersFilter from './useDialogsListResponsibleUsersFilter';
import usePrevious from '../usePrevious';

/**
 * @description Hook that provides all necessary data and functions for dialogs list.
 */
const useDialogsListData = () => {
  const { error, handleError: handleReqError } = useErrorState();

  const [loading, setLoading] = useState(true);

  const {
    dialogsListPositions,
    itemCount,
    requestDialogsListPositionsRefresh,
    mapItemIdToIndex,
    mapIndexToItemId,
  } = useDialogsListPositions({
    handleReqError,
  });

  /**
   * Handle list positions loading state
   */
  const prevDialogsListPositions = usePrevious(dialogsListPositions);
  useEffect(() => {
    if (loading && dialogsListPositions !== prevDialogsListPositions) {
      setLoading(false);
    }
  }, [dialogsListPositions, prevDialogsListPositions, loading]);

  /**
   * List positions refresh handler:
   * 1. Sets loading state
   * 2. Requests positions refresh
   */
  const handleListPositionsRefresh = useCallback(() => {
    if (!loading) {
      setLoading(true);
      requestDialogsListPositionsRefresh();
    }
  }, [requestDialogsListPositionsRefresh, loading]);

  /**
   * Request dialogs list positions refresh on list filters/sort change
   */
  useDialogsListMode({ onModeChange: handleListPositionsRefresh });
  useDialogsListSearch({ onSearchChange: handleListPositionsRefresh });
  useDialogsListChannelFilters({
    onChannelFiltersChange: handleListPositionsRefresh,
  });
  const { filterStatus: currentFilterStatus } = useDialogsListFilterStatus({
    onFilterStatusChange: handleListPositionsRefresh,
  });
  useDialogsListSort({
    onSortChange: handleListPositionsRefresh,
  });
  useDialogsListStatusCounters({
    onStatusCountersChange: requestDialogsListPositionsRefresh,
    currentFilterStatus,
  });
  useDialogsListResponsibleUsersFilter({
    onResponsibleUsersFilterChange: handleListPositionsRefresh,
  });

  const { loadMore } = useDialogsListLoadMore({ handleReqError });

  return {
    loadMore,
    mapItemIdToIndex,
    mapIndexToItemId,
    itemCount,
    hasContent: itemCount === null || itemCount > 0,
    error,
    loading,
  };
};

export default useDialogsListData;
