/* eslint-disable no-console */
import fetchJWT from '../../utils/fetchJWT';
import { getClientEventsItems } from '../../reducers/client-events';
import { logout } from '../../actions';
import backend from '../../configs/backend';

const getContactEvents =
  (
    clientId,
    contactsId,
    { offset, limit, cb } = { offset: 0, limit: 50, cb: () => null },
  ) =>
  (dispatch) => {
    const request = new Request(
      backend.applyBaseUrl(
        `/api/messages/read/client/events/${
          clientId ? 'byClientId' : 'byContactsId'
        }/${clientId || contactsId}?offset=${offset}&limit=${limit}`,
      ),
      {
        method: 'GET',
        cache: 'default',
        mode: 'cors',
      },
    );
    fetchJWT(request)
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        if (res.status === 401) {
          dispatch(logout());
        }
        return {};
      })
      .then((res) => {
        if (res && typeof res === 'object' && res.items) {
          dispatch(getClientEventsItems(res.items || []));
          cb(res.hasMoreItems ? res.items.length || 0 : 0);
        } else {
          cb(0);
        }
      })
      .catch((err) => console.error(err));
  };

export default getContactEvents;
