import fetchJWT from '../../utils/fetchJWT';
import { logout } from '../../actions';
import { getContacts } from '../contacts';
import { recieveDialogParticipants } from '../../reducers/dialog-participants';
import backend from '../../configs/backend';

const getDialogParticipants =
  ({ dialogIdArray = [] }) =>
  async (dispatch, getState) => {
    const { dialogParticipants = [] } = await getState();

    const dialogIdsForReq = dialogIdArray.filter(
      (dialogId) => !Object.keys(dialogParticipants).includes(dialogId),
    );

    if (!dialogIdsForReq.length) {
      return [];
    }

    const iterationDelimiter = 150;
    let iterationsCount = 1;

    if (
      dialogIdsForReq &&
      dialogIdsForReq.length &&
      dialogIdsForReq.length > iterationDelimiter
    ) {
      iterationsCount = Math.ceil(dialogIdsForReq.length / iterationDelimiter);
    }

    const performRequest = (startIndex, endIndex) => {
      const request = new Request(
        backend.applyBaseUrl(
          `/api/messages/read/dialog/participants/${dialogIdsForReq
            .slice(startIndex, endIndex)
            .join(',')}`,
        ),
        { method: 'GET', mode: 'cors', cache: 'default' },
      );
      fetchJWT(request)
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          if (res.status === 401) {
            dispatch(logout());
          }
          return [];
        })
        .then(async (res) => {
          const contactsIdArray = Object.values(res).reduce((acc, v) => {
            const newAcc = new Set(acc);
            if (v.length) {
              v.forEach((vValue) => newAcc.add(vValue));
            }
            return [...newAcc];
          }, []);

          if (contactsIdArray.length) {
            const { clients } = await getState();
            const clientIdsForReq = new Set();

            contactsIdArray.forEach((contactsId) => {
              if (
                !Object.values(clients).find(
                  (c) =>
                    c.contacts &&
                    c.contacts.find((contact) => contact.id === contactsId),
                )
              ) {
                clientIdsForReq.add(contactsId);
              }
            });

            if ([...clientIdsForReq].length) {
              dispatch(getContacts('', [...clientIdsForReq]));
            }
          }

          dispatch(recieveDialogParticipants(res));

          iterationsCount -= 1;

          if (iterationsCount > 0) {
            performRequest(
              startIndex + iterationDelimiter,
              endIndex + iterationDelimiter,
            );
          }
        })
        .catch((e) => {
          console.error(e);
          return [];
        });
    };

    performRequest(0, iterationDelimiter);
  };

export default getDialogParticipants;
