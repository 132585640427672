const analyticsConfig = {
  utm: {
    interestedInRoutes: ['/signin', '/signup', '/forgot'],
    queryParameterRegexp: /^utm_.*=.*$/,
    storage: {
      key: 'registrationUtmParameters',
    },
  },
  allowedDomains: [
    'app.yeahdesk.ru',
    'develop.yeahdesk.ru',
    'm.yeahdesk.ru',
    'm.develop.yeahdesk.ru',
  ],
  events: {
    confirmRegistration: 'confirm_registration',
    chooseChannel: 'choose_channel',
    addChannel: 'add_channel',
    failChannel: 'fail_channel',
    triggeredAction: 'triggered_action',
  },
  actionTypes: {
    outcomingMessageCreation: 'outcoming_message_creation',
    noteCreation: 'note_creation',
    commentCreation: 'comment_creation',
    dialogStatusChange: 'dialog_status_change',
    dialogTitleChange: 'dialog_title_change',
    responsibleChange: 'responsible_change',
  },
  sdks: [
    {
      type: 'gtm',
      elements: [
        {
          type: 'script',
          innerHtml: (function (w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({
              'gtm.start': new Date().getTime(),
              event: 'gtm.js',
            });
            var f = d.getElementsByTagName(s)[0],
              j = d.createElement(s),
              dl = l !== 'dataLayer' ? '&l=' + l : '';
            j.async = true;
            j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
            f.parentNode.insertBefore(j, f);
          })(window, document, 'script', 'dataLayer', 'GTM-WPBD8B5'),
          attributes: [],
          insertTo: {
            selectorType: 'tagName',
            queryValue: 'head',
          },
        },
        {
          type: 'iframe',
          innerHtml: null,
          attributes: [
            ['src', 'https://www.googletagmanager.com/ns.html?id=GTM-WPBD8B5'],
            ['height', '0'],
            ['width', '0'],
            ['style', 'display:none;visibility:hidden'],
          ],
          insertTo: {
            selectorType: 'id',
            queryValue: 'gtmBody',
          },
        },
      ],
      postInsertAction: null,
    },
  ],
};

export default analyticsConfig;
