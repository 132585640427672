import { receiveMessage } from '../../reducers/messages';
import fetchJWT from '../../utils/fetchJWT';
import { logout } from '../../actions';
import { getContactEvents, getContacts } from '../contacts';
import backend from '../../configs/backend';

const getMessage = (messageId) => async (dispatch, getState) => {
  const request = new Request(
    backend.applyBaseUrl(`/api/messages/read/?id=${messageId}`),
    {
      method: 'GET',
      cache: 'default',
      mode: 'cors',
    },
  );
  fetchJWT(request)
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
      if (res.status === 401) {
        dispatch(logout());
      }
      return {};
    })
    .then((res) => {
      if (res.length) {
        const [message] = res;
        const { contactsId } = message;

        const reduxState = getState();
        if (contactsId) {
          const { clients = {} } = reduxState;

          const cachedClient = Object.values(clients).find(
            (c) =>
              c.contacts &&
              c.contacts.find((contact) => contact.id === contactsId),
          );

          if (!cachedClient) {
            dispatch(getContacts('', [contactsId]));
          }
          if (window.location.pathname.match(/clients\/contacts/i)) {
            dispatch(getContactEvents(null, contactsId));
          }
        }

        dispatch(receiveMessage(messageId, message));
      }
    })
    .catch((err) => console.error(err));
};

export default getMessage;
