import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  setFilterChannels,
  setFilterStatuses,
  setFilterResponsibleUsers,
  getDialogsChannelFilters,
  SHOW_ALL,
  dialogsListFilterResponsibleUsersSelector,
  SHOW_OPENED,
} from '../../../../../reducers/dialogsFilters';
import {
  getDialogsCountOfStatus,
  getDialogs,
} from '../../../../../api/dialogs';
import readMailAccounts from '../../../../../api/mail-accounts/readMailAccounts';
import { getChatraAccounts } from '../../../../../api/chatra-accounts';
import { getTelegramAccounts } from '../../../../../api/telegram-accounts';
import { getDemoAccounts } from '../../../../../api/demo-accounts';
import { getDialogsArraySelector } from '../../../../../reducers/dialogs';
import { getWhatsAppAccounts } from '../../../../../api/whatsapp-accounts';
import { getVKAccounts } from '../../../../../api/vk-accounts';
import { getSkypeAccounts } from '../../../../../api/skype-accounts';
import { getPbxAccounts } from '../../../../../api/pbx-accounts';
import { getFbAccounts } from '../../../../../api/facebook-accounts';
import { getIgAccounts } from '../../../../../api/instagram-accounts';
import getActiveChannelsFromState from '../../../../../selectors/getActiveChannelsFromState';
import {
  getFilteredCallsDialogs,
  getFilteredDialogs,
} from '../../../../../selectors';
import { setCacheSessionSaved } from '../../../../../reducers/cache-status';
import { getCustomAccounts } from '../../../../../api/custom-accounts';
import {
  channels as channelsConf,
  components as componentsConf,
} from '../../../../../configs';
// import DialogFilterSections from './DialogFilterSections';
// import DialogFilterStatusTabs from './DialogFilterStatusTabs';

import {
  getCallsChannelFilters,
  callsListFilterResponsibleUsersSelector,
  SHOW_ALL_CALLS,
} from '../../../../../reducers/callsFilters';
import { dialogsListModeSelector } from '../../../../../reducers/dialogsListMode';
import {
  deletedPbxAccountsArraySelector,
  pbxAccountsArraySelector,
} from '../../../../../reducers/pbx-accounts';
import { getWabaAccounts } from '../../../../../api/whatsapp-accounts/i2crm/waba';
import getDeletedChannelsFromState from '../../../../../selectors/getDeletedChannelsFromState';
import checkUserRightsForEntity from '../../../../../utils/checkUserRightsForEntity';
import { loggedUserSelector } from '../../../../../reducers/auth';
import { getGreenApiAccounts } from '../../../../../api/whatsapp-accounts/greenApi';
import DialogFilterStatusTabs from './DialogFilterStatusTabs';

class DialogFilter extends Component {
  static displayName = 'DialogFilter';

  static propTypes = {
    children: PropTypes.node,
    user: PropTypes.object,
    userForChannels: PropTypes.object,
    dialogsListMode: PropTypes.string.isRequired,
    setFilterChannels: PropTypes.func,
    setFilterStatuses: PropTypes.func,
    statusCounters: PropTypes.object,
    filterChannels: PropTypes.array,
    filterResponsibleUsers: PropTypes.array,
    filterStatus: PropTypes.string,
    channels: PropTypes.array,
    dialogs: PropTypes.array,
    readMailAccounts: PropTypes.func,
    getChatraAccounts: PropTypes.func,
    getDemoAccounts: PropTypes.func,
    getWhatsAppAccounts: PropTypes.func,
    getTelegramAccounts: PropTypes.func,
    getVKAccounts: PropTypes.func,
    getSkypeAccounts: PropTypes.func,
    getPbxAccounts: PropTypes.func,
    getDialogsCountOfStatus: PropTypes.func,
    getFbAccounts: PropTypes.func,
    getIgAccounts: PropTypes.func,
    getCustomAccounts: PropTypes.func,
    activeChannels: PropTypes.array,
    setCacheSessionSaved: PropTypes.func,
    cachedSession: PropTypes.bool,
    getOnboardingDialog: PropTypes.func,
    count: PropTypes.number,
    user_logged_in: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    users: PropTypes.array,
    deletedChannels: PropTypes.array,
    setFilterResponsibleUsers: PropTypes.func,
  };

  constructor() {
    super();

    const {
      pages: {
        dialogs: {
          filter: {
            statusLabels: {
              dialogs: { mainFilter: dialogsStatusLabels },
              calls: { mainFilter: callsStatusLabels },
            },
          },
        },
      },
    } = componentsConf;

    this.state = {
      firstVisit: false,
      sendOnboardingDialogRequest: false,
      popoverVisible: false,
      isExtendedFilterSelected: false,
      channelsCounter: null,
    };

    this.documentTitleByFilterStatus = {
      SHOW_ALL: dialogsStatusLabels.SHOW_ALL,
      SHOW_MISSED: `${dialogsStatusLabels.SHOW_MISSED} диалоги`,
      SHOW_MINE: dialogsStatusLabels.SHOW_MINE,
      SHOW_WAITING: `${dialogsStatusLabels.SHOW_WAITING} диалоги`,
      SHOW_OPENED: `${dialogsStatusLabels.SHOW_OPENED} диалоги`,
      SHOW_CLOSED: `${dialogsStatusLabels.SHOW_CLOSED} диалоги`,
      SHOW_NOT_ASSIGNED_RESP: `Неназначенные диалоги`,
      SHOW_BOT_ASSIGNED: 'Обработка ботом',
      SHOW_MINE_CALLS: callsStatusLabels.SHOW_MINE_CALLS,
      SHOW_IN_CALLS: `${callsStatusLabels.SHOW_IN_CALLS} звонки`,
      SHOW_OUT_CALLS: `${callsStatusLabels.SHOW_OUT_CALLS} звонки`,
      SHOW_NOT_ASSIGNED_RESP_CALLS: `Неназначенные звонки`,
      SHOW_MISSED_CALLS: `${callsStatusLabels.SHOW_MISSED_CALLS} звонки`,
      SHOW_ALL_CALLS: callsStatusLabels.SHOW_ALL_CALLS,
    };
  }

  componentDidMount() {
    // const {
    //   instagram: { types: igChTypes },
    // } = channelsConf;

    // get channels

    // if (!this.props.channels.find((ch) => ch.channelType === 'telegram')) {
    //   this.props.getTelegramAccounts();
    // }

    // if (!this.props.channels.find((ch) => ch.channelType === 'fb')) {
    //   this.props.getFbAccounts();
    // }

    // if (!this.props.channels.find((ch) => ch.channelType === 'chatra')) {
    //   this.props.getChatraAccounts();
    // }

    // if (!this.props.channels.find((ch) => ch.channelType === 'skype')) {
    //   this.props.getSkypeAccounts();
    // }

    // if (!this.props.channels.find((ch) => ch.channelType === 'email')) {
    //   this.props.readMailAccounts();
    // }

    // if (!this.props.channels.find((ch) => ch.channelType === 'demo')) {
    //   this.props.getDemoAccounts();
    // }

    // if (!this.props.channels.find((ch) => ch.channelType === 'whatsapp')) {
    //   this.props.getWhatsAppAccounts();
    // }

    // if (!this.props.channels.find((ch) => ch.channelType === 'vk')) {
    //   this.props.getVKAccounts();
    // }

    // if (!this.props.channels.find((ch) => ch.channelType === 'pbx')) {
    //   this.props.getPbxAccounts();
    // }
    // if (!this.props.channels.find((ch) => ch.channelType === 'custom')) {
    //   this.props.getCustomAccounts();
    // }
    // if (
    //   !this.props.channels.find((ch) => ch.channelType === 'ig') ||
    //   !this.props.channels.find((ch) => ch.channelType === 'ig2')
    // ) {
    //   igChTypes.forEach((type) => this.props.getIgAccounts(type));
    // }

    this.setChannelCounter();

    if (
      this.props.filterResponsibleUsers.length ||
      this.state.channelsCounter !== this.props.filterChannels.length
    ) {
      this.setState({ isExtendedFilterSelected: true });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // set channel counter when we get all channels
    if (
      this.props.channels.length !== prevProps.channels.length ||
      this.props.deletedChannels.length !== prevProps.deletedChannels.length
    ) {
      this.setChannelCounter();
    }

    if (
      this.props.dialogsListMode !== prevProps.dialogsListMode &&
      this.props.dialogsListMode === 'calls' &&
      this.state.isExtendedFilterSelected === true
    ) {
      if (this.state.isExtendedFilterSelected === true) {
        this.setState({ isExtendedFilterSelected: false });
      }
      this.props.setFilterChannels(
        [...this.props.activeChannels, ...this.props.deletedChannels]
          .filter((ch) =>
            checkUserRightsForEntity({
              entity: ch,
              entityType: 'channel',
              user: this.props.user,
            }),
          )
          .map((ch) => ch._id),
        this.props.user,
      );
    }

    // when we set extra filters, change main filter to all dialogs and add classes to dialog filter header
    if (
      (this.props.filterResponsibleUsers.length !== 0 &&
        this.props.filterResponsibleUsers.length !==
          prevProps.filterResponsibleUsers.length) ||
      ((this.state.channelsCounter !== prevState.channelsCounter ||
        this.props.filterChannels.length !== prevProps.filterChannels.length) &&
        this.state.channelsCounter !== this.props.filterChannels.length)
    ) {
      this.setState({ isExtendedFilterSelected: true });
      this.props.setFilterStatuses(SHOW_OPENED);
    }

    // drop extra filters when set main filter from all dialogs
    if (
      this.props.dialogsListMode !== prevProps.dialogsListMode ||
      (this.props.filterStatus !== prevProps.filterStatus &&
        this.props.filterStatus !== SHOW_ALL &&
        (this.props.filterResponsibleUsers.length ||
          this.state.channelsCounter !== this.props.filterChannels.length))
    ) {
      if (this.props.filterResponsibleUsers.length) {
        this.props.setFilterResponsibleUsers([]);
      }
      if (
        (this.props.dialogsListMode !== prevProps.dialogsListMode &&
          this.props.dialogsListMode === 'dialogs') ||
        this.state.channelsCounter !== this.props.filterChannels.length
      ) {
        this.props.setFilterChannels(
          this.props.activeChannels
            .filter((ch) =>
              checkUserRightsForEntity({
                entity: ch,
                entityType: 'channel',
                user: this.props.user,
              }),
            )
            .map((ch) => ch._id),
          this.props.user,
        );
      }
    }

    // change styles to default

    if (
      (this.props.filterResponsibleUsers.length !==
        prevProps.filterResponsibleUsers.length ||
        this.state.channelsCounter !== prevState.channelsCounter ||
        this.props.filterChannels.length !== prevProps.filterChannels.length) &&
      this.props.filterResponsibleUsers.length === 0 &&
      this.state.channelsCounter === this.props.filterChannels.length
    ) {
      this.setState({ isExtendedFilterSelected: false });
    }

    if (
      this.props.dialogsListMode !== prevProps.dialogsListMode &&
      this.props.dialogsListMode === 'dialogs'
    ) {
      this.props.setFilterStatuses(SHOW_OPENED);
    }

    if (this.props.dialogs.length && this.state.firstVisit) {
      const demoDialog = this.props.dialogs.find(
        (d) =>
          d.origin === 'demo' &&
          this.props.filterChannels.includes(d.channelId),
      );
      if (demoDialog) {
        this.setState({ firstVisit: false });
        // history.push(`/conversations/${demoDialog._id}`);
      } else {
        // request the onboarding dialog
        if (
          !this.state.sendOnboardingDialogRequest &&
          this.props.channels.find((ch) => ch.channelType === 'demo')
        ) {
          this.props.getOnboardingDialog([
            this.props.channels.find((ch) => ch.channelType === 'demo')._id ||
              '',
          ]);
          this.setState({ sendOnboardingDialogRequest: true });
        }
      }
      // if the server does n ot respond after a while we need to stop the onboarding dialog search process
      setTimeout(() => this.setState({ firstVisit: false }), 2500);
    }

    if (
      this.props.activeChannels.length &&
      this.props.activeChannels.length === prevProps.activeChannels.length &&
      !this.props.cachedSession &&
      this.props.user
    ) {
      setTimeout(() => {
        const channelsEnabledKeys = Object.keys(this.props.user.channels || {});
        let allChannelsEnabledForUser = [];

        channelsEnabledKeys.forEach((typeKey) => {
          const channels = Object.keys(this.props.user.channels[typeKey]);
          allChannelsEnabledForUser =
            allChannelsEnabledForUser.concat(channels);
        });

        const newChannelFilter = this.props.activeChannels.reduce(
          (acc, curr) => {
            if (
              this.props.user.admin ||
              allChannelsEnabledForUser.includes(curr._id)
            ) {
              acc.push(curr._id);
            }
            return acc;
          },
          [],
        );

        this.props.setFilterChannels(newChannelFilter, this.props.user);
        this.setState({ firstVisit: true });
      }, 350);
      this.props.setCacheSessionSaved(true);
    }

    // if (
    //   this.props.filterStatus.length &&
    //   window.location.pathname === '/conversations'
    // ) {
    //   if (
    //     this.documentTitleByFilterStatus[this.props.filterStatus] &&
    //     document.title !==
    //       `Еадеск | ${
    //         this.documentTitleByFilterStatus[this.props.filterStatus]
    //       }`
    //   ) {
    //     document.title = `Еадеск | ${
    //       this.documentTitleByFilterStatus[this.props.filterStatus]
    //     }`;
    //   }
    // }
  }

  setChannelCounter = () => {
    this.setState({
      channelsCounter: this.props.channels.filter((ch) =>
        checkUserRightsForEntity({
          entity: ch,
          entityType: 'channel',
          user: this.props.user,
        }),
      ).length,
    });
  };

  dialogsCounterByStatus = () => {
    const { statusCounters, user, filterStatus } = this.props;
    const statusCounterKeysByFilter = {
      SHOW_MINE: user._id,
      SHOW_CLOSED: 'closed',
      SHOW_OPENED: 'opened',
      SHOW_WAITING: 'waiting',
      SHOW_NOT_ASSIGNED_RESP: 'noResponsible',
      SHOW_BOT_ASSIGNED: 'botResponsible',
    };

    if (filterStatus === SHOW_ALL || filterStatus === SHOW_ALL_CALLS) {
      let totalCount = 0;
      Object.keys(statusCounters).forEach((key) => {
        if (key === 'opened' || key === 'closed' || key === 'waiting') {
          totalCount += statusCounters[key];
        }
      });
      return totalCount;
    }

    const key = statusCounterKeysByFilter[filterStatus];
    return statusCounters[key] ? statusCounters[key] : 0;
  };

  changeExtendedFilter = () => {
    if (this.props.filterResponsibleUsers.length === 0) {
      this.setState({ isExtendedFilterSelected: false });
    }
  };

  setPopoverVisible = (popoverVisible) => {
    this.setState({ popoverVisible });
  };

  render() {
    const { filterStatus, count, dialogsListMode } = this.props;
    const {
      pages: {
        dialogs: {
          filter: {
            statusLabels: {
              [dialogsListMode]: { mainFilter: dialogsStatusLabels },
            },
            statusFiltersRelation: {
              [dialogsListMode]: { mainFilter: mainFilterStatuses },
            },
          },
        },
      },
    } = componentsConf;

    return <DialogFilterStatusTabs />;
  }
}

const mapStateToProps = (state) => ({
  dialogsListMode: dialogsListModeSelector(state),
  filterStatus:
    dialogsListModeSelector(state) === 'calls'
      ? state.callsFilters.filterStatus
      : state.dialogsFilters.filterStatus,
  filterChannels:
    dialogsListModeSelector(state) === 'calls'
      ? getCallsChannelFilters(state)
      : getDialogsChannelFilters(state),
  filterResponsibleUsers:
    dialogsListModeSelector(state) === 'calls'
      ? callsListFilterResponsibleUsersSelector(state)
      : dialogsListFilterResponsibleUsersSelector(state),
  channels:
    dialogsListModeSelector(state) === 'calls'
      ? pbxAccountsArraySelector(state)
      : getActiveChannelsFromState(state),
  deletedChannels:
    dialogsListModeSelector(state) === 'calls'
      ? deletedPbxAccountsArraySelector(state)
      : getDeletedChannelsFromState(state),
  user_logged_in: state.auth.user && state.auth.user.email,
  dialogs: getDialogsArraySelector(state),
  user: state.auth.user,
  userForChannels: loggedUserSelector(state),
  statusCounters:
    dialogsListModeSelector(state) === 'calls'
      ? state.statusCounters.calls || {}
      : state.statusCounters.dialogs || {},
  count:
    dialogsListModeSelector(state) === 'calls'
      ? getFilteredCallsDialogs(state).length
      : getFilteredDialogs(state).length,
  activeChannels:
    dialogsListModeSelector(state) === 'calls'
      ? pbxAccountsArraySelector(state)
      : getActiveChannelsFromState(state),
  cachedSession: state.cacheStatus.currentSessionSaved,
  users: Object.values(state.users),
});

const mapDispatchToProps = (dispatch) => ({
  setFilterChannels: (filter, user) =>
    dispatch(setFilterChannels(filter, user)),
  setFilterStatuses: (filter) => dispatch(setFilterStatuses(filter)),
  setFilterResponsibleUsers: (users) =>
    dispatch(setFilterResponsibleUsers(users)),
  readMailAccounts: () => dispatch(readMailAccounts()),
  getChatraAccounts: () => dispatch(getChatraAccounts()),
  getTelegramAccounts: () => dispatch(getTelegramAccounts()),
  getDemoAccounts: () => dispatch(getDemoAccounts()),
  getWhatsAppAccounts: () => {
    dispatch(getWhatsAppAccounts());
    dispatch(getWabaAccounts());
    dispatch(getGreenApiAccounts());
  },
  getOnboardingDialog: (filterChannels) =>
    dispatch(getDialogs(0, 1, '', 'SHOW_NEW', 'SHOW_OPENED', filterChannels)),
  setCacheSessionSaved: (status) => dispatch(setCacheSessionSaved(status)),
  getVKAccounts: () => dispatch(getVKAccounts()),
  getSkypeAccounts: () => dispatch(getSkypeAccounts()),
  getPbxAccounts: () => dispatch(getPbxAccounts()),
  getFbAccounts: () => dispatch(getFbAccounts()),
  getIgAccounts: (type) => dispatch(getIgAccounts(type)),
  getCustomAccounts: () => dispatch(getCustomAccounts()),
  getDialogsCountOfStatus: (type) => dispatch(getDialogsCountOfStatus(type)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DialogFilter);
