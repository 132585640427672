// TODO -> write tests

function findElBySelectorType(selectorType, queryValue) {
  if (selectorType === 'id') {
    return document.querySelector(`#${queryValue}`);
  }
  if (selectorType === 'tagName') {
    return document.querySelector(queryValue);
  }
  return undefined;
}

export default findElBySelectorType;
