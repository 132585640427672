// TODO -> add tests for all cases when we refactor generation algorithm
/**
 * @description This function generates avatar from target
 * entity's data based on:
 * - email (user)
 * - name (user or client)
 * - contacts (client).
 * @param {{ name: string, email?: string, contacts: object[] }} entityData
 * @param {{ origin: string, contactsId: string }} [dialog]
 * @returns {string | ''} Avatar string or an empty string
 * if avatar cannot be constructed on base of provided data.
 */
const generateAvatarForEntity = (
  entityData = {},
  dialog = { origin: 'mail' },
) => {
  const { name = '', email = '', contacts = [] } = entityData;

  if (name || email || contacts.length) {
    if (name) {
      /**
       * Construct Telegram-like avatar
       * by schema: `FS` (F - firstNameStartLetter, S - surnameStartLetter)
       */
      const nameParts = name.toUpperCase().split(' ');

      if (nameParts.length) {
        const [firstName = '', surname = ''] = nameParts;
        // TODO -> escape non-letter characters
        const firstNameStartLetter =
          firstName.charAt(0) === '"' && firstName.charAt(1)
            ? firstName.charAt(1)
            : firstName.charAt(0);

        if (surname) {
          // TODO -> escape non-letter characters
          const surnameStartLetter =
            surname.charAt(0) === '—' && surname.charAt(1)
              ? surname.charAt(1)
              : surname.charAt(0);

          return `${firstNameStartLetter}${surnameStartLetter}`;
        }

        return `${firstNameStartLetter}`;
      }
    }

    if (email) {
      /**
       * Construct avatar for user without name
       * by using first letter of his/her email
       */
      return email.toUpperCase().charAt(0);
    }

    if (contacts) {
      /**
       * Construct client avatar from his/her contacts values
       */
      const { origin, contactsId } = dialog;
      const matchingServiceContacts = contacts.filter(
        (c) => c.service === origin,
      );

      if (matchingServiceContacts.length) {
        const dialogContact = matchingServiceContacts.find(
          (c) => c.id === contactsId,
        );

        if (dialogContact) {
          const { value: dialogContactValue = '' } = dialogContact;

          return dialogContactValue.toString().toUpperCase().charAt(0);
        }
        /**
         * If there is no matching dialog contact,
         * it usually means that entityData has single contact
         * and we just need to return the first one
         */
        const [firstMatchingServiceContact] = matchingServiceContacts;
        const { value: contactValue = '' } = firstMatchingServiceContact;

        return contactValue.toString().toUpperCase().charAt(0);
      }

      return entityData.contacts[0].value
        ? (entityData.contacts[0].value || '')
            .toString()
            .toUpperCase()
            .charAt(0)
        : '';
    }
  }

  return '';
};

export default generateAvatarForEntity;
