import * as R from 'ramda';
import { createSelector } from 'reselect';

import dialogsConf from '../configs/dialogs';

const {
  search: { contentSearchMode: contentSearchModeConf },
} = dialogsConf;
/**
 * Setter constants
 */
export const SET_FILTER_CALLS_CHANNELS = 'SET_FILTER_CALLS_CHANNELS';
export const SET_FILTER_CALLS_STATUSES = 'SET_FILTER_CALLS_STATUSES';
export const SET_CALLS_SORT = 'SET_CALLS_SORT';
export const SET_CALLS_SEARCH = 'SET_CALLS_SEARCH';
export const SET_CALLS_CONTENT_SEARCH_MODE = 'SET_CONTENT_SEARCH_MODE';
export const SET_CALLS_RESPONSIBLE_USERS = 'SET_CALLS_RESPONSIBLE_USERS';
/**
 * Status filter constants
 */
export const SHOW_IN_CALLS = 'SHOW_IN_CALLS';
export const SHOW_OUT_CALLS = 'SHOW_OUT_CALLS';
export const SHOW_ALL_CALLS = 'SHOW_ALL_CALLS';
export const SHOW_MINE_CALLS = 'SHOW_MINE_CALLS';
export const SHOW_NOT_ASSIGNED_RESP_CALLS = 'SHOW_NOT_ASSIGNED_RESP_CALLS';
export const SHOW_MISSED_CALLS = 'SHOW_MISSED_CALLS';
/**
 * Sort type constants
 */
export const SHOW_NEW_CALLS = 'SHOW_NEW_CALLS';
export const SHOW_OLD_CALLS = 'SHOW_OLD_CALLS';
export const SHOW_RECENT_UPDATED_CALLS = 'SHOW_RECENT_UPDATED_CALLS';
export const SHOW_OLDEST_UPDATED_CALLS = 'SHOW_OLDEST_UPDATED_CALLS';

const initialCallsFilters = Object.freeze({
  search: '',
  contentSearchMode: contentSearchModeConf.defaultMode,
  filterStatus: SHOW_ALL_CALLS,
  sortBy: SHOW_NEW_CALLS,
  responsibleUsers: [],
});

function sortReducer(state = initialCallsFilters, sorting = '') {
  switch (sorting) {
    case SHOW_NEW_CALLS: {
      return { ...state, sortBy: SHOW_NEW_CALLS };
    }
    case SHOW_OLD_CALLS: {
      return { ...state, sortBy: SHOW_OLD_CALLS };
    }
    case SHOW_RECENT_UPDATED_CALLS: {
      return { ...state, sortBy: SHOW_RECENT_UPDATED_CALLS };
    }
    case SHOW_OLDEST_UPDATED_CALLS: {
      return { ...state, sortBy: SHOW_OLDEST_UPDATED_CALLS };
    }
    default: {
      return state;
    }
  }
}

function filterStatusReducer(state = initialCallsFilters, status = '') {
  switch (status) {
    case SHOW_ALL_CALLS: {
      return { ...state, filterStatus: SHOW_ALL_CALLS };
    }
    case SHOW_MINE_CALLS: {
      return { ...state, filterStatus: SHOW_MINE_CALLS };
    }
    case SHOW_NOT_ASSIGNED_RESP_CALLS: {
      return { ...state, filterStatus: SHOW_NOT_ASSIGNED_RESP_CALLS };
    }
    case SHOW_MISSED_CALLS: {
      return { ...state, filterStatus: SHOW_MISSED_CALLS };
    }
    case SHOW_IN_CALLS: {
      return { ...state, filterStatus: SHOW_IN_CALLS };
    }
    case SHOW_OUT_CALLS: {
      return { ...state, filterStatus: SHOW_OUT_CALLS };
    }
    default: {
      return state;
    }
  }
}

function channelReducer(state, payload) {
  const { filter, user } = payload;
  return {
    ...state,
    [user.account]: { [user._id]: { filterChannels: [...new Set(filter)] } },
  };
}

function responsibleUserReducer(state, payload) {
  return {
    ...state,
    responsibleUsers: payload,
  };
}

export function setCallsSearch(search) {
  return {
    type: SET_CALLS_SEARCH,
    payload: { search },
  };
}

export function setCallsContentSearchMode(contentSearchMode) {
  return {
    type: SET_CALLS_CONTENT_SEARCH_MODE,
    payload: { contentSearchMode },
  };
}

export function setCallsSort(sort) {
  return {
    type: SET_CALLS_SORT,
    payload: { sort },
  };
}

export function setCallsFilterChannels(filter, user) {
  return {
    type: SET_FILTER_CALLS_CHANNELS,
    payload: { filter, user },
  };
}

export function setCallsFilterStatuses(filter) {
  return {
    type: SET_FILTER_CALLS_STATUSES,
    payload: { filter },
  };
}

export function setCallsFilterResponsibleUsers(responsibleUsers) {
  return {
    type: SET_CALLS_RESPONSIBLE_USERS,
    payload: { responsibleUsers },
  };
}

export default function reducer(state = initialCallsFilters, action) {
  switch (action.type) {
    case SET_CALLS_SEARCH: {
      return { ...state, search: action.payload.search };
    }
    case SET_CALLS_CONTENT_SEARCH_MODE: {
      return { ...state, contentSearchMode: action.payload.contentSearchMode };
    }
    case SET_CALLS_SORT: {
      return { ...state, ...sortReducer(state, action.payload.sort) };
    }
    case SET_FILTER_CALLS_STATUSES: {
      return {
        ...state,
        ...filterStatusReducer(state, action.payload.filter),
      };
    }
    case SET_FILTER_CALLS_CHANNELS: {
      return { ...state, ...channelReducer(state, action.payload) };
    }
    case SET_CALLS_RESPONSIBLE_USERS: {
      return {
        ...state,
        ...responsibleUserReducer(state, action.payload.responsibleUsers),
      };
    }
    default: {
      return state;
    }
  }
}

export const getCallsFilters = (state) =>
  state.callsFilters || initialCallsFilters;

const getAuthUser = (state) => state.auth.user;

export const callsListSearchSelector = createSelector(
  getCallsFilters,
  (callsFilters) => callsFilters.search || initialCallsFilters.search,
);

export const callsListContentSearchModeSelector = createSelector(
  getCallsFilters,
  (callsFilters) =>
    callsFilters.contentSearchMode || initialCallsFilters.contentSearchMode,
);

export const callsListFilterStatusSelector = createSelector(
  getCallsFilters,
  (callsFilters) =>
    callsFilters.filterStatus || initialCallsFilters.filterStatus,
);

export const callsListSortSelector = createSelector(
  getCallsFilters,
  (callsFilters) => callsFilters.sortBy || initialCallsFilters.sortBy,
);

export const getCallsChannelFilters = createSelector(
  [getCallsFilters, getAuthUser],
  (filter, user) =>
    R.pathOr([], [user.account, user._id, 'filterChannels'], filter),
);

export const callsListFilterResponsibleUsersSelector = createSelector(
  getCallsFilters,
  (callsFilters) =>
    callsFilters.responsibleUsers || initialCallsFilters.responsibleUsers,
);
