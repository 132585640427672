export const SET_RESPONSIBLE_FILTER = 'SET_RESPONSIBLE_FILTER';
export const CLEAR_RESPONSIBLE_FILTER = 'CLEAR_RESPONSIBLE_FILTER';

export const setResponsibleFilter = (value) => ({
  type: SET_RESPONSIBLE_FILTER,
  payload: value,
});

export const clearResponsibleFilter = () => ({
  type: CLEAR_RESPONSIBLE_FILTER,
});

export default function reducer(state = '', action = {}) {
  switch (action.type) {
    case SET_RESPONSIBLE_FILTER: {
      return action.payload;
    }
    case CLEAR_RESPONSIBLE_FILTER: {
      return '';
    }
    default: {
      return state;
    }
  }
}

export const getResponsibleFilter = (state) => state.responsibleFilter;
