import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getFileLink } from '../../api/files';
import proxyAttachments from '../../api/attachments/proxyAttachments';
import AudioWrapper from './AudioWrapper';
import { getCallAttachment } from '../../api/pbx-accounts';

const AudioWrapperContainer = ({
  file,
  getAudioLink,
  messageId,
  type,
  text,
}) => {
  const [audioSrc, setAudioSrc] = useState(file.url || null);
  const [fetchInitiated, setFetchInitiated] = useState(false);

  const fileKey = file.Key || file.key || file.file_id;

  const handleAudioDownload = useCallback(() => {
    setFetchInitiated(true);

    getAudioLink((res) => {
      if (res && res.url) {
        setAudioSrc(res.url);
      }
    });
  }, [getAudioLink]);

  useEffect(() => {
    if (fileKey && !audioSrc && !fetchInitiated) {
      handleAudioDownload();
    }
  }, [fileKey, audioSrc, fetchInitiated, handleAudioDownload]);

  return (
    <>
      <AudioWrapper
        audioSrc={audioSrc}
        fileName={file.filename || file.file_name || file.title}
        contentType={file.contentType}
        uniqueId={`${messageId}_${file.Key || file.key || file.file_id}`}
      />
      {text ? <div dangerouslySetInnerHTML={{ __html: text }} /> : null}
    </>
  );
};

AudioWrapperContainer.displayName = 'AudioWrapperContainer';

AudioWrapperContainer.propTypes = {
  file: PropTypes.object.isRequired,
  dialog: PropTypes.object.isRequired,
  needProxy: PropTypes.bool.isRequired,
  messageId: PropTypes.string.isRequired,
  getAudioLink: PropTypes.func.isRequired,
  type: PropTypes.string,
  text: PropTypes.string,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getAudioLink: (cb) => {
    if (ownProps.dialog.origin === 'pbx') {
      return dispatch(getCallAttachment(ownProps.messageId, cb));
    }
    return dispatch(
      ownProps.needProxy
        ? proxyAttachments(
            {
              channelType: ownProps.dialog.origin,
              channelId: ownProps.dialog.channelId,
              Key:
                ownProps.file.Key || ownProps.file.key || ownProps.file.file_id,
              name:
                ownProps.file.filename ||
                ownProps.file.file_name ||
                ownProps.file.title,
              messageId: ownProps.messageId,
              setNameFromHeader: ownProps.file.setNameFromHeader,
              mode: 'src',
            },
            cb,
          )
        : getFileLink(
            {
              key:
                ownProps.file.Key || ownProps.file.key || ownProps.file.file_id,
              origin: ownProps.dialog.origin,
              messageId: ownProps.messageId,
              mode: 'src',
            },
            cb,
          ),
    );
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AudioWrapperContainer);
