import * as R from 'ramda';
import { createSelector } from 'reselect';

export const RECIEVE_PBX_ACCOUNTS = 'RECIEVE_PBX_ACCOUNTS';
export const RECIEVE_PBX_ACCOUNT = 'RECIEVE_PBX_ACCOUNT';
export const UPDATE_PBX_ACCOUNT = 'UPDATE_PBX_ACCOUNT';
export const SET_PBX_ACC_NAME = 'SET_PBX_ACC_NAME';
export const SET_PBX_ACC_DOMAIN = 'SET_PBX_ACC_DOMAIN';
export const SET_PBX_ACC_API_KEY = 'SET_PBX_ACC_API_KEY';
export const DELETE_PBX_ACCOUNT = 'DELETE_PBX_ACCOUNT';
export const DELETE_PBX_ACCOUNT_DRAFT = 'DELETE_PBX_ACCOUNT_DRAFT';
export const ENABLE_PBX_ACCOUNT = 'ENABLE_PBX_ACCOUNT';
export const DISABLE_PBX_ACCOUNT = 'DISABLE_PBX_ACCOUNT';
export const RESET_PBX_ACCOUNT = 'RESET_PBX_ACCOUNT';
export const SET_PBX_MATCH = 'SET_PBX_MATCH';

const pbxAccountsFactory = (account) => ({ [account._id]: account });

function pbxAccountsReducer(state = {}, action = { type: '' }) {
  switch (action.type) {
    case RECIEVE_PBX_ACCOUNTS: {
      return R.mergeAll(R.map(pbxAccountsFactory, action.payload.pbxAccounts));
    }
    case RECIEVE_PBX_ACCOUNT: {
      return { ...state, ...action.payload.pbxAccount };
    }
    case ENABLE_PBX_ACCOUNT: {
      return { ...state, isEnabled: true };
    }
    case DISABLE_PBX_ACCOUNT: {
      return { ...state, isEnabled: false };
    }
    case DELETE_PBX_ACCOUNT: {
      return { ...state, isDeleted: true };
    }
    case SET_PBX_ACC_NAME: {
      return { ...state, name: action.payload.name };
    }
    case SET_PBX_ACC_DOMAIN: {
      return { ...state, domain: action.payload.domain };
    }
    case SET_PBX_ACC_API_KEY: {
      return { ...state, key: action.payload.key };
    }
    case SET_PBX_MATCH: {
      const { userId, pbxPhone } = action.payload;
      return {
        ...state,
        match: R.mergeRight(state.match || {}, { [userId]: pbxPhone }),
      };
    }
    case UPDATE_PBX_ACCOUNT: {
      return {
        ...state,
        ...(action.payload.name ? { name: action.payload.name } : {}),
        ...(action.payload.domain ? { domain: action.payload.domain } : {}),
        ...(action.payload.key ? { key: action.payload.key } : {}),
        ...(action.payload.match ? { match: action.payload.match } : {}),
        ...(action.payload.notifications
          ? { notifications: action.payload.notifications }
          : {}),
      };
    }
    default: {
      return state;
    }
  }
}

export const recievePbxAccounts = (pbxAccounts) => ({
  type: RECIEVE_PBX_ACCOUNTS,
  payload: { pbxAccounts },
});

export const recievePbxAccount = (pbxAccount) => ({
  type: RECIEVE_PBX_ACCOUNT,
  payload: { pbxAccount },
});

export const setPbxAccName = (_id, name) => ({
  type: SET_PBX_ACC_NAME,
  payload: {
    _id,
    name,
  },
});

export const setPbxAccDomain = (_id, domain) => ({
  type: SET_PBX_ACC_DOMAIN,
  payload: {
    _id,
    domain,
  },
});

export const setPbxAccApiKey = (_id, key) => ({
  type: SET_PBX_ACC_API_KEY,
  payload: {
    _id,
    key,
  },
});

export const setPbxMatch = (_id, pbxPhone, userId) => ({
  type: SET_PBX_MATCH,
  payload: { _id, pbxPhone, userId },
});

export const renewPbxAccount = ({
  pbxAccountId,
  domain,
  name,
  key,
  match,
  notifications,
}) => ({
  type: UPDATE_PBX_ACCOUNT,
  payload: {
    pbxAccountId,
    domain,
    name,
    key,
    match,
    notifications,
  },
});

export const removePbxAccount = (_id) => ({
  type: DELETE_PBX_ACCOUNT,
  payload: {
    _id,
  },
});

export const deletePbxAccountDraft = () => ({
  type: DELETE_PBX_ACCOUNT_DRAFT,
});

export const enablePbxAccount = (_id) => ({
  type: ENABLE_PBX_ACCOUNT,
  payload: { _id },
});

export const disablePbxAccount = (_id) => ({
  type: DISABLE_PBX_ACCOUNT,
  payload: { _id },
});

export const resetPbxAccount = (pbxAccount) => ({
  type: RESET_PBX_ACCOUNT,
  payload: { pbxAccount },
});

export default function reducer(state = {}, action = { type: '' }) {
  switch (action.type) {
    case RECIEVE_PBX_ACCOUNTS: {
      return R.mergeRight(state, pbxAccountsReducer(state, action));
    }
    case RECIEVE_PBX_ACCOUNT: {
      return R.assoc(
        action.payload.pbxAccount._id,
        pbxAccountsReducer(state[action.payload.pbxAccount._id], action),
      )(state);
    }
    case ENABLE_PBX_ACCOUNT:
    case DISABLE_PBX_ACCOUNT:
    case DELETE_PBX_ACCOUNT:
    case SET_PBX_ACC_API_KEY:
    case SET_PBX_ACC_NAME:
    case SET_PBX_MATCH:
    case SET_PBX_ACC_DOMAIN: {
      return R.assoc(
        action.payload._id,
        pbxAccountsReducer(state[action.payload._id], action),
      )(state);
    }
    case UPDATE_PBX_ACCOUNT: {
      return R.assoc(
        action.payload.pbxAccountId,
        pbxAccountsReducer(state[action.payload.pbxAccountId], action),
      )(state);
    }
    case RESET_PBX_ACCOUNT: {
      // reseting state = no choice but to overwrite field!
      return R.assoc(
        action.payload.pbxAccount._id,
        action.payload.pbxAccount,
      )(state);
    }
    case DELETE_PBX_ACCOUNT_DRAFT: {
      return R.dissoc('new')(state);
    }
    default: {
      return state;
    }
  }
}

export const pbxAccountsSelector = (state) => state.pbxAccounts;

export const pbxAccountsArraySelector = createSelector(
  pbxAccountsSelector,
  (pbxAccounts) =>
    R.filter(
      (_) => !_.isDeleted && _._id && _._id !== 'new',
      R.values(pbxAccounts).map((ch) => ({ ...ch, channelType: 'pbx' })),
    ),
);

export const deletedPbxAccountsArraySelector = createSelector(
  pbxAccountsSelector,
  (pbxAccounts) =>
    R.filter(
      (_) => _.isDeleted,
      R.values(pbxAccounts).map((ch) => ({ ...ch, channelType: 'pbx' })),
    ),
);

export const pbxAccountIdSelector = (state, ownProps) => {
  const { params: { channelId } = {} } = ownProps.match;
  return channelId || ownProps.id || ownProps._id;
};

export const pbxAccountSelector = createSelector(
  pbxAccountsSelector,
  pbxAccountIdSelector,
  (accounts, id) => R.propOr({}, id, accounts),
);
