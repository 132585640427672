import * as R from 'ramda';
import { createSelector } from 'reselect';

export const RECIEVE_DIALOGS_STATUS_COUNTERS =
  'RECIEVE_DIALOGS_STATUS_COUNTERS';

function statusCountersReducer(state = {}, action = { type: '' }) {
  switch (action.type) {
    case RECIEVE_DIALOGS_STATUS_COUNTERS: {
      const {
        statusCounters: { dialogs = {}, calls = {} } = {
          dialogs: {},
          calls: {},
        },
      } = action.payload;
      return { ...state, dialogs, calls };
    }
    default: {
      return state;
    }
  }
}

export const recieveDialogsStatusCounters = (statusCounters) => ({
  type: RECIEVE_DIALOGS_STATUS_COUNTERS,
  payload: {
    statusCounters,
  },
});

const defaultState = {};

export default function reducer(state = defaultState, action = { type: '' }) {
  switch (action.type) {
    case RECIEVE_DIALOGS_STATUS_COUNTERS: {
      return R.mergeRight(state, statusCountersReducer(state, action));
    }
    default: {
      return state;
    }
  }
}

const getDialogsStatusCounters = (state) => state.statusCounters;

export const dialogsStatusCountersSelector = createSelector(
  getDialogsStatusCounters,
  (statusCounters) => statusCounters,
);
