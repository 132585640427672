import { useState, useCallback } from 'react';

const useErrorState = () => {
  const [error, setError] = useState(null);

  const handleError = useCallback(
    /**
     * @description Sets/removes state error based on passed value:
     * - error -> set error if not set, else do nothing
     * - undefined -> remove error if it was set, else do nothing
     * @param {any} passedError
     */
    (passedError) => {
      if (passedError) {
        if (!error) {
          setError(passedError);
        }

        return;
      }

      if (error) {
        setError(null);
      }
    },
    [error],
  );

  return { error, handleError };
};

export default useErrorState;
