const imagesConfig = {
  preview: {
    maxHeight: 300,
    maxWidth: 300,
  },
  avatar: {
    maxSize: 512000,
  },
};

export default imagesConfig;
