import { createSelector } from 'reselect';

import { getDialogsSelector } from '../reducers/dialogs';
import { dialogsListPositionsSelector } from '../reducers/dialogsList';

const getDialogPositionFromProps = (_, props) => props.index;

const getDialogsListItemByPosition = createSelector(
  [
    dialogsListPositionsSelector,
    getDialogPositionFromProps,
    getDialogsSelector,
  ],
  (dialogsListPositions, dialogPosition, dialogs = {}) => {
    const dialogId = dialogsListPositions[dialogPosition];

    if (dialogId) {
      const dialog = dialogs[dialogId];

      return dialog;
    }

    return null;
  },
);

export default getDialogsListItemByPosition;
