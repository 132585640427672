import fetchJWT from '../../utils/fetchJWT';
import { logout } from '../../actions';
// import { history } from '../..';
import { backend, api as apiConf } from '../../configs';
import createOutcomingMsgCreationBody from '../../utils/createOutcomingMsgCreationBody';

const {
  messages: {
    originsThatAllowSendingAttachmentsWithText,
    apiProvidersThatAllowSendingAttachmentsWithText,
  },
} = apiConf;

const createDialog =
  (
    {
      channelId,
      origin,
      mailTo,
      mailToId,
      ccTo,
      title,
      text,
      html,
      attachments,
      apiProvider,
      wabaTemplate,
    },
    cb,
  ) =>
  (dispatch) => {
    const messageData = {
      contactsValue: mailTo,
      contactsId: mailToId,
      ccTo,
      title,
      text,
      html,
      attachments,
      provider: apiProvider,
      wabaTemplate,
    };

    const request = new Request(
      backend.applyBaseUrl(`/api/dialogs/create/${origin}/${channelId}`),
      {
        ...(originsThatAllowSendingAttachmentsWithText.includes(origin) ||
        apiProvidersThatAllowSendingAttachmentsWithText.includes(apiProvider)
          ? {}
          : {
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
            }),
        method: 'POST',
        body: createOutcomingMsgCreationBody({
          messageData,
          origin,
          apiProvider,
        }),
      },
    );

    return fetchJWT(request)
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        if (res.status === 401) {
          dispatch(logout());
        }
        // return err string
        return res.text();
      })
      .then((res) => {
        if (cb) {
          // TODO -> sync error handling in all API (find appropriate scheme)
          cb({
            ...(typeof res === 'string' ? { err: res } : { ...(res || {}) }),
          });
        }
        if (res.dialogId) {
          // history.push(`/conversations/${res.dialogId}`);
        }
      })
      .catch((err) => console.error(err));
  };

export default createDialog;
