import React from 'react';
import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';
import EventIconByOrigin from './EventIconByOrigin';
import { useTranslation } from 'react-i18next';

function ServiceEvent({ item, dialog, moment, task = {} }) {
  const { t } = useTranslation();
  const { origin } = dialog;
  const {
    metadata: { header = t('ServiceEvent.metadataHeader') },
  } = item;

  const eventType =
    item.version && item.version === '2'
      ? item.eventType
      : item.metadata.eventType;
  let headerForDisplay = header;
  // used single space instead of negative lookbehind to mismatch prefixes due to support problems (works only in V8)
  if (eventType.match(/ connected.*task/i)) {
    headerForDisplay = t('ServiceEvent.taskLinked');
  } else if (eventType.match(/disconnected.*task/i)) {
    headerForDisplay = t('ServiceEvent.taskUnlinked');
  }

  const eventTextDisplayHandler = () => {
    if (eventType.match(/task/i)) {
      return (
        <span>
          {t('ServiceEvent.task')}
          {/* <Link to={`/tasks/${task.taskNumber || 0}`}>{`#${
            task.taskNumber || 0
          }`}</Link> */}
          {`:\n«${task.title || t('ServiceEvent.untitled')}»`}
        </span>
      );
    }
    return `${item.text || ''}`;
  };

  const agentOriginByDialog = {
    chatra: t('Message.chatra'),
    vk: t('Message.vk'),
    telegram: t('Message.telegram'),
    whatsapp: t('Message.whatsapp'),
    mail: t('Message.mail'),
    skype: t('Message.skype'),
    pbx: t('Message.pbx'),
    phone: t('Message.phone'),
    fb: t('Message.fb'),
    ig: t('Message.ig'),
    ig2: t('Message.ig'),
    custom: t('Message.custom'),
  };

  return (
    <div
      id={item._id}
      style={{
        padding: '6px 5px',
        display: 'flex',
        gap: '6px',
      }}
    >
      <div>
        <EventIconByOrigin
          origin={eventType.match(/task/i) ? 'task' : origin}
        />
      </div>
      <div>
        <div style={{ marginBottom: '5px' }}>
          <b>{agentOriginByDialog[origin]}</b>{' '}
          {moment(item.createdAt).format('DD.MM.YYYY • HH:mm')}
        </div>
        <div style={{ wordBreak: 'break-word', color: '#7d8d9a' }}>
          <p>{headerForDisplay}</p>
          {eventTextDisplayHandler()}
        </div>
      </div>
    </div>
  );
}

ServiceEvent.propTypes = {
  item: PropTypes.object,
  moment: PropTypes.func,
  dialog: PropTypes.object,
  task: PropTypes.object,
};

export default ServiceEvent;
