import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Attachments from '../../attachments';
import './index.css';

const MessageQuote = ({
  quotedMessage: { text = '', attachments = [] } = {},
  dialog = {},
  messageId = '',
}) => (
  <div className="message-quote">
    <div className="message-quote__content">
      {attachments.length ? (
        <div className="message-quote__attachments">
          <Attachments
            attachments={attachments}
            dialog={dialog}
            messageId={messageId}
            type="quoted"
            text={text}
          />
        </div>
      ) : (
        <div
          className="message-quote__text"
          dangerouslySetInnerHTML={{ __html: text }}
        />
      )}
    </div>
  </div>
);

MessageQuote.propTypes = {
  quotedMessage: PropTypes.shape({
    text: PropTypes.string,
    attachments: PropTypes.array,
  }),
  dialog: PropTypes.object,
  messageId: PropTypes.string,
};

export default memo(MessageQuote);
