import React from 'react';
import DialogsListContent from './DialogsListContent';
import DialogsListHeader from './DialogsListHeader';
import './index.css';

const DialogsList = () => (
  <div className="dialogs-list">
    <DialogsListHeader />
    <DialogsListContent />
  </div>
);

export default DialogsList;
