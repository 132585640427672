import { createSelector } from 'reselect';

export const RECIEVE_DIALOG_PARTICIPANTS = 'RECIEVE_DIALOG_PARTICIPANTS';

export const recieveDialogParticipants = (dialogParticipantsData) => ({
  type: RECIEVE_DIALOG_PARTICIPANTS,
  payload: {
    dialogParticipantsData,
  },
});

export default function reducer(state = {}, action = { type: '' }) {
  switch (action.type) {
    case RECIEVE_DIALOG_PARTICIPANTS: {
      return { ...state, ...action.payload.dialogParticipantsData };
    }
    default: {
      return state;
    }
  }
}

export const dialogParticipantsSelector = (state) => state.dialogParticipants;

export const dialogParticipantsArraySelector = createSelector(
  dialogParticipantsSelector,
  (dialogParticipants) => Object.values(dialogParticipants),
);
