import * as R from 'ramda';
import { createSelector } from 'reselect';

export const RECIEVE_CLIENT_SEGMENTS = 'RECIEVE_CLIENT_SEGMENTS';
export const RECIEVE_CLIENT_SEGMENT = 'RECIEVE_CLIENT_SEGMENT';
export const UPDATE_CLIENT_SEGMENT = 'UPDATE_CLIENT_SEGMENT';
export const DELETE_CLIENT_SEGMENT = 'DELETE_CLIENT_SEGMENT';

function clientSegmentFactory(account) {
  return { [account._id]: account };
}

function clientSegmentReducer(state = {}, action = { type: '' }) {
  switch (action.type) {
    case RECIEVE_CLIENT_SEGMENTS: {
      return R.mergeAll(
        R.map(clientSegmentFactory, action.payload.clientSegments),
      );
    }
    case RECIEVE_CLIENT_SEGMENT: {
      return { ...state, ...action.payload.clientSegment };
    }
    case DELETE_CLIENT_SEGMENT: {
      return { ...state, isDeleted: true };
    }
    case UPDATE_CLIENT_SEGMENT: {
      const { payload: { name, color } = {} } = action;

      return {
        ...state,
        ...(name !== undefined ? { name } : {}),
        ...(color !== undefined ? { color } : {}),
      };
    }
    default: {
      return state;
    }
  }
}

export const recieveClientSegments = (clientSegments) => ({
  type: RECIEVE_CLIENT_SEGMENTS,
  payload: { clientSegments },
});

export const recieveClientSegment = (clientSegment) => ({
  type: RECIEVE_CLIENT_SEGMENT,
  payload: { clientSegment },
});

export const renewClientSegment = ({ id, name, color }) => ({
  type: UPDATE_CLIENT_SEGMENT,
  payload: {
    id,
    name,
    color,
  },
});

export const removeClientSegment = (id) => ({
  type: DELETE_CLIENT_SEGMENT,
  payload: {
    id,
  },
});

export default function reducer(state = {}, action = { type: '' }) {
  switch (action.type) {
    case RECIEVE_CLIENT_SEGMENTS: {
      return R.mergeRight(state, clientSegmentReducer(state, action));
    }
    case RECIEVE_CLIENT_SEGMENT: {
      return R.assoc(
        action.payload.clientSegment._id,
        clientSegmentReducer(state[action.payload.clientSegment._id], action),
      )(state);
    }
    case DELETE_CLIENT_SEGMENT: {
      return R.assoc(
        action.payload.id,
        clientSegmentReducer(state[action.payload.id], action),
      )(state);
    }
    case UPDATE_CLIENT_SEGMENT: {
      return R.assoc(
        action.payload.id,
        clientSegmentReducer(state[action.payload.id], action),
      )(state);
    }
    default: {
      return state;
    }
  }
}

export const clientSegmentsSelector = (state) => state.clientSegments;

export const clientSegmentsArraySelector = createSelector(
  clientSegmentsSelector,
  (clientSegments) =>
    R.filter(
      (_) => !_.isDeleted && _._id && _._id !== 'new',
      R.values(clientSegments),
    ),
);

export const deletedClientSegmentsArraySelector = createSelector(
  clientSegmentsSelector,
  (clientSegments) => R.filter((_) => _.isDeleted, R.values(clientSegments)),
);

export const clientSegmentIdSelector = (_, ownProps) => {
  const { match: { params: { id } = {} } = {} } = ownProps;
  return id || ownProps.id || ownProps._id;
};

export const clientSegmentSelector = createSelector(
  clientSegmentsSelector,
  clientSegmentIdSelector,
  (accounts, id) => R.propOr({}, id, accounts),
);
