import PropTypes from 'prop-types';

import withDynamicItems from '../../../with/virtualList/withDynamicItems';
import VirtualList from '../VirtualList';

const DynamicVirtualList = withDynamicItems(VirtualList);

DynamicVirtualList.displayName = 'DynamicVirtualList';
DynamicVirtualList.propTypes = {
  recalcThrottleTimeout: PropTypes.number.isRequired,
  fallbackItemSize: PropTypes.number.isRequired,
  mapItemIdToIndex: PropTypes.func.isRequired,
  mapIndexToItemId: PropTypes.func.isRequired,
  itemCount: PropTypes.number.isRequired,
  overscanCount: PropTypes.number,
  ListItem: PropTypes.elementType.isRequired,
  itemData: PropTypes.object,
  onItemsRendered: PropTypes.func,
  onScroll: PropTypes.func,
  customClassnames: PropTypes.arrayOf(PropTypes.string),
};

export default DynamicVirtualList;
