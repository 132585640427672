import { getDialogItem } from '../../reducers/dialogs';
import { getContact, getContacts } from '../contacts';
import getDialogsListPositions from './getDialogsListPositions';
import { getDialogParticipants } from '../messages';

// TODO get rid of it prefer socket
const onReceiveDialog = (dialog, cb) => async (dispatch, getState) => {
  const { clients, dialogParticipants, users } = await getState();

  if (dialog._id) {
    // TODO get rid of it prefer socket
    dispatch(getDialogItem(dialog));
    // это временное решение
    dispatch(getDialogsListPositions(() => {}));
    // TODO store participants in dialog entry
    if (!dialogParticipants[dialog._id]) {
      dispatch(getDialogParticipants({ dialogIdArray: [dialog._id] }));
    }
    if (cb) {
      cb(dialog);
    }
  }

  if (dialog.authorId && !clients[dialog.authorId] && !users[dialog.authorId]) {
    // TODO get rid of it prefer socket
    dispatch(getContact(dialog.authorId));
  }

  if (
    dialog.contactsId &&
    !Object.values(clients).find(
      (c) =>
        c.contacts &&
        c.contacts.find((contact) => contact.id === dialog.contactsId),
    )
  ) {
    dispatch(getContacts('', [dialog.contactsId]));
  }
};

export default onReceiveDialog;
