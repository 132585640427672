import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { areEqual } from 'react-window';
import { Skeleton } from 'antd-mobile';
import useDialogMessagesListItemData from '../../../../hooks/dialogs/dialogMessagesList/useDialogMessagesListItemData';
import Call from '../messageTypes/Call';
import Message from '../messageTypes/Message';
import Note from '../messageTypes/Note';
import Comment from '../messageTypes/Comment';
import Event from '../messageTypes/Event';

const DialogMessagesListItem = ({
  itemId,
  data: { dialogAuthor, dialogClient, dialog, userName, userEmail, userId },
}) => {
  const {
    item = {},
    messageAuthor,
    messageAuthorDisplay,
  } = useDialogMessagesListItemData({ itemId, dialog });

  const renderItemContent = useCallback(() => {
    const itemViewByType = {
      message:
        dialog.origin === 'pbx' ? (
          <Call
            item={item}
            dialog={dialog}
            dialogAuthor={dialogAuthor}
            dialogClient={dialogClient}
            messageAuthor={messageAuthor}
            messageAuthorDisplay={messageAuthorDisplay}
          />
        ) : (
          <Message
            item={item}
            dialog={dialog}
            dialogAuthor={dialogAuthor}
            dialogClient={dialogClient}
            messageAuthor={messageAuthor}
            messageAuthorDisplay={messageAuthorDisplay}
          />
        ),
      note:
        item.authorId && item.authorId === userId ? (
          <Note
            item={item}
            messageAuthor={messageAuthor}
            messageAuthorDisplay={messageAuthorDisplay}
          />
        ) : null,
      comment: (
        <Comment
          item={item}
          messageAuthor={messageAuthor}
          messageAuthorDisplay={messageAuthorDisplay}
        />
      ),
      event: (
        <Event
          item={item}
          dialog={dialog}
          dialogAuthor={dialogAuthor}
          name={userName}
          email={userEmail}
        />
      ),
    };

    return itemViewByType[item.type];
  }, [
    dialog,
    dialogAuthor,
    dialogClient,
    item,
    messageAuthor,
    messageAuthorDisplay,
    userEmail,
    userId,
    userName,
  ]);

  const isItemLoading = useCallback(
    () =>
      !itemId ||
      (item.type !== 'event' &&
        (!dialogAuthor || !dialogClient || !messageAuthor)),
    [itemId, item, dialogAuthor, dialogClient, messageAuthor],
  );

  return (
    <>
      {isItemLoading() ? (
        <Skeleton.Paragraph lineCount={2} animated />
      ) : (
        renderItemContent()
      )}
    </>
  );
};

DialogMessagesListItem.propTypes = {
  itemId: PropTypes.string.isRequired,
  data: PropTypes.shape({
    dialogAuthor: PropTypes.object,
    dialogClient: PropTypes.object,
    dialog: PropTypes.object,
    userName: PropTypes.string,
    userEmail: PropTypes.string,
    userId: PropTypes.string,
  }).isRequired,
};

DialogMessagesListItem.displayName = 'DialogMessagesListItem';

export default memo(DialogMessagesListItem, areEqual);
