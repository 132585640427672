import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { Toast, SpinLoading } from 'antd-mobile';
import { useTranslation } from 'react-i18next';
import { getDialog } from '../../../api/dialogs';
// import { getConfigData } from '../../../api/conf';
import dialogOpenedSelector from '../../../selectors/dialogOpenedSelector';
// import {
//   typingActionsForDialogSelector,
//   readingActionsForDialogSelector,
// } from '../../../reducers/dialogActions';
import dialogFormChannelSelector from '../../../selectors/dialogFormChannelSelector';
import DialogOpenedHeader from './DialogOpenedHeader';
import DialogOpenedMessagesList from './DialogOpenedMessagesList';
import DialogForm from './DialogForm';
import DialogNoticeBarWaiting from './DialogNoticeBarWaiting';
import DialogOpenedClientError from './Errors/DialogOpenedClientError';
import DialogOpenedChannelError from './Errors/DialogOpenedChannelError';
import DialogOpenedChannelDeleted from './Errors/DialogOpenedChannelDeleted';
import DialogOpenedChannelEnabled from './Errors/DialogOpenedChannelEnabled';
import './index.css';

const DialogOpened = () => {
  const { t } = useTranslation();
  const { dialogId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const openedDialog = useSelector((state) =>
    dialogOpenedSelector(state, dialogId),
  );
  // const configData = useSelector((state) => state.configData);
  const dialogChannel = useSelector((state) =>
    dialogFormChannelSelector(state, { dialogId }),
  );
  const user = useSelector((state) => state.auth.user);
  const clients = useSelector((state) => state.clients);
  const users = useSelector((state) => state.users);
  // const dialogParticipants = useSelector(
  //   (state) => state.dialogParticipants[(openedDialog || {})._id],
  // );

  // } = useSelector((state) => ({
  //   // typingActions: typingActionsForDialogSelector(state, props),
  //   // readingActions: readingActionsForDialogSelector(state, props),
  //   // dialogs: Object.values(state.dialogs),
  //   // filterStatus: state.dialogsFilters.filterStatus,
  // }));

  useEffect(() => {
    const fetchDialog = async () => {
      try {
        await dispatch(
          getDialog(dialogId, (data) => {
            if (data?.err) {
              Toast.show({
                content: t('DialogOpened.pageNotFound'),
                position: 'bottom',
              });
              navigate(`/conversations`);
              return;
            }
            setLoading(false);
          }),
        );
        // await dispatch(getConfigData());
      } catch (error) {
        console.error('Failed to load dialog:', error);
      }
    };
    fetchDialog();
  }, [dialogId, dispatch, navigate, t]);

  const clientsArray = Object.values(clients);

  if (!loading && openedDialog && clientsArray?.length) {
    const { authorId, contactsId, metadata: { cc = [] } = {} } = openedDialog;

    // const clientParticipants = [];

    // if (dialogParticipants?.length) {
    //   dialogParticipants.forEach((cId) => {
    //     const clientParticipant = clientsArray.find(
    //       (client) =>
    //         client.contacts &&
    //         client.contacts.length &&
    //         client.contacts.find((c) => c.id === cId),
    //     );
    //     if (clientParticipant) {
    //       clientParticipants.push({
    //         ...clientParticipant,
    //         contactsIdForDisplay: cId,
    //         ...(contactsId && openedDialog.origin === 'mail'
    //           ? { isAuthor: contactsId === cId }
    //           : {}),
    //         ...(cc.includes(cId) &&
    //         (contactsId !== cId || clientParticipants.find((cp) => cp.isAuthor))
    //           ? { fromCC: true }
    //           : {}),
    //       });
    //     }
    //   });
    // }

    let dialogContact = { contacts: [] };

    if (contactsId) {
      dialogContact = clientsArray.find(
        (client) =>
          client?.contacts?.length &&
          client.contacts.find((c) => c.id === contactsId),
      );
    }

    if (authorId && (!dialogContact || (dialogContact && !dialogContact._id))) {
      if (users[authorId]) {
        dialogContact = users[authorId];
      } else if (clients[authorId]) {
        dialogContact = clients[authorId];
      }
    }

    return (
      <div className="dialog-opened">
        <div className="dialog-opened-top">
          <DialogOpenedHeader
            dialog={openedDialog}
            title={openedDialog.title}
            user={user}
            users={users}
            dialogId={dialogId}
            dialogContact={dialogContact}
          />
          <DialogNoticeBarWaiting openedDialog={openedDialog} />
        </div>
        <div className="dialog-opened-list">
          <DialogOpenedMessagesList dialog={openedDialog} />
        </div>
        {(user.account_info && user.account_info.status === 'disabled') ||
        openedDialog.origin === 'demo' ||
        // dialogs from vk board ar read-only
        (openedDialog.origin === 'vk' &&
          openedDialog.origin_conf &&
          openedDialog.origin_conf === 'board') ||
        (dialogChannel && dialogChannel.error) ||
        dialogChannel.isDeleted ||
        !dialogChannel.isEnabled ||
        openedDialog.origin === 'pbx' ||
        openedDialog.origin === 'phone' ||
        !dialogContact ? null : (
          <DialogForm dialog={openedDialog} dialogId={dialogId} />
        )}
        {!dialogContact ? <DialogOpenedClientError /> : null}
        {dialogChannel && dialogChannel.error ? (
          <DialogOpenedChannelError />
        ) : dialogChannel.isDeleted ? (
          <DialogOpenedChannelDeleted />
        ) : !dialogChannel.isEnabled ||
          openedDialog.origin === 'pbx' ||
          openedDialog.origin === 'phone' ? (
          <DialogOpenedChannelEnabled />
        ) : null}
      </div>
    );
  }

  return (
    <div
      style={{
        width: '100%',
        height: 'var(--app-height, 100vh)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <SpinLoading color="default" />
    </div>
  );
};

export default DialogOpened;
