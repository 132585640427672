import fetchJWT from '../../utils/fetchJWT';
import { logout } from '../../actions';
import { recieveTaskItem } from '../../reducers/tasks';
import backend from '../../configs/backend';

const readTask =
  ({ id, taskNumber }, cb) =>
  (dispatch) => {
    const request = new Request(
      backend.applyBaseUrl(
        `/api/tasks/read?${id ? `id=${id}` : `taskNumber=${taskNumber}`}`,
      ),
      {
        method: 'GET',
        mode: 'cors',
        cache: 'default',
      },
    );
    fetchJWT(request)
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        if (res.status === 401) {
          dispatch(logout());
        }
        return [];
      })
      .then((res) => {
        if (res.length) {
          dispatch(recieveTaskItem(res[0]));
        }
        if (!res.length) {
          if (cb) {
            cb();
          }
        }
      })
      .catch((e) => console.error(e));
  };

export default readTask;
