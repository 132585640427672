import React from 'react';
import { components as componentsConf } from '../../../../../../configs';
import getFilterStatusDialogCount from '../../../../../../utils/getFilterStatusDialogCount';
import useDialogsListMode from '../../../../../../hooks/dialogs/useDialogsListMode';
import useDialogsListFilterStatus from '../../../../../../hooks/dialogs/useDialogsListFilterStatus';
import useDialogsListStatusCounters from '../../../../../../hooks/dialogs/useDialogsListStatusCounters';
import useLoggedUser from '../../../../../../hooks/auth/useLoggedUser';
import { Tabs } from 'antd-mobile';
import { useTranslation } from 'react-i18next';

const DialogFilterStatusTabs = () => {
  const { t } = useTranslation();
  const { dialogsListMode } = useDialogsListMode();
  const { filterStatus: currentFilterStatus, updateFilterStatus } =
    useDialogsListFilterStatus();
  const { user: authorizedUser } = useLoggedUser();
  const { statusCounters: dialogsStatusCounters = {} } =
    useDialogsListStatusCounters();

  const {
    pages: {
      dialogs: {
        filter: {
          statusFiltersRelation: {
            [dialogsListMode]: { filterTabs: filterTabsStatuses },
          },
          statusLabels: {
            [dialogsListMode]: { filterTabs: filterTabsStatusLabels },
          },
          filterStatusTooltipText: {
            [dialogsListMode]: { filterTabs: filterTabsTooltipText },
          },
        },
      },
    },
  } = componentsConf;

  const handleTabChange = (filterStatus) => updateFilterStatus(filterStatus);

  const renderFilterStatusTabPane = (filterStatus) => {
    // const count = getFilterStatusDialogCount({
    //   filterStatus,
    //   statusCounters: dialogsStatusCounters,
    //   authorizedUser,
    // });

    return (
      <Tabs.Tab
        title={t(filterTabsStatusLabels[filterStatus])}
        key={filterStatus}
      />
    );
  };

  return (
    <Tabs
      activeKey={currentFilterStatus}
      onChange={handleTabChange}
      stretch={false}
    >
      {filterTabsStatuses.map(renderFilterStatusTabPane)}
    </Tabs>
  );
};

export default DialogFilterStatusTabs;
