/* eslint-disable no-console */
import fetchJWT from '../../utils/fetchJWT';
import { logout } from '../../actions';
import { recieveFbAccount, enableFbAccount } from '../../reducers/fb-accounts';
import backend from '../../configs/backend';

const getFbAccount = (id) => (dispatch) => {
  const request = new Request(
    backend.applyBaseUrl(`/api/fb-channel/accounts/read/${id}`),
    {
      method: 'GET',
      mode: 'cors',
      cache: 'default',
    },
  );
  fetchJWT(request)
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
      if (res.status === 401) {
        dispatch(logout());
      }
      return [];
    })
    .then((res) => {
      dispatch(recieveFbAccount(res[0]));

      dispatch(enableFbAccount(res[0]._id));
    })
    .catch((err) => console.error(err));
};

export default getFbAccount;
