import React, { useMemo, useState } from 'react';
import { Ellipsis, CheckList, Popup, SearchBar, Avatar } from 'antd-mobile';
import {
  UserOutline,
  FaceRecognitionOutline,
  StopOutline,
} from 'antd-mobile-icons';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { updateDialog } from '../../../../api/dialogs';

const resUserIconStyle = {
  width: '20px',
  height: '20px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#eeeeee',
  borderRadius: '4px',
};

const noResponsibleIconStyle = {
  width: '20px',
  height: '20px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '4px',
};

const ResponsiblePopup = ({ dialog, users, visible, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState('');
  const usersList = useMemo(() => {
    const list = Object.values(users)?.filter(
      (user) => !(user.status === 'deleted' || user.isDeleted),
    );
    if (searchText) {
      return list.filter((item) =>
        (item.name ? item.name : item.email)
          .toLowerCase()
          .includes(searchText.toLowerCase()),
      );
    }
    return list;
  }, [users, searchText]);

  const avatarUser = (user) => {
    if (user?.avatar) {
      return (
        <Avatar src={user.avatar} style={{ '--size': '20px' }} fit="cover" />
      );
    }
    return (
      <div style={resUserIconStyle}>
        {user?.email ? (
          <UserOutline fontSize={16} />
        ) : (
          <FaceRecognitionOutline fontSize={16} />
        )}
      </div>
    );
  };

  const setResponsible = (id) => {
    const { _id, channelId } = dialog;
    const user = usersList.find((val) => val._id === id);
    let data = {
      id: _id,
      channelId,
      responsibleUserName: null,
      responsibleId: null,
    };

    if (id) {
      data = {
        ...data,
        responsibleUserName: user.name,
        responsibleId: user._id,
        responsibleType: user.email ? 'user' : 'bot',
      };
    }

    dispatch(updateDialog(data));
  };

  return (
    <Popup
      visible={visible}
      onMaskClick={() => {
        onClose(false);
        setSearchText('');
      }}
      destroyOnClose
    >
      <div
        style={{
          padding: '10px',
          borderBottom: 'solid 1px var(--adm-color-border)',
        }}
      >
        <SearchBar
          placeholder={t('ResponsiblePopup.searchBarPlaceholder')}
          value={searchText}
          onChange={setSearchText}
        />
      </div>
      <div style={{ height: '400px', overflowY: 'auto' }}>
        <CheckList
          defaultValue={[dialog?.responsibleId]}
          onChange={(val) => {
            setResponsible(val[0]);
            onClose(false);
            setSearchText('');
          }}
        >
          {searchText ? null : (
            <CheckList.Item
              value={null}
              disabled={null === dialog?.responsibleId}
              prefix={
                <div style={noResponsibleIconStyle}>
                  <StopOutline fontSize={16} />
                </div>
              }
            >
              <Ellipsis
                direction="end"
                content={t('ResponsiblePopup.noResponsible')}
              />
            </CheckList.Item>
          )}
          {usersList.map((item) => (
            <CheckList.Item
              key={item._id}
              value={item._id}
              disabled={item._id === dialog?.responsibleId}
              prefix={avatarUser(item)}
            >
              <Ellipsis
                direction="end"
                content={item.name ? item.name : item.email}
              />
            </CheckList.Item>
          ))}
        </CheckList>
      </div>
    </Popup>
  );
};

export default ResponsiblePopup;
