import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import dialogAuthorSelector from '../../../selectors/dialogAuthorSelector';
import dialogClientSelector from '../../../selectors/dialogClientSelector';
import useLoggedUser from '../../auth/useLoggedUser';
import useErrorState from '../../useErrorState';
import usePrevious from '../../usePrevious';
import useDialogMessagesCount from '../useDialogMessagesCount';
import useDialogMessagesListLoadMore from './useDialogMessagesListLoadMore';
import useDialogMessagesListPositions from './useDialogMessagesListPositions';
import useDialogMessagesListReadState from './useDialogMessagesListReadState';
import { components as componentsConf } from '../../../configs';

const {
  pages: {
    dialogs: {
      messagesList: { readThrottleTimeout, batchSize },
    },
  },
} = componentsConf;

/**
 * @description Hook that provides all necessary data and functions for messages list.
 * @param {object} objectParams
 * @param {object} objectParams.dialog
 */
const useDialogMessagesListData = ({ dialog = {} }) => {
  const { error, handleError: handleReqError } = useErrorState();

  const [loading, setLoading] = useState(true);

  const { _id: dialogId } = dialog;

  const {
    listPositions: messagesListPositions,
    itemCount,
    requestListPositionsRefresh: requestMessagesListPositionsRefresh,
    mapItemIdToIndex,
    mapIndexToItemId,
    resetListPositions: resetMessagesListPositions,
  } = useDialogMessagesListPositions({
    dialogId,
    handleReqError,
  });

  /**
   * Positions update logic
   */
  useDialogMessagesCount({
    dialogId,
    onCountChange: requestMessagesListPositionsRefresh,
  });

  /**
   * Load more logic
   */
  const { loadMore } = useDialogMessagesListLoadMore({
    dialogId,
    handleReqError,
  });

  /**
   * Top loaded message id logic
   */
  const [topLoadedMessageId, setTopLoadedMessageId] = useState(null);
  const [hasMoreToLoad, setHasMoreToLoad] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);

  const getTopLoadedMessageIndex = useCallback(() => {
    if (topLoadedMessageId && messagesListPositions?.length) {
      return mapItemIdToIndex(topLoadedMessageId);
    }
    return -1;
  }, [mapItemIdToIndex, messagesListPositions, topLoadedMessageId]);

  const determineIfListHasMoreMessagesToLoad = useCallback(
    (topIndex) => {
      if (messagesListPositions[topIndex - 1]) {
        return true;
      }
      return false;
    },
    [messagesListPositions],
  );

  const loadMessagesAfterTopLoadedMessage = useCallback(
    (cb) => {
      if (messagesListPositions?.length && !loadingMore) {
        setLoadingMore(true);
        const topLoadedMessageIndex = getTopLoadedMessageIndex();

        const lastLoadedPositionIndex =
          topLoadedMessageIndex === -1
            ? messagesListPositions.length - 1
            : topLoadedMessageIndex;
        const nextTopLoadedMessageIndex = messagesListPositions[
          lastLoadedPositionIndex - batchSize + 1
        ]
          ? lastLoadedPositionIndex - batchSize + 1
          : // reached last pack
            0;

        const messageIdsToLoad = messagesListPositions.slice(
          nextTopLoadedMessageIndex,
          nextTopLoadedMessageIndex + batchSize,
        );

        if (messageIdsToLoad.length) {
          loadMore(messageIdsToLoad, () => {
            setLoadingMore(false);

            const nextTopLoadeMessageId = mapIndexToItemId(
              nextTopLoadedMessageIndex,
            );
            setTopLoadedMessageId(nextTopLoadeMessageId);

            if (
              !determineIfListHasMoreMessagesToLoad(nextTopLoadedMessageIndex)
            ) {
              setHasMoreToLoad(false);
            }

            if (cb) {
              cb();
            }
          });
        } else if (cb) {
          cb();
        }
      }
    },
    [
      getTopLoadedMessageIndex,
      determineIfListHasMoreMessagesToLoad,
      loadMore,
      loadingMore,
      mapIndexToItemId,
      messagesListPositions,
    ],
  );

  /**
   * Handle list positions loading state
   */
  useEffect(() => {
    if (loading && messagesListPositions) {
      loadMessagesAfterTopLoadedMessage(() => setLoading(false));
    }
  }, [
    messagesListPositions,
    topLoadedMessageId,
    loading,
    loadMessagesAfterTopLoadedMessage,
  ]);

  /**
   * List positions refresh handler:
   * 1. Sets loading state
   * 2. Requests positions refresh
   */
  const handleListPositionsRefresh = useCallback(() => {
    setLoading(true);
    requestMessagesListPositionsRefresh();
  }, [requestMessagesListPositionsRefresh]);

  /**
   * Handle selected dialog change
   */
  const prevDialogId = usePrevious(dialogId);
  useEffect(() => {
    if (prevDialogId && dialogId && prevDialogId !== dialogId) {
      handleListPositionsRefresh();
      // reset list state
      setTopLoadedMessageId(null);
      setHasMoreToLoad(true);
      setLoadingMore(false);
      resetMessagesListPositions(prevDialogId);
    }
  }, [
    prevDialogId,
    dialogId,
    handleListPositionsRefresh,
    resetMessagesListPositions,
  ]);

  const { canMessageBeRead, isMessageRead, processUnreadMessages } =
    useDialogMessagesListReadState({ readThrottleTimeout, dialogId });

  const {
    user: { _id: userId, name: userName = '', email: userEmail = '' } = {},
  } = useLoggedUser();

  const dialogAuthorSelectorWithInjectedProps = useCallback(
    (state) => dialogAuthorSelector(state, { dialog }),
    [dialog],
  );
  const dialogAuthor = useSelector(dialogAuthorSelectorWithInjectedProps);

  const dialogClientSelectorWithInjectedProps = useCallback(
    (state) => dialogClientSelector(state, { dialog }),
    [dialog],
  );
  const dialogClient = useSelector(dialogClientSelectorWithInjectedProps);

  const getLoadedItemIds = useCallback(() => {
    if (messagesListPositions?.length && getTopLoadedMessageIndex() !== -1) {
      return messagesListPositions.slice(getTopLoadedMessageIndex());
    }
    return [];
  }, [getTopLoadedMessageIndex, messagesListPositions]);

  const loadedItemIds = useMemo(() => getLoadedItemIds(), [getLoadedItemIds]);

  const hasContent = useMemo(
    () => itemCount === null || itemCount > 0,
    [itemCount],
  );

  return {
    loadedItemIds,
    loadMore: loadMessagesAfterTopLoadedMessage,
    hasMoreToLoad,
    hasContent,
    loading,
    error,
    canMessageBeRead,
    isMessageRead,
    processUnreadMessages,
    dialogAuthor,
    dialogClient,
    userName,
    userEmail,
    userId,
  };
};

export default useDialogMessagesListData;
