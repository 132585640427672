import { getDialogItems } from '../../reducers/dialogs';
import fetchJWT from '../../utils/fetchJWT';
import { logout } from '../../actions';
import backend from '../../configs/backend';

const getDialogsByArray =
  ({ dialogsIdArray }, cb) =>
  async (dispatch, getState) => {
    const { dialogs } = getState();

    const notCachedDialogIds = dialogsIdArray.filter((id) => !dialogs[id]);

    if (!notCachedDialogIds.length) {
      if (cb) {
        cb({});
      }

      return;
    }

    const request = new Request(
      backend.applyBaseUrl(`/api/dialogs/read/byArray/${dialogsIdArray}`),
      {
        method: 'GET',
        cache: 'default',
        mode: 'cors',
      },
    );

    fetchJWT(request)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        if (response.status === 401) {
          dispatch(logout());
        }

        throw new Error(
          `Cannot get dialogs by array: ${response.err || response.status}`,
        );
      })
      .then((response) => {
        if (cb) {
          cb({});
        }

        dispatch(getDialogItems(response));
      })
      .catch((err) => {
        console.error(err);

        if (cb) {
          cb({ err });
        }
      });
  };

export default getDialogsByArray;
