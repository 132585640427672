import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Image, ImageViewer } from 'antd-mobile';
import { connect } from 'react-redux';
import { getFileLink } from '../../api/files';
import proxyAttachments from '../../api/attachments/proxyAttachments';
import calculateAspectRatioFit from '../../utils/calculateAspectRatioFit';
import { images as imagesConf } from '../../configs';

const LightBoxWrapper = ({ image, getSrcLink, type, text }) => {
  const [imageSrc, setImageSrc] = useState(image.url || null);
  const [imageLoading, setImageLoading] = useState(!imageSrc);
  const [fetchInitiated, setFetchInitiated] = useState(false);
  const [lightBoxVisible, setLightBoxVisible] = useState(false);
  const [fetchError, setFetchError] = useState(null);
  const [imagePreviewStyle, setImagePreviewStyle] = useState({});

  const openLightBox = () => setLightBoxVisible(true);

  const closeLightBox = () => setLightBoxVisible(false);

  const markImageAsLoaded = () => setImageLoading(false);

  const lightBoxActions = {
    'open lightbox': openLightBox,
  };

  const imageKey = image.Key || image.key || image.file_id;

  const handleImageDownload = useCallback(
    (action = '') => {
      setFetchInitiated(true);

      if (fetchError) {
        setFetchError(null);
      }

      getSrcLink((res) => {
        if (res && res.url) {
          let fileUrl = res.url;
          if (res.originalFile) {
            const blob = new Blob([res.originalFile], {
              type:
                image.contentType === 'image'
                  ? 'image/jpeg'
                  : image.contentType,
            });
            fileUrl = window.URL.createObjectURL(blob);
          }

          setImageSrc(fileUrl);

          setTimeout(() => {
            markImageAsLoaded();

            const postLoadAction = lightBoxActions[action];

            if (postLoadAction) {
              postLoadAction();
            }
          }, 100);
        } else {
          setFetchError(true);
        }
        setFetchInitiated(false);
      });

      setTimeout(
        () => (fetchInitiated ? setFetchInitiated(false) : null),
        10000,
      );
    },
    [fetchError, fetchInitiated, getSrcLink, lightBoxActions],
  );

  useEffect(() => {
    if (imageKey && !imageSrc && !fetchInitiated && !fetchError) {
      handleImageDownload();
    }
  }, [imageKey, imageSrc, fetchInitiated, handleImageDownload, fetchError]);

  // const lightBoxViewModeHandler = useCallback(() => {
  //   if ((imageKey && !imageSrc && !fetchInitiated) || fetchError) {
  //     return handleImageDownload('open lightbox');
  //   }
  //   return lightBoxVisible ? closeLightBox() : openLightBox();
  // }, [
  //   fetchError,
  //   fetchInitiated,
  //   handleImageDownload,
  //   imageKey,
  //   imageSrc,
  //   lightBoxVisible,
  // ]);

  const resizeImageElement = useCallback(({ imgRef }) => {
    const { width: imgWidth, height: imgHeight } = imgRef;

    const {
      preview: { maxHeight, maxWidth },
    } = imagesConf;

    const { width, height } = calculateAspectRatioFit({
      imgHeight,
      imgWidth,
      maxHeight,
      maxWidth,
    });

    setImagePreviewStyle({ width, height });
  }, []);

  const handleImgLoadingError = () => (fetchError ? null : setFetchError(true));

  const handleImgLoadingSuccess = (e) => {
    const { target: imgRef = {} } = e;

    if (fetchError) {
      setFetchError(null);
    }

    if (imgRef && imageSrc && !imagePreviewStyle.width) {
      resizeImageElement({ imgRef });
    }
  };

  let HeightAndWidth = 200;
  if (type === 'quoted') {
    HeightAndWidth = 34;
  }

  return (
    <>
      <div className="lightBoxWrapper">
        <Image
          onClick={openLightBox}
          lazy
          fit="cover"
          src={imageSrc}
          onError={handleImgLoadingError}
          onLoad={handleImgLoadingSuccess}
          alt={
            image.filename ||
            image.fileName ||
            image.file_name ||
            image.title ||
            'image'
          }
          height={HeightAndWidth}
          width={HeightAndWidth}
        />
        {text ? (
          <div
            dangerouslySetInnerHTML={{ __html: text }}
            style={{ marginTop: '5px' }}
          />
        ) : null}
        <ImageViewer
          image={imageSrc}
          visible={lightBoxVisible}
          onClose={closeLightBox}
        />
      </div>
    </>
  );
};

LightBoxWrapper.propTypes = {
  image: PropTypes.object.isRequired,
  dialog: PropTypes.object.isRequired,
  needProxy: PropTypes.bool.isRequired,
  messageId: PropTypes.string.isRequired,
  getSrcLink: PropTypes.func.isRequired,
  type: PropTypes.string,
  text: PropTypes.string,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getSrcLink: (cb) =>
    dispatch(
      ownProps.needProxy
        ? proxyAttachments(
            {
              channelType: ownProps.dialog.origin,
              channelId: ownProps.dialog.channelId,
              Key:
                ownProps.image.Key ||
                ownProps.image.key ||
                ownProps.image.file_id,
              name:
                ownProps.image.filename ||
                ownProps.image.file_name ||
                ownProps.image.title,
              messageId: ownProps.messageId,
              setNameFromHeader: ownProps.image.setNameFromHeader,
              mode: 'src',
            },
            cb,
          )
        : getFileLink(
            {
              key:
                ownProps.image.Key ||
                ownProps.image.key ||
                ownProps.image.file_id,
              origin: ownProps.dialog.origin,
              messageId: ownProps.messageId,
              mode: 'src',
            },
            cb,
          ),
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(LightBoxWrapper);
