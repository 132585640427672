export const ENQUEUE_NOTIFICATION = 'ENQUEUE_NOTIFICATION';
export const DEQUEUE_NOTIFICATION = 'DEQUEUE_NOTIFICATION';

const initialNotificationsState = {
  queue: [],
};

/**
 * @param {*} notification
 * @param {string} notification.type supported types: dialog, call
 * @param {object} notification.data Object containing data for notification
 * (Ex: dialog/call = { _id, channelId, direction, ...etc})
 * @returns {object} Returns redux action object.
 */

export const enqueueNotification = (notification) => ({
  type: ENQUEUE_NOTIFICATION,
  payload: {
    notification,
  },
});

export const dequeueNotification = () => ({
  type: DEQUEUE_NOTIFICATION,
  payload: {},
});

export default function reducer(
  state = initialNotificationsState,
  action = {},
) {
  switch (action.type) {
    case ENQUEUE_NOTIFICATION: {
      return { ...state, queue: [...state.queue, action.payload.notification] };
    }
    case DEQUEUE_NOTIFICATION: {
      const updatedQueue = [...state.queue];
      updatedQueue.shift();
      return { ...state, queue: [...updatedQueue] };
    }
    default: {
      return state;
    }
  }
}

export const notificationsQueueSelector = (state) => [
  ...state.notifications.queue,
];
