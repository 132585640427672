import { receiveInvite } from '../../reducers/invites';
import fetchJWT from '../../utils/fetchJWT';
import { logout } from '../../actions';
import backend from '../../configs/backend';

const getInvite = (_id) => async (dispatch) => {
  const request = new Request(
    backend.applyBaseUrl(`/api/users/read/invites?id=${_id}`),
    {
      method: 'GET',
      cache: 'default',
      mode: 'cors',
    },
  );
  fetchJWT(request)
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
      if (res.status === 401) {
        dispatch(logout());
      }
      return [];
    })
    .then((res) => dispatch(receiveInvite(res[0])))
    .catch((err) => console.error(err));
};

export default getInvite;
