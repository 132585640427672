import {
  SHOW_MINE,
  SHOW_OPENED,
  SHOW_NOT_ASSIGNED_RESP,
  SHOW_WAITING,
  SHOW_CLOSED,
  SHOW_ALL,
  SHOW_NEW,
  SHOW_OLD,
  SHOW_RECENT_UPDATED,
  SHOW_OLDEST_UPDATED,
} from '../reducers/dialogsFilters';
import {
  SHOW_MINE_CALLS,
  SHOW_IN_CALLS,
  SHOW_OUT_CALLS,
  SHOW_NOT_ASSIGNED_RESP_CALLS,
  SHOW_MISSED_CALLS,
  SHOW_ALL_CALLS,
  SHOW_RECENT_UPDATED_CALLS,
  SHOW_OLDEST_UPDATED_CALLS,
  SHOW_NEW_CALLS,
  SHOW_OLD_CALLS,
} from '../reducers/callsFilters';

const componentsConfig = {
  common: {
    copyIcon: {
      statusMessages: {
        success: 'Текст скопирован',
        failure: 'Не удалось, попробуйте скопировать вручную',
      },
    },
  },
  contentWrappers: {
    drawer: {
      validPlacements: ['right', 'left'],
    },
  },
  sidebar: {
    loggedUserOptions: {
      adminBadgeColor: '#69C0FF',
      adminTooltipText: 'Вы администратор',
      adminTooltipMouseEnterDelay: 0.5,
      mobileAppIconColor: '#8C8C8C',
      networkStatuses: ['online', 'away', 'offline'],
      networkStatusColors: {
        online: '#52C41A',
        away: '#FA8C16',
        offline: '#BFBFBF',
      },
      networkStatusLabels: {
        online: 'Активен',
        away: 'Недоступен',
        offline: 'Не в сети',
      },
      networkError: {
        notificationId: 'networkStatusUpdateError',
        message: 'Упс, что-то пошло не так!',
        description:
          'Не удалось сменить статус.\nПопробуйте обновить страницу или войти в Еадеск заново.\nА также проверьте подключение к интернету.',
      },
    },
  },
  pages: {
    dialogs: {
      list: {
        skeletonParagraphConfig: {
          rows: 5,
          width: ['100%', '50%', '100%', '50%', '100%'],
        },
        overscanCount: 5,
        fallbackItemSize: 64,
        recalcThrottleTimeout: 50,
        loadMoreDebounceTimeout: 1000,
        itemCountRefreshTimeout: 350,
        item: {
          labels: {
            maxTextLength: 5,
            previewLimit: 3,
          },
          lastMessageFallbackText: '',
          defaultAvatarBgColor: '#65aadd',
          typeOfMessages: {
            err: 'Ошибка',
            message: 'Ответ',
            comment: 'Коммент',
            robot: 'Робот',
          },
        },
        callAvatarIconAltByStatus: {
          missed: 'Пропущенный звонок',
          inbound: 'Входящий звонок',
          outbound: 'Исходящий звонок',
        },
      },
      messagesList: {
        skeletonParagraphConfig: {
          rows: 5,
          width: ['100%', '50%', '100%', '50%', '100%'],
        },
        itemCountRefreshTimeout: 350,
        batchSize: 25,
      },
      filter: {
        sections: {
          dialogs: ['filters', 'extendedFilters'],
          calls: ['filters', 'channels'],
        },
        sectionTitles: {
          sort: 'Сортировка',
          filters: 'Быстрые фильтры',
          extendedFilters: 'Расширенный фильтр',
          tags: 'Теги',
        },
        availableSortTypes: {
          dialogs: {
            byUpdate: [SHOW_RECENT_UPDATED, SHOW_OLDEST_UPDATED],
            byLastMessage: [SHOW_NEW, SHOW_OLD],
          },
          calls: {
            byUpdate: [SHOW_RECENT_UPDATED_CALLS, SHOW_OLDEST_UPDATED_CALLS],
            byLastMessage: [SHOW_NEW_CALLS, SHOW_OLD_CALLS],
          },
        },
        availableStatusFilters: {
          dialogs: {
            SHOW_MINE,
            SHOW_OPENED,
            SHOW_NOT_ASSIGNED_RESP,
            SHOW_WAITING,
            SHOW_CLOSED,
            SHOW_ALL,
          },
          calls: {
            SHOW_MINE_CALLS,
            SHOW_IN_CALLS,
            SHOW_OUT_CALLS,
            SHOW_NOT_ASSIGNED_RESP_CALLS,
            SHOW_MISSED_CALLS,
            SHOW_ALL_CALLS,
          },
        },
        statusFiltersRelation: {
          dialogs: {
            mainFilter: [SHOW_NOT_ASSIGNED_RESP, SHOW_WAITING, SHOW_CLOSED],
            filterTabs: [SHOW_MINE, SHOW_OPENED, SHOW_ALL],
          },
          calls: {
            mainFilter: [
              SHOW_NOT_ASSIGNED_RESP_CALLS,
              SHOW_IN_CALLS,
              SHOW_OUT_CALLS,
            ],
            filterTabs: [SHOW_MINE_CALLS, SHOW_MISSED_CALLS, SHOW_ALL_CALLS],
          },
        },
        statusLabels: {
          dialogs: {
            mainFilter: {
              SHOW_ALL: 'Все диалоги',
              SHOW_MINE: 'Мои диалоги',
              SHOW_MISSED: 'Пропущенные',
              SHOW_OPENED: 'Открытые',
              SHOW_CLOSED: 'Закрытые',
              SHOW_WAITING: 'Отложенные',
              SHOW_NOT_ASSIGNED_RESP: 'Не назначены',
              SHOW_BOT_ASSIGNED: 'Обработка ботом',
            },
            filterTabs: {
              SHOW_ALL: 'DialogFilterStatusTabs.filterTabs.showAll', //'Все'
              SHOW_MINE: 'DialogFilterStatusTabs.filterTabs.showMine', //'Мои'
              SHOW_OPENED: 'DialogFilterStatusTabs.filterTabs.showOpened', //'Открытые'
            },
          },
          calls: {
            mainFilter: {
              SHOW_MINE_CALLS: 'Мои звонки',
              SHOW_IN_CALLS: 'Входящие',
              SHOW_OUT_CALLS: 'Исходящие',
              SHOW_NOT_ASSIGNED_RESP_CALLS: 'Не назначены',
              SHOW_MISSED_CALLS: 'Пропущенные',
              SHOW_ALL_CALLS: 'Все звонки',
            },
            filterTabs: {
              SHOW_MINE_CALLS: 'Мои',
              SHOW_MISSED_CALLS: 'Пропущ.',
              SHOW_ALL_CALLS: 'Все',
            },
          },
        },
        sortLabels: {
          dialogs: {
            byUpdate: 'По обновлению',
            byLastMessage: 'По сообщению клиента',
          },
          calls: {
            byUpdate: 'По обновлению',
            byLastMessage: 'По сообщению клиента',
          },
        },
        sortTooltipText: {
          dialogs: {
            SHOW_RECENT_UPDATED:
              'Как в мессенджерах: сначала недавно обновлённые диалоги',
            SHOW_OLDEST_UPDATED:
              'Как в мессенджерах: сначала давно не обновляемые диалоги',
            SHOW_NEW: ' Как в почте: сначала диалоги, где клиент писал недавно',
            SHOW_OLD: 'Как в почте: сначала диалоги, где клиент давно не писал',
          },
          calls: {
            SHOW_RECENT_UPDATED_CALLS:
              'Как в мессенджерах: сначала недавно обновлённые звонки',
            SHOW_OLDEST_UPDATED_CALLS:
              'Как в мессенджерах: сначала давно не обновляемые звонки',
            SHOW_NEW_CALLS:
              'Как в почте: сначала звонки, в которых недавно была активность',
            SHOW_OLD_CALLS:
              'Как в почте: сначала звонки, где давно не было активности',
          },
        },
        filterStatusTooltipText: {
          dialogs: {
            filterTabs: {
              SHOW_MINE: 'Незакрытые диалоги, за которые вы отвечаете',
              SHOW_OPENED: 'Диалоги в работе у всей команды',
              SHOW_ALL: 'Все открытые, закрытые и отложенные диалоги',
            },
          },
          calls: {
            filterTabs: {
              SHOW_MINE_CALLS: 'Звонки, за которые вы отвечаете',
              SHOW_MISSED_CALLS: 'Пропущенные звонки',
              SHOW_ALL_CALLS: 'Все входящие и исходящие звонки',
            },
          },
        },
        extendedFiltersTitles: {
          responsibleUsersSection: {
            allUsers: 'Все пользователи',
            deletedUsers: 'Удаленные пользователи',
            withoutResponsible: 'Без ответственного',
            bot: 'Робот',
          },
          channelSection: {
            allChannels: 'Все каналы',
            deletedChannels: 'Удаленные каналы',
          },
        },
      },
      search: {
        debounceTimeout: 350,
        contentSearchMode: {
          labels: {
            dialogClient: 'Клиент',
            dialogTitle: 'Название',
            // dialogMessagesText: 'Сообщение',
          },
          placeholders: {
            dialogClient: 'Искать по клиентам',
            dialogTitle: 'Искать по названию',
            // dialogMessagesText: 'Искать по сообщениям',
          },
        },
      },
      openedDialog: {
        notSelected: {
          emptyCaption: 'Выберите диалог',
        },
      },
      dialogListOptions: {
        listMode: {
          availableModes: ['dialogs', 'calls'],
          tooltipText: 'Режим ленты',
          modeLabels: {
            dialogs: 'Все диалоги',
            calls: 'Звонки',
          },
          modeIconNames: {
            dialogs: 'MessageOutlined',
            calls: 'PhoneOutlined',
          },
        },
        outcomingDialog: {
          availableOrigins: ['mail', 'whatsapp'],
          tooltipText: 'Новое сообщение',
          originLabels: {
            mail: 'Электронная почта',
            whatsapp: 'Ватсап',
          },
          channelSelection: {
            selectionFailedMessageText:
              'Этот канал недоступен. Выберите другой',
          },
        },
      },
    },
    channels: {
      ig: {
        cannotDeleteTooltipText:
          'Этот канал невозможно удалить, вместо этого отключите его.',
        paidPeriodOverWarning: 'Закончился оплаченный период',
      },
      whatsapp: {
        waba: {
          cannotDeleteTooltipText:
            'Этот канал невозможно удалить, вместо этого отключите его.',
        },
      },
    },
  },
  payment: {
    page: {
      title: 'Оплатить Еадеск',
      description: 'В этом разделе вы можете оплатить доступ к сервису',
    },
    link: {
      error: {
        title: 'Ошибка во время генерации ссылки на оплату',
        description: 'Попробуйте повторить запрос позже',
      },
    },
    result: {
      error: {
        title: 'Статус оплаты',
        description: 'Оплата не прошла',
      },
      success: {
        title: 'Статус оплаты',
        description: 'Оплата прошла успешно',
      },
    },
    form: {
      info: {
        title: {
          testPeriod: '690 ₽ за пользователя в месяц',
          postTestPeriod: 'Единый тариф для всех целей',
        },
      },
      modal: {
        title: 'Настройте вашу подписку',
        okButton: 'Оформить подписку',
        cancelButton: 'Отмена',
      },
      alert: {
        title: 'Подписка отменена',
        message:
          'Мы не смогли автоматически продлить подписку на следующий период. Проверьте корректность работы карты и повторно оформите подписку.',
      },
    },
  },
};

export default componentsConfig;
