import { backend } from '../configs';

// TODO -> use this util in fetch_jwt
const getNewJwtToken = async (refreshToken) => {
  const request = new Request(backend.applyBaseUrl('/api/users/read'), {
    headers: {
      Authorization: refreshToken,
    },
  });

  const response = await fetch(request);

  if (response.ok) {
    const jwt = response.headers.get('jwt');

    if (jwt) {
      return jwt;
    }
  }

  return null;
};

export default getNewJwtToken;
