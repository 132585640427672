// https://stackoverflow.com/a/14731922/10943520

/**
 * This function calculates aspect ratio for images.
 *
 * @param {Object} objectParams
 * @param {Number} objectParams.imgWidth
 * @param {Number} objectParams.imgHeight
 * @param {Number} objectParams.maxWidth
 * @param {Number} objectParams.maxHeight
 * @return {Object} { width, height }
 */

const calculateAspectRatioFit = ({
  imgHeight,
  imgWidth,
  maxHeight,
  maxWidth,
}) => {
  const ratio = Math.min(maxWidth / imgWidth, maxHeight / imgHeight);
  return { width: imgWidth * ratio, height: imgHeight * ratio };
};

export default calculateAspectRatioFit;
