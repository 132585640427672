import * as R from 'ramda';
import { createSelector } from 'reselect';
import { release as releaseConf } from '../configs';

export const SET_CACHE_SESSION_SAVED = 'SET_CACHE_SESSION_SAVED';
export const UPDATE_CACHE_NEWS_READ_AT = 'UPDATE_CACHE_NEWS_READ_AT';
export const UPDATE_ONBOARDING_CACHE = 'UPDATE_ONBOARDING_CACHE';
export const SET_DIALOG_ONBOARDING = 'SET_DIALOG_ONBOARDING';

const initialCacheStatus = {
  currentSessionSaved: false,
  newsReadAt: 0,
  isDialogOnboardingStarted: false,
  onboarding: {},
};

export const setIsDialogOnboardingStarted = (status) => ({
  type: SET_DIALOG_ONBOARDING,
  payload: status,
});

export const setCacheSessionSaved = (currentSessionSaved) => ({
  type: SET_CACHE_SESSION_SAVED,
  payload: { currentSessionSaved },
});

export const updateCacheNewsReadAt = () => ({
  type: UPDATE_CACHE_NEWS_READ_AT,
  payload: {},
});

export const updateOnboardingCache = (onboardingCache) => ({
  type: UPDATE_ONBOARDING_CACHE,
  payload: { onboarding: onboardingCache },
});

export default function reducer(
  state = initialCacheStatus,
  action = { type: '' },
) {
  switch (action.type) {
    case SET_DIALOG_ONBOARDING: {
      if (action.payload) {
        return {
          ...state,
          isDialogOnboardingStarted: action.payload,
          onboarding: {},
        };
      }
      return {
        ...state,
        isDialogOnboardingStarted: action.payload,
      };
    }
    case SET_CACHE_SESSION_SAVED: {
      return {
        ...state,
        currentSessionSaved: action.payload.currentSessionSaved,
      };
    }
    case UPDATE_CACHE_NEWS_READ_AT: {
      return { ...state, newsReadAt: Date.now() };
    }
    case UPDATE_ONBOARDING_CACHE: {
      return { ...state, onboarding: action.payload.onboarding };
    }
    default: {
      return state;
    }
  }
}

export const cacheStatusSelector = (state) =>
  R.propOr({}, 'cacheStatus', state);

export const newsReadSelector = createSelector(
  [cacheStatusSelector],
  (cacheStatus) => cacheStatus.newsReadAt >= releaseConf.releaseDate,
);

export const onboardingCacheSelector = createSelector(
  [cacheStatusSelector],
  (cacheStatus) => cacheStatus.onboarding || {},
);

export const dialogOnboardingSelector = createSelector(
  [cacheStatusSelector],
  (cacheStatus) => cacheStatus.isDialogOnboardingStarted || {},
);
