import * as R from 'ramda';
import { createSelector } from 'reselect';

export const RECEIVE_TICKET = 'RECEIVE_TICKET';
export const RECEIVE_TICKETS = 'RECEIVE_TICKETS';

const Ticket = {};

function ticketFactory(ticket) {
  return { [ticket._id]: ticket };
}

function ticketsReducer(state = Ticket, action = {}) {
  switch (action.type) {
    case RECEIVE_TICKET: {
      return { ...state, ...action.payload.ticket };
    }
    case RECEIVE_TICKETS: {
      return R.mergeAll(R.map(ticketFactory, action.payload.tickets));
    }
    default: {
      return state;
    }
  }
}

export const receiveTicket = (ticket) => ({
  type: RECEIVE_TICKET,
  payload: { ticket },
});

export const receiveTickets = (tickets) => ({
  type: RECEIVE_TICKETS,
  payload: { tickets },
});

export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case RECEIVE_TICKET: {
      return R.assoc(
        action.payload._id,
        ticketsReducer(state[action.payload._id], action),
      )(state);
    }
    case RECEIVE_TICKETS: {
      return R.mergeRight(state, ticketsReducer(state, action));
    }
    default: {
      return state;
    }
  }
}

export const ticketsSelector = (state) => R.propOr({}, 'tickets', state);

export const ticketIdSelector = (state, ownProps) => {
  const { params: { ticketId } = {} } = ownProps.match;
  return ticketId || ownProps.id || ownProps._id;
};

export const ticketsArraySelector = (state) =>
  R.values(R.propOr({}, 'tickets', state));

export const ticketByIdSelector = createSelector(
  ticketIdSelector,
  ticketsSelector,
  (id, tickets) => R.propOr({}, id, tickets),
);

const sortByUpdatedAt = R.sortBy(R.ascend(R.prop('updatedAt')));

export const ticketsSortedByUpdatedAtSelector = createSelector(
  ticketsArraySelector,
  (tickets) => sortByUpdatedAt(tickets),
);
