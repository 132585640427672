/**
 * @description This function checks if we need to show notification
 * for the newly created dialog (only incoming calls at this moment).
 * @param {object} dialog
 * @param {object} reduxStore
 * @returns {boolean} Returns true if condition passes, else returns false.
 */

const checkDialogNotificationCondition = async (dialog = {}, reduxStore) => {
  const { origin, direction, channelId } = dialog;

  if (origin !== 'pbx' || direction !== 'inbound') {
    return false;
  }

  const {
    pbxAccounts = {},
    auth: { user: loggedUser },
  } = await reduxStore.getState();

  const callChannel = pbxAccounts[channelId];

  if (!callChannel) {
    return false;
  }

  const { notifications = {} } = callChannel;

  if (!notifications[loggedUser._id]) {
    return false;
  }

  return true;
};

export default checkDialogNotificationCondition;
