import { subscribeToEvents } from './websocketsActions';

/* Делает fetch, добавляя jwt или refresh_token
 * Когда использует refresh_token обновляет jwt в jwtStorage
 * Returns promise response
 */

export default function fetchJWT(...args) {
  const request = new Request(...args);
  const request2 = request.clone();
  // Поймем где хранятся токены. sessionStorage win
  const jwtStorage = sessionStorage.getItem('refresh_token')
    ? sessionStorage
    : localStorage;

  const jwt = jwtStorage.getItem('jwt');
  const refreshToken = jwtStorage.getItem('refresh_token');

  function getNewJwtToken() {
    console.log('V3');
    request2.headers.set('Authorization', refreshToken);
    return fetch(request2).then((response) => {
      if (response.ok) {
        // eslint-disable-next-line no-shadow
        const jwt = response.headers.get('jwt');
        if (jwt) {
          jwtStorage.setItem('jwt', jwt);
        }
      }
      return response;
    });
  }

  if (!jwt && !refreshToken) {
    return new Promise((resolve) => {
      resolve({ ok: false });
    });
  }
  /* Возможны 3 вариантв:
   * 1. нет  (refresh_token и jwt)  (не залогиненный пользователь)
   * 2. Есть (refresh_token и jwt)  (залогиненный пользователь)
   * 3. Есть refresh_token и пока нет jwt  (только что залогинившийся по имени/паролю пользователь)
   */
  if (!refreshToken) {
    // Вариант 1
    console.log('V1');
    return fetch(request);
  }

  if (jwt) {
    // Вариант 2
    console.log('V2');
    request.headers.set('Authorization', `JWT ${jwt}`);
    // TODO -> change ws subscription logic (do not subscribe in every request)
    subscribeToEvents(jwt);
    return fetch(request).then((response) => {
      if (response.ok) {
        return response;
      }
      if (response.status === 401) {
        return getNewJwtToken();
      }
      return response;
    });
  } else {
    // Вариант 3
    return getNewJwtToken();
  }
}
