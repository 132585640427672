import * as R from 'ramda';
import { createSelector } from 'reselect';
import { integrations as integrationsConf } from '../configs';

export const RECIEVE_AMOCRM_INTEGRATION_DATA =
  'RECIEVE_AMOCRM_INTEGRATION_DATA';
export const CLEAR_AMOCRM_INTEGRATION_DATA = 'CLEAR_AMOCRM_INTEGRATION_DATA';

const defaultClientState = { contacts: [], companies: [], leads: [] };

function amoCrmDataReducer(state = defaultClientState, action = { type: '' }) {
  switch (action.type) {
    case RECIEVE_AMOCRM_INTEGRATION_DATA: {
      const {
        amoCrm: {
          items: { amoCrmKeyByType },
        },
      } = integrationsConf;
      const { amoCrmData } = action.payload;
      // overwrite client state to keep only fresh data
      return amoCrmData.reduce((acc, item) => {
        const typeKey = amoCrmKeyByType[item.type];
        return { ...acc, [typeKey]: [...acc[typeKey], item] };
      }, defaultClientState);
    }
    default: {
      return state;
    }
  }
}

export const recieveAmoCrmData = ({ amoCrmData, clientId }) => ({
  type: RECIEVE_AMOCRM_INTEGRATION_DATA,
  payload: {
    amoCrmData,
    clientId,
  },
});

export const clearAmoCrmIntegrationData = () => ({
  type: CLEAR_AMOCRM_INTEGRATION_DATA,
  payload: {},
});

export default function reducer(state = {}, action = { type: '' }) {
  switch (action.type) {
    case RECIEVE_AMOCRM_INTEGRATION_DATA: {
      return R.assoc(
        action.payload.clientId,
        amoCrmDataReducer(state[action.payload.clientId], action),
      )(state);
    }
    case CLEAR_AMOCRM_INTEGRATION_DATA: {
      return {};
    }
    default: {
      return state;
    }
  }
}

export const amoCrmDataSelector = (state) => state.amoCrmData;

export const amoCrmDataArraySelector = createSelector(
  amoCrmDataSelector,
  (amoCrmData) => Object.values(amoCrmData),
);
