import { createSelector } from 'reselect';

const getUsers = (state) => state.users;

const getChatBots = (state) => state.chatBots;

const getLoggedInUser = (state) => state.auth.user;

const getDialog = (state) => state.dialog || state.task;

const dialogResponsibleSelector = createSelector(
  [getDialog, getUsers, getChatBots, getLoggedInUser],
  /**
   * @returns {Object}
   */
  (dialog = {}, users, chatBots, loggedInUser) => {
    const { responsibleId } = dialog;

    const responsibleUser = users[responsibleId];

    if (responsibleUser) {
      return {
        ...responsibleUser,
        isMe: responsibleUser._id === loggedInUser._id,
      };
    }

    const responsibleBot = chatBots[responsibleId];

    return responsibleBot;
  },
);

export default dialogResponsibleSelector;
