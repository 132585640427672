import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loggedUserSelector } from '../../reducers/auth';
import { updateUser } from '../../api/users';
import { loginUser } from '../../api/auth';
import { logout as logoutAction } from '../../actions';

const useLoggedUser = () => {
  const dispatch = useDispatch();

  const update = useCallback(
    (user, cb) => dispatch(updateUser(user, false, cb)),
    [dispatch],
  );

  const login = useCallback((user) => dispatch(loginUser(user)), [dispatch]);

  const logout = useCallback(() => dispatch(logoutAction()), [dispatch]);

  const user = useSelector(loggedUserSelector);

  return { user, update, login, logout };
};

export default useLoggedUser;
