import * as R from 'ramda';
import { createSelector } from 'reselect';

export const RECIEVE_IG_ACCOUNTS = 'RECIEVE_IG_ACCOUNTS';
export const RECIEVE_IG_ACCOUNT = 'RECIEVE_IG_ACCOUNT';
export const UPDATE_IG_ACCOUNT = 'UPDATE_IG_ACCOUNT';
export const SET_IG_ACC_NAME = 'SET_IG_ACC_NAME';
export const SET_IG_ACC_LOGIN = 'SET_IG_ACC_LOGIN';
export const SET_IG_ACC_PASSWORD = 'SET_IG_ACC_PASSWORD';
export const SET_IG_ACC_CONF = 'SET_IG_ACC_CONF';
export const DELETE_IG_ACCOUNT = 'DELETE_IG_ACCOUNT';
export const DELETE_IG_ACCOUNT_DRAFT = 'DELETE_IG_ACCOUNT_DRAFT';
export const ENABLE_IG_ACCOUNT = 'ENABLE_IG_ACCOUNT';
export const DISABLE_IG_ACCOUNT = 'DISABLE_IG_ACCOUNT';
export const RESET_IG_ACCOUNT = 'RESET_IG_ACCOUNT';

function igAccountsFactory(account) {
  return { [account._id]: account };
}

function igAccountsReducer(state = {}, action = { type: '' }) {
  switch (action.type) {
    case RECIEVE_IG_ACCOUNTS: {
      return R.mergeAll(R.map(igAccountsFactory, action.payload.igAccounts));
    }
    case RECIEVE_IG_ACCOUNT: {
      return { ...state, ...action.payload.igAccount };
    }
    case ENABLE_IG_ACCOUNT: {
      return { ...state, isEnabled: true };
    }
    case DISABLE_IG_ACCOUNT: {
      return { ...state, isEnabled: false };
    }
    case DELETE_IG_ACCOUNT: {
      return { ...state, isDeleted: true };
    }
    case SET_IG_ACC_NAME: {
      return { ...state, name: action.payload.name };
    }
    case SET_IG_ACC_LOGIN: {
      return { ...state, login: action.payload.login };
    }
    case SET_IG_ACC_PASSWORD: {
      return { ...state, password: action.payload.password };
    }
    case SET_IG_ACC_CONF: {
      return { ...state, conf: action.payload.conf };
    }
    case UPDATE_IG_ACCOUNT: {
      const {
        payload: {
          igAccountId,
          name,
          login,
          password,
          type,
          conf,
          directChannelConf,
          isProxied,
          proxyOptions,
        } = {},
      } = action;

      return {
        ...state,
        ...(!state._id && igAccountId ? { _id: igAccountId } : {}),
        ...(name !== undefined ? { name } : {}),
        ...(login !== undefined ? { login } : {}),
        ...(password !== undefined ? { password } : {}),
        ...(type !== undefined ? { type } : {}),
        ...(conf !== undefined
          ? // merge updated data into current conf
            { conf: { ...(state.conf || {}), ...conf } }
          : {}),
        ...(directChannelConf !== undefined && state.igChannels
          ? {
              igChannels: state.igChannels.reduce((channels, currChannel) => {
                const newCurrChannel = { ...currChannel };

                if (newCurrChannel.type === 'instdir') {
                  const { conf: prevDirectChannelConf = {} } = newCurrChannel;
                  // merge updated data into current conf
                  newCurrChannel.conf = {
                    ...prevDirectChannelConf,
                    ...directChannelConf,
                  };
                }

                channels.push(newCurrChannel);

                return channels;
              }, []),
            }
          : {}),
        ...(isProxied !== undefined ? { isProxied } : {}),
        ...(proxyOptions ? { proxyOptions } : {}),
      };
    }
    default: {
      return state;
    }
  }
}

export const recieveIgAccounts = (igAccounts) => ({
  type: RECIEVE_IG_ACCOUNTS,
  payload: { igAccounts },
});

export const recieveIgAccount = (igAccount) => ({
  type: RECIEVE_IG_ACCOUNT,
  payload: { igAccount },
});

export const setIgAccName = (_id, name) => ({
  type: SET_IG_ACC_NAME,
  payload: {
    _id,
    name,
  },
});

export const setIgAccLogin = (_id, login) => ({
  type: SET_IG_ACC_LOGIN,
  payload: {
    _id,
    login,
  },
});

export const setIgAccPassword = (_id, password) => ({
  type: SET_IG_ACC_PASSWORD,
  payload: {
    _id,
    password,
  },
});

export const setIgAccConf = (_id, conf) => ({
  type: SET_IG_ACC_CONF,
  payload: {
    _id,
    conf,
  },
});

export const renewIgAccount = ({
  igAccountId,
  name,
  login,
  password,
  conf,
  directChannelConf,
  isProxied,
  proxyOptions,
  type,
}) => ({
  type: UPDATE_IG_ACCOUNT,
  payload: {
    igAccountId,
    name,
    login,
    password,
    conf,
    directChannelConf,
    isProxied,
    proxyOptions,
    type,
  },
});

export const removeIgAccount = (_id) => ({
  type: DELETE_IG_ACCOUNT,
  payload: {
    _id,
  },
});

export const deleteIgAccountDraft = () => ({
  type: DELETE_IG_ACCOUNT_DRAFT,
});

export const enableIgAccount = (_id) => ({
  type: ENABLE_IG_ACCOUNT,
  payload: { _id },
});

export const disableIgAccount = (_id) => ({
  type: DISABLE_IG_ACCOUNT,
  payload: { _id },
});

export const resetIgAccount = (igAccount) => ({
  type: RESET_IG_ACCOUNT,
  payload: { igAccount },
});

export default function reducer(state = {}, action = { type: '' }) {
  switch (action.type) {
    case RECIEVE_IG_ACCOUNTS: {
      return R.mergeRight(state, igAccountsReducer(state, action));
    }
    case RECIEVE_IG_ACCOUNT: {
      return R.assoc(
        action.payload.igAccount._id,
        igAccountsReducer(state[action.payload.igAccount._id], action),
      )(state);
    }
    case ENABLE_IG_ACCOUNT:
    case DISABLE_IG_ACCOUNT:
    case DELETE_IG_ACCOUNT:
    case SET_IG_ACC_NAME:
    case SET_IG_ACC_LOGIN:
    case SET_IG_ACC_PASSWORD:
    case SET_IG_ACC_CONF: {
      return R.assoc(
        action.payload._id,
        igAccountsReducer(state[action.payload._id], action),
      )(state);
    }
    case UPDATE_IG_ACCOUNT: {
      return R.assoc(
        action.payload.igAccountId,
        igAccountsReducer(state[action.payload.igAccountId], action),
      )(state);
    }
    case RESET_IG_ACCOUNT: {
      // reseting state = no choice but to overwrite field!
      return R.assoc(
        action.payload.igAccount._id,
        action.payload.igAccount,
      )(state);
    }
    case DELETE_IG_ACCOUNT_DRAFT: {
      return R.dissoc('new')(state);
    }
    default: {
      return state;
    }
  }
}

export const igAccountsSelector = (state) => state.igAccounts;

export const igAccountsArraySelector = createSelector(
  igAccountsSelector,
  (igAccounts) =>
    R.filter(
      (_) => !_.isDeleted && _._id && _._id !== 'new',
      R.values(igAccounts),
    ),
);

export const deletedIgAccountsArraySelector = createSelector(
  igAccountsSelector,
  (igAccounts) => R.filter((_) => _.isDeleted, R.values(igAccounts)),
);

export const igAccountIdSelector = (state, ownProps) => {
  const { match: { params: { channelId } = {} } = {} } = ownProps;
  return channelId || ownProps.id || ownProps._id;
};

export const igAccountSelector = createSelector(
  igAccountsSelector,
  igAccountIdSelector,
  (accounts, id) => R.propOr({}, id, accounts),
);
