import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useDialogsListData from '../../../hooks/dialogs/useDialogsListData';
// import { getDialogsListPositions } from '../../../api/dialogs';
import DialogsListView from './DialogsListView';

const DialogsListContent = () => {
  const {
    loadMore,
    mapItemIdToIndex,
    mapIndexToItemId,
    itemCount,
    hasContent,
    error,
    loading,
  } = useDialogsListData();

  // const dispatch = useDispatch();

  useEffect(() => {
    if (error) {
      /**
       * Throw error to nearest Error Boundary
       */
      throw error;
    }
  }, [error]);

  // useEffect(() => {
  //   const refreshInterval = setInterval(() => {
  //     dispatch(getDialogsListPositions(() => {}));
  //   }, 60000);

  //   return () => clearInterval(refreshInterval);
  // }, [dispatch]);

  return (
    <DialogsListView
      loadMore={loadMore}
      mapItemIdToIndex={mapItemIdToIndex}
      mapIndexToItemId={mapIndexToItemId}
      itemCount={itemCount}
      hasContent={hasContent}
      loading={loading}
    />
  );
};

export default DialogsListContent;
