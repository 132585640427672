import * as R from 'ramda';
import { createSelector } from 'reselect';

export const RECIEVE_CHAT_BOTS = 'RECIEVE_CHAT_BOTS';
export const RECIEVE_CHAT_BOT = 'RECIEVE_CHAT_BOT';
export const UPDATE_CHAT_BOT = 'UPDATE_CHAT_BOT';
export const DELETE_CHAT_BOT = 'DELETE_CHAT_BOT';
export const DELETE_CHAT_BOT_DRAFT = 'DELETE_CHAT_BOT_DRAFT';

const chatBotFactory = (record) => ({ [record._id]: record });

const chatBotsReducer = (state = {}, action = { type: '' }) => {
  switch (action.type) {
    case RECIEVE_CHAT_BOTS: {
      return R.mergeAll(R.map(chatBotFactory, action.payload.chatBots));
    }
    case RECIEVE_CHAT_BOT: {
      return { ...state, ...action.payload.chatBot };
    }
    case UPDATE_CHAT_BOT: {
      const { mainMenuMsg, notUnderstoodMsg, menu, name } = action.payload;
      return {
        ...state,
        ...(mainMenuMsg ? { mainMenuMsg } : {}),
        ...(notUnderstoodMsg ? { notUnderstoodMsg } : {}),
        ...(menu ? { menu } : {}),
        ...(name ? { name } : {}),
      };
    }
    case DELETE_CHAT_BOT: {
      return { ...state, isDeleted: true };
    }
    default: {
      return state;
    }
  }
};

export const recieveChatBots = (chatBots) => ({
  type: RECIEVE_CHAT_BOTS,
  payload: { chatBots },
});

export const recieveChatBot = (chatBot) => ({
  type: RECIEVE_CHAT_BOT,
  payload: { chatBot },
});

export const renewChatBot = ({
  id,
  mainMenuMsg,
  notUnderstoodMsg,
  menu,
  name,
}) => ({
  type: UPDATE_CHAT_BOT,
  payload: {
    id,
    mainMenuMsg,
    notUnderstoodMsg,
    menu,
    name,
  },
});

export const removeChatBot = (id) => ({
  type: DELETE_CHAT_BOT,
  payload: {
    id,
  },
});

export const deleteChatBotDraft = () => ({
  type: DELETE_CHAT_BOT_DRAFT,
});

const reducer = (state = {}, action = { type: '' }) => {
  switch (action.type) {
    case RECIEVE_CHAT_BOTS: {
      return R.mergeRight(state, chatBotsReducer(state, action));
    }
    case RECIEVE_CHAT_BOT: {
      return R.assoc(
        action.payload.chatBot._id,
        chatBotsReducer(state[action.payload.chatBot._id], action),
      )(state);
    }
    case UPDATE_CHAT_BOT:
    case DELETE_CHAT_BOT: {
      return R.assoc(
        action.payload.id,
        chatBotsReducer(state[action.payload.id], action),
      )(state);
    }
    case DELETE_CHAT_BOT_DRAFT: {
      return R.dissoc('new')(state);
    }
    default: {
      return state;
    }
  }
};

export default reducer;

export const chatBotsSelector = (state) => state.chatBots;

export const chatBotsArraySelector = createSelector(
  chatBotsSelector,
  (chatBots) => Object.values(chatBots),
);

export const chatBotsArraySelectorIsActive = createSelector(
  chatBotsSelector,
  (chatBots) => R.filter((_) => !_.isDeleted, Object.values(chatBots)),
);

export const chatBotIdSelector = (state, ownProps) => {
  const { params: { id } = {} } = ownProps.match;
  return id || ownProps.id || ownProps._id;
};

export const chatBotSelector = createSelector(
  chatBotsSelector,
  chatBotIdSelector,
  (chatBots, id) => R.propOr({ _id: 'new' }, id, chatBots),
);
