import * as R from 'ramda';
import { createSelector } from 'reselect';

export const RECEIVE_INVITES = 'RECEIVE_INVITES';
export const RECEIVE_INVITE = 'RECEIVE_INVITE';

function invitesFactory(invitedUser, single = false) {
  const copiedUser = { ...invitedUser };
  const { draft } = copiedUser;

  const finishedUser = {
    _id: copiedUser._id,
    email: copiedUser.email,
    name: draft.name ? draft.name : null,
    admin: draft.admin,
    accountId: draft.account,
  };

  return {
    ...(single ? finishedUser : { [finishedUser._id]: finishedUser }),
  };
}

function invitesReducer(state = {}, action = { type: '' }) {
  switch (action.type) {
    case RECEIVE_INVITES: {
      return R.mergeAll(R.map(invitesFactory, action.payload.invites));
    }
    case RECEIVE_INVITE: {
      return invitesFactory(action.payload.invite, true);
    }
    default: {
      return state;
    }
  }
}

export const receiveInvites = (invites) => ({
  type: RECEIVE_INVITES,
  payload: { invites },
});

export const receiveInvite = (invite) => ({
  type: RECEIVE_INVITE,
  payload: { invite },
});

export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case RECEIVE_INVITES: {
      return R.mergeRight(state, invitesReducer(state, action));
    }
    case RECEIVE_INVITE: {
      return R.assoc(
        action.payload.invite._id,
        invitesReducer(state[action.payload.invite._id], action),
      )(state);
    }
    default:
      return state;
  }
}

export const invitesSelector = (state) => R.propOr({}, 'invites', state);

export const invitesArraySelector = createSelector(invitesSelector, (invites) =>
  R.values(invites),
);
