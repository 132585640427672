/* eslint-disable no-underscore-dangle  */
import * as R from 'ramda';
import { createSelector } from 'reselect';

export const RECIEVE_TELEGRAM_ACCOUNTS = 'RECIEVE_TELEGRAM_ACCOUNTS';
export const RECIEVE_TELEGRAM_ACCOUNT = 'RECIEVE_TELEGRAM_ACCOUNT';
export const DELETE_TELEGRAM_ACCOUNT = 'DELETE_TELEGRAM_ACCOUNT';
export const UPDATE_TELEGRAM_ACCOUNT = 'UPDATE_TELEGRAM_ACCOUNT';
export const ENABLE_TELEGRAM_ACCOUNT = 'ENABLE_TELEGRAM_ACCOUNT';
export const DISABLE_TELEGRAM_ACCOUNT = 'DISABLE_TELEGRAM_ACCOUNT';
export const SET_TELEGRAM_CH_NAME = 'SET_TELEGRAM_CH_NAME';
export const SET_BOT_TOKEN = 'SET_BOT_TOKEN';
export const SET_TELEGRAM_CH_MATCH = 'SET_TELEGRAM_CH_MATCH';
export const DELETE_TELEGRAM_ACCOUNT_DRAFT = 'DELETE_TELEGRAM_ACCOUNT_DRAFT';

function telegramAccountFactory(account) {
  return { [account._id]: account };
}

function telegramAccountsReducer(state = {}, action = { type: '' }) {
  switch (action.type) {
    case RECIEVE_TELEGRAM_ACCOUNTS: {
      return R.mergeAll(
        R.map(telegramAccountFactory, action.payload.telegramAccounts),
      );
    }
    case RECIEVE_TELEGRAM_ACCOUNT: {
      return { ...state, ...action.payload.telegramAccount };
    }
    case UPDATE_TELEGRAM_ACCOUNT: {
      return {
        ...state,
        ...(action.payload.botToken
          ? { botToken: action.payload.botToken }
          : {}),
        ...(action.payload.name ? { name: action.payload.name } : {}),
        ...(action.payload.match ? { match: action.payload.match } : {}),
      };
    }
    case DISABLE_TELEGRAM_ACCOUNT: {
      return { ...state, isEnabled: false };
    }
    case ENABLE_TELEGRAM_ACCOUNT: {
      return { ...state, isEnabled: true };
    }
    case SET_TELEGRAM_CH_NAME: {
      return { ...state, name: action.payload.name };
    }
    case SET_BOT_TOKEN: {
      return { ...state, botToken: action.payload.botToken };
    }
    case SET_TELEGRAM_CH_MATCH: {
      const { email, telegramEmail } = action.payload;
      return {
        ...state,
        match: R.mergeRight(state.match || {}, { [email]: telegramEmail }),
      };
    }
    case DELETE_TELEGRAM_ACCOUNT: {
      return { ...state, isDeleted: true };
    }
    default: {
      return state;
    }
  }
}

export const recieveTelegramAccounts = (telegramAccounts) => ({
  type: RECIEVE_TELEGRAM_ACCOUNTS,
  payload: { telegramAccounts },
});

export const recieveTelegramAccount = (telegramAccount) => ({
  type: RECIEVE_TELEGRAM_ACCOUNT,
  payload: { telegramAccount },
});

export const renewTelegramAccount = ({
  telegramAccountId,
  botToken,
  name,
  match,
}) => ({
  type: UPDATE_TELEGRAM_ACCOUNT,
  payload: { telegramAccountId, botToken, name, match },
});

export const removeTelegramAccount = (_id) => ({
  type: DELETE_TELEGRAM_ACCOUNT,
  payload: { _id },
});

export const enableTelegramAccount = (telegramAccountId) => ({
  type: ENABLE_TELEGRAM_ACCOUNT,
  payload: { telegramAccountId },
});

export const disableTelegramAccount = (telegramAccountId) => ({
  type: DISABLE_TELEGRAM_ACCOUNT,
  payload: { telegramAccountId },
});

export const setName = (_id, name) => ({
  type: SET_TELEGRAM_CH_NAME,
  payload: { _id, name },
});

export const setBotToken = (_id, botToken) => ({
  type: SET_BOT_TOKEN,
  payload: { _id, botToken },
});

export const setMatch = (_id, telegramEmail, email) => ({
  type: SET_TELEGRAM_CH_MATCH,
  payload: { _id, telegramEmail, email },
});

export const deleteTelegramAccountDraft = () => ({
  type: DELETE_TELEGRAM_ACCOUNT_DRAFT,
});

export default function reducer(state = {}, action = { type: '' }) {
  switch (action.type) {
    case RECIEVE_TELEGRAM_ACCOUNTS: {
      return R.mergeRight(state, telegramAccountsReducer(state, action));
    }
    case RECIEVE_TELEGRAM_ACCOUNT: {
      return R.assoc(
        action.payload.telegramAccount._id,
        telegramAccountsReducer(
          state[action.payload.telegramAccount._id],
          action,
        ),
      )(state);
    }
    case ENABLE_TELEGRAM_ACCOUNT:
    case DISABLE_TELEGRAM_ACCOUNT:
    case UPDATE_TELEGRAM_ACCOUNT: {
      return R.assoc(
        action.payload.telegramAccountId,
        telegramAccountsReducer(
          state[action.payload.telegramAccountId],
          action,
        ),
      )(state);
    }
    case SET_TELEGRAM_CH_NAME:
    case SET_BOT_TOKEN:
    case SET_TELEGRAM_CH_MATCH:
    case DELETE_TELEGRAM_ACCOUNT: {
      return R.assoc(
        action.payload._id,
        telegramAccountsReducer(state[action.payload._id], action),
      )(state);
    }
    case DELETE_TELEGRAM_ACCOUNT_DRAFT: {
      return R.dissoc('new')(state);
    }
    default: {
      return state;
    }
  }
}

export const telegramAccountsSelector = (state) => state.telegramAccounts;

export const telegramAccountsArraySelector = createSelector(
  telegramAccountsSelector,
  (telegramAccounts) =>
    R.filter(
      (_) => !_.isDeleted && _._id && _._id !== 'new',
      R.values(telegramAccounts),
    ),
);

export const deletedTelegramAccountsArraySelector = createSelector(
  telegramAccountsSelector,
  (telegramAccounts) =>
    R.filter((_) => _.isDeleted, R.values(telegramAccounts)),
);

export const telegramAccountIdSelector = (state, ownProps) => {
  const { params: { channelId } = {} } = ownProps.match;
  return channelId || ownProps.id || ownProps._id;
};

export const telegramAccountSelector = createSelector(
  telegramAccountsSelector,
  telegramAccountIdSelector,
  (accounts, id) => R.propOr({}, id, accounts),
);
