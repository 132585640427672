import * as R from 'ramda';
import { createSelector } from 'reselect';

export const GET_CLIENTS = 'GET_CLIENTS';
export const GET_CLIENT = 'GET_CLIENT';
export const DELETE_CLIENT = 'DELETE_CLIENT';
export const DELETE_CLIENT_DRAFT = 'DELETE_CLIENT_DRAFT';
export const SET_CLIENT_NAME = 'SET_CLIENT_NAME';
export const SET_CLIENT_DESCRIPTION = 'SET_CLIENT_DESCRIPTION';
export const CLEAR_CLIENTS_STATE = 'CLEAR_CLIENTS_STATE';
export const REMOVE_CLIENT_SEGMENT_FROM_CLIENTS =
  'REMOVE_CLIENT_SEGMENT_FROM_CLIENTS';

function clientFactory(client) {
  return { [client._id]: client };
}

function clientReducer(state = {}, action = { type: '' }) {
  switch (action.type) {
    case GET_CLIENTS: {
      return R.mergeAll(R.map(clientFactory, action.payload.clients));
    }
    case GET_CLIENT: {
      return { ...state, ...action.payload.client };
    }
    case SET_CLIENT_NAME: {
      return { ...state, name: action.payload.name };
    }
    case SET_CLIENT_DESCRIPTION: {
      return { ...state, description: action.payload.description };
    }
    case REMOVE_CLIENT_SEGMENT_FROM_CLIENTS: {
      const newState = { ...state };
      const updatedState = Object.entries(newState).reduce(
        (acc, [clientId, clientRecordState]) => ({
          ...acc,
          [clientId]: {
            ...clientRecordState,
            segments: (clientRecordState.segments || []).filter(
              (sId) => sId !== action.payload.segmentId,
            ),
          },
        }),

        {},
      );
      return updatedState;
    }
    default: {
      return state;
    }
  }
}

export const getClientItem = (client) => ({
  type: GET_CLIENT,
  payload: { client },
});

export const getClientsItems = (clients) => ({
  type: GET_CLIENTS,
  payload: { clients },
});

export const removeClientItem = (clientId) => ({
  type: DELETE_CLIENT,
  payload: { clientId },
});

export const removeClientDraft = () => ({
  type: DELETE_CLIENT_DRAFT,
  payload: {},
});

export const setClientName = (_id, name) => ({
  type: SET_CLIENT_NAME,
  payload: { _id, name },
});

export const setClientDescription = (_id, description) => ({
  type: SET_CLIENT_DESCRIPTION,
  payload: { _id, description },
});

export const clearClientsState = () => ({
  type: CLEAR_CLIENTS_STATE,
  payload: {},
});

export const removeClientSegmentFromClients = (segmentId) => ({
  type: REMOVE_CLIENT_SEGMENT_FROM_CLIENTS,
  payload: { segmentId },
});

export default function reducer(state = {}, action = { type: '' }) {
  switch (action.type) {
    case GET_CLIENTS: {
      return R.mergeRight(state, clientReducer(state, action));
    }
    case GET_CLIENT: {
      return R.assoc(
        action.payload.client._id,
        clientReducer(state[action.payload.client._id], action),
      )(state);
    }
    case DELETE_CLIENT: {
      return R.dissoc(action.payload.clientId, state);
    }
    case DELETE_CLIENT_DRAFT: {
      return R.dissoc('new', state);
    }
    case SET_CLIENT_NAME:
    case SET_CLIENT_DESCRIPTION: {
      return R.assoc(
        action.payload._id,
        clientReducer(state[action.payload._id], action),
      )(state);
    }
    case CLEAR_CLIENTS_STATE: {
      return {};
    }
    case REMOVE_CLIENT_SEGMENT_FROM_CLIENTS: {
      return R.mergeRight(state, clientReducer(state, action));
    }
    default: {
      return state;
    }
  }
}

export const clientsSelector = (state) => R.propOr({}, 'clients', state);

export const clientIdSelector = (state, ownProps) =>
  R.pathOr(null, ['match', 'params', 'clientId'], ownProps) ||
  R.propOr(null, 'clientId', ownProps) ||
  R.pathOr(null, ['client', '_id'], ownProps);

export const clientsArraySelector = createSelector(clientsSelector, (clients) =>
  R.filter((_) => _.status !== 'deleted', R.values(clients)),
);

export const clientSelector = createSelector(
  clientIdSelector,
  clientsSelector,
  (clientId, clients) => R.propOr({}, clientId, clients),
);
