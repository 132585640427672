import { useRef, useEffect } from 'react';

/**
 * @description Hook that enables access to a previous state of passed value.
 * @param {any} value
 * @returns {any} Saved previous value.
 */
const usePrevious = (value) => {
  const valueRef = useRef();

  useEffect(() => {
    valueRef.current = value;
  });

  return valueRef.current;
};

export default usePrevious;
