/**
 * @description Function that throttles provided fn call by provided ms.
 * @param {function} fn
 * @param {number} ms
 */
function throttle(fn, ms) {
  let isThrottled = false;
  let savedThis;
  let savedArgs;

  return function wrapper(...args) {
    if (isThrottled) {
      savedThis = this;
      savedArgs = args;
      return;
    }

    fn.apply(this, args);

    isThrottled = true;

    setTimeout(() => {
      isThrottled = false;

      if (savedArgs) {
        wrapper.apply(savedThis, savedArgs);
        savedThis = undefined;
        savedArgs = undefined;
      }
    }, ms);
  };
}

export default throttle;
