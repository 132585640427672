// TODO -> use this module in auth reducer
const subscribeToEvents = (jwt) => {
  if (window.webSocketsConn && window.webSocketsConn.connected) {
    window.webSocketsConn.emit('JWT', jwt);
  }
};

const unsubscribeFromEvents = () => {
  if (window.webSocketsConn && window.webSocketsConn.connected) {
    window.webSocketsConn.emit('JWT', null);
  }
};

export { subscribeToEvents, unsubscribeFromEvents };
