import { createSelector } from 'reselect';

const getPossibleUsersSelector = (state) => Object.values(state.users);

const getPossibleClientsSelector = (state) => Object.values(state.clients);

const getOurDialog = (state, props) => props.dialog;

const dialogAuthorSelector = createSelector(
  [getPossibleUsersSelector, getPossibleClientsSelector, getOurDialog],
  (users, clients, dialog = {}) => {
    const { authorId, contactsId } = dialog;
    if (authorId) {
      if (users.find((u) => u._id === authorId)) {
        return users.find((u) => u._id === authorId);
      }
      if (clients.find((c) => c._id === authorId)) {
        return clients.find((c) => c._id === authorId);
      }
    }
    if (
      authorId &&
      clients.find(
        (client) =>
          client.contacts &&
          client.contacts.find((cData) => cData.id === authorId),
      )
    ) {
      return clients.find(
        (client) =>
          client.contacts &&
          client.contacts.find((cData) => cData.id === authorId),
      );
    }
    if (
      contactsId &&
      clients.find(
        (client) =>
          client.contacts &&
          client.contacts.find((cData) => cData.id === contactsId),
      )
    ) {
      return clients.find(
        (client) =>
          client.contacts &&
          client.contacts.find((cData) => cData.id === contactsId),
      );
    }
    return null;
  },
);

export default dialogAuthorSelector;
