import { receiveMessage } from '../../reducers/messages';
import { getContactEvents, getContacts } from '../contacts';

// TODO get rid of it prefer socket
const onReceiveMessage = (message) => async (dispatch, getState) => {
  const { _id: messageId, contactsId } = message;

  const reduxState = getState();
  if (contactsId) {
    const { clients = {} } = reduxState;

    const cachedClient = Object.values(clients).find(
      (c) =>
        c.contacts && c.contacts.find((contact) => contact.id === contactsId),
    );

    if (!cachedClient) {
      // TODO get rid of it prefer socket
      dispatch(getContacts('', [contactsId]));
    }
    if (window.location.pathname.match(/clients\/contacts/i)) {
      // TODO get rid of it prefer socket
      dispatch(getContactEvents(null, contactsId));
    }
  }

  dispatch(receiveMessage(messageId, message));
};

export default onReceiveMessage;
