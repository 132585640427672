import { createSelector } from 'reselect';

import { dialogsStatusCountersSelector } from '../reducers/statusCounters';
import { dialogsListModeSelector } from '../reducers/dialogsListMode';

const currentListModeDialogsStatusCountersSelector = createSelector(
  [dialogsStatusCountersSelector, dialogsListModeSelector],
  (statusCounters, listMode) => statusCounters[listMode] || {},
);

export default currentListModeDialogsStatusCountersSelector;
