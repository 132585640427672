import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { TabBar } from 'antd-mobile';
import {
  MailFill,
  MessageOutline,
  MailOutline,
  MessageFill,
} from 'antd-mobile-icons';
import { useTranslation } from 'react-i18next';
import UserOutline from './icon/UserOutline';
import UserSolid from './icon/UserSolid';
import chartBarOutline from './icon/chartBarOutline';
import chartBarSolid from './icon/chartBarSolid';
import './layout.css';

const Tab = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const setRouteActive = (value) => {
    navigate(value);
  };

  const list = [
    {
      key: '/profile',
      title: t('Layout.profile'),
      icon: UserOutline,
      activeIcon: UserSolid,
    },
    {
      key: '/customers',
      title: t('Layout.customers'),
      icon: <MailOutline />,
      activeIcon: <MailFill />,
    },
    {
      key: '/dashbord',
      title: t('Layout.dashbord'),
      icon: chartBarOutline,
      activeIcon: chartBarSolid,
    },
    {
      key: '/conversations',
      title: t('Layout.conversations'),
      icon: <MessageOutline />,
      activeIcon: <MessageFill />,
    },
  ];

  return (
    <TabBar activeKey={pathname} onChange={(value) => setRouteActive(value)}>
      {list.map((item) => (
        <TabBar.Item
          key={item.key}
          icon={pathname === item.key ? item.activeIcon : item.icon}
          title={item.title}
        />
      ))}
    </TabBar>
  );
};

const Layout = ({ children }) => {
  return (
    <div className="layout">
      <div className="layout-content">{children}</div>
      <div className="layout-tab">
        <Tab />
      </div>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
};

export default Layout;
