import * as R from 'ramda';
import { createSelector } from 'reselect';
import { getDialogsArraySelector } from '../reducers/dialogs';
import { clientsArraySelector } from '../reducers/clients';
import {
  getDialogsFilters,
  getDialogsChannelFilters,
  SHOW_NEW,
  SHOW_OLD,
  SHOW_RECENT_UPDATED,
  SHOW_BOT_ASSIGNED,
  SHOW_MINE,
  SHOW_ALL,
  SHOW_NOT_ASSIGNED_RESP,
} from '../reducers/dialogsFilters';
import checkFilterSearchCond from '../utils/checkFilterSearchCond';

const getUserIdSelector = (state) =>
  R.pathOr({}, ['auth', 'user', '_id'], state);

const sortByParams = (params) => {
  const sortingFunction = R.sortBy(
    R.path(
      params === SHOW_NEW || params === SHOW_OLD
        ? ['metadata', 'lastMessageDate']
        : ['updatedAt'],
    ),
  );
  if (params === SHOW_NEW || params === SHOW_RECENT_UPDATED) {
    return R.compose(R.reverse, sortingFunction);
  }
  return sortingFunction;
};

const statusesMap = {
  SHOW_OPENED: 'opened',
  SHOW_CLOSED: 'closed',
  SHOW_WAITING: 'waiting',
};

const filterByParams = (params) => {
  const {
    search,
    filterStatus,
    filterChannels = [],
    userId,
    clients,
    responsibleUsers = [],
  } = params;

  if (filterStatus === SHOW_MINE) {
    return R.filter(
      (_) =>
        checkFilterSearchCond({
          search,
          entityType: 'dialog',
          item: _,
          clients,
        }) &&
        R.prop('responsibleId', _) === userId &&
        R.prop('status', _) !== 'closed' &&
        (filterChannels.includes(R.prop('channelId', _)) ||
          R.prop('responsibleId', _) === userId),
    );
  }

  if (filterStatus === SHOW_BOT_ASSIGNED) {
    return R.filter(
      (_) =>
        checkFilterSearchCond({
          search,
          entityType: 'dialog',
          item: _,
          clients,
        }) &&
        filterChannels.includes(R.prop('channelId', _)) &&
        _.systemLabels &&
        _.systemLabels.includes('bot'),
    );
  }

  if (filterStatus === SHOW_ALL) {
    if (responsibleUsers.length) {
      return R.filter(
        (_) =>
          checkFilterSearchCond({
            search,
            entityType: 'dialog',
            item: _,
            clients,
          }) &&
          filterChannels.includes(R.prop('channelId', _)) &&
          responsibleUsers.includes(R.prop('responsibleId', _)),
      );
    }
    return R.filter(
      (_) =>
        checkFilterSearchCond({
          search,
          entityType: 'dialog',
          item: _,
          clients,
        }) && filterChannels.includes(R.prop('channelId', _)),
    );
  }

  if (filterStatus === SHOW_NOT_ASSIGNED_RESP) {
    return R.filter(
      (_) =>
        checkFilterSearchCond({
          search,
          entityType: 'dialog',
          item: _,
          clients,
        }) &&
        !R.prop('responsibleId', _) &&
        R.prop('status', _) === 'opened' &&
        filterChannels.includes(R.prop('channelId', _)),
    );
  }

  if (responsibleUsers.length) {
    return R.filter(
      (_) =>
        checkFilterSearchCond({
          search,
          entityType: 'dialog',
          item: _,
          clients,
        }) &&
        R.prop('status', _) === statusesMap[filterStatus] &&
        filterChannels.includes(R.prop('channelId', _)) &&
        responsibleUsers.includes(R.prop('responsibleId', _)),
    );
  }

  return R.filter(
    (_) =>
      checkFilterSearchCond({
        search,
        entityType: 'dialog',
        item: _,
        clients,
      }) &&
      R.prop('status', _) === statusesMap[filterStatus] &&
      filterChannels.includes(R.prop('channelId', _)),
  );
};

const getFilteredDialogs = createSelector(
  getDialogsArraySelector,
  getDialogsFilters,
  getDialogsChannelFilters,
  getUserIdSelector,
  clientsArraySelector,
  (dialogs, filter, filterChannels, userId, clients) => {
    const { search = '', filterStatus, sortBy, responsibleUsers } = filter;
    const sortFn = sortByParams(sortBy);
    const filterFn = filterByParams({
      search,
      filterStatus,
      filterChannels,
      userId,
      clients,
      responsibleUsers,
    });
    const filteredDialogs = sortFn(filterFn(dialogs));

    return filteredDialogs;
  },
);

export default getFilteredDialogs;
