import React from 'react';
import { NoticeBar } from 'antd-mobile';
import { useTranslation } from 'react-i18next';

const DialogOpenedChannelDeleted = () => {
  const { t } = useTranslation();
  return (
    <NoticeBar
      icon={null}
      wrap
      color="error"
      content={t('DialogOpenedChannelDeleted.content')}
    />
  );
};

export default DialogOpenedChannelDeleted;
