import { recieveMailAccounts } from '../../reducers/mail-accounts';
import fetchJWT from '../../utils/fetchJWT';
import { logout } from '../../actions';
import backend from '../../configs/backend';

const readMailAccounts = () => async (dispatch) => {
  const request = new Request(backend.applyBaseUrl('/api/mail/accounts/read'), {
    method: 'GET',
    cache: 'default',
    mode: 'cors',
  });

  fetchJWT(request)
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
      if (res.status === 401) {
        dispatch(logout());
      }
      return [];
    })
    .then((res) => {
      if (res.length) {
        dispatch(recieveMailAccounts(res));
      }
    })
    .catch((err) => console.error(err));
};

export default readMailAccounts;
