import * as R from 'ramda';

export const RECEIVE_TEMPLATES = 'RECEIVE_TEMPLATES';
export const RECEIVE_TEMPLATE = 'RECEIVE_TEMPLATE';
export const UPDATE_TEMPLATE = 'UPDATE_TEMPLATE';
export const DELETE_TEMPLATE = 'DELETE_TEMPLATE';

const Template = {};

function templateFactory(template) {
  return { [template._id]: template };
}

function templatesReducer(state = Template, action = {}) {
  switch (action.type) {
    case RECEIVE_TEMPLATE: {
      return { ...state, ...action.payload.template };
    }
    case RECEIVE_TEMPLATES: {
      return R.mergeAll(R.map(templateFactory, action.payload.templates));
    }
    case UPDATE_TEMPLATE: {
      return {
        ...state,
        ...(action.payload.type ? { type: action.payload.type } : {}),
        ...(action.payload.text !== undefined
          ? { text: action.payload.text }
          : {}),
        ...(action.payload.title !== undefined
          ? { title: action.payload.title }
          : {}),
      };
    }
    default: {
      return state;
    }
  }
}

export const receiveTemplates = (templates) => ({
  type: RECEIVE_TEMPLATES,
  payload: { templates },
});

export const renewTemplate = ({ templateId, type, text, title }) => ({
  type: UPDATE_TEMPLATE,
  payload: { templateId, type, text, title },
});

export const removeTemplate = (templateId) => ({
  type: DELETE_TEMPLATE,
  payload: { templateId },
});

export const receiveTemplate = (template) => ({
  type: RECEIVE_TEMPLATE,
  payload: { template },
});

export default function reducer(state = {}, action = { type: '' }) {
  switch (action.type) {
    case RECEIVE_TEMPLATE: {
      return R.assoc(
        action.payload.template._id,
        templatesReducer(state[action.payload.template._id], action),
      )(state);
    }
    case RECEIVE_TEMPLATES: {
      return R.mergeRight(state, templatesReducer(state, action));
    }
    case UPDATE_TEMPLATE: {
      return R.assoc(
        action.payload.templateId,
        templatesReducer(state[action.payload.templateId], action),
      )(state);
    }
    case DELETE_TEMPLATE: {
      return R.dissoc(action.payload.templateId)(state);
    }
    default: {
      return state;
    }
  }
}
