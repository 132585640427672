import * as R from 'ramda';

export const GET_CONFIGURATION_DATA = 'GET_CONFIGURATION_DATA';

function configReducer(state = {}, action = { type: '' }) {
  switch (action.type) {
    case GET_CONFIGURATION_DATA: {
      return R.mergeAll(action.payload.data);
    }
    default: {
      return state;
    }
  }
}

export const getConfigDataItems = (data) => ({
  type: GET_CONFIGURATION_DATA,
  payload: {
    data,
  },
});

export default function reducer(state = {}, action = { type: '' }) {
  switch (action.type) {
    case GET_CONFIGURATION_DATA: {
      return R.mergeRight(state, configReducer(state, action));
    }
    default: {
      return state;
    }
  }
}
