import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';

import useCachedInfiniteLoader from '../../hooks/useCachedInfiniteLoader';
import debounce from '../../utils/debounce';

function withInfiniteLoading(VirtualListComponent) {
  function InfiniteLoadingVirtualList(props, ref) {
    const {
      mapIndexToItemId,
      onItemsRendered,
      loadMoreFn,
      loadMoreDebounceTimeout,
      ...otherProps
    } = props;

    const { loadMore } = useCachedInfiniteLoader(loadMoreFn);

    const debouncedLoadMore = useRef(
      debounce(loadMore, loadMoreDebounceTimeout),
    );

    const handleItemsRendered = useCallback(
      ({
        overscanStartIndex,
        overscanStopIndex,
        visibleStartIndex,
        visibleStopIndex,
      }) => {
        if (onItemsRendered) {
          onItemsRendered({
            visibleStartIndex,
            visibleStopIndex,
          });
        }

        const itemIdsToLoad = [];

        for (
          let itemIndex = overscanStartIndex;
          itemIndex <= overscanStopIndex;
          itemIndex += 1
        ) {
          const itemId = mapIndexToItemId(itemIndex);

          itemIdsToLoad.push(itemId);
        }

        debouncedLoadMore.current(itemIdsToLoad);
      },
      [debouncedLoadMore, mapIndexToItemId, onItemsRendered],
    );

    return (
      <VirtualListComponent
        // TODO -> deal with object fields copy on each render
        {...otherProps}
        ref={ref}
        onItemsRendered={handleItemsRendered}
        mapIndexToItemId={mapIndexToItemId}
      />
    );
  }

  InfiniteLoadingVirtualList.displayName = `withInfiniteLoading(${
    VirtualListComponent.displayName || VirtualListComponent.name
  })`;

  InfiniteLoadingVirtualList.propTypes = {
    mapIndexToItemId: PropTypes.func.isRequired,
    onItemsRendered: PropTypes.func,
    loadMoreFn: PropTypes.func.isRequired,
    loadMoreDebounceTimeout: PropTypes.number,
  };

  return React.forwardRef(InfiniteLoadingVirtualList);
}

withInfiniteLoading.propTypes = {
  VirtualListComponent: PropTypes.node,
};

export default withInfiniteLoading;
